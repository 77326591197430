import { Document } from '@contentful/rich-text-types';
import { gql } from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Dimension: number;
  HexColor: string;
  JSON: Document;
  Quality: number;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordion) */
export type Accordion = Entry &
  _Node & {
    __typename?: 'Accordion';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<Scalars['String']>;
    itemsCollection?: Maybe<AccordionItemsCollection>;
    linkedFrom?: Maybe<AccordionLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordion) */
export type AccordionBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordion) */
export type AccordionHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordion) */
export type AccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordion) */
export type AccordionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordion) */
export type AccordionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AccordionCollection = {
  __typename?: 'AccordionCollection';
  items: Array<Maybe<Accordion>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AccordionFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccordionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AccordionFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  items?: InputMaybe<CfAccordionItemNestedFilter>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

/** This section holds the list items for the accordion component [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordionItem) */
export type AccordionItem = Entry &
  _Node & {
    __typename?: 'AccordionItem';
    _id: Scalars['ID'];
    content?: Maybe<AccordionItemContent>;
    contentBackgroundColor?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<AccordionItemLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** This section holds the list items for the accordion component [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordionItem) */
export type AccordionItemContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This section holds the list items for the accordion component [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordionItem) */
export type AccordionItemContentBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This section holds the list items for the accordion component [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordionItem) */
export type AccordionItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This section holds the list items for the accordion component [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordionItem) */
export type AccordionItemNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This section holds the list items for the accordion component [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/accordionItem) */
export type AccordionItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AccordionItemCollection = {
  __typename?: 'AccordionItemCollection';
  items: Array<Maybe<AccordionItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AccordionItemContent = {
  __typename?: 'AccordionItemContent';
  json: Scalars['JSON'];
  links: AccordionItemContentLinks;
};

export type AccordionItemContentAssets = {
  __typename?: 'AccordionItemContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AccordionItemContentEntries = {
  __typename?: 'AccordionItemContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AccordionItemContentLinks = {
  __typename?: 'AccordionItemContentLinks';
  assets: AccordionItemContentAssets;
  entries: AccordionItemContentEntries;
  resources: AccordionItemContentResources;
};

export type AccordionItemContentResources = {
  __typename?: 'AccordionItemContentResources';
  block: Array<AccordionItemContentResourcesBlock>;
  hyperlink: Array<AccordionItemContentResourcesHyperlink>;
  inline: Array<AccordionItemContentResourcesInline>;
};

export type AccordionItemContentResourcesBlock = ResourceLink & {
  __typename?: 'AccordionItemContentResourcesBlock';
  sys: ResourceSys;
};

export type AccordionItemContentResourcesHyperlink = ResourceLink & {
  __typename?: 'AccordionItemContentResourcesHyperlink';
  sys: ResourceSys;
};

export type AccordionItemContentResourcesInline = ResourceLink & {
  __typename?: 'AccordionItemContentResourcesInline';
  sys: ResourceSys;
};

export type AccordionItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccordionItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AccordionItemFilter>>>;
  contentBackgroundColor?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  contentBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentBackgroundColor_not?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AccordionItemLinkingCollections = {
  __typename?: 'AccordionItemLinkingCollections';
  accordionCollection?: Maybe<AccordionCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type AccordionItemLinkingCollectionsAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<AccordionItemLinkingCollectionsAccordionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AccordionItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AccordionItemLinkingCollectionsAccordionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AccordionItemOrder {
  ContentBackgroundColorAsc = 'contentBackgroundColor_ASC',
  ContentBackgroundColorDesc = 'contentBackgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AccordionItemsCollection = {
  __typename?: 'AccordionItemsCollection';
  items: Array<Maybe<AccordionItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AccordionItemsCollectionOrder {
  ContentBackgroundColorAsc = 'contentBackgroundColor_ASC',
  ContentBackgroundColorDesc = 'contentBackgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AccordionLinkingCollections = {
  __typename?: 'AccordionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type AccordionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AccordionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBanner = Entry &
  _Node & {
    __typename?: 'AppBanner';
    _id: Scalars['ID'];
    appStoreIconLink?: Maybe<Link>;
    bannerDescription?: Maybe<Scalars['String']>;
    bannerTitle?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<AppBannerLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    playStoreIconLink?: Maybe<Link>;
    sys: Sys;
  };

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBannerAppStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBannerBannerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBannerBannerTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBannerImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1922%3A37692&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBanner) */
export type AppBannerPlayStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

export type AppBannerCollection = {
  __typename?: 'AppBannerCollection';
  items: Array<Maybe<AppBanner>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AppBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<AppBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AppBannerFilter>>>;
  appStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreIconLink_exists?: InputMaybe<Scalars['Boolean']>;
  bannerDescription?: InputMaybe<Scalars['String']>;
  bannerDescription_contains?: InputMaybe<Scalars['String']>;
  bannerDescription_exists?: InputMaybe<Scalars['Boolean']>;
  bannerDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bannerDescription_not?: InputMaybe<Scalars['String']>;
  bannerDescription_not_contains?: InputMaybe<Scalars['String']>;
  bannerDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bannerTitle?: InputMaybe<Scalars['String']>;
  bannerTitle_contains?: InputMaybe<Scalars['String']>;
  bannerTitle_exists?: InputMaybe<Scalars['Boolean']>;
  bannerTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bannerTitle_not?: InputMaybe<Scalars['String']>;
  bannerTitle_not_contains?: InputMaybe<Scalars['String']>;
  bannerTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  playStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  playStoreIconLink_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type AppBannerLinkingCollections = {
  __typename?: 'AppBannerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type AppBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AppBannerOrder {
  BannerDescriptionAsc = 'bannerDescription_ASC',
  BannerDescriptionDesc = 'bannerDescription_DESC',
  BannerTitleAsc = 'bannerTitle_ASC',
  BannerTitleDesc = 'bannerTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSection = Entry &
  _Node & {
    __typename?: 'AppBannerSection';
    _id: Scalars['ID'];
    appStoreIconLink?: Maybe<Link>;
    content?: Maybe<AppBannerSectionContent>;
    contentfulMetadata: ContentfulMetadata;
    imageCollection?: Maybe<AssetCollection>;
    imageMobile?: Maybe<Asset>;
    linkDesktop?: Maybe<Link>;
    linkedFrom?: Maybe<AppBannerSectionLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    playStoreIconLink?: Maybe<Link>;
    sys: Sys;
  };

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionAppStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionLinkDesktopArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/appBannerSection) */
export type AppBannerSectionPlayStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

export type AppBannerSectionCollection = {
  __typename?: 'AppBannerSectionCollection';
  items: Array<Maybe<AppBannerSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AppBannerSectionContent = {
  __typename?: 'AppBannerSectionContent';
  json: Scalars['JSON'];
  links: AppBannerSectionContentLinks;
};

export type AppBannerSectionContentAssets = {
  __typename?: 'AppBannerSectionContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AppBannerSectionContentEntries = {
  __typename?: 'AppBannerSectionContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AppBannerSectionContentLinks = {
  __typename?: 'AppBannerSectionContentLinks';
  assets: AppBannerSectionContentAssets;
  entries: AppBannerSectionContentEntries;
  resources: AppBannerSectionContentResources;
};

export type AppBannerSectionContentResources = {
  __typename?: 'AppBannerSectionContentResources';
  block: Array<AppBannerSectionContentResourcesBlock>;
  hyperlink: Array<AppBannerSectionContentResourcesHyperlink>;
  inline: Array<AppBannerSectionContentResourcesInline>;
};

export type AppBannerSectionContentResourcesBlock = ResourceLink & {
  __typename?: 'AppBannerSectionContentResourcesBlock';
  sys: ResourceSys;
};

export type AppBannerSectionContentResourcesHyperlink = ResourceLink & {
  __typename?: 'AppBannerSectionContentResourcesHyperlink';
  sys: ResourceSys;
};

export type AppBannerSectionContentResourcesInline = ResourceLink & {
  __typename?: 'AppBannerSectionContentResourcesInline';
  sys: ResourceSys;
};

export type AppBannerSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<AppBannerSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AppBannerSectionFilter>>>;
  appStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreIconLink_exists?: InputMaybe<Scalars['Boolean']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  imageCollection_exists?: InputMaybe<Scalars['Boolean']>;
  imageMobile_exists?: InputMaybe<Scalars['Boolean']>;
  linkDesktop?: InputMaybe<CfLinkNestedFilter>;
  linkDesktop_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  playStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  playStoreIconLink_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type AppBannerSectionLinkingCollections = {
  __typename?: 'AppBannerSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type AppBannerSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AppBannerSectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
  transform?: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']>;
  contentType_contains?: InputMaybe<Scalars['String']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not?: InputMaybe<Scalars['String']>;
  contentType_not_contains?: InputMaybe<Scalars['String']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName?: InputMaybe<Scalars['String']>;
  fileName_contains?: InputMaybe<Scalars['String']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not?: InputMaybe<Scalars['String']>;
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size?: InputMaybe<Scalars['Int']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_gt?: InputMaybe<Scalars['Int']>;
  size_gte?: InputMaybe<Scalars['Int']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt?: InputMaybe<Scalars['Int']>;
  size_lte?: InputMaybe<Scalars['Int']>;
  size_not?: InputMaybe<Scalars['Int']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  appBannerCollection?: Maybe<AppBannerCollection>;
  appBannerSectionCollection?: Maybe<AppBannerSectionCollection>;
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  cardCollection?: Maybe<CardCollection>;
  cardsCollection?: Maybe<CardsCollection>;
  comparisonTableRowCollection?: Maybe<ComparisonTableRowCollection>;
  containedBannerCollection?: Maybe<ContainedBannerCollection>;
  entryCollection?: Maybe<EntryCollection>;
  exchangeCalculatorCollection?: Maybe<ExchangeCalculatorCollection>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
  faqTopicCollection?: Maybe<FaqTopicCollection>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
  footerCollection?: Maybe<FooterCollection>;
  fullWidthBannerCollection?: Maybe<FullWidthBannerCollection>;
  heroSlotCollection?: Maybe<HeroSlotCollection>;
  heroTextVariationCollection?: Maybe<HeroTextVariationCollection>;
  iconWithTextCollection?: Maybe<IconWithTextCollection>;
  imageCollection?: Maybe<ImageCollection>;
  imageGridItemCollection?: Maybe<ImageGridItemCollection>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
  leadershipTeamEntryCollection?: Maybe<LeadershipTeamEntryCollection>;
  linkCollection?: Maybe<LinkCollection>;
  nestedAccordionItemsCollection?: Maybe<NestedAccordionItemsCollection>;
  pageCollection?: Maybe<PageCollection>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  personCollection?: Maybe<PersonCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  reviewsCardCollection?: Maybe<ReviewsCardCollection>;
  reviewsSectionCollection?: Maybe<ReviewsSectionCollection>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
  stepCollection?: Maybe<StepCollection>;
  textCardCollection?: Maybe<TextCardCollection>;
  textMeTheAppModuleCollection?: Maybe<TextMeTheAppModuleCollection>;
  textSectionCollection?: Maybe<TextSectionCollection>;
  textSectionV2Collection?: Maybe<TextSectionV2Collection>;
  textWithColorCollection?: Maybe<TextWithColorCollection>;
  videoCollection?: Maybe<VideoCollection>;
  whyChooseCollection?: Maybe<WhyChooseCollection>;
  whyChooseReasonCollection?: Maybe<WhyChooseReasonCollection>;
};

export type AssetLinkingCollectionsAppBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsAppBannerSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsComparisonTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsContainedBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsExchangeCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFaqTopicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFullWidthBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsHeroSlotCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsHeroTextVariationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsIconWithTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsImageGridItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsLeadershipTeamEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsNestedAccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsReviewsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsReviewsSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsSeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsTextCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsTextMeTheAppModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsTextSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsTextSectionV2CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsTextWithColorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsWhyChooseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsWhyChooseReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTop = Entry &
  _Node & {
    __typename?: 'BetterWayTop';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    appStoreIconLink?: Maybe<Link>;
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    bottomWaveImage?: Maybe<Asset>;
    calculatorPage?: Maybe<CalculatorPage>;
    contentfulMetadata: ContentfulMetadata;
    foregroundImage?: Maybe<Asset>;
    heading?: Maybe<BetterWayTopHeading>;
    headingColor?: Maybe<Scalars['String']>;
    hideDescriptionOnMobile?: Maybe<Scalars['Boolean']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<BetterWayTopLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    playStoreIconLink?: Maybe<Link>;
    qrCode?: Maybe<MobileAppQrCode>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    rightModule?: Maybe<BetterWayTopRightModule>;
    secondBackgroundColor?: Maybe<Scalars['String']>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    subheading?: Maybe<BetterWayTopSubheading>;
    sys: Sys;
    trustpilotLink?: Maybe<Link>;
    trustpilotLogo?: Maybe<Asset>;
    trustpilotMessage?: Maybe<Scalars['String']>;
    trustpilotWidget?: Maybe<TrustpilotWidget>;
  };

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopAppStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopBottomWaveImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopCalculatorPageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CalculatorPageFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopForegroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopHeadingColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopHideDescriptionOnMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopPlayStoreIconLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopQrCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<MobileAppQrCodeFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopRightModuleArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopSecondBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopSubheadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopTrustpilotLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopTrustpilotLogoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopTrustpilotMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Section "A better way to send money" on top of Home Page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/betterWayTop) */
export type BetterWayTopTrustpilotWidgetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<TrustpilotWidgetFilter>;
};

export type BetterWayTopCollection = {
  __typename?: 'BetterWayTopCollection';
  items: Array<Maybe<BetterWayTop>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BetterWayTopFilter = {
  AND?: InputMaybe<Array<InputMaybe<BetterWayTopFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BetterWayTopFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  appStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreIconLink_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  bottomWaveImage_exists?: InputMaybe<Scalars['Boolean']>;
  calculatorPage?: InputMaybe<CfCalculatorPageNestedFilter>;
  calculatorPage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  foregroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  headingColor?: InputMaybe<Scalars['String']>;
  headingColor_contains?: InputMaybe<Scalars['String']>;
  headingColor_exists?: InputMaybe<Scalars['Boolean']>;
  headingColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headingColor_not?: InputMaybe<Scalars['String']>;
  headingColor_not_contains?: InputMaybe<Scalars['String']>;
  headingColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  hideDescriptionOnMobile?: InputMaybe<Scalars['Boolean']>;
  hideDescriptionOnMobile_exists?: InputMaybe<Scalars['Boolean']>;
  hideDescriptionOnMobile_not?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  playStoreIconLink?: InputMaybe<CfLinkNestedFilter>;
  playStoreIconLink_exists?: InputMaybe<Scalars['Boolean']>;
  qrCode?: InputMaybe<CfMobileAppQrCodeNestedFilter>;
  qrCode_exists?: InputMaybe<Scalars['Boolean']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  rightModule_exists?: InputMaybe<Scalars['Boolean']>;
  secondBackgroundColor?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  secondBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondBackgroundColor_not?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  subheading_contains?: InputMaybe<Scalars['String']>;
  subheading_exists?: InputMaybe<Scalars['Boolean']>;
  subheading_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
  trustpilotLink?: InputMaybe<CfLinkNestedFilter>;
  trustpilotLink_exists?: InputMaybe<Scalars['Boolean']>;
  trustpilotLogo_exists?: InputMaybe<Scalars['Boolean']>;
  trustpilotMessage?: InputMaybe<Scalars['String']>;
  trustpilotMessage_contains?: InputMaybe<Scalars['String']>;
  trustpilotMessage_exists?: InputMaybe<Scalars['Boolean']>;
  trustpilotMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  trustpilotMessage_not?: InputMaybe<Scalars['String']>;
  trustpilotMessage_not_contains?: InputMaybe<Scalars['String']>;
  trustpilotMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  trustpilotWidget?: InputMaybe<CfTrustpilotWidgetNestedFilter>;
  trustpilotWidget_exists?: InputMaybe<Scalars['Boolean']>;
};

export type BetterWayTopHeading = {
  __typename?: 'BetterWayTopHeading';
  json: Scalars['JSON'];
  links: BetterWayTopHeadingLinks;
};

export type BetterWayTopHeadingAssets = {
  __typename?: 'BetterWayTopHeadingAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BetterWayTopHeadingEntries = {
  __typename?: 'BetterWayTopHeadingEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BetterWayTopHeadingLinks = {
  __typename?: 'BetterWayTopHeadingLinks';
  assets: BetterWayTopHeadingAssets;
  entries: BetterWayTopHeadingEntries;
  resources: BetterWayTopHeadingResources;
};

export type BetterWayTopHeadingResources = {
  __typename?: 'BetterWayTopHeadingResources';
  block: Array<BetterWayTopHeadingResourcesBlock>;
  hyperlink: Array<BetterWayTopHeadingResourcesHyperlink>;
  inline: Array<BetterWayTopHeadingResourcesInline>;
};

export type BetterWayTopHeadingResourcesBlock = ResourceLink & {
  __typename?: 'BetterWayTopHeadingResourcesBlock';
  sys: ResourceSys;
};

export type BetterWayTopHeadingResourcesHyperlink = ResourceLink & {
  __typename?: 'BetterWayTopHeadingResourcesHyperlink';
  sys: ResourceSys;
};

export type BetterWayTopHeadingResourcesInline = ResourceLink & {
  __typename?: 'BetterWayTopHeadingResourcesInline';
  sys: ResourceSys;
};

export type BetterWayTopLinkingCollections = {
  __typename?: 'BetterWayTopLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type BetterWayTopLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<BetterWayTopLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type BetterWayTopLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BetterWayTopLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum BetterWayTopOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export type BetterWayTopRightModule =
  | Calculator
  | ExchangeCalculator
  | Image
  | Search
  | SendToCountryModule;

export type BetterWayTopSubheading = {
  __typename?: 'BetterWayTopSubheading';
  json: Scalars['JSON'];
  links: BetterWayTopSubheadingLinks;
};

export type BetterWayTopSubheadingAssets = {
  __typename?: 'BetterWayTopSubheadingAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BetterWayTopSubheadingEntries = {
  __typename?: 'BetterWayTopSubheadingEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BetterWayTopSubheadingLinks = {
  __typename?: 'BetterWayTopSubheadingLinks';
  assets: BetterWayTopSubheadingAssets;
  entries: BetterWayTopSubheadingEntries;
  resources: BetterWayTopSubheadingResources;
};

export type BetterWayTopSubheadingResources = {
  __typename?: 'BetterWayTopSubheadingResources';
  block: Array<BetterWayTopSubheadingResourcesBlock>;
  hyperlink: Array<BetterWayTopSubheadingResourcesHyperlink>;
  inline: Array<BetterWayTopSubheadingResourcesInline>;
};

export type BetterWayTopSubheadingResourcesBlock = ResourceLink & {
  __typename?: 'BetterWayTopSubheadingResourcesBlock';
  sys: ResourceSys;
};

export type BetterWayTopSubheadingResourcesHyperlink = ResourceLink & {
  __typename?: 'BetterWayTopSubheadingResourcesHyperlink';
  sys: ResourceSys;
};

export type BetterWayTopSubheadingResourcesInline = ResourceLink & {
  __typename?: 'BetterWayTopSubheadingResourcesInline';
  sys: ResourceSys;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyText = Entry &
  _Node & {
    __typename?: 'BodyText';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    content?: Maybe<BodyTextContent>;
    contentAlign?: Maybe<Scalars['String']>;
    contentTwo?: Maybe<BodyTextContentTwo>;
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<BodyTextHeading>;
    headingAlign?: Maybe<Scalars['String']>;
    isPageSection?: Maybe<Scalars['Boolean']>;
    linkedFrom?: Maybe<BodyTextLinkingCollections>;
    listIndent?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    paragraphIndent?: Maybe<Scalars['Int']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextContentAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextContentTwoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextHeadingAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextIsPageSectionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextListIndentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextParagraphIndentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Heading with optional underline and content as rich text editors in up to 2 columns [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/bodyText) */
export type BodyTextSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BodyTextCollection = {
  __typename?: 'BodyTextCollection';
  items: Array<Maybe<BodyText>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BodyTextContent = {
  __typename?: 'BodyTextContent';
  json: Scalars['JSON'];
  links: BodyTextContentLinks;
};

export type BodyTextContentAssets = {
  __typename?: 'BodyTextContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BodyTextContentEntries = {
  __typename?: 'BodyTextContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BodyTextContentLinks = {
  __typename?: 'BodyTextContentLinks';
  assets: BodyTextContentAssets;
  entries: BodyTextContentEntries;
  resources: BodyTextContentResources;
};

export type BodyTextContentResources = {
  __typename?: 'BodyTextContentResources';
  block: Array<BodyTextContentResourcesBlock>;
  hyperlink: Array<BodyTextContentResourcesHyperlink>;
  inline: Array<BodyTextContentResourcesInline>;
};

export type BodyTextContentResourcesBlock = ResourceLink & {
  __typename?: 'BodyTextContentResourcesBlock';
  sys: ResourceSys;
};

export type BodyTextContentResourcesHyperlink = ResourceLink & {
  __typename?: 'BodyTextContentResourcesHyperlink';
  sys: ResourceSys;
};

export type BodyTextContentResourcesInline = ResourceLink & {
  __typename?: 'BodyTextContentResourcesInline';
  sys: ResourceSys;
};

export type BodyTextContentTwo = {
  __typename?: 'BodyTextContentTwo';
  json: Scalars['JSON'];
  links: BodyTextContentTwoLinks;
};

export type BodyTextContentTwoAssets = {
  __typename?: 'BodyTextContentTwoAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BodyTextContentTwoEntries = {
  __typename?: 'BodyTextContentTwoEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BodyTextContentTwoLinks = {
  __typename?: 'BodyTextContentTwoLinks';
  assets: BodyTextContentTwoAssets;
  entries: BodyTextContentTwoEntries;
  resources: BodyTextContentTwoResources;
};

export type BodyTextContentTwoResources = {
  __typename?: 'BodyTextContentTwoResources';
  block: Array<BodyTextContentTwoResourcesBlock>;
  hyperlink: Array<BodyTextContentTwoResourcesHyperlink>;
  inline: Array<BodyTextContentTwoResourcesInline>;
};

export type BodyTextContentTwoResourcesBlock = ResourceLink & {
  __typename?: 'BodyTextContentTwoResourcesBlock';
  sys: ResourceSys;
};

export type BodyTextContentTwoResourcesHyperlink = ResourceLink & {
  __typename?: 'BodyTextContentTwoResourcesHyperlink';
  sys: ResourceSys;
};

export type BodyTextContentTwoResourcesInline = ResourceLink & {
  __typename?: 'BodyTextContentTwoResourcesInline';
  sys: ResourceSys;
};

export type BodyTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<BodyTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BodyTextFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentAlign?: InputMaybe<Scalars['String']>;
  contentAlign_contains?: InputMaybe<Scalars['String']>;
  contentAlign_exists?: InputMaybe<Scalars['Boolean']>;
  contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentAlign_not?: InputMaybe<Scalars['String']>;
  contentAlign_not_contains?: InputMaybe<Scalars['String']>;
  contentAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentTwo_contains?: InputMaybe<Scalars['String']>;
  contentTwo_exists?: InputMaybe<Scalars['Boolean']>;
  contentTwo_not_contains?: InputMaybe<Scalars['String']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headingAlign?: InputMaybe<Scalars['String']>;
  headingAlign_contains?: InputMaybe<Scalars['String']>;
  headingAlign_exists?: InputMaybe<Scalars['Boolean']>;
  headingAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headingAlign_not?: InputMaybe<Scalars['String']>;
  headingAlign_not_contains?: InputMaybe<Scalars['String']>;
  headingAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  isPageSection?: InputMaybe<Scalars['Boolean']>;
  isPageSection_exists?: InputMaybe<Scalars['Boolean']>;
  isPageSection_not?: InputMaybe<Scalars['Boolean']>;
  listIndent?: InputMaybe<Scalars['Int']>;
  listIndent_exists?: InputMaybe<Scalars['Boolean']>;
  listIndent_gt?: InputMaybe<Scalars['Int']>;
  listIndent_gte?: InputMaybe<Scalars['Int']>;
  listIndent_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  listIndent_lt?: InputMaybe<Scalars['Int']>;
  listIndent_lte?: InputMaybe<Scalars['Int']>;
  listIndent_not?: InputMaybe<Scalars['Int']>;
  listIndent_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paragraphIndent?: InputMaybe<Scalars['Int']>;
  paragraphIndent_exists?: InputMaybe<Scalars['Boolean']>;
  paragraphIndent_gt?: InputMaybe<Scalars['Int']>;
  paragraphIndent_gte?: InputMaybe<Scalars['Int']>;
  paragraphIndent_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  paragraphIndent_lt?: InputMaybe<Scalars['Int']>;
  paragraphIndent_lte?: InputMaybe<Scalars['Int']>;
  paragraphIndent_not?: InputMaybe<Scalars['Int']>;
  paragraphIndent_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type BodyTextHeading = {
  __typename?: 'BodyTextHeading';
  json: Scalars['JSON'];
  links: BodyTextHeadingLinks;
};

export type BodyTextHeadingAssets = {
  __typename?: 'BodyTextHeadingAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BodyTextHeadingEntries = {
  __typename?: 'BodyTextHeadingEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BodyTextHeadingLinks = {
  __typename?: 'BodyTextHeadingLinks';
  assets: BodyTextHeadingAssets;
  entries: BodyTextHeadingEntries;
  resources: BodyTextHeadingResources;
};

export type BodyTextHeadingResources = {
  __typename?: 'BodyTextHeadingResources';
  block: Array<BodyTextHeadingResourcesBlock>;
  hyperlink: Array<BodyTextHeadingResourcesHyperlink>;
  inline: Array<BodyTextHeadingResourcesInline>;
};

export type BodyTextHeadingResourcesBlock = ResourceLink & {
  __typename?: 'BodyTextHeadingResourcesBlock';
  sys: ResourceSys;
};

export type BodyTextHeadingResourcesHyperlink = ResourceLink & {
  __typename?: 'BodyTextHeadingResourcesHyperlink';
  sys: ResourceSys;
};

export type BodyTextHeadingResourcesInline = ResourceLink & {
  __typename?: 'BodyTextHeadingResourcesInline';
  sys: ResourceSys;
};

export type BodyTextLinkingCollections = {
  __typename?: 'BodyTextLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type BodyTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type BodyTextLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<BodyTextLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BodyTextLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum BodyTextOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ContentAlignAsc = 'contentAlign_ASC',
  ContentAlignDesc = 'contentAlign_DESC',
  HeadingAlignAsc = 'headingAlign_ASC',
  HeadingAlignDesc = 'headingAlign_DESC',
  IsPageSectionAsc = 'isPageSection_ASC',
  IsPageSectionDesc = 'isPageSection_DESC',
  ListIndentAsc = 'listIndent_ASC',
  ListIndentDesc = 'listIndent_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ParagraphIndentAsc = 'paragraphIndent_ASC',
  ParagraphIndentDesc = 'paragraphIndent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type Calculator = Entry &
  _Node & {
    __typename?: 'Calculator';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    countriesSearchPlaceholder?: Maybe<Scalars['String']>;
    estimatedFeeLabel?: Maybe<Scalars['String']>;
    exchangeRateLabel?: Maybe<Scalars['String']>;
    exchangeRatePromoLabel?: Maybe<Scalars['String']>;
    feeLabel?: Maybe<Scalars['String']>;
    generalErrorMessage?: Maybe<Scalars['String']>;
    incorrectCountrySelectedErrorMessage?: Maybe<Scalars['String']>;
    instantDiscountLabel?: Maybe<Scalars['String']>;
    isLite?: Maybe<Scalars['Boolean']>;
    linkedFrom?: Maybe<CalculatorLinkingCollections>;
    loginLink?: Maybe<Link>;
    name?: Maybe<Scalars['String']>;
    partnerSelectErrorMessage?: Maybe<Scalars['String']>;
    partnersLabel?: Maybe<Scalars['String']>;
    partnersListDefaultValue?: Maybe<Scalars['String']>;
    payoutMethodsLabel?: Maybe<Scalars['String']>;
    payoutMethodsListCollection?: Maybe<CalculatorPayoutMethodsListCollection>;
    phoneInputInfoMoreInfoLabel?: Maybe<Scalars['String']>;
    phoneInputInfoPopupText?: Maybe<Scalars['String']>;
    phoneNumberInputErrorMessage?: Maybe<Scalars['String']>;
    phoneNumberInvalidErrorMessage?: Maybe<Scalars['String']>;
    phoneValidatorLabel?: Maybe<Scalars['String']>;
    phoneValidatorPlaceholder?: Maybe<Scalars['String']>;
    promotionalTermsLink?: Maybe<Link>;
    receiveLabel?: Maybe<Scalars['String']>;
    receiverPayoutMethodUnavailableMessage?: Maybe<Scalars['String']>;
    recipientGetsLabel?: Maybe<Scalars['String']>;
    sendLabel?: Maybe<Scalars['String']>;
    senderPayoutMethodUnavailableMessage?: Maybe<Scalars['String']>;
    signUpLink?: Maybe<Link>;
    sys: Sys;
    topUpAmountsLabel?: Maybe<Scalars['String']>;
    topUpAmountsPlaceholder?: Maybe<Scalars['String']>;
    topUpAmountsSelectErrorMessage?: Maybe<Scalars['String']>;
    topUpAmountsSelectRetrievalErrorMessage?: Maybe<Scalars['String']>;
    topUpAmountsTitle?: Maybe<Scalars['String']>;
    totalToPayLabel?: Maybe<Scalars['String']>;
    transferTimeLabel?: Maybe<Scalars['String']>;
    unavailableCorridorErrorMessage?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorCountriesSearchPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorEstimatedFeeLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorExchangeRateLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorExchangeRatePromoLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorFeeLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorGeneralErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorIncorrectCountrySelectedErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorInstantDiscountLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorIsLiteArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorLoginLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPartnerSelectErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPartnersLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPartnersListDefaultValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPayoutMethodsLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPayoutMethodsListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CalculatorPayoutMethodsListCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayoutMethodFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPhoneInputInfoMoreInfoLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPhoneInputInfoPopupTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPhoneNumberInputErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPhoneNumberInvalidErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPhoneValidatorLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPhoneValidatorPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorPromotionalTermsLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorReceiveLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorReceiverPayoutMethodUnavailableMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorRecipientGetsLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorSendLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorSenderPayoutMethodUnavailableMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorSignUpLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorTopUpAmountsLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorTopUpAmountsPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorTopUpAmountsSelectErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorTopUpAmountsSelectRetrievalErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorTopUpAmountsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorTotalToPayLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorTransferTimeLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculator) */
export type CalculatorUnavailableCorridorErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinks = Entry &
  _Node & {
    __typename?: 'CalculatorAppLeadingLinks';
    _id: Scalars['ID'];
    androidLink?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    desktopLink?: Maybe<Link>;
    iosLink?: Maybe<Link>;
    label?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<CalculatorAppLeadingLinksLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksAndroidLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksDesktopLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksIosLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Links used for the lite calculator "Get started CTA on app" CTA  [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorAppLeadingLinks) */
export type CalculatorAppLeadingLinksNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CalculatorAppLeadingLinksCollection = {
  __typename?: 'CalculatorAppLeadingLinksCollection';
  items: Array<Maybe<CalculatorAppLeadingLinks>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CalculatorAppLeadingLinksFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorAppLeadingLinksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorAppLeadingLinksFilter>>>;
  androidLink?: InputMaybe<CfLinkNestedFilter>;
  androidLink_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  desktopLink?: InputMaybe<CfLinkNestedFilter>;
  desktopLink_exists?: InputMaybe<Scalars['Boolean']>;
  iosLink?: InputMaybe<CfLinkNestedFilter>;
  iosLink_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CalculatorAppLeadingLinksLinkingCollections = {
  __typename?: 'CalculatorAppLeadingLinksLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CalculatorAppLeadingLinksLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CalculatorAppLeadingLinksOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CalculatorCollection = {
  __typename?: 'CalculatorCollection';
  items: Array<Maybe<Calculator>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CalculatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesSearchPlaceholder?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_contains?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  countriesSearchPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  countriesSearchPlaceholder_not?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  estimatedFeeLabel?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_contains?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  estimatedFeeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  estimatedFeeLabel_not?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_not_contains?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']>;
  exchangeRateLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRatePromoLabel?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_contains?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_exists?: InputMaybe<Scalars['Boolean']>;
  exchangeRatePromoLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRatePromoLabel_not?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_not_contains?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  feeLabel?: InputMaybe<Scalars['String']>;
  feeLabel_contains?: InputMaybe<Scalars['String']>;
  feeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  feeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  feeLabel_not?: InputMaybe<Scalars['String']>;
  feeLabel_not_contains?: InputMaybe<Scalars['String']>;
  feeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generalErrorMessage?: InputMaybe<Scalars['String']>;
  generalErrorMessage_contains?: InputMaybe<Scalars['String']>;
  generalErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  generalErrorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generalErrorMessage_not?: InputMaybe<Scalars['String']>;
  generalErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  generalErrorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  incorrectCountrySelectedErrorMessage?: InputMaybe<Scalars['String']>;
  incorrectCountrySelectedErrorMessage_contains?: InputMaybe<Scalars['String']>;
  incorrectCountrySelectedErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  incorrectCountrySelectedErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  incorrectCountrySelectedErrorMessage_not?: InputMaybe<Scalars['String']>;
  incorrectCountrySelectedErrorMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  incorrectCountrySelectedErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  instantDiscountLabel?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_contains?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_exists?: InputMaybe<Scalars['Boolean']>;
  instantDiscountLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  instantDiscountLabel_not?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_not_contains?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  isLite?: InputMaybe<Scalars['Boolean']>;
  isLite_exists?: InputMaybe<Scalars['Boolean']>;
  isLite_not?: InputMaybe<Scalars['Boolean']>;
  loginLink?: InputMaybe<CfLinkNestedFilter>;
  loginLink_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partnerSelectErrorMessage?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_contains?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  partnerSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  partnerSelectErrorMessage_not?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  partnersLabel?: InputMaybe<Scalars['String']>;
  partnersLabel_contains?: InputMaybe<Scalars['String']>;
  partnersLabel_exists?: InputMaybe<Scalars['Boolean']>;
  partnersLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partnersLabel_not?: InputMaybe<Scalars['String']>;
  partnersLabel_not_contains?: InputMaybe<Scalars['String']>;
  partnersLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partnersListDefaultValue?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_contains?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_exists?: InputMaybe<Scalars['Boolean']>;
  partnersListDefaultValue_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  partnersListDefaultValue_not?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_not_contains?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  payoutMethodsLabel?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_contains?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_exists?: InputMaybe<Scalars['Boolean']>;
  payoutMethodsLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodsLabel_not?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_not_contains?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodsList?: InputMaybe<CfPayoutMethodNestedFilter>;
  payoutMethodsListCollection_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputInfoMoreInfoLabel?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputInfoMoreInfoLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneInputInfoMoreInfoLabel_not?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_not_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneInputInfoPopupText?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputInfoPopupText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneInputInfoPopupText_not?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_not_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInputErrorMessage?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  phoneNumberInputErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInputErrorMessage_not?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInvalidErrorMessage?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  phoneNumberInvalidErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInvalidErrorMessage_not?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneValidatorLabel?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_exists?: InputMaybe<Scalars['Boolean']>;
  phoneValidatorLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneValidatorLabel_not?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_not_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneValidatorPlaceholder?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  phoneValidatorPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneValidatorPlaceholder_not?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  promotionalTermsLink?: InputMaybe<CfLinkNestedFilter>;
  promotionalTermsLink_exists?: InputMaybe<Scalars['Boolean']>;
  receiveLabel?: InputMaybe<Scalars['String']>;
  receiveLabel_contains?: InputMaybe<Scalars['String']>;
  receiveLabel_exists?: InputMaybe<Scalars['Boolean']>;
  receiveLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveLabel_not?: InputMaybe<Scalars['String']>;
  receiveLabel_not_contains?: InputMaybe<Scalars['String']>;
  receiveLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiverPayoutMethodUnavailableMessage?: InputMaybe<Scalars['String']>;
  receiverPayoutMethodUnavailableMessage_contains?: InputMaybe<
    Scalars['String']
  >;
  receiverPayoutMethodUnavailableMessage_exists?: InputMaybe<
    Scalars['Boolean']
  >;
  receiverPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiverPayoutMethodUnavailableMessage_not?: InputMaybe<Scalars['String']>;
  receiverPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  receiverPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  recipientGetsLabel?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_contains?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_exists?: InputMaybe<Scalars['Boolean']>;
  recipientGetsLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  recipientGetsLabel_not?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_not_contains?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendLabel?: InputMaybe<Scalars['String']>;
  sendLabel_contains?: InputMaybe<Scalars['String']>;
  sendLabel_exists?: InputMaybe<Scalars['Boolean']>;
  sendLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendLabel_not?: InputMaybe<Scalars['String']>;
  sendLabel_not_contains?: InputMaybe<Scalars['String']>;
  sendLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  senderPayoutMethodUnavailableMessage?: InputMaybe<Scalars['String']>;
  senderPayoutMethodUnavailableMessage_contains?: InputMaybe<Scalars['String']>;
  senderPayoutMethodUnavailableMessage_exists?: InputMaybe<Scalars['Boolean']>;
  senderPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  senderPayoutMethodUnavailableMessage_not?: InputMaybe<Scalars['String']>;
  senderPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  senderPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  signUpLink?: InputMaybe<CfLinkNestedFilter>;
  signUpLink_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  topUpAmountsLabel?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsLabel_not?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsPlaceholder?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsPlaceholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsPlaceholder_not?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectErrorMessage?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectErrorMessage_not?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectRetrievalErrorMessage_contains?: InputMaybe<
    Scalars['String']
  >;
  topUpAmountsSelectRetrievalErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']
  >;
  topUpAmountsSelectRetrievalErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage_not?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectRetrievalErrorMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  topUpAmountsSelectRetrievalErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsTitle?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsTitle_not?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  totalToPayLabel?: InputMaybe<Scalars['String']>;
  totalToPayLabel_contains?: InputMaybe<Scalars['String']>;
  totalToPayLabel_exists?: InputMaybe<Scalars['Boolean']>;
  totalToPayLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  totalToPayLabel_not?: InputMaybe<Scalars['String']>;
  totalToPayLabel_not_contains?: InputMaybe<Scalars['String']>;
  totalToPayLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeLabel?: InputMaybe<Scalars['String']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  transferTimeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeLabel_not?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unavailableCorridorErrorMessage?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_contains?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  unavailableCorridorErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  unavailableCorridorErrorMessage_not?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
};

export type CalculatorLinkingCollections = {
  __typename?: 'CalculatorLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CalculatorLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CalculatorLinkingCollectionsBetterWayTopCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CalculatorLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CalculatorLinkingCollectionsCalculatorPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CalculatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CalculatorLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum CalculatorLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CalculatorOrder {
  CountriesSearchPlaceholderAsc = 'countriesSearchPlaceholder_ASC',
  CountriesSearchPlaceholderDesc = 'countriesSearchPlaceholder_DESC',
  EstimatedFeeLabelAsc = 'estimatedFeeLabel_ASC',
  EstimatedFeeLabelDesc = 'estimatedFeeLabel_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  ExchangeRatePromoLabelAsc = 'exchangeRatePromoLabel_ASC',
  ExchangeRatePromoLabelDesc = 'exchangeRatePromoLabel_DESC',
  FeeLabelAsc = 'feeLabel_ASC',
  FeeLabelDesc = 'feeLabel_DESC',
  IncorrectCountrySelectedErrorMessageAsc = 'incorrectCountrySelectedErrorMessage_ASC',
  IncorrectCountrySelectedErrorMessageDesc = 'incorrectCountrySelectedErrorMessage_DESC',
  InstantDiscountLabelAsc = 'instantDiscountLabel_ASC',
  InstantDiscountLabelDesc = 'instantDiscountLabel_DESC',
  IsLiteAsc = 'isLite_ASC',
  IsLiteDesc = 'isLite_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerSelectErrorMessageAsc = 'partnerSelectErrorMessage_ASC',
  PartnerSelectErrorMessageDesc = 'partnerSelectErrorMessage_DESC',
  PartnersLabelAsc = 'partnersLabel_ASC',
  PartnersLabelDesc = 'partnersLabel_DESC',
  PartnersListDefaultValueAsc = 'partnersListDefaultValue_ASC',
  PartnersListDefaultValueDesc = 'partnersListDefaultValue_DESC',
  PayoutMethodsLabelAsc = 'payoutMethodsLabel_ASC',
  PayoutMethodsLabelDesc = 'payoutMethodsLabel_DESC',
  PhoneInputInfoMoreInfoLabelAsc = 'phoneInputInfoMoreInfoLabel_ASC',
  PhoneInputInfoMoreInfoLabelDesc = 'phoneInputInfoMoreInfoLabel_DESC',
  PhoneInputInfoPopupTextAsc = 'phoneInputInfoPopupText_ASC',
  PhoneInputInfoPopupTextDesc = 'phoneInputInfoPopupText_DESC',
  PhoneNumberInputErrorMessageAsc = 'phoneNumberInputErrorMessage_ASC',
  PhoneNumberInputErrorMessageDesc = 'phoneNumberInputErrorMessage_DESC',
  PhoneNumberInvalidErrorMessageAsc = 'phoneNumberInvalidErrorMessage_ASC',
  PhoneNumberInvalidErrorMessageDesc = 'phoneNumberInvalidErrorMessage_DESC',
  PhoneValidatorLabelAsc = 'phoneValidatorLabel_ASC',
  PhoneValidatorLabelDesc = 'phoneValidatorLabel_DESC',
  PhoneValidatorPlaceholderAsc = 'phoneValidatorPlaceholder_ASC',
  PhoneValidatorPlaceholderDesc = 'phoneValidatorPlaceholder_DESC',
  ReceiveLabelAsc = 'receiveLabel_ASC',
  ReceiveLabelDesc = 'receiveLabel_DESC',
  RecipientGetsLabelAsc = 'recipientGetsLabel_ASC',
  RecipientGetsLabelDesc = 'recipientGetsLabel_DESC',
  SendLabelAsc = 'sendLabel_ASC',
  SendLabelDesc = 'sendLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopUpAmountsLabelAsc = 'topUpAmountsLabel_ASC',
  TopUpAmountsLabelDesc = 'topUpAmountsLabel_DESC',
  TopUpAmountsPlaceholderAsc = 'topUpAmountsPlaceholder_ASC',
  TopUpAmountsPlaceholderDesc = 'topUpAmountsPlaceholder_DESC',
  TopUpAmountsSelectErrorMessageAsc = 'topUpAmountsSelectErrorMessage_ASC',
  TopUpAmountsSelectErrorMessageDesc = 'topUpAmountsSelectErrorMessage_DESC',
  TopUpAmountsSelectRetrievalErrorMessageAsc = 'topUpAmountsSelectRetrievalErrorMessage_ASC',
  TopUpAmountsSelectRetrievalErrorMessageDesc = 'topUpAmountsSelectRetrievalErrorMessage_DESC',
  TopUpAmountsTitleAsc = 'topUpAmountsTitle_ASC',
  TopUpAmountsTitleDesc = 'topUpAmountsTitle_DESC',
  TotalToPayLabelAsc = 'totalToPayLabel_ASC',
  TotalToPayLabelDesc = 'totalToPayLabel_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  UnavailableCorridorErrorMessageAsc = 'unavailableCorridorErrorMessage_ASC',
  UnavailableCorridorErrorMessageDesc = 'unavailableCorridorErrorMessage_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPage = Entry &
  _Node & {
    __typename?: 'CalculatorPage';
    _id: Scalars['ID'];
    analyticsPageName?: Maybe<Scalars['String']>;
    analyticsPageType?: Maybe<Scalars['String']>;
    buildLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
    calculator?: Maybe<Calculator>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    footer?: Maybe<LayoutFooter>;
    isTopPage?: Maybe<Scalars['Boolean']>;
    linkedFrom?: Maybe<CalculatorPageLinkingCollections>;
    meta?: Maybe<Scalars['JSON']>;
    modulesCollection?: Maybe<CalculatorPageModulesCollection>;
    name?: Maybe<Scalars['String']>;
    noIndex?: Maybe<Scalars['Boolean']>;
    pricingCalculator?: Maybe<PricingCalculator>;
    sitemap?: Maybe<Scalars['Boolean']>;
    sitemapTitle?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageAnalyticsPageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageAnalyticsPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageCalculatorArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CalculatorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageFooterArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageIsTopPageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageMetaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalculatorPageModulesFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPagePricingCalculatorArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PricingCalculatorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageSitemapArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageSitemapTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/calculatorPage) */
export type CalculatorPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CalculatorPageCollection = {
  __typename?: 'CalculatorPageCollection';
  items: Array<Maybe<CalculatorPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CalculatorPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorPageFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageName_not?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType?: InputMaybe<Scalars['String']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType_not?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']>;
  calculator?: InputMaybe<CfCalculatorNestedFilter>;
  calculator_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footer?: InputMaybe<CfLayoutFooterNestedFilter>;
  footer_exists?: InputMaybe<Scalars['Boolean']>;
  isTopPage?: InputMaybe<Scalars['Boolean']>;
  isTopPage_exists?: InputMaybe<Scalars['Boolean']>;
  isTopPage_not?: InputMaybe<Scalars['Boolean']>;
  meta_exists?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<CfmodulesMultiTypeNestedFilter>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
  pricingCalculator?: InputMaybe<CfPricingCalculatorNestedFilter>;
  pricingCalculator_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle?: InputMaybe<Scalars['String']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemap_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CalculatorPageLinkingCollections = {
  __typename?: 'CalculatorPageLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CalculatorPageLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CalculatorPageLinkingCollectionsBetterWayTopCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CalculatorPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CalculatorPageLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export type CalculatorPageModulesCollection = {
  __typename?: 'CalculatorPageModulesCollection';
  items: Array<Maybe<CalculatorPageModulesItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CalculatorPageModulesFilter = {
  AND?: InputMaybe<Array<InputMaybe<CalculatorPageModulesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CalculatorPageModulesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CalculatorPageModulesItem =
  | BetterWayTop
  | Correspondents
  | PromotionalBanner
  | PromotionalBannerDisclaimer;

export enum CalculatorPageOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CalculatorPayoutMethodsListCollection = {
  __typename?: 'CalculatorPayoutMethodsListCollection';
  items: Array<Maybe<PayoutMethod>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CalculatorPayoutMethodsListCollectionOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type Card = Entry &
  _Node & {
    __typename?: 'Card';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundImage?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    header?: Maybe<Scalars['String']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<CardLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    payoutMethodId?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/card) */
export type CardPayoutMethodIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CardCollection = {
  __typename?: 'CardCollection';
  items: Array<Maybe<Card>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CardFilter = {
  AND?: InputMaybe<Array<InputMaybe<CardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CardFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CardLinkingCollections = {
  __typename?: 'CardLinkingCollections';
  cardsCollection?: Maybe<CardsCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CardLinkingCollectionsCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CardLinkingCollectionsCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CardLinkingCollectionsCardsCollectionOrder {
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaymentTextAsc = 'paymentText_ASC',
  PaymentTextDesc = 'paymentText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CardOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type Cards = Entry &
  _Node & {
    __typename?: 'Cards';
    _id: Scalars['ID'];
    cardsCollection?: Maybe<CardsCardsCollection>;
    carouselText?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<CardsLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    paymentIcons?: Maybe<Asset>;
    paymentText?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CardsCardsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsCarouselTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsPaymentIconsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cards) */
export type CardsPaymentTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CardsCardsCollection = {
  __typename?: 'CardsCardsCollection';
  items: Array<Maybe<Card>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CardsCardsCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CardsCollection = {
  __typename?: 'CardsCollection';
  items: Array<Maybe<Cards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CardsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CardsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CardsFilter>>>;
  cards?: InputMaybe<CfCardNestedFilter>;
  cardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  carouselText?: InputMaybe<Scalars['String']>;
  carouselText_contains?: InputMaybe<Scalars['String']>;
  carouselText_exists?: InputMaybe<Scalars['Boolean']>;
  carouselText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  carouselText_not?: InputMaybe<Scalars['String']>;
  carouselText_not_contains?: InputMaybe<Scalars['String']>;
  carouselText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paymentIcons_exists?: InputMaybe<Scalars['Boolean']>;
  paymentText?: InputMaybe<Scalars['String']>;
  paymentText_contains?: InputMaybe<Scalars['String']>;
  paymentText_exists?: InputMaybe<Scalars['Boolean']>;
  paymentText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paymentText_not?: InputMaybe<Scalars['String']>;
  paymentText_not_contains?: InputMaybe<Scalars['String']>;
  paymentText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CardsLinkingCollections = {
  __typename?: 'CardsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CardsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CardsOrder {
  CarouselTextAsc = 'carouselText_ASC',
  CarouselTextDesc = 'carouselText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaymentTextAsc = 'paymentText_ASC',
  PaymentTextDesc = 'paymentText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrency = Entry &
  _Node & {
    __typename?: 'CexCurrency';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    corridorGridTitle?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    countryName?: Maybe<Scalars['String']>;
    currencyCode?: Maybe<Scalars['String']>;
    currencyName?: Maybe<Scalars['String']>;
    description?: Maybe<CexCurrencyDescription>;
    faqContent?: Maybe<CexCurrencyFaqContent>;
    linkedFrom?: Maybe<CexCurrencyLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    pageSlug?: Maybe<Scalars['String']>;
    sys: Sys;
    topCurrencyPairsCollection?: Maybe<CexCurrencyTopCurrencyPairsCollection>;
  };

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyCorridorGridTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyCountryCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyCountryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyCurrencyCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyCurrencyNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyFaqContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency information for CEX pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrency) */
export type CexCurrencyTopCurrencyPairsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CexCurrencyTopCurrencyPairsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

export type CexCurrencyCollection = {
  __typename?: 'CexCurrencyCollection';
  items: Array<Maybe<CexCurrency>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridor = Entry &
  _Node & {
    __typename?: 'CexCurrencyCorridor';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CexCurrencyCorridorLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    pageSlug?: Maybe<Scalars['String']>;
    receiveCurrency?: Maybe<CexCurrency>;
    sendCurrency?: Maybe<CexCurrency>;
    sys: Sys;
  };

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorReceiveCurrencyArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

/** Currency conversion corridors with exchange rate [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexCurrencyCorridor) */
export type CexCurrencyCorridorSendCurrencyArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

export type CexCurrencyCorridorCollection = {
  __typename?: 'CexCurrencyCorridorCollection';
  items: Array<Maybe<CexCurrencyCorridor>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CexCurrencyCorridorFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexCurrencyCorridorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexCurrencyCorridorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageSlug?: InputMaybe<Scalars['String']>;
  pageSlug_contains?: InputMaybe<Scalars['String']>;
  pageSlug_exists?: InputMaybe<Scalars['Boolean']>;
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageSlug_not?: InputMaybe<Scalars['String']>;
  pageSlug_not_contains?: InputMaybe<Scalars['String']>;
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCurrency?: InputMaybe<CfCexCurrencyNestedFilter>;
  receiveCurrency_exists?: InputMaybe<Scalars['Boolean']>;
  sendCurrency?: InputMaybe<CfCexCurrencyNestedFilter>;
  sendCurrency_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CexCurrencyCorridorLinkingCollections = {
  __typename?: 'CexCurrencyCorridorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CexCurrencyCorridorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CexCurrencyCorridorOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CexCurrencyDescription = {
  __typename?: 'CexCurrencyDescription';
  json: Scalars['JSON'];
  links: CexCurrencyDescriptionLinks;
};

export type CexCurrencyDescriptionAssets = {
  __typename?: 'CexCurrencyDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CexCurrencyDescriptionEntries = {
  __typename?: 'CexCurrencyDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CexCurrencyDescriptionLinks = {
  __typename?: 'CexCurrencyDescriptionLinks';
  assets: CexCurrencyDescriptionAssets;
  entries: CexCurrencyDescriptionEntries;
  resources: CexCurrencyDescriptionResources;
};

export type CexCurrencyDescriptionResources = {
  __typename?: 'CexCurrencyDescriptionResources';
  block: Array<CexCurrencyDescriptionResourcesBlock>;
  hyperlink: Array<CexCurrencyDescriptionResourcesHyperlink>;
  inline: Array<CexCurrencyDescriptionResourcesInline>;
};

export type CexCurrencyDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'CexCurrencyDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type CexCurrencyDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'CexCurrencyDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type CexCurrencyDescriptionResourcesInline = ResourceLink & {
  __typename?: 'CexCurrencyDescriptionResourcesInline';
  sys: ResourceSys;
};

export type CexCurrencyFaqContent = {
  __typename?: 'CexCurrencyFaqContent';
  json: Scalars['JSON'];
  links: CexCurrencyFaqContentLinks;
};

export type CexCurrencyFaqContentAssets = {
  __typename?: 'CexCurrencyFaqContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CexCurrencyFaqContentEntries = {
  __typename?: 'CexCurrencyFaqContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CexCurrencyFaqContentLinks = {
  __typename?: 'CexCurrencyFaqContentLinks';
  assets: CexCurrencyFaqContentAssets;
  entries: CexCurrencyFaqContentEntries;
  resources: CexCurrencyFaqContentResources;
};

export type CexCurrencyFaqContentResources = {
  __typename?: 'CexCurrencyFaqContentResources';
  block: Array<CexCurrencyFaqContentResourcesBlock>;
  hyperlink: Array<CexCurrencyFaqContentResourcesHyperlink>;
  inline: Array<CexCurrencyFaqContentResourcesInline>;
};

export type CexCurrencyFaqContentResourcesBlock = ResourceLink & {
  __typename?: 'CexCurrencyFaqContentResourcesBlock';
  sys: ResourceSys;
};

export type CexCurrencyFaqContentResourcesHyperlink = ResourceLink & {
  __typename?: 'CexCurrencyFaqContentResourcesHyperlink';
  sys: ResourceSys;
};

export type CexCurrencyFaqContentResourcesInline = ResourceLink & {
  __typename?: 'CexCurrencyFaqContentResourcesInline';
  sys: ResourceSys;
};

export type CexCurrencyFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexCurrencyFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexCurrencyFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  corridorGridTitle?: InputMaybe<Scalars['String']>;
  corridorGridTitle_contains?: InputMaybe<Scalars['String']>;
  corridorGridTitle_exists?: InputMaybe<Scalars['Boolean']>;
  corridorGridTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  corridorGridTitle_not?: InputMaybe<Scalars['String']>;
  corridorGridTitle_not_contains?: InputMaybe<Scalars['String']>;
  corridorGridTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryCode?: InputMaybe<Scalars['String']>;
  countryCode_contains?: InputMaybe<Scalars['String']>;
  countryCode_exists?: InputMaybe<Scalars['Boolean']>;
  countryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryCode_not?: InputMaybe<Scalars['String']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']>;
  countryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryName?: InputMaybe<Scalars['String']>;
  countryName_contains?: InputMaybe<Scalars['String']>;
  countryName_exists?: InputMaybe<Scalars['Boolean']>;
  countryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryName_not?: InputMaybe<Scalars['String']>;
  countryName_not_contains?: InputMaybe<Scalars['String']>;
  countryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyCode?: InputMaybe<Scalars['String']>;
  currencyCode_contains?: InputMaybe<Scalars['String']>;
  currencyCode_exists?: InputMaybe<Scalars['Boolean']>;
  currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyCode_not?: InputMaybe<Scalars['String']>;
  currencyCode_not_contains?: InputMaybe<Scalars['String']>;
  currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyName?: InputMaybe<Scalars['String']>;
  currencyName_contains?: InputMaybe<Scalars['String']>;
  currencyName_exists?: InputMaybe<Scalars['Boolean']>;
  currencyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyName_not?: InputMaybe<Scalars['String']>;
  currencyName_not_contains?: InputMaybe<Scalars['String']>;
  currencyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  faqContent_contains?: InputMaybe<Scalars['String']>;
  faqContent_exists?: InputMaybe<Scalars['Boolean']>;
  faqContent_not_contains?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageSlug?: InputMaybe<Scalars['String']>;
  pageSlug_contains?: InputMaybe<Scalars['String']>;
  pageSlug_exists?: InputMaybe<Scalars['Boolean']>;
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageSlug_not?: InputMaybe<Scalars['String']>;
  pageSlug_not_contains?: InputMaybe<Scalars['String']>;
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  topCurrencyPairs?: InputMaybe<CfCexCurrencyNestedFilter>;
  topCurrencyPairsCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CexCurrencyLinkingCollections = {
  __typename?: 'CexCurrencyLinkingCollections';
  cexCurrencyCollection?: Maybe<CexCurrencyCollection>;
  cexCurrencyCorridorCollection?: Maybe<CexCurrencyCorridorCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CexCurrencyLinkingCollectionsCexCurrencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CexCurrencyLinkingCollectionsCexCurrencyCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CexCurrencyLinkingCollectionsCexCurrencyCorridorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CexCurrencyLinkingCollectionsCexCurrencyCorridorCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CexCurrencyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CexCurrencyLinkingCollectionsCexCurrencyCollectionOrder {
  CorridorGridTitleAsc = 'corridorGridTitle_ASC',
  CorridorGridTitleDesc = 'corridorGridTitle_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CountryNameAsc = 'countryName_ASC',
  CountryNameDesc = 'countryName_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  CurrencyNameAsc = 'currencyName_ASC',
  CurrencyNameDesc = 'currencyName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CexCurrencyLinkingCollectionsCexCurrencyCorridorCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CexCurrencyOrder {
  CorridorGridTitleAsc = 'corridorGridTitle_ASC',
  CorridorGridTitleDesc = 'corridorGridTitle_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CountryNameAsc = 'countryName_ASC',
  CountryNameDesc = 'countryName_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  CurrencyNameAsc = 'currencyName_ASC',
  CurrencyNameDesc = 'currencyName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CexCurrencyTopCurrencyPairsCollection = {
  __typename?: 'CexCurrencyTopCurrencyPairsCollection';
  items: Array<Maybe<CexCurrency>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CexCurrencyTopCurrencyPairsCollectionOrder {
  CorridorGridTitleAsc = 'corridorGridTitle_ASC',
  CorridorGridTitleDesc = 'corridorGridTitle_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CountryNameAsc = 'countryName_ASC',
  CountryNameDesc = 'countryName_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  CurrencyNameAsc = 'currencyName_ASC',
  CurrencyNameDesc = 'currencyName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageSlugAsc = 'pageSlug_ASC',
  PageSlugDesc = 'pageSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexSearch) */
export type CexSearch = Entry &
  _Node & {
    __typename?: 'CexSearch';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CexSearchLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    searchInputLabel?: Maybe<Scalars['String']>;
    searchResultListLabel?: Maybe<Scalars['String']>;
    showCurrencyGrid?: Maybe<Scalars['Boolean']>;
    sys: Sys;
    titleTemplate?: Maybe<CexSearchTitleTemplate>;
  };

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexSearch) */
export type CexSearchLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexSearch) */
export type CexSearchNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexSearch) */
export type CexSearchSearchInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexSearch) */
export type CexSearchSearchResultListLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexSearch) */
export type CexSearchShowCurrencyGridArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/7gF0gftDVc9saNZjdZ39Ic/Currency-exchange-pages?type=design&node-id=2088%3A504361&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/cexSearch) */
export type CexSearchTitleTemplateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CexSearchCollection = {
  __typename?: 'CexSearchCollection';
  items: Array<Maybe<CexSearch>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CexSearchFilter = {
  AND?: InputMaybe<Array<InputMaybe<CexSearchFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CexSearchFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchInputLabel?: InputMaybe<Scalars['String']>;
  searchInputLabel_contains?: InputMaybe<Scalars['String']>;
  searchInputLabel_exists?: InputMaybe<Scalars['Boolean']>;
  searchInputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchInputLabel_not?: InputMaybe<Scalars['String']>;
  searchInputLabel_not_contains?: InputMaybe<Scalars['String']>;
  searchInputLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchResultListLabel?: InputMaybe<Scalars['String']>;
  searchResultListLabel_contains?: InputMaybe<Scalars['String']>;
  searchResultListLabel_exists?: InputMaybe<Scalars['Boolean']>;
  searchResultListLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchResultListLabel_not?: InputMaybe<Scalars['String']>;
  searchResultListLabel_not_contains?: InputMaybe<Scalars['String']>;
  searchResultListLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showCurrencyGrid?: InputMaybe<Scalars['Boolean']>;
  showCurrencyGrid_exists?: InputMaybe<Scalars['Boolean']>;
  showCurrencyGrid_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  titleTemplate_contains?: InputMaybe<Scalars['String']>;
  titleTemplate_exists?: InputMaybe<Scalars['Boolean']>;
  titleTemplate_not_contains?: InputMaybe<Scalars['String']>;
};

export type CexSearchLinkingCollections = {
  __typename?: 'CexSearchLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CexSearchLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CexSearchOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SearchInputLabelAsc = 'searchInputLabel_ASC',
  SearchInputLabelDesc = 'searchInputLabel_DESC',
  SearchResultListLabelAsc = 'searchResultListLabel_ASC',
  SearchResultListLabelDesc = 'searchResultListLabel_DESC',
  ShowCurrencyGridAsc = 'showCurrencyGrid_ASC',
  ShowCurrencyGridDesc = 'showCurrencyGrid_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CexSearchTitleTemplate = {
  __typename?: 'CexSearchTitleTemplate';
  json: Scalars['JSON'];
  links: CexSearchTitleTemplateLinks;
};

export type CexSearchTitleTemplateAssets = {
  __typename?: 'CexSearchTitleTemplateAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CexSearchTitleTemplateEntries = {
  __typename?: 'CexSearchTitleTemplateEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CexSearchTitleTemplateLinks = {
  __typename?: 'CexSearchTitleTemplateLinks';
  assets: CexSearchTitleTemplateAssets;
  entries: CexSearchTitleTemplateEntries;
  resources: CexSearchTitleTemplateResources;
};

export type CexSearchTitleTemplateResources = {
  __typename?: 'CexSearchTitleTemplateResources';
  block: Array<CexSearchTitleTemplateResourcesBlock>;
  hyperlink: Array<CexSearchTitleTemplateResourcesHyperlink>;
  inline: Array<CexSearchTitleTemplateResourcesInline>;
};

export type CexSearchTitleTemplateResourcesBlock = ResourceLink & {
  __typename?: 'CexSearchTitleTemplateResourcesBlock';
  sys: ResourceSys;
};

export type CexSearchTitleTemplateResourcesHyperlink = ResourceLink & {
  __typename?: 'CexSearchTitleTemplateResourcesHyperlink';
  sys: ResourceSys;
};

export type CexSearchTitleTemplateResourcesInline = ResourceLink & {
  __typename?: 'CexSearchTitleTemplateResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTable = Entry &
  _Node & {
    __typename?: 'ComparisonTable';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    caption?: Maybe<Scalars['String']>;
    content?: Maybe<ComparisonTableContent>;
    contentfulMetadata: ContentfulMetadata;
    footer?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<ComparisonTableLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    providersLabel?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    rowsCollection?: Maybe<ComparisonTableRowsCollection>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableCaptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableFooterArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableProvidersLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableRowsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableRowFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTable) */
export type ComparisonTableSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCell = Entry &
  _Node & {
    __typename?: 'ComparisonTableCell';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    amount?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    fee?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<ComparisonTableCellLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellAmountArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellFeeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableCell) */
export type ComparisonTableCellSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ComparisonTableCellCollection = {
  __typename?: 'ComparisonTableCellCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ComparisonTableCellFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComparisonTableCellFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ComparisonTableCellFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['String']>;
  amount_contains?: InputMaybe<Scalars['String']>;
  amount_exists?: InputMaybe<Scalars['Boolean']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  amount_not?: InputMaybe<Scalars['String']>;
  amount_not_contains?: InputMaybe<Scalars['String']>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fee?: InputMaybe<Scalars['String']>;
  fee_contains?: InputMaybe<Scalars['String']>;
  fee_exists?: InputMaybe<Scalars['Boolean']>;
  fee_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fee_not?: InputMaybe<Scalars['String']>;
  fee_not_contains?: InputMaybe<Scalars['String']>;
  fee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ComparisonTableCellLinkingCollections = {
  __typename?: 'ComparisonTableCellLinkingCollections';
  comparisonTableRowCollection?: Maybe<ComparisonTableRowCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ComparisonTableCellLinkingCollectionsComparisonTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ComparisonTableCellLinkingCollectionsComparisonTableRowCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ComparisonTableCellLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ComparisonTableCellLinkingCollectionsComparisonTableRowCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComparisonTableCellOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableCollection = {
  __typename?: 'ComparisonTableCollection';
  items: Array<Maybe<ComparisonTable>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ComparisonTableContent = {
  __typename?: 'ComparisonTableContent';
  json: Scalars['JSON'];
  links: ComparisonTableContentLinks;
};

export type ComparisonTableContentAssets = {
  __typename?: 'ComparisonTableContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ComparisonTableContentEntries = {
  __typename?: 'ComparisonTableContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ComparisonTableContentLinks = {
  __typename?: 'ComparisonTableContentLinks';
  assets: ComparisonTableContentAssets;
  entries: ComparisonTableContentEntries;
  resources: ComparisonTableContentResources;
};

export type ComparisonTableContentResources = {
  __typename?: 'ComparisonTableContentResources';
  block: Array<ComparisonTableContentResourcesBlock>;
  hyperlink: Array<ComparisonTableContentResourcesHyperlink>;
  inline: Array<ComparisonTableContentResourcesInline>;
};

export type ComparisonTableContentResourcesBlock = ResourceLink & {
  __typename?: 'ComparisonTableContentResourcesBlock';
  sys: ResourceSys;
};

export type ComparisonTableContentResourcesHyperlink = ResourceLink & {
  __typename?: 'ComparisonTableContentResourcesHyperlink';
  sys: ResourceSys;
};

export type ComparisonTableContentResourcesInline = ResourceLink & {
  __typename?: 'ComparisonTableContentResourcesInline';
  sys: ResourceSys;
};

export type ComparisonTableFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComparisonTableFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ComparisonTableFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  caption?: InputMaybe<Scalars['String']>;
  caption_contains?: InputMaybe<Scalars['String']>;
  caption_exists?: InputMaybe<Scalars['Boolean']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  caption_not?: InputMaybe<Scalars['String']>;
  caption_not_contains?: InputMaybe<Scalars['String']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  footer?: InputMaybe<Scalars['String']>;
  footer_contains?: InputMaybe<Scalars['String']>;
  footer_exists?: InputMaybe<Scalars['Boolean']>;
  footer_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footer_not?: InputMaybe<Scalars['String']>;
  footer_not_contains?: InputMaybe<Scalars['String']>;
  footer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  providersLabel?: InputMaybe<Scalars['String']>;
  providersLabel_contains?: InputMaybe<Scalars['String']>;
  providersLabel_exists?: InputMaybe<Scalars['Boolean']>;
  providersLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  providersLabel_not?: InputMaybe<Scalars['String']>;
  providersLabel_not_contains?: InputMaybe<Scalars['String']>;
  providersLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  rows?: InputMaybe<CfComparisonTableRowNestedFilter>;
  rowsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ComparisonTableLinkingCollections = {
  __typename?: 'ComparisonTableLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type ComparisonTableLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ComparisonTableLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ComparisonTableLinkingCollectionsPageSectionCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ComparisonTableLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComparisonTableOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  FooterAsc = 'footer_ASC',
  FooterDesc = 'footer_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProvidersLabelAsc = 'providersLabel_ASC',
  ProvidersLabelDesc = 'providersLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRow = Entry &
  _Node & {
    __typename?: 'ComparisonTableRow';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    atpCollection?: Maybe<ComparisonTableRowAtpCollection>;
    bnkCollection?: Maybe<ComparisonTableRowBnkCollection>;
    contentfulMetadata: ContentfulMetadata;
    cshCollection?: Maybe<ComparisonTableRowCshCollection>;
    linkedFrom?: Maybe<ComparisonTableRowLinkingCollections>;
    mobCollection?: Maybe<ComparisonTableRowMobCollection>;
    name?: Maybe<Scalars['String']>;
    provider?: Maybe<Asset>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowAtpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowAtpCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowBnkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowBnkCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowCshCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowCshCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowMobCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowMobCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowProviderArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/comparisonTableRow) */
export type ComparisonTableRowSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ComparisonTableRowAtpCollection = {
  __typename?: 'ComparisonTableRowATPCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ComparisonTableRowAtpCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowBnkCollection = {
  __typename?: 'ComparisonTableRowBNKCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ComparisonTableRowBnkCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowCshCollection = {
  __typename?: 'ComparisonTableRowCSHCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ComparisonTableRowCshCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowCollection = {
  __typename?: 'ComparisonTableRowCollection';
  items: Array<Maybe<ComparisonTableRow>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ComparisonTableRowFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComparisonTableRowFilter>>>;
  ATP?: InputMaybe<CfComparisonTableCellNestedFilter>;
  ATPCollection_exists?: InputMaybe<Scalars['Boolean']>;
  BNK?: InputMaybe<CfComparisonTableCellNestedFilter>;
  BNKCollection_exists?: InputMaybe<Scalars['Boolean']>;
  CSH?: InputMaybe<CfComparisonTableCellNestedFilter>;
  CSHCollection_exists?: InputMaybe<Scalars['Boolean']>;
  MOB?: InputMaybe<CfComparisonTableCellNestedFilter>;
  MOBCollection_exists?: InputMaybe<Scalars['Boolean']>;
  OR?: InputMaybe<Array<InputMaybe<ComparisonTableRowFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  provider_exists?: InputMaybe<Scalars['Boolean']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ComparisonTableRowLinkingCollections = {
  __typename?: 'ComparisonTableRowLinkingCollections';
  comparisonTableCollection?: Maybe<ComparisonTableCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ComparisonTableRowLinkingCollectionsComparisonTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ComparisonTableRowLinkingCollectionsComparisonTableCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ComparisonTableRowLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ComparisonTableRowLinkingCollectionsComparisonTableCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  FooterAsc = 'footer_ASC',
  FooterDesc = 'footer_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ProvidersLabelAsc = 'providersLabel_ASC',
  ProvidersLabelDesc = 'providersLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowMobCollection = {
  __typename?: 'ComparisonTableRowMOBCollection';
  items: Array<Maybe<ComparisonTableCell>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ComparisonTableRowMobCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComparisonTableRowOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComparisonTableRowsCollection = {
  __typename?: 'ComparisonTableRowsCollection';
  items: Array<Maybe<ComparisonTableRow>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ComparisonTableRowsCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBanner = Entry &
  _Node & {
    __typename?: 'ContainedBanner';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    desktopBanner?: Maybe<Asset>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<ContainedBannerLinkingCollections>;
    mobileBanner?: Maybe<Asset>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    text?: Maybe<ContainedBannerText>;
    textAlign?: Maybe<Scalars['String']>;
    textBodyColor?: Maybe<Scalars['String']>;
    textHeadingColor?: Maybe<Scalars['String']>;
  };

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerDesktopBannerArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerMobileBannerArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerTextAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerTextBodyColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=843%3A22258&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/containedBanner) */
export type ContainedBannerTextHeadingColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ContainedBannerCollection = {
  __typename?: 'ContainedBannerCollection';
  items: Array<Maybe<ContainedBanner>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ContainedBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContainedBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContainedBannerFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  desktopBanner_exists?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  mobileBanner_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  textAlign?: InputMaybe<Scalars['String']>;
  textAlign_contains?: InputMaybe<Scalars['String']>;
  textAlign_exists?: InputMaybe<Scalars['Boolean']>;
  textAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlign_not?: InputMaybe<Scalars['String']>;
  textAlign_not_contains?: InputMaybe<Scalars['String']>;
  textAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textBodyColor?: InputMaybe<Scalars['String']>;
  textBodyColor_contains?: InputMaybe<Scalars['String']>;
  textBodyColor_exists?: InputMaybe<Scalars['Boolean']>;
  textBodyColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textBodyColor_not?: InputMaybe<Scalars['String']>;
  textBodyColor_not_contains?: InputMaybe<Scalars['String']>;
  textBodyColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textHeadingColor?: InputMaybe<Scalars['String']>;
  textHeadingColor_contains?: InputMaybe<Scalars['String']>;
  textHeadingColor_exists?: InputMaybe<Scalars['Boolean']>;
  textHeadingColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textHeadingColor_not?: InputMaybe<Scalars['String']>;
  textHeadingColor_not_contains?: InputMaybe<Scalars['String']>;
  textHeadingColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
};

export type ContainedBannerLinkingCollections = {
  __typename?: 'ContainedBannerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ContainedBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ContainedBannerOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextBodyColorAsc = 'textBodyColor_ASC',
  TextBodyColorDesc = 'textBodyColor_DESC',
  TextHeadingColorAsc = 'textHeadingColor_ASC',
  TextHeadingColorDesc = 'textHeadingColor_DESC',
}

export type ContainedBannerText = {
  __typename?: 'ContainedBannerText';
  json: Scalars['JSON'];
  links: ContainedBannerTextLinks;
};

export type ContainedBannerTextAssets = {
  __typename?: 'ContainedBannerTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContainedBannerTextEntries = {
  __typename?: 'ContainedBannerTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContainedBannerTextLinks = {
  __typename?: 'ContainedBannerTextLinks';
  assets: ContainedBannerTextAssets;
  entries: ContainedBannerTextEntries;
  resources: ContainedBannerTextResources;
};

export type ContainedBannerTextResources = {
  __typename?: 'ContainedBannerTextResources';
  block: Array<ContainedBannerTextResourcesBlock>;
  hyperlink: Array<ContainedBannerTextResourcesHyperlink>;
  inline: Array<ContainedBannerTextResourcesInline>;
};

export type ContainedBannerTextResourcesBlock = ResourceLink & {
  __typename?: 'ContainedBannerTextResourcesBlock';
  sys: ResourceSys;
};

export type ContainedBannerTextResourcesHyperlink = ResourceLink & {
  __typename?: 'ContainedBannerTextResourcesHyperlink';
  sys: ResourceSys;
};

export type ContainedBannerTextResourcesInline = ResourceLink & {
  __typename?: 'ContainedBannerTextResourcesInline';
  sys: ResourceSys;
};

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type Correspondents = Entry &
  _Node & {
    __typename?: 'Correspondents';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CorrespondentsLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    subtitle?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/correspondents) */
export type CorrespondentsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CorrespondentsCollection = {
  __typename?: 'CorrespondentsCollection';
  items: Array<Maybe<Correspondents>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CorrespondentsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CorrespondentsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CorrespondentsFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CorrespondentsLinkingCollections = {
  __typename?: 'CorrespondentsLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CorrespondentsLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CorrespondentsLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CorrespondentsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CorrespondentsLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CorrespondentsOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/countriesGrid) */
export type CountriesGrid = Entry &
  _Node & {
    __typename?: 'CountriesGrid';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CountriesGridLinkingCollections>;
    moneyTransfers?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/countriesGrid) */
export type CountriesGridLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/countriesGrid) */
export type CountriesGridMoneyTransfersArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/countriesGrid) */
export type CountriesGridNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CountriesGridCollection = {
  __typename?: 'CountriesGridCollection';
  items: Array<Maybe<CountriesGrid>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CountriesGridFilter = {
  AND?: InputMaybe<Array<InputMaybe<CountriesGridFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CountriesGridFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  moneyTransfers?: InputMaybe<Scalars['String']>;
  moneyTransfers_contains?: InputMaybe<Scalars['String']>;
  moneyTransfers_exists?: InputMaybe<Scalars['Boolean']>;
  moneyTransfers_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moneyTransfers_not?: InputMaybe<Scalars['String']>;
  moneyTransfers_not_contains?: InputMaybe<Scalars['String']>;
  moneyTransfers_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CountriesGridLinkingCollections = {
  __typename?: 'CountriesGridLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CountriesGridLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CountriesGridOrder {
  MoneyTransfersAsc = 'moneyTransfers_ASC',
  MoneyTransfersDesc = 'moneyTransfers_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type Country = Entry &
  _Node & {
    __typename?: 'Country';
    _id: Scalars['ID'];
    article?: Maybe<Scalars['String']>;
    calculatorReceiveCountry?: Maybe<Country>;
    code?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<CountryLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    prepositionFrom?: Maybe<Scalars['String']>;
    prepositionIn?: Maybe<Scalars['String']>;
    prepositionTo?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryArticleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryCalculatorReceiveCountryArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryPrepositionFromArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryPrepositionInArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountryPrepositionToArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/country) */
export type CountrySlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CountryCollection = {
  __typename?: 'CountryCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CountryFilter = {
  AND?: InputMaybe<Array<InputMaybe<CountryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CountryFilter>>>;
  article?: InputMaybe<Scalars['String']>;
  article_contains?: InputMaybe<Scalars['String']>;
  article_exists?: InputMaybe<Scalars['Boolean']>;
  article_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  article_not?: InputMaybe<Scalars['String']>;
  article_not_contains?: InputMaybe<Scalars['String']>;
  article_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calculatorReceiveCountry?: InputMaybe<CfCountryNestedFilter>;
  calculatorReceiveCountry_exists?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  code_contains?: InputMaybe<Scalars['String']>;
  code_exists?: InputMaybe<Scalars['Boolean']>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code_not?: InputMaybe<Scalars['String']>;
  code_not_contains?: InputMaybe<Scalars['String']>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionFrom?: InputMaybe<Scalars['String']>;
  prepositionFrom_contains?: InputMaybe<Scalars['String']>;
  prepositionFrom_exists?: InputMaybe<Scalars['Boolean']>;
  prepositionFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionFrom_not?: InputMaybe<Scalars['String']>;
  prepositionFrom_not_contains?: InputMaybe<Scalars['String']>;
  prepositionFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionIn?: InputMaybe<Scalars['String']>;
  prepositionIn_contains?: InputMaybe<Scalars['String']>;
  prepositionIn_exists?: InputMaybe<Scalars['Boolean']>;
  prepositionIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionIn_not?: InputMaybe<Scalars['String']>;
  prepositionIn_not_contains?: InputMaybe<Scalars['String']>;
  prepositionIn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionTo?: InputMaybe<Scalars['String']>;
  prepositionTo_contains?: InputMaybe<Scalars['String']>;
  prepositionTo_exists?: InputMaybe<Scalars['Boolean']>;
  prepositionTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionTo_not?: InputMaybe<Scalars['String']>;
  prepositionTo_not_contains?: InputMaybe<Scalars['String']>;
  prepositionTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CountryLinkingCollections = {
  __typename?: 'CountryLinkingCollections';
  countryCollection?: Maybe<CountryCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqItemCollection?: Maybe<FaqItemCollection>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
  listOfCountriesCollection?: Maybe<ListOfCountriesCollection>;
  nestedAccordionItemsCollection?: Maybe<NestedAccordionItemsCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  reviewsCardCollection?: Maybe<ReviewsCardCollection>;
};

export type CountryLinkingCollectionsCountryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsCountryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsFaqItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsFaqItemCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CountryLinkingCollectionsFaqTopicsModuleItemsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsListOfCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsListOfCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsNestedAccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<CountryLinkingCollectionsNestedAccordionItemsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsPayoutMethodCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CountryLinkingCollectionsReviewsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<CountryLinkingCollectionsReviewsCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CountryLinkingCollectionsCountryCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsFaqItemCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CountryLinkingCollectionsFaqTopicsModuleItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CountryLinkingCollectionsListOfCountriesCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ButtonLabelExpandedListAsc = 'buttonLabelExpandedList_ASC',
  ButtonLabelExpandedListDesc = 'buttonLabelExpandedList_DESC',
  ButtonLabelHiddenListAsc = 'buttonLabelHiddenList_ASC',
  ButtonLabelHiddenListDesc = 'buttonLabelHiddenList_DESC',
  ExpandListTextAsc = 'expandListText_ASC',
  ExpandListTextDesc = 'expandListText_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsNestedAccordionItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CountryLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CountryLinkingCollectionsPayoutMethodCollectionOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

export enum CountryLinkingCollectionsReviewsCardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RatingsAsc = 'ratings_ASC',
  RatingsDesc = 'ratings_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CountryOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculator = Entry &
  _Node & {
    __typename?: 'ExchangeCalculator';
    _id: Scalars['ID'];
    bank?: Maybe<Asset>;
    cash?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    exchangeRate?: Maybe<Scalars['String']>;
    heading?: Maybe<Scalars['String']>;
    limitReachedError?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<ExchangeCalculatorLinkingCollections>;
    mobile?: Maybe<Asset>;
    name?: Maybe<Scalars['String']>;
    receiveLabel?: Maybe<Scalars['String']>;
    sendLabel?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorBankArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorCashArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorExchangeRateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorLimitReachedErrorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorReceiveLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorSendLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/exchangeCalculator) */
export type ExchangeCalculatorTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ExchangeCalculatorCollection = {
  __typename?: 'ExchangeCalculatorCollection';
  items: Array<Maybe<ExchangeCalculator>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExchangeCalculatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExchangeCalculatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ExchangeCalculatorFilter>>>;
  bank_exists?: InputMaybe<Scalars['Boolean']>;
  cash_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRate?: InputMaybe<Scalars['String']>;
  exchangeRate_contains?: InputMaybe<Scalars['String']>;
  exchangeRate_exists?: InputMaybe<Scalars['Boolean']>;
  exchangeRate_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRate_not?: InputMaybe<Scalars['String']>;
  exchangeRate_not_contains?: InputMaybe<Scalars['String']>;
  exchangeRate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limitReachedError?: InputMaybe<Scalars['String']>;
  limitReachedError_contains?: InputMaybe<Scalars['String']>;
  limitReachedError_exists?: InputMaybe<Scalars['Boolean']>;
  limitReachedError_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limitReachedError_not?: InputMaybe<Scalars['String']>;
  limitReachedError_not_contains?: InputMaybe<Scalars['String']>;
  limitReachedError_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobile_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveLabel?: InputMaybe<Scalars['String']>;
  receiveLabel_contains?: InputMaybe<Scalars['String']>;
  receiveLabel_exists?: InputMaybe<Scalars['Boolean']>;
  receiveLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveLabel_not?: InputMaybe<Scalars['String']>;
  receiveLabel_not_contains?: InputMaybe<Scalars['String']>;
  receiveLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendLabel?: InputMaybe<Scalars['String']>;
  sendLabel_contains?: InputMaybe<Scalars['String']>;
  sendLabel_exists?: InputMaybe<Scalars['Boolean']>;
  sendLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendLabel_not?: InputMaybe<Scalars['String']>;
  sendLabel_not_contains?: InputMaybe<Scalars['String']>;
  sendLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ExchangeCalculatorLinkingCollections = {
  __typename?: 'ExchangeCalculatorLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ExchangeCalculatorLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ExchangeCalculatorLinkingCollectionsBetterWayTopCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ExchangeCalculatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ExchangeCalculatorLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum ExchangeCalculatorOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateAsc = 'exchangeRate_ASC',
  ExchangeRateDesc = 'exchangeRate_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  LimitReachedErrorAsc = 'limitReachedError_ASC',
  LimitReachedErrorDesc = 'limitReachedError_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReceiveLabelAsc = 'receiveLabel_ASC',
  ReceiveLabelDesc = 'receiveLabel_DESC',
  SendLabelAsc = 'sendLabel_ASC',
  SendLabelDesc = 'sendLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHero = Entry &
  _Node & {
    __typename?: 'FaqHero';
    _id: Scalars['ID'];
    allCountries?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    footerImage?: Maybe<Asset>;
    heading?: Maybe<Scalars['String']>;
    heroImage?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqHeroLinkingCollections>;
    linksCollection?: Maybe<FaqHeroLinksCollection>;
    name?: Maybe<Scalars['String']>;
    primaryBackgroundColor?: Maybe<Scalars['String']>;
    search?: Maybe<Search>;
    secondaryBackgroundColor?: Maybe<Scalars['String']>;
    sendToText?: Maybe<Scalars['String']>;
    sys: Sys;
    textColor?: Maybe<Scalars['String']>;
  };

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroAllCountriesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroFooterImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqHeroLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroPrimaryBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroSearchArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SearchFilter>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroSecondaryBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroSendToTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** New hero component for FAQ pages [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqHero) */
export type FaqHeroTextColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqHeroCollection = {
  __typename?: 'FaqHeroCollection';
  items: Array<Maybe<FaqHero>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqHeroFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqHeroFilter>>>;
  allCountries?: InputMaybe<Scalars['String']>;
  allCountries_contains?: InputMaybe<Scalars['String']>;
  allCountries_exists?: InputMaybe<Scalars['Boolean']>;
  allCountries_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  allCountries_not?: InputMaybe<Scalars['String']>;
  allCountries_not_contains?: InputMaybe<Scalars['String']>;
  allCountries_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  footerImage_exists?: InputMaybe<Scalars['Boolean']>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heroImage_exists?: InputMaybe<Scalars['Boolean']>;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryBackgroundColor?: InputMaybe<Scalars['String']>;
  primaryBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  primaryBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  primaryBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryBackgroundColor_not?: InputMaybe<Scalars['String']>;
  primaryBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  primaryBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  search?: InputMaybe<CfSearchNestedFilter>;
  search_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryBackgroundColor?: InputMaybe<Scalars['String']>;
  secondaryBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  secondaryBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryBackgroundColor_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  secondaryBackgroundColor_not?: InputMaybe<Scalars['String']>;
  secondaryBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  secondaryBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendToText?: InputMaybe<Scalars['String']>;
  sendToText_contains?: InputMaybe<Scalars['String']>;
  sendToText_exists?: InputMaybe<Scalars['Boolean']>;
  sendToText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendToText_not?: InputMaybe<Scalars['String']>;
  sendToText_not_contains?: InputMaybe<Scalars['String']>;
  sendToText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  textColor?: InputMaybe<Scalars['String']>;
  textColor_contains?: InputMaybe<Scalars['String']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textColor_not?: InputMaybe<Scalars['String']>;
  textColor_not_contains?: InputMaybe<Scalars['String']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqHeroLinkingCollections = {
  __typename?: 'FaqHeroLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqHeroLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqHeroLinksCollection = {
  __typename?: 'FaqHeroLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqHeroLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum FaqHeroOrder {
  AllCountriesAsc = 'allCountries_ASC',
  AllCountriesDesc = 'allCountries_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrimaryBackgroundColorAsc = 'primaryBackgroundColor_ASC',
  PrimaryBackgroundColorDesc = 'primaryBackgroundColor_DESC',
  SecondaryBackgroundColorAsc = 'secondaryBackgroundColor_ASC',
  SecondaryBackgroundColorDesc = 'secondaryBackgroundColor_DESC',
  SendToTextAsc = 'sendToText_ASC',
  SendToTextDesc = 'sendToText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqItem) */
export type FaqItem = Entry &
  _Node & {
    __typename?: 'FaqItem';
    _id: Scalars['ID'];
    body?: Maybe<FaqItemBody>;
    contentfulMetadata: ContentfulMetadata;
    countriesCollection?: Maybe<FaqItemCountriesCollection>;
    linkedFrom?: Maybe<FaqItemLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqItem) */
export type FaqItemBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqItem) */
export type FaqItemCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqItemCountriesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqItem) */
export type FaqItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqItem) */
export type FaqItemNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqItem) */
export type FaqItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqItemBody = {
  __typename?: 'FaqItemBody';
  json: Scalars['JSON'];
  links: FaqItemBodyLinks;
};

export type FaqItemBodyAssets = {
  __typename?: 'FaqItemBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FaqItemBodyEntries = {
  __typename?: 'FaqItemBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FaqItemBodyLinks = {
  __typename?: 'FaqItemBodyLinks';
  assets: FaqItemBodyAssets;
  entries: FaqItemBodyEntries;
  resources: FaqItemBodyResources;
};

export type FaqItemBodyResources = {
  __typename?: 'FaqItemBodyResources';
  block: Array<FaqItemBodyResourcesBlock>;
  hyperlink: Array<FaqItemBodyResourcesHyperlink>;
  inline: Array<FaqItemBodyResourcesInline>;
};

export type FaqItemBodyResourcesBlock = ResourceLink & {
  __typename?: 'FaqItemBodyResourcesBlock';
  sys: ResourceSys;
};

export type FaqItemBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqItemBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type FaqItemBodyResourcesInline = ResourceLink & {
  __typename?: 'FaqItemBodyResourcesInline';
  sys: ResourceSys;
};

export type FaqItemCollection = {
  __typename?: 'FaqItemCollection';
  items: Array<Maybe<FaqItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqItemCountriesCollection = {
  __typename?: 'FaqItemCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqItemCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FaqItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqItemFilter>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries?: InputMaybe<CfCountryNestedFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqItemLinkingCollections = {
  __typename?: 'FaqItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqPopularQuestionsCollection?: Maybe<FaqPopularQuestionsCollection>;
  faqTopicCollection?: Maybe<FaqTopicCollection>;
};

export type FaqItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqItemLinkingCollectionsFaqPopularQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqItemLinkingCollectionsFaqPopularQuestionsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqItemLinkingCollectionsFaqTopicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqItemLinkingCollectionsFaqTopicCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqItemLinkingCollectionsFaqPopularQuestionsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqItemLinkingCollectionsFaqTopicCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqItemOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModule = Entry &
  _Node & {
    __typename?: 'FaqModule';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    contentfulMetadata: ContentfulMetadata;
    ctaButton?: Maybe<Link>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqModuleLinkingCollections>;
    mobileImage?: Maybe<Asset>;
    name?: Maybe<Scalars['String']>;
    questionsCollection?: Maybe<FaqModuleQuestionsCollection>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    subheading?: Maybe<Scalars['String']>;
    sys: Sys;
    tabletImage?: Maybe<Asset>;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleCtaButtonArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleMobileImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqModuleQuestionsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqModuleItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleSubheadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleTabletImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModule) */
export type FaqModuleTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqModuleCollection = {
  __typename?: 'FaqModuleCollection';
  items: Array<Maybe<FaqModule>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqModuleFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  ctaButton?: InputMaybe<CfLinkNestedFilter>;
  ctaButton_exists?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  mobileImage_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  questions?: InputMaybe<CfFaqModuleItemNestedFilter>;
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  subheading?: InputMaybe<Scalars['String']>;
  subheading_contains?: InputMaybe<Scalars['String']>;
  subheading_exists?: InputMaybe<Scalars['Boolean']>;
  subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subheading_not?: InputMaybe<Scalars['String']>;
  subheading_not_contains?: InputMaybe<Scalars['String']>;
  subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tabletImage_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItem = Entry &
  _Node & {
    __typename?: 'FaqModuleItem';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<FaqModuleItemDescription>;
    linkedFrom?: Maybe<FaqModuleItemLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    path?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
    umbracoId?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItemDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItemNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItemPathArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItemSortOrderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqModuleItem) */
export type FaqModuleItemUmbracoIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqModuleItemCollection = {
  __typename?: 'FaqModuleItemCollection';
  items: Array<Maybe<FaqModuleItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqModuleItemDescription = {
  __typename?: 'FaqModuleItemDescription';
  json: Scalars['JSON'];
  links: FaqModuleItemDescriptionLinks;
};

export type FaqModuleItemDescriptionAssets = {
  __typename?: 'FaqModuleItemDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FaqModuleItemDescriptionEntries = {
  __typename?: 'FaqModuleItemDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FaqModuleItemDescriptionLinks = {
  __typename?: 'FaqModuleItemDescriptionLinks';
  assets: FaqModuleItemDescriptionAssets;
  entries: FaqModuleItemDescriptionEntries;
  resources: FaqModuleItemDescriptionResources;
};

export type FaqModuleItemDescriptionResources = {
  __typename?: 'FaqModuleItemDescriptionResources';
  block: Array<FaqModuleItemDescriptionResourcesBlock>;
  hyperlink: Array<FaqModuleItemDescriptionResourcesHyperlink>;
  inline: Array<FaqModuleItemDescriptionResourcesInline>;
};

export type FaqModuleItemDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'FaqModuleItemDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type FaqModuleItemDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqModuleItemDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type FaqModuleItemDescriptionResourcesInline = ResourceLink & {
  __typename?: 'FaqModuleItemDescriptionResourcesInline';
  sys: ResourceSys;
};

export type FaqModuleItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqModuleItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqModuleItemFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  path?: InputMaybe<Scalars['String']>;
  path_contains?: InputMaybe<Scalars['String']>;
  path_exists?: InputMaybe<Scalars['Boolean']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  path_not?: InputMaybe<Scalars['String']>;
  path_not_contains?: InputMaybe<Scalars['String']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortOrder_contains?: InputMaybe<Scalars['String']>;
  sortOrder_exists?: InputMaybe<Scalars['Boolean']>;
  sortOrder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortOrder_not?: InputMaybe<Scalars['String']>;
  sortOrder_not_contains?: InputMaybe<Scalars['String']>;
  sortOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  umbracoId?: InputMaybe<Scalars['String']>;
  umbracoId_contains?: InputMaybe<Scalars['String']>;
  umbracoId_exists?: InputMaybe<Scalars['Boolean']>;
  umbracoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  umbracoId_not?: InputMaybe<Scalars['String']>;
  umbracoId_not_contains?: InputMaybe<Scalars['String']>;
  umbracoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqModuleItemLinkingCollections = {
  __typename?: 'FaqModuleItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
};

export type FaqModuleItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqModuleItemLinkingCollectionsFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqModuleItemLinkingCollectionsFaqModuleCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqModuleItemLinkingCollectionsFaqModuleCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqModuleItemOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UmbracoIdAsc = 'umbracoId_ASC',
  UmbracoIdDesc = 'umbracoId_DESC',
}

export type FaqModuleLinkingCollections = {
  __typename?: 'FaqModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqModuleOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqModuleQuestionsCollection = {
  __typename?: 'FaqModuleQuestionsCollection';
  items: Array<Maybe<FaqModuleItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqModuleQuestionsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UmbracoIdAsc = 'umbracoId_ASC',
  UmbracoIdDesc = 'umbracoId_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqPopularQuestions) */
export type FaqPopularQuestions = Entry &
  _Node & {
    __typename?: 'FaqPopularQuestions';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FaqPopularQuestionsLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    questionsCollection?: Maybe<FaqPopularQuestionsQuestionsCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqPopularQuestionsQuestionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqPopularQuestions) */
export type FaqPopularQuestionsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqPopularQuestionsCollection = {
  __typename?: 'FaqPopularQuestionsCollection';
  items: Array<Maybe<FaqPopularQuestions>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqPopularQuestionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqPopularQuestionsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqPopularQuestionsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  questions?: InputMaybe<CfFaqItemNestedFilter>;
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqPopularQuestionsLinkingCollections = {
  __typename?: 'FaqPopularQuestionsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqSectionCollection?: Maybe<FaqSectionCollection>;
  faqTopicsModuleCollection?: Maybe<FaqTopicsModuleCollection>;
  nestedAccordionCollection?: Maybe<NestedAccordionCollection>;
};

export type FaqPopularQuestionsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqPopularQuestionsLinkingCollectionsFaqSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqPopularQuestionsLinkingCollectionsFaqSectionCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqPopularQuestionsLinkingCollectionsFaqTopicsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqPopularQuestionsLinkingCollectionsFaqTopicsModuleCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqPopularQuestionsLinkingCollectionsNestedAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqPopularQuestionsLinkingCollectionsNestedAccordionCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqPopularQuestionsLinkingCollectionsFaqSectionCollectionOrder {
  ClearTextAsc = 'clearText_ASC',
  ClearTextDesc = 'clearText_DESC',
  EmptySearchTextAsc = 'emptySearchText_ASC',
  EmptySearchTextDesc = 'emptySearchText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResultsForTextAsc = 'resultsForText_ASC',
  ResultsForTextDesc = 'resultsForText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqPopularQuestionsLinkingCollectionsFaqTopicsModuleCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqPopularQuestionsLinkingCollectionsNestedAccordionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqPopularQuestionsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqPopularQuestionsQuestionsCollection = {
  __typename?: 'FaqPopularQuestionsQuestionsCollection';
  items: Array<Maybe<FaqItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqPopularQuestionsQuestionsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSection = Entry &
  _Node & {
    __typename?: 'FaqSection';
    _id: Scalars['ID'];
    clearText?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    emptySearchText?: Maybe<Scalars['String']>;
    faqTopicsCollection?: Maybe<FaqSectionFaqTopicsCollection>;
    linkedFrom?: Maybe<FaqSectionLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    popularQuestions?: Maybe<FaqPopularQuestions>;
    resultsForText?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionClearTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionEmptySearchTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionFaqTopicsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqSectionFaqTopicsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqTopicFilter>;
};

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionPopularQuestionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FaqPopularQuestionsFilter>;
};

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionResultsForTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Container for placing FAQ items in a page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqSection) */
export type FaqSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqSectionCollection = {
  __typename?: 'FaqSectionCollection';
  items: Array<Maybe<FaqSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqSectionFaqTopicsCollection = {
  __typename?: 'FaqSectionFaqTopicsCollection';
  items: Array<Maybe<FaqTopic>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqSectionFaqTopicsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqSectionFilter>>>;
  clearText?: InputMaybe<Scalars['String']>;
  clearText_contains?: InputMaybe<Scalars['String']>;
  clearText_exists?: InputMaybe<Scalars['Boolean']>;
  clearText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clearText_not?: InputMaybe<Scalars['String']>;
  clearText_not_contains?: InputMaybe<Scalars['String']>;
  clearText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  emptySearchText?: InputMaybe<Scalars['String']>;
  emptySearchText_contains?: InputMaybe<Scalars['String']>;
  emptySearchText_exists?: InputMaybe<Scalars['Boolean']>;
  emptySearchText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  emptySearchText_not?: InputMaybe<Scalars['String']>;
  emptySearchText_not_contains?: InputMaybe<Scalars['String']>;
  emptySearchText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  faqTopics?: InputMaybe<CfFaqTopicNestedFilter>;
  faqTopicsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  popularQuestions?: InputMaybe<CfFaqPopularQuestionsNestedFilter>;
  popularQuestions_exists?: InputMaybe<Scalars['Boolean']>;
  resultsForText?: InputMaybe<Scalars['String']>;
  resultsForText_contains?: InputMaybe<Scalars['String']>;
  resultsForText_exists?: InputMaybe<Scalars['Boolean']>;
  resultsForText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resultsForText_not?: InputMaybe<Scalars['String']>;
  resultsForText_not_contains?: InputMaybe<Scalars['String']>;
  resultsForText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqSectionLinkingCollections = {
  __typename?: 'FaqSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqSectionOrder {
  ClearTextAsc = 'clearText_ASC',
  ClearTextDesc = 'clearText_DESC',
  EmptySearchTextAsc = 'emptySearchText_ASC',
  EmptySearchTextDesc = 'emptySearchText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResultsForTextAsc = 'resultsForText_ASC',
  ResultsForTextDesc = 'resultsForText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTitleAndDescription) */
export type FaqTitleAndDescription = Entry &
  _Node & {
    __typename?: 'FaqTitleAndDescription';
    _id: Scalars['ID'];
    content?: Maybe<FaqTitleAndDescriptionContent>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<FaqTitleAndDescriptionLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTitleAndDescription) */
export type FaqTitleAndDescriptionContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTitleAndDescription) */
export type FaqTitleAndDescriptionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTitleAndDescription) */
export type FaqTitleAndDescriptionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTitleAndDescription) */
export type FaqTitleAndDescriptionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTitleAndDescription) */
export type FaqTitleAndDescriptionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqTitleAndDescriptionCollection = {
  __typename?: 'FaqTitleAndDescriptionCollection';
  items: Array<Maybe<FaqTitleAndDescription>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqTitleAndDescriptionContent = {
  __typename?: 'FaqTitleAndDescriptionContent';
  json: Scalars['JSON'];
  links: FaqTitleAndDescriptionContentLinks;
};

export type FaqTitleAndDescriptionContentAssets = {
  __typename?: 'FaqTitleAndDescriptionContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FaqTitleAndDescriptionContentEntries = {
  __typename?: 'FaqTitleAndDescriptionContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FaqTitleAndDescriptionContentLinks = {
  __typename?: 'FaqTitleAndDescriptionContentLinks';
  assets: FaqTitleAndDescriptionContentAssets;
  entries: FaqTitleAndDescriptionContentEntries;
  resources: FaqTitleAndDescriptionContentResources;
};

export type FaqTitleAndDescriptionContentResources = {
  __typename?: 'FaqTitleAndDescriptionContentResources';
  block: Array<FaqTitleAndDescriptionContentResourcesBlock>;
  hyperlink: Array<FaqTitleAndDescriptionContentResourcesHyperlink>;
  inline: Array<FaqTitleAndDescriptionContentResourcesInline>;
};

export type FaqTitleAndDescriptionContentResourcesBlock = ResourceLink & {
  __typename?: 'FaqTitleAndDescriptionContentResourcesBlock';
  sys: ResourceSys;
};

export type FaqTitleAndDescriptionContentResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqTitleAndDescriptionContentResourcesHyperlink';
  sys: ResourceSys;
};

export type FaqTitleAndDescriptionContentResourcesInline = ResourceLink & {
  __typename?: 'FaqTitleAndDescriptionContentResourcesInline';
  sys: ResourceSys;
};

export type FaqTitleAndDescriptionFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqTitleAndDescriptionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqTitleAndDescriptionFilter>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqTitleAndDescriptionLinkingCollections = {
  __typename?: 'FaqTitleAndDescriptionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
};

export type FaqTitleAndDescriptionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqTitleAndDescriptionLinkingCollectionsFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqTitleAndDescriptionLinkingCollectionsFaqTopicsModuleItemsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqTitleAndDescriptionLinkingCollectionsFaqTopicsModuleItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqTitleAndDescriptionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopic = Entry &
  _Node & {
    __typename?: 'FaqTopic';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    faqItemsCollection?: Maybe<FaqTopicFaqItemsCollection>;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqTopicLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopicDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopicFaqItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicFaqItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopicIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopicLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopicNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopicSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopic) */
export type FaqTopicTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqTopicCollection = {
  __typename?: 'FaqTopicCollection';
  items: Array<Maybe<FaqTopic>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqTopicFaqItemsCollection = {
  __typename?: 'FaqTopicFaqItemsCollection';
  items: Array<Maybe<FaqItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqTopicFaqItemsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqTopicFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqTopicFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqTopicFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  faqItems?: InputMaybe<CfFaqItemNestedFilter>;
  faqItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqTopicLinkingCollections = {
  __typename?: 'FaqTopicLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqSectionCollection?: Maybe<FaqSectionCollection>;
};

export type FaqTopicLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqTopicLinkingCollectionsFaqSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqTopicLinkingCollectionsFaqSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqTopicLinkingCollectionsFaqSectionCollectionOrder {
  ClearTextAsc = 'clearText_ASC',
  ClearTextDesc = 'clearText_DESC',
  EmptySearchTextAsc = 'emptySearchText_ASC',
  EmptySearchTextDesc = 'emptySearchText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResultsForTextAsc = 'resultsForText_ASC',
  ResultsForTextDesc = 'resultsForText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqTopicOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModule) */
export type FaqTopicsModule = Entry &
  _Node & {
    __typename?: 'FaqTopicsModule';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FaqTopicsModuleLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    popularQuestions?: Maybe<FaqPopularQuestions>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
    topicsCollection?: Maybe<FaqTopicsModuleTopicsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModule) */
export type FaqTopicsModuleBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModule) */
export type FaqTopicsModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModule) */
export type FaqTopicsModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModule) */
export type FaqTopicsModulePopularQuestionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FaqPopularQuestionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModule) */
export type FaqTopicsModuleTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModule) */
export type FaqTopicsModuleTopicsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicsModuleTopicsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqTopicsModuleItemsFilter>;
};

export type FaqTopicsModuleCollection = {
  __typename?: 'FaqTopicsModuleCollection';
  items: Array<Maybe<FaqTopicsModule>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqTopicsModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqTopicsModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqTopicsModuleFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  popularQuestions?: InputMaybe<CfFaqPopularQuestionsNestedFilter>;
  popularQuestions_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topics?: InputMaybe<CfFaqTopicsModuleItemsNestedFilter>;
  topicsCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItems = Entry &
  _Node & {
    __typename?: 'FaqTopicsModuleItems';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    countriesCollection?: Maybe<FaqTopicsModuleItemsCountriesCollection>;
    description?: Maybe<Scalars['String']>;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<FaqTopicsModuleItemsLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    subtitleCollection?: Maybe<FaqTopicsModuleItemsSubtitleCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
    topicLinksCollection?: Maybe<FaqTopicsModuleItemsTopicLinksCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqTopicsModuleItemsCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsSubtitleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqTopicsModuleItemsSubtitleCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqTitleAndDescriptionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/faqTopicsModuleItems) */
export type FaqTopicsModuleItemsTopicLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqTopicsModuleItemsTopicLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageFilter>;
};

export type FaqTopicsModuleItemsCollection = {
  __typename?: 'FaqTopicsModuleItemsCollection';
  items: Array<Maybe<FaqTopicsModuleItems>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqTopicsModuleItemsCountriesCollection = {
  __typename?: 'FaqTopicsModuleItemsCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqTopicsModuleItemsCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FaqTopicsModuleItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqTopicsModuleItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqTopicsModuleItemsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries?: InputMaybe<CfCountryNestedFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle?: InputMaybe<CfFaqTitleAndDescriptionNestedFilter>;
  subtitleCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topicLinks?: InputMaybe<CfPageNestedFilter>;
  topicLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type FaqTopicsModuleItemsLinkingCollections = {
  __typename?: 'FaqTopicsModuleItemsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqTopicsModuleCollection?: Maybe<FaqTopicsModuleCollection>;
};

export type FaqTopicsModuleItemsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FaqTopicsModuleItemsLinkingCollectionsFaqTopicsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FaqTopicsModuleItemsLinkingCollectionsFaqTopicsModuleCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqTopicsModuleItemsLinkingCollectionsFaqTopicsModuleCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqTopicsModuleItemsOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqTopicsModuleItemsSubtitleCollection = {
  __typename?: 'FaqTopicsModuleItemsSubtitleCollection';
  items: Array<Maybe<FaqTitleAndDescription>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqTopicsModuleItemsSubtitleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqTopicsModuleItemsTopicLinksCollection = {
  __typename?: 'FaqTopicsModuleItemsTopicLinksCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqTopicsModuleItemsTopicLinksCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type FaqTopicsModuleLinkingCollections = {
  __typename?: 'FaqTopicsModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqTopicsModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqTopicsModuleOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqTopicsModuleTopicsCollection = {
  __typename?: 'FaqTopicsModuleTopicsCollection';
  items: Array<Maybe<FaqTopicsModuleItems>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqTopicsModuleTopicsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlag) */
export type FeatureFlag = Entry &
  _Node & {
    __typename?: 'FeatureFlag';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FeatureFlagLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    value?: Maybe<Scalars['Boolean']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlag) */
export type FeatureFlagLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlag) */
export type FeatureFlagNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlag) */
export type FeatureFlagValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FeatureFlagCollection = {
  __typename?: 'FeatureFlagCollection';
  items: Array<Maybe<FeatureFlag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeatureFlagFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeatureFlagFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeatureFlagFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['Boolean']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_not?: InputMaybe<Scalars['Boolean']>;
};

export type FeatureFlagLinkingCollections = {
  __typename?: 'FeatureFlagLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  featureFlagsCollection?: Maybe<FeatureFlagsCollection>;
};

export type FeatureFlagLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FeatureFlagLinkingCollectionsFeatureFlagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FeatureFlagLinkingCollectionsFeatureFlagsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FeatureFlagLinkingCollectionsFeatureFlagsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FeatureFlagOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** Feature flag collection [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlags) */
export type FeatureFlags = Entry &
  _Node & {
    __typename?: 'FeatureFlags';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    featureFlagsCollection?: Maybe<FeatureFlagsFeatureFlagsCollection>;
    linkedFrom?: Maybe<FeatureFlagsLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Feature flag collection [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlags) */
export type FeatureFlagsFeatureFlagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FeatureFlagsFeatureFlagsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureFlagFilter>;
};

/** Feature flag collection [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlags) */
export type FeatureFlagsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Feature flag collection [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/featureFlags) */
export type FeatureFlagsNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FeatureFlagsCollection = {
  __typename?: 'FeatureFlagsCollection';
  items: Array<Maybe<FeatureFlags>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeatureFlagsFeatureFlagsCollection = {
  __typename?: 'FeatureFlagsFeatureFlagsCollection';
  items: Array<Maybe<FeatureFlag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FeatureFlagsFeatureFlagsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type FeatureFlagsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeatureFlagsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeatureFlagsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featureFlags?: InputMaybe<CfFeatureFlagNestedFilter>;
  featureFlagsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FeatureFlagsLinkingCollections = {
  __typename?: 'FeatureFlagsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FeatureFlagsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FeatureFlagsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** MS TEST [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footer) */
export type Footer = Entry &
  _Node & {
    __typename?: 'Footer';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    copyrightText?: Maybe<Scalars['String']>;
    footerLogo?: Maybe<Asset>;
    linkedFrom?: Maybe<FooterLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    navigationCollection?: Maybe<FooterNavigationCollection>;
    socialIconsLinksCollection?: Maybe<FooterSocialIconsLinksCollection>;
    sys: Sys;
  };

/** MS TEST [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footer) */
export type FooterCopyrightTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footer) */
export type FooterFooterLogoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footer) */
export type FooterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footer) */
export type FooterNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footer) */
export type FooterNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterNavigationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterNavigationGroupFilter>;
};

/** MS TEST [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footer) */
export type FooterSocialIconsLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterSocialIconsLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};

export type FooterCollection = {
  __typename?: 'FooterCollection';
  items: Array<Maybe<Footer>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  copyrightText?: InputMaybe<Scalars['String']>;
  copyrightText_contains?: InputMaybe<Scalars['String']>;
  copyrightText_exists?: InputMaybe<Scalars['Boolean']>;
  copyrightText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not?: InputMaybe<Scalars['String']>;
  copyrightText_not_contains?: InputMaybe<Scalars['String']>;
  copyrightText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footerLogo_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  navigation?: InputMaybe<CfFooterNavigationGroupNestedFilter>;
  navigationCollection_exists?: InputMaybe<Scalars['Boolean']>;
  socialIconsLinks?: InputMaybe<CfLinkNestedFilter>;
  socialIconsLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type FooterLinkingCollections = {
  __typename?: 'FooterLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FooterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationCollection = {
  __typename?: 'FooterNavigationCollection';
  items: Array<Maybe<FooterNavigationGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FooterNavigationCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroup) */
export type FooterNavigationGroup = Entry &
  _Node & {
    __typename?: 'FooterNavigationGroup';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    extras?: Maybe<FooterNavigationGroupExtras>;
    linkedFrom?: Maybe<FooterNavigationGroupLinkingCollections>;
    linksCollection?: Maybe<FooterNavigationGroupLinksCollection>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupExtrasArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FooterNavigationGroupExtrasFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<FooterNavigationGroupLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroup) */
export type FooterNavigationGroupTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FooterNavigationGroupCollection = {
  __typename?: 'FooterNavigationGroupCollection';
  items: Array<Maybe<FooterNavigationGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtras = Entry &
  _Node & {
    __typename?: 'FooterNavigationGroupExtras';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FooterNavigationGroupExtrasLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/footerNavigationGroupExtras) */
export type FooterNavigationGroupExtrasTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FooterNavigationGroupExtrasCollection = {
  __typename?: 'FooterNavigationGroupExtrasCollection';
  items: Array<Maybe<FooterNavigationGroupExtras>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterNavigationGroupExtrasFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupExtrasFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupExtrasFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterNavigationGroupExtrasLinkingCollections = {
  __typename?: 'FooterNavigationGroupExtrasLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
};

export type FooterNavigationGroupExtrasLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupExtrasLinkingCollectionsFooterNavigationGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FooterNavigationGroupExtrasLinkingCollectionsFooterNavigationGroupCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FooterNavigationGroupExtrasLinkingCollectionsFooterNavigationGroupCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FooterNavigationGroupExtrasOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FooterNavigationGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterNavigationGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterNavigationGroupFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  extras?: InputMaybe<CfFooterNavigationGroupExtrasNestedFilter>;
  extras_exists?: InputMaybe<Scalars['Boolean']>;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterNavigationGroupLinkingCollections = {
  __typename?: 'FooterNavigationGroupLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerCollection?: Maybe<FooterCollection>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
};

export type FooterNavigationGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FooterNavigationGroupLinkingCollectionsFooterCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FooterNavigationGroupLinkingCollectionsLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<FooterNavigationGroupLinkingCollectionsLayoutFooterCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FooterNavigationGroupLinkingCollectionsFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FooterNavigationGroupLinkingCollectionsLayoutFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FooterNavigationGroupLinksCollection = {
  __typename?: 'FooterNavigationGroupLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FooterNavigationGroupLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum FooterNavigationGroupOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FooterOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FooterSocialIconsLinksCollection = {
  __typename?: 'FooterSocialIconsLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FooterSocialIconsLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBanner = Entry &
  _Node & {
    __typename?: 'FullWidthBanner';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    borderColor?: Maybe<Scalars['String']>;
    contentWidth?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    image?: Maybe<Asset>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<FullWidthBannerLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    secondBackgroundColor?: Maybe<Scalars['String']>;
    secondBorderColor?: Maybe<Scalars['String']>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
    text?: Maybe<FullWidthBannerText>;
    textAlign?: Maybe<Scalars['String']>;
    textColor?: Maybe<Scalars['String']>;
    textVerticalAlign?: Maybe<Scalars['String']>;
  };

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerBorderColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerContentWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerSecondBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerSecondBorderColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * Section with background color/gradient/image, Rich Text content centered vertically and optional border.
 * Xenia Module:
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4813%3A142689
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=4944%3A143745 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/fullWidthBanner)
 */
export type FullWidthBannerTextVerticalAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FullWidthBannerCollection = {
  __typename?: 'FullWidthBannerCollection';
  items: Array<Maybe<FullWidthBanner>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FullWidthBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<FullWidthBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FullWidthBannerFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  borderColor?: InputMaybe<Scalars['String']>;
  borderColor_contains?: InputMaybe<Scalars['String']>;
  borderColor_exists?: InputMaybe<Scalars['Boolean']>;
  borderColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  borderColor_not?: InputMaybe<Scalars['String']>;
  borderColor_not_contains?: InputMaybe<Scalars['String']>;
  borderColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentWidth?: InputMaybe<Scalars['String']>;
  contentWidth_contains?: InputMaybe<Scalars['String']>;
  contentWidth_exists?: InputMaybe<Scalars['Boolean']>;
  contentWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentWidth_not?: InputMaybe<Scalars['String']>;
  contentWidth_not_contains?: InputMaybe<Scalars['String']>;
  contentWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  secondBackgroundColor?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  secondBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondBackgroundColor_not?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  secondBorderColor?: InputMaybe<Scalars['String']>;
  secondBorderColor_contains?: InputMaybe<Scalars['String']>;
  secondBorderColor_exists?: InputMaybe<Scalars['Boolean']>;
  secondBorderColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondBorderColor_not?: InputMaybe<Scalars['String']>;
  secondBorderColor_not_contains?: InputMaybe<Scalars['String']>;
  secondBorderColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  textAlign?: InputMaybe<Scalars['String']>;
  textAlign_contains?: InputMaybe<Scalars['String']>;
  textAlign_exists?: InputMaybe<Scalars['Boolean']>;
  textAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textAlign_not?: InputMaybe<Scalars['String']>;
  textAlign_not_contains?: InputMaybe<Scalars['String']>;
  textAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textColor?: InputMaybe<Scalars['String']>;
  textColor_contains?: InputMaybe<Scalars['String']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textColor_not?: InputMaybe<Scalars['String']>;
  textColor_not_contains?: InputMaybe<Scalars['String']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textVerticalAlign?: InputMaybe<Scalars['String']>;
  textVerticalAlign_contains?: InputMaybe<Scalars['String']>;
  textVerticalAlign_exists?: InputMaybe<Scalars['Boolean']>;
  textVerticalAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textVerticalAlign_not?: InputMaybe<Scalars['String']>;
  textVerticalAlign_not_contains?: InputMaybe<Scalars['String']>;
  textVerticalAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
};

export type FullWidthBannerLinkingCollections = {
  __typename?: 'FullWidthBannerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FullWidthBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FullWidthBannerOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BorderColorAsc = 'borderColor_ASC',
  BorderColorDesc = 'borderColor_DESC',
  ContentWidthAsc = 'contentWidth_ASC',
  ContentWidthDesc = 'contentWidth_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SecondBorderColorAsc = 'secondBorderColor_ASC',
  SecondBorderColorDesc = 'secondBorderColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
  TextVerticalAlignAsc = 'textVerticalAlign_ASC',
  TextVerticalAlignDesc = 'textVerticalAlign_DESC',
}

export type FullWidthBannerText = {
  __typename?: 'FullWidthBannerText';
  json: Scalars['JSON'];
  links: FullWidthBannerTextLinks;
};

export type FullWidthBannerTextAssets = {
  __typename?: 'FullWidthBannerTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FullWidthBannerTextEntries = {
  __typename?: 'FullWidthBannerTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FullWidthBannerTextLinks = {
  __typename?: 'FullWidthBannerTextLinks';
  assets: FullWidthBannerTextAssets;
  entries: FullWidthBannerTextEntries;
  resources: FullWidthBannerTextResources;
};

export type FullWidthBannerTextResources = {
  __typename?: 'FullWidthBannerTextResources';
  block: Array<FullWidthBannerTextResourcesBlock>;
  hyperlink: Array<FullWidthBannerTextResourcesHyperlink>;
  inline: Array<FullWidthBannerTextResourcesInline>;
};

export type FullWidthBannerTextResourcesBlock = ResourceLink & {
  __typename?: 'FullWidthBannerTextResourcesBlock';
  sys: ResourceSys;
};

export type FullWidthBannerTextResourcesHyperlink = ResourceLink & {
  __typename?: 'FullWidthBannerTextResourcesHyperlink';
  sys: ResourceSys;
};

export type FullWidthBannerTextResourcesInline = ResourceLink & {
  __typename?: 'FullWidthBannerTextResourcesInline';
  sys: ResourceSys;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/genericErrorPage) */
export type GenericErrorPage = Entry &
  _Node & {
    __typename?: 'GenericErrorPage';
    _id: Scalars['ID'];
    buildLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<GenericErrorPageDescription>;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<GenericErrorPageLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    subDescription?: Maybe<GenericErrorPageSubDescription>;
    sys: Sys;
  };

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/genericErrorPage) */
export type GenericErrorPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/genericErrorPage) */
export type GenericErrorPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/genericErrorPage) */
export type GenericErrorPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/genericErrorPage) */
export type GenericErrorPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/genericErrorPage) */
export type GenericErrorPageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 5xx page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/genericErrorPage) */
export type GenericErrorPageSubDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GenericErrorPageCollection = {
  __typename?: 'GenericErrorPageCollection';
  items: Array<Maybe<GenericErrorPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GenericErrorPageDescription = {
  __typename?: 'GenericErrorPageDescription';
  json: Scalars['JSON'];
  links: GenericErrorPageDescriptionLinks;
};

export type GenericErrorPageDescriptionAssets = {
  __typename?: 'GenericErrorPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GenericErrorPageDescriptionEntries = {
  __typename?: 'GenericErrorPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GenericErrorPageDescriptionLinks = {
  __typename?: 'GenericErrorPageDescriptionLinks';
  assets: GenericErrorPageDescriptionAssets;
  entries: GenericErrorPageDescriptionEntries;
  resources: GenericErrorPageDescriptionResources;
};

export type GenericErrorPageDescriptionResources = {
  __typename?: 'GenericErrorPageDescriptionResources';
  block: Array<GenericErrorPageDescriptionResourcesBlock>;
  hyperlink: Array<GenericErrorPageDescriptionResourcesHyperlink>;
  inline: Array<GenericErrorPageDescriptionResourcesInline>;
};

export type GenericErrorPageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'GenericErrorPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type GenericErrorPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'GenericErrorPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type GenericErrorPageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'GenericErrorPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type GenericErrorPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<GenericErrorPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GenericErrorPageFilter>>>;
  buildLocales_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subDescription_contains?: InputMaybe<Scalars['String']>;
  subDescription_exists?: InputMaybe<Scalars['Boolean']>;
  subDescription_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
};

export type GenericErrorPageLinkingCollections = {
  __typename?: 'GenericErrorPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type GenericErrorPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GenericErrorPageOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type GenericErrorPageSubDescription = {
  __typename?: 'GenericErrorPageSubDescription';
  json: Scalars['JSON'];
  links: GenericErrorPageSubDescriptionLinks;
};

export type GenericErrorPageSubDescriptionAssets = {
  __typename?: 'GenericErrorPageSubDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GenericErrorPageSubDescriptionEntries = {
  __typename?: 'GenericErrorPageSubDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GenericErrorPageSubDescriptionLinks = {
  __typename?: 'GenericErrorPageSubDescriptionLinks';
  assets: GenericErrorPageSubDescriptionAssets;
  entries: GenericErrorPageSubDescriptionEntries;
  resources: GenericErrorPageSubDescriptionResources;
};

export type GenericErrorPageSubDescriptionResources = {
  __typename?: 'GenericErrorPageSubDescriptionResources';
  block: Array<GenericErrorPageSubDescriptionResourcesBlock>;
  hyperlink: Array<GenericErrorPageSubDescriptionResourcesHyperlink>;
  inline: Array<GenericErrorPageSubDescriptionResourcesInline>;
};

export type GenericErrorPageSubDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'GenericErrorPageSubDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type GenericErrorPageSubDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'GenericErrorPageSubDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type GenericErrorPageSubDescriptionResourcesInline = ResourceLink & {
  __typename?: 'GenericErrorPageSubDescriptionResourcesInline';
  sys: ResourceSys;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlot = Entry &
  _Node & {
    __typename?: 'HeroSlot';
    _id: Scalars['ID'];
    appStoreLink?: Maybe<Link>;
    buttonTitle?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    dividerLabel?: Maybe<Scalars['String']>;
    downloadAppsPageLink?: Maybe<Link>;
    googlePlayStoreLink?: Maybe<Link>;
    header?: Maybe<Scalars['String']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<HeroSlotLinkingCollections>;
    loginInLinkTitle?: Maybe<Scalars['String']>;
    loginInText?: Maybe<Scalars['String']>;
    loginPageLink?: Maybe<Link>;
    name?: Maybe<Scalars['String']>;
    qrCode?: Maybe<Asset>;
    qrCodeLabel?: Maybe<Scalars['String']>;
    subheader?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotAppStoreLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotButtonTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotDividerLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotDownloadAppsPageLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotGooglePlayStoreLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotLoginInLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotLoginInTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotLoginPageLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotQrCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotQrCodeLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Path to mobile: https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=2732%3A68726&amp;t=Sg5JfAb9gRWqwHfZ-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroSlot) */
export type HeroSlotSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HeroSlotCollection = {
  __typename?: 'HeroSlotCollection';
  items: Array<Maybe<HeroSlot>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HeroSlotFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeroSlotFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HeroSlotFilter>>>;
  appStoreLink?: InputMaybe<CfLinkNestedFilter>;
  appStoreLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonTitle?: InputMaybe<Scalars['String']>;
  buttonTitle_contains?: InputMaybe<Scalars['String']>;
  buttonTitle_exists?: InputMaybe<Scalars['Boolean']>;
  buttonTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonTitle_not?: InputMaybe<Scalars['String']>;
  buttonTitle_not_contains?: InputMaybe<Scalars['String']>;
  buttonTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dividerLabel?: InputMaybe<Scalars['String']>;
  dividerLabel_contains?: InputMaybe<Scalars['String']>;
  dividerLabel_exists?: InputMaybe<Scalars['Boolean']>;
  dividerLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dividerLabel_not?: InputMaybe<Scalars['String']>;
  dividerLabel_not_contains?: InputMaybe<Scalars['String']>;
  dividerLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  downloadAppsPageLink?: InputMaybe<CfLinkNestedFilter>;
  downloadAppsPageLink_exists?: InputMaybe<Scalars['Boolean']>;
  googlePlayStoreLink?: InputMaybe<CfLinkNestedFilter>;
  googlePlayStoreLink_exists?: InputMaybe<Scalars['Boolean']>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  loginInLinkTitle?: InputMaybe<Scalars['String']>;
  loginInLinkTitle_contains?: InputMaybe<Scalars['String']>;
  loginInLinkTitle_exists?: InputMaybe<Scalars['Boolean']>;
  loginInLinkTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loginInLinkTitle_not?: InputMaybe<Scalars['String']>;
  loginInLinkTitle_not_contains?: InputMaybe<Scalars['String']>;
  loginInLinkTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loginInText?: InputMaybe<Scalars['String']>;
  loginInText_contains?: InputMaybe<Scalars['String']>;
  loginInText_exists?: InputMaybe<Scalars['Boolean']>;
  loginInText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loginInText_not?: InputMaybe<Scalars['String']>;
  loginInText_not_contains?: InputMaybe<Scalars['String']>;
  loginInText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loginPageLink?: InputMaybe<CfLinkNestedFilter>;
  loginPageLink_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qrCodeLabel?: InputMaybe<Scalars['String']>;
  qrCodeLabel_contains?: InputMaybe<Scalars['String']>;
  qrCodeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  qrCodeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qrCodeLabel_not?: InputMaybe<Scalars['String']>;
  qrCodeLabel_not_contains?: InputMaybe<Scalars['String']>;
  qrCodeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qrCode_exists?: InputMaybe<Scalars['Boolean']>;
  subheader?: InputMaybe<Scalars['String']>;
  subheader_contains?: InputMaybe<Scalars['String']>;
  subheader_exists?: InputMaybe<Scalars['Boolean']>;
  subheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subheader_not?: InputMaybe<Scalars['String']>;
  subheader_not_contains?: InputMaybe<Scalars['String']>;
  subheader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type HeroSlotLinkingCollections = {
  __typename?: 'HeroSlotLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HeroSlotLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HeroSlotOrder {
  ButtonTitleAsc = 'buttonTitle_ASC',
  ButtonTitleDesc = 'buttonTitle_DESC',
  DividerLabelAsc = 'dividerLabel_ASC',
  DividerLabelDesc = 'dividerLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  LoginInLinkTitleAsc = 'loginInLinkTitle_ASC',
  LoginInLinkTitleDesc = 'loginInLinkTitle_DESC',
  LoginInTextAsc = 'loginInText_ASC',
  LoginInTextDesc = 'loginInText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  QrCodeLabelAsc = 'qrCodeLabel_ASC',
  QrCodeLabelDesc = 'qrCodeLabel_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariation = Entry &
  _Node & {
    __typename?: 'HeroTextVariation';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundImage?: Maybe<Asset>;
    bottomWaveImage?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    heading?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<HeroTextVariationLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    textColor?: Maybe<Scalars['String']>;
  };

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationBottomWaveImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Sendwave: https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=918%3A11055&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/heroTextVariation) */
export type HeroTextVariationTextColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HeroTextVariationCollection = {
  __typename?: 'HeroTextVariationCollection';
  items: Array<Maybe<HeroTextVariation>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HeroTextVariationFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeroTextVariationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HeroTextVariationFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  bottomWaveImage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  textColor?: InputMaybe<Scalars['String']>;
  textColor_contains?: InputMaybe<Scalars['String']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textColor_not?: InputMaybe<Scalars['String']>;
  textColor_not_contains?: InputMaybe<Scalars['String']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HeroTextVariationLinkingCollections = {
  __typename?: 'HeroTextVariationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HeroTextVariationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HeroTextVariationOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/iconWithText) */
export type IconWithText = Entry &
  _Node & {
    __typename?: 'IconWithText';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<IconWithTextLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    text?: Maybe<TextWithColor>;
  };

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/iconWithText) */
export type IconWithTextIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/iconWithText) */
export type IconWithTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/iconWithText) */
export type IconWithTextNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Small icon asset with text using TextWithColor content model [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/iconWithText) */
export type IconWithTextTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<TextWithColorFilter>;
};

export type IconWithTextCollection = {
  __typename?: 'IconWithTextCollection';
  items: Array<Maybe<IconWithText>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type IconWithTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<IconWithTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<IconWithTextFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  text?: InputMaybe<CfTextWithColorNestedFilter>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
};

export type IconWithTextLinkingCollections = {
  __typename?: 'IconWithTextLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type IconWithTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type IconWithTextLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<IconWithTextLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum IconWithTextLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum IconWithTextOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type Image = Entry &
  _Node & {
    __typename?: 'Image';
    _id: Scalars['ID'];
    align?: Maybe<Scalars['String']>;
    asset?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    height?: Maybe<Scalars['Int']>;
    linkedFrom?: Maybe<ImageLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    position?: Maybe<Scalars['String']>;
    sys: Sys;
    width?: Maybe<Scalars['Int']>;
  };

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type ImageAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type ImageAssetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type ImageHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type ImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type ImageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type ImagePositionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Image asset with alignment and size options [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/image) */
export type ImageWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ImageCollection = {
  __typename?: 'ImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  asset_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position?: InputMaybe<Scalars['String']>;
  position_contains?: InputMaybe<Scalars['String']>;
  position_exists?: InputMaybe<Scalars['Boolean']>;
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position_not?: InputMaybe<Scalars['String']>;
  position_not_contains?: InputMaybe<Scalars['String']>;
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGrid) */
export type ImageGrid = Entry &
  _Node & {
    __typename?: 'ImageGrid';
    _id: Scalars['ID'];
    content?: Maybe<ImageGridContent>;
    contentfulMetadata: ContentfulMetadata;
    gridColumnSize?: Maybe<Scalars['Int']>;
    gridItemsCollection?: Maybe<ImageGridGridItemsCollection>;
    heading?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<ImageGridLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGrid) */
export type ImageGridContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGrid) */
export type ImageGridGridColumnSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGrid) */
export type ImageGridGridItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageGridGridItemsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageGridItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGrid) */
export type ImageGridHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGrid) */
export type ImageGridLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGrid) */
export type ImageGridNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ImageGridCollection = {
  __typename?: 'ImageGridCollection';
  items: Array<Maybe<ImageGrid>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ImageGridContent = {
  __typename?: 'ImageGridContent';
  json: Scalars['JSON'];
  links: ImageGridContentLinks;
};

export type ImageGridContentAssets = {
  __typename?: 'ImageGridContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ImageGridContentEntries = {
  __typename?: 'ImageGridContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ImageGridContentLinks = {
  __typename?: 'ImageGridContentLinks';
  assets: ImageGridContentAssets;
  entries: ImageGridContentEntries;
  resources: ImageGridContentResources;
};

export type ImageGridContentResources = {
  __typename?: 'ImageGridContentResources';
  block: Array<ImageGridContentResourcesBlock>;
  hyperlink: Array<ImageGridContentResourcesHyperlink>;
  inline: Array<ImageGridContentResourcesInline>;
};

export type ImageGridContentResourcesBlock = ResourceLink & {
  __typename?: 'ImageGridContentResourcesBlock';
  sys: ResourceSys;
};

export type ImageGridContentResourcesHyperlink = ResourceLink & {
  __typename?: 'ImageGridContentResourcesHyperlink';
  sys: ResourceSys;
};

export type ImageGridContentResourcesInline = ResourceLink & {
  __typename?: 'ImageGridContentResourcesInline';
  sys: ResourceSys;
};

export type ImageGridFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageGridFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageGridFilter>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gridColumnSize?: InputMaybe<Scalars['Int']>;
  gridColumnSize_exists?: InputMaybe<Scalars['Boolean']>;
  gridColumnSize_gt?: InputMaybe<Scalars['Int']>;
  gridColumnSize_gte?: InputMaybe<Scalars['Int']>;
  gridColumnSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  gridColumnSize_lt?: InputMaybe<Scalars['Int']>;
  gridColumnSize_lte?: InputMaybe<Scalars['Int']>;
  gridColumnSize_not?: InputMaybe<Scalars['Int']>;
  gridColumnSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  gridItems?: InputMaybe<CfImageGridItemNestedFilter>;
  gridItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ImageGridGridItemsCollection = {
  __typename?: 'ImageGridGridItemsCollection';
  items: Array<Maybe<ImageGridItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ImageGridGridItemsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGridItem) */
export type ImageGridItem = Entry &
  _Node & {
    __typename?: 'ImageGridItem';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<ImageGridItemLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGridItem) */
export type ImageGridItemImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGridItem) */
export type ImageGridItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/imageGridItem) */
export type ImageGridItemNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ImageGridItemCollection = {
  __typename?: 'ImageGridItemCollection';
  items: Array<Maybe<ImageGridItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ImageGridItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageGridItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageGridItemFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ImageGridItemLinkingCollections = {
  __typename?: 'ImageGridItemLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  imageGridCollection?: Maybe<ImageGridCollection>;
};

export type ImageGridItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ImageGridItemLinkingCollectionsImageGridCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<ImageGridItemLinkingCollectionsImageGridCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ImageGridItemLinkingCollectionsImageGridCollectionOrder {
  GridColumnSizeAsc = 'gridColumnSize_ASC',
  GridColumnSizeDesc = 'gridColumnSize_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageGridItemOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ImageGridLinkingCollections = {
  __typename?: 'ImageGridLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ImageGridLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ImageGridOrder {
  GridColumnSizeAsc = 'gridColumnSize_ASC',
  GridColumnSizeDesc = 'gridColumnSize_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ImageLinkingCollections = {
  __typename?: 'ImageLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ImageLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<ImageLinkingCollectionsBetterWayTopCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ImageLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum ImageOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/infoCards) */
export type InfoCards = Entry &
  _Node & {
    __typename?: 'InfoCards';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    cardsCollection?: Maybe<InfoCardsCardsCollection>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<InfoCardsLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/infoCards) */
export type InfoCardsBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/infoCards) */
export type InfoCardsCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<InfoCardsCardsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TextCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/infoCards) */
export type InfoCardsHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/infoCards) */
export type InfoCardsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/infoCards) */
export type InfoCardsNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type InfoCardsCardsCollection = {
  __typename?: 'InfoCardsCardsCollection';
  items: Array<Maybe<TextCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum InfoCardsCardsCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

export type InfoCardsCollection = {
  __typename?: 'InfoCardsCollection';
  items: Array<Maybe<InfoCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type InfoCardsFilter = {
  AND?: InputMaybe<Array<InputMaybe<InfoCardsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<InfoCardsFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cards?: InputMaybe<CfTextCardNestedFilter>;
  cardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type InfoCardsLinkingCollections = {
  __typename?: 'InfoCardsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type InfoCardsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum InfoCardsOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooter = Entry &
  _Node & {
    __typename?: 'LayoutFooter';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    copyrightText?: Maybe<Scalars['String']>;
    footerLogo?: Maybe<Asset>;
    linkedFrom?: Maybe<LayoutFooterLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    navigationCollection?: Maybe<LayoutFooterNavigationCollection>;
    region?: Maybe<Scalars['String']>;
    socialIconsLinksCollection?: Maybe<LayoutFooterSocialIconsLinksCollection>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooterCopyrightTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooterFooterLogoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooterNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooterNavigationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LayoutFooterNavigationCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterNavigationGroupFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooterRegionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutFooter) */
export type LayoutFooterSocialIconsLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LayoutFooterSocialIconsLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};

export type LayoutFooterCollection = {
  __typename?: 'LayoutFooterCollection';
  items: Array<Maybe<LayoutFooter>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LayoutFooterFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutFooterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutFooterFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  copyrightText?: InputMaybe<Scalars['String']>;
  copyrightText_contains?: InputMaybe<Scalars['String']>;
  copyrightText_exists?: InputMaybe<Scalars['Boolean']>;
  copyrightText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not?: InputMaybe<Scalars['String']>;
  copyrightText_not_contains?: InputMaybe<Scalars['String']>;
  copyrightText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footerLogo_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  navigation?: InputMaybe<CfFooterNavigationGroupNestedFilter>;
  navigationCollection_exists?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  region_contains?: InputMaybe<Scalars['String']>;
  region_exists?: InputMaybe<Scalars['Boolean']>;
  region_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  region_not?: InputMaybe<Scalars['String']>;
  region_not_contains?: InputMaybe<Scalars['String']>;
  region_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialIconsLinks?: InputMaybe<CfLinkNestedFilter>;
  socialIconsLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type LayoutFooterLinkingCollections = {
  __typename?: 'LayoutFooterLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};

export type LayoutFooterLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LayoutFooterLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LayoutFooterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LayoutFooterLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LayoutFooterLinkingCollectionsPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LayoutFooterLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LayoutFooterLinkingCollectionsPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type LayoutFooterNavigationCollection = {
  __typename?: 'LayoutFooterNavigationCollection';
  items: Array<Maybe<FooterNavigationGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LayoutFooterNavigationCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LayoutFooterOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LayoutFooterSocialIconsLinksCollection = {
  __typename?: 'LayoutFooterSocialIconsLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LayoutFooterSocialIconsLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeader = Entry &
  _Node & {
    __typename?: 'LayoutHeader';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    languageSwitcherOption?: Maybe<Scalars['String']>;
    lastLogin?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<LayoutHeaderLinkingCollections>;
    loggedInMenuItemsCollection?: Maybe<LayoutHeaderLoggedInMenuItemsCollection>;
    logo?: Maybe<Asset>;
    logoDark?: Maybe<Asset>;
    logoDarkMobile?: Maybe<Asset>;
    logoLight?: Maybe<Asset>;
    logoLightMobile?: Maybe<Asset>;
    logoRelaunch?: Maybe<Asset>;
    logoRelaunchMobile?: Maybe<Asset>;
    menuLabel?: Maybe<Scalars['String']>;
    mobileAppLeadingLinks?: Maybe<MobileAppLeadingLinks>;
    myAccount?: Maybe<Scalars['String']>;
    myAccountLogoDark?: Maybe<Asset>;
    myAccountLogoLight?: Maybe<Asset>;
    name?: Maybe<Scalars['String']>;
    notLoggedInMenuItemsCollection?: Maybe<LayoutHeaderNotLoggedInMenuItemsCollection>;
    referFriendLink?: Maybe<LayoutHeaderReferFriendLink>;
    sendFrom?: Maybe<Scalars['String']>;
    skipToMainContent?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
    userDropdownMenuItemsCollection?: Maybe<LayoutHeaderUserDropdownMenuItemsCollection>;
    welcome?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLanguageSwitcherOptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLastLoginArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLoggedInMenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LayoutHeaderLoggedInMenuItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLogoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLogoDarkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLogoDarkMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLogoLightArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLogoLightMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLogoRelaunchArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderLogoRelaunchMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderMenuLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderMobileAppLeadingLinksArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<MobileAppLeadingLinksFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderMyAccountArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderMyAccountLogoDarkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderMyAccountLogoLightArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderNotLoggedInMenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LayoutHeaderNotLoggedInMenuItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderReferFriendLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LayoutHeaderReferFriendLinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderSendFromArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderSkipToMainContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderUserDropdownMenuItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LayoutHeaderUserDropdownMenuItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/layoutHeader) */
export type LayoutHeaderWelcomeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LayoutHeaderCollection = {
  __typename?: 'LayoutHeaderCollection';
  items: Array<Maybe<LayoutHeader>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LayoutHeaderFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  languageSwitcherOption?: InputMaybe<Scalars['String']>;
  languageSwitcherOption_contains?: InputMaybe<Scalars['String']>;
  languageSwitcherOption_exists?: InputMaybe<Scalars['Boolean']>;
  languageSwitcherOption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  languageSwitcherOption_not?: InputMaybe<Scalars['String']>;
  languageSwitcherOption_not_contains?: InputMaybe<Scalars['String']>;
  languageSwitcherOption_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  lastLogin?: InputMaybe<Scalars['String']>;
  lastLogin_contains?: InputMaybe<Scalars['String']>;
  lastLogin_exists?: InputMaybe<Scalars['Boolean']>;
  lastLogin_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastLogin_not?: InputMaybe<Scalars['String']>;
  lastLogin_not_contains?: InputMaybe<Scalars['String']>;
  lastLogin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loggedInMenuItems?: InputMaybe<CfloggedInMenuItemsMultiTypeNestedFilter>;
  loggedInMenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  logoDarkMobile_exists?: InputMaybe<Scalars['Boolean']>;
  logoDark_exists?: InputMaybe<Scalars['Boolean']>;
  logoLightMobile_exists?: InputMaybe<Scalars['Boolean']>;
  logoLight_exists?: InputMaybe<Scalars['Boolean']>;
  logoRelaunchMobile_exists?: InputMaybe<Scalars['Boolean']>;
  logoRelaunch_exists?: InputMaybe<Scalars['Boolean']>;
  logo_exists?: InputMaybe<Scalars['Boolean']>;
  menuLabel?: InputMaybe<Scalars['String']>;
  menuLabel_contains?: InputMaybe<Scalars['String']>;
  menuLabel_exists?: InputMaybe<Scalars['Boolean']>;
  menuLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  menuLabel_not?: InputMaybe<Scalars['String']>;
  menuLabel_not_contains?: InputMaybe<Scalars['String']>;
  menuLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppLeadingLinks?: InputMaybe<CfMobileAppLeadingLinksNestedFilter>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']>;
  myAccount?: InputMaybe<Scalars['String']>;
  myAccountLogoDark_exists?: InputMaybe<Scalars['Boolean']>;
  myAccountLogoLight_exists?: InputMaybe<Scalars['Boolean']>;
  myAccount_contains?: InputMaybe<Scalars['String']>;
  myAccount_exists?: InputMaybe<Scalars['Boolean']>;
  myAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  myAccount_not?: InputMaybe<Scalars['String']>;
  myAccount_not_contains?: InputMaybe<Scalars['String']>;
  myAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notLoggedInMenuItems?: InputMaybe<CfnotLoggedInMenuItemsMultiTypeNestedFilter>;
  notLoggedInMenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  referFriendLink?: InputMaybe<CfreferFriendLinkMultiTypeNestedFilter>;
  referFriendLink_exists?: InputMaybe<Scalars['Boolean']>;
  sendFrom?: InputMaybe<Scalars['String']>;
  sendFrom_contains?: InputMaybe<Scalars['String']>;
  sendFrom_exists?: InputMaybe<Scalars['Boolean']>;
  sendFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendFrom_not?: InputMaybe<Scalars['String']>;
  sendFrom_not_contains?: InputMaybe<Scalars['String']>;
  sendFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  skipToMainContent?: InputMaybe<Scalars['String']>;
  skipToMainContent_contains?: InputMaybe<Scalars['String']>;
  skipToMainContent_exists?: InputMaybe<Scalars['Boolean']>;
  skipToMainContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  skipToMainContent_not?: InputMaybe<Scalars['String']>;
  skipToMainContent_not_contains?: InputMaybe<Scalars['String']>;
  skipToMainContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userDropdownMenuItems?: InputMaybe<CfuserDropdownMenuItemsMultiTypeNestedFilter>;
  userDropdownMenuItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  welcome?: InputMaybe<Scalars['String']>;
  welcome_contains?: InputMaybe<Scalars['String']>;
  welcome_exists?: InputMaybe<Scalars['Boolean']>;
  welcome_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  welcome_not?: InputMaybe<Scalars['String']>;
  welcome_not_contains?: InputMaybe<Scalars['String']>;
  welcome_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LayoutHeaderLinkingCollections = {
  __typename?: 'LayoutHeaderLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LayoutHeaderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LayoutHeaderLoggedInMenuItemsCollection = {
  __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
  items: Array<Maybe<LayoutHeaderLoggedInMenuItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LayoutHeaderLoggedInMenuItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderLoggedInMenuItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderLoggedInMenuItemsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type LayoutHeaderLoggedInMenuItemsItem = Link | LinkGroup;

export type LayoutHeaderNotLoggedInMenuItemsCollection = {
  __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
  items: Array<Maybe<LayoutHeaderNotLoggedInMenuItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LayoutHeaderNotLoggedInMenuItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderNotLoggedInMenuItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderNotLoggedInMenuItemsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type LayoutHeaderNotLoggedInMenuItemsItem = Link | LinkGroup;

export enum LayoutHeaderOrder {
  LanguageSwitcherOptionAsc = 'languageSwitcherOption_ASC',
  LanguageSwitcherOptionDesc = 'languageSwitcherOption_DESC',
  LastLoginAsc = 'lastLogin_ASC',
  LastLoginDesc = 'lastLogin_DESC',
  MenuLabelAsc = 'menuLabel_ASC',
  MenuLabelDesc = 'menuLabel_DESC',
  MyAccountAsc = 'myAccount_ASC',
  MyAccountDesc = 'myAccount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SendFromAsc = 'sendFrom_ASC',
  SendFromDesc = 'sendFrom_DESC',
  SkipToMainContentAsc = 'skipToMainContent_ASC',
  SkipToMainContentDesc = 'skipToMainContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WelcomeAsc = 'welcome_ASC',
  WelcomeDesc = 'welcome_DESC',
}

export type LayoutHeaderReferFriendLink = Link;

export type LayoutHeaderReferFriendLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderReferFriendLinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderReferFriendLinkFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel?: InputMaybe<Scalars['String']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlighted?: InputMaybe<Scalars['Boolean']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']>;
  underline_contains?: InputMaybe<Scalars['String']>;
  underline_exists?: InputMaybe<Scalars['Boolean']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  underline_not?: InputMaybe<Scalars['String']>;
  underline_not_contains?: InputMaybe<Scalars['String']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant?: InputMaybe<Scalars['String']>;
  variant_contains?: InputMaybe<Scalars['String']>;
  variant_exists?: InputMaybe<Scalars['Boolean']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant_not?: InputMaybe<Scalars['String']>;
  variant_not_contains?: InputMaybe<Scalars['String']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LayoutHeaderUserDropdownMenuItemsCollection = {
  __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
  items: Array<Maybe<LayoutHeaderUserDropdownMenuItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LayoutHeaderUserDropdownMenuItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LayoutHeaderUserDropdownMenuItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LayoutHeaderUserDropdownMenuItemsFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel?: InputMaybe<Scalars['String']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlighted?: InputMaybe<Scalars['Boolean']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']>;
  underline_contains?: InputMaybe<Scalars['String']>;
  underline_exists?: InputMaybe<Scalars['Boolean']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  underline_not?: InputMaybe<Scalars['String']>;
  underline_not_contains?: InputMaybe<Scalars['String']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant?: InputMaybe<Scalars['String']>;
  variant_contains?: InputMaybe<Scalars['String']>;
  variant_exists?: InputMaybe<Scalars['Boolean']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant_not?: InputMaybe<Scalars['String']>;
  variant_not_contains?: InputMaybe<Scalars['String']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LayoutHeaderUserDropdownMenuItemsItem = Link;

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeam) */
export type LeadershipTeam = Entry &
  _Node & {
    __typename?: 'LeadershipTeam';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<LeadershipTeamLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    teamMembersCollection?: Maybe<LeadershipTeamTeamMembersCollection>;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeam) */
export type LeadershipTeamDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeam) */
export type LeadershipTeamLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeam) */
export type LeadershipTeamNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeam) */
export type LeadershipTeamTeamMembersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LeadershipTeamTeamMembersCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeadershipTeamEntryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeam) */
export type LeadershipTeamTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LeadershipTeamCollection = {
  __typename?: 'LeadershipTeamCollection';
  items: Array<Maybe<LeadershipTeam>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeamEntry) */
export type LeadershipTeamEntry = Entry &
  _Node & {
    __typename?: 'LeadershipTeamEntry';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    image?: Maybe<Asset>;
    jobTitle?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<LeadershipTeamEntryLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeamEntry) */
export type LeadershipTeamEntryImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeamEntry) */
export type LeadershipTeamEntryJobTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeamEntry) */
export type LeadershipTeamEntryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/leadershipTeamEntry) */
export type LeadershipTeamEntryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LeadershipTeamEntryCollection = {
  __typename?: 'LeadershipTeamEntryCollection';
  items: Array<Maybe<LeadershipTeamEntry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LeadershipTeamEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<LeadershipTeamEntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LeadershipTeamEntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  jobTitle_contains?: InputMaybe<Scalars['String']>;
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobTitle_not?: InputMaybe<Scalars['String']>;
  jobTitle_not_contains?: InputMaybe<Scalars['String']>;
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type LeadershipTeamEntryLinkingCollections = {
  __typename?: 'LeadershipTeamEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  leadershipTeamCollection?: Maybe<LeadershipTeamCollection>;
};

export type LeadershipTeamEntryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LeadershipTeamEntryLinkingCollectionsLeadershipTeamCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LeadershipTeamEntryLinkingCollectionsLeadershipTeamCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LeadershipTeamEntryLinkingCollectionsLeadershipTeamCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LeadershipTeamEntryOrder {
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LeadershipTeamFilter = {
  AND?: InputMaybe<Array<InputMaybe<LeadershipTeamFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LeadershipTeamFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  teamMembers?: InputMaybe<CfLeadershipTeamEntryNestedFilter>;
  teamMembersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LeadershipTeamLinkingCollections = {
  __typename?: 'LeadershipTeamLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LeadershipTeamLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LeadershipTeamOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type LeadershipTeamTeamMembersCollection = {
  __typename?: 'LeadershipTeamTeamMembersCollection';
  items: Array<Maybe<LeadershipTeamEntry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LeadershipTeamTeamMembersCollectionOrder {
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessaging = Entry &
  _Node & {
    __typename?: 'LegalMessaging';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    content?: Maybe<LegalMessagingContent>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<LegalMessagingLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessagingAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessagingAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessagingContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessagingLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessagingNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessagingReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/legalMessaging) */
export type LegalMessagingSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LegalMessagingCollection = {
  __typename?: 'LegalMessagingCollection';
  items: Array<Maybe<LegalMessaging>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LegalMessagingContent = {
  __typename?: 'LegalMessagingContent';
  json: Scalars['JSON'];
  links: LegalMessagingContentLinks;
};

export type LegalMessagingContentAssets = {
  __typename?: 'LegalMessagingContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type LegalMessagingContentEntries = {
  __typename?: 'LegalMessagingContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type LegalMessagingContentLinks = {
  __typename?: 'LegalMessagingContentLinks';
  assets: LegalMessagingContentAssets;
  entries: LegalMessagingContentEntries;
  resources: LegalMessagingContentResources;
};

export type LegalMessagingContentResources = {
  __typename?: 'LegalMessagingContentResources';
  block: Array<LegalMessagingContentResourcesBlock>;
  hyperlink: Array<LegalMessagingContentResourcesHyperlink>;
  inline: Array<LegalMessagingContentResourcesInline>;
};

export type LegalMessagingContentResourcesBlock = ResourceLink & {
  __typename?: 'LegalMessagingContentResourcesBlock';
  sys: ResourceSys;
};

export type LegalMessagingContentResourcesHyperlink = ResourceLink & {
  __typename?: 'LegalMessagingContentResourcesHyperlink';
  sys: ResourceSys;
};

export type LegalMessagingContentResourcesInline = ResourceLink & {
  __typename?: 'LegalMessagingContentResourcesInline';
  sys: ResourceSys;
};

export type LegalMessagingFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalMessagingFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LegalMessagingFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type LegalMessagingLinkingCollections = {
  __typename?: 'LegalMessagingLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LegalMessagingLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LegalMessagingOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type Link = Entry &
  _Node & {
    __typename?: 'Link';
    _id: Scalars['ID'];
    align?: Maybe<Scalars['String']>;
    ariaLabel?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    gaTrackName?: Maybe<Scalars['String']>;
    highlighted?: Maybe<Scalars['Boolean']>;
    image?: Maybe<Asset>;
    isDisplayedWhenLoggedIn?: Maybe<Scalars['Boolean']>;
    isInternal?: Maybe<Scalars['Boolean']>;
    label?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<LinkLinkingCollections>;
    mobileAppLeadingLinks?: Maybe<MobileAppLeadingLinks>;
    mobileMenuToggle?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    noFollow?: Maybe<Scalars['Boolean']>;
    openInNewTab?: Maybe<Scalars['Boolean']>;
    redirectToLegacyProject?: Maybe<Scalars['Boolean']>;
    sys: Sys;
    underline?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
    variant?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkAriaLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkGaTrackNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkHighlightedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkIsDisplayedWhenLoggedInArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkIsInternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkMobileAppLeadingLinksArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<MobileAppLeadingLinksFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkMobileMenuToggleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkNoFollowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkOpenInNewTabArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkRedirectToLegacyProjectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkUnderlineArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/link) */
export type LinkVariantArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LinkCollection = {
  __typename?: 'LinkCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel?: InputMaybe<Scalars['String']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlighted?: InputMaybe<Scalars['Boolean']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppLeadingLinks?: InputMaybe<CfMobileAppLeadingLinksNestedFilter>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']>;
  underline_contains?: InputMaybe<Scalars['String']>;
  underline_exists?: InputMaybe<Scalars['Boolean']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  underline_not?: InputMaybe<Scalars['String']>;
  underline_not_contains?: InputMaybe<Scalars['String']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant?: InputMaybe<Scalars['String']>;
  variant_contains?: InputMaybe<Scalars['String']>;
  variant_exists?: InputMaybe<Scalars['Boolean']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant_not?: InputMaybe<Scalars['String']>;
  variant_not_contains?: InputMaybe<Scalars['String']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Grouping of links for use in dropdown menus [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/linkGroup) */
export type LinkGroup = Entry &
  _Node & {
    __typename?: 'LinkGroup';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    label?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<LinkGroupLinkingCollections>;
    linksCollection?: Maybe<LinkGroupLinksCollection>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Grouping of links for use in dropdown menus [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/linkGroup) */
export type LinkGroupLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Grouping of links for use in dropdown menus [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/linkGroup) */
export type LinkGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Grouping of links for use in dropdown menus [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/linkGroup) */
export type LinkGroupLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LinkGroupLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};

/** Grouping of links for use in dropdown menus [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/linkGroup) */
export type LinkGroupNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LinkGroupCollection = {
  __typename?: 'LinkGroupCollection';
  items: Array<Maybe<LinkGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LinkGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LinkGroupFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type LinkGroupLinkingCollections = {
  __typename?: 'LinkGroupLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
};

export type LinkGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkGroupLinkingCollectionsLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkGroupLinkingCollectionsLayoutHeaderCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LinkGroupLinkingCollectionsLayoutHeaderCollectionOrder {
  LanguageSwitcherOptionAsc = 'languageSwitcherOption_ASC',
  LanguageSwitcherOptionDesc = 'languageSwitcherOption_DESC',
  LastLoginAsc = 'lastLogin_ASC',
  LastLoginDesc = 'lastLogin_DESC',
  MenuLabelAsc = 'menuLabel_ASC',
  MenuLabelDesc = 'menuLabel_DESC',
  MyAccountAsc = 'myAccount_ASC',
  MyAccountDesc = 'myAccount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SendFromAsc = 'sendFrom_ASC',
  SendFromDesc = 'sendFrom_DESC',
  SkipToMainContentAsc = 'skipToMainContent_ASC',
  SkipToMainContentDesc = 'skipToMainContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WelcomeAsc = 'welcome_ASC',
  WelcomeDesc = 'welcome_DESC',
}

export type LinkGroupLinksCollection = {
  __typename?: 'LinkGroupLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LinkGroupLinksCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum LinkGroupOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LinkLinkingCollections = {
  __typename?: 'LinkLinkingCollections';
  appBannerCollection?: Maybe<AppBannerCollection>;
  appBannerSectionCollection?: Maybe<AppBannerSectionCollection>;
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  calculatorAppLeadingLinksCollection?: Maybe<CalculatorAppLeadingLinksCollection>;
  calculatorCollection?: Maybe<CalculatorCollection>;
  cardCollection?: Maybe<CardCollection>;
  containedBannerCollection?: Maybe<ContainedBannerCollection>;
  countryCollection?: Maybe<CountryCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
  footerCollection?: Maybe<FooterCollection>;
  footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
  fullWidthBannerCollection?: Maybe<FullWidthBannerCollection>;
  heroSlotCollection?: Maybe<HeroSlotCollection>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
  linkGroupCollection?: Maybe<LinkGroupCollection>;
  mobileAppLeadingLinksCollection?: Maybe<MobileAppLeadingLinksCollection>;
  notFoundContentCollection?: Maybe<NotFoundContentCollection>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  stepByStepCollection?: Maybe<StepByStepCollection>;
  textCardCollection?: Maybe<TextCardCollection>;
};

export type LinkLinkingCollectionsAppBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsAppBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsAppBannerSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsAppBannerSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsBetterWayTopCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsCalculatorAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsCalculatorAppLeadingLinksCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCalculatorCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsContainedBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsContainedBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsCountryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCountryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFaqHeroCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFaqModuleCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFooterCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsFooterNavigationGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsFooterNavigationGroupCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsFullWidthBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFullWidthBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsHeroSlotCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsHeroSlotCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLayoutFooterCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLayoutHeaderCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsLinkGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLinkGroupCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsMobileAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsMobileAppLeadingLinksCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsNotFoundContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsNotFoundContentCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPayoutMethodCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPromotionalBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsStepByStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsStepByStepCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkLinkingCollectionsTextCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsTextCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LinkLinkingCollectionsAppBannerCollectionOrder {
  BannerDescriptionAsc = 'bannerDescription_ASC',
  BannerDescriptionDesc = 'bannerDescription_DESC',
  BannerTitleAsc = 'bannerTitle_ASC',
  BannerTitleDesc = 'bannerTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsAppBannerSectionCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum LinkLinkingCollectionsCalculatorAppLeadingLinksCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsCalculatorCollectionOrder {
  CountriesSearchPlaceholderAsc = 'countriesSearchPlaceholder_ASC',
  CountriesSearchPlaceholderDesc = 'countriesSearchPlaceholder_DESC',
  EstimatedFeeLabelAsc = 'estimatedFeeLabel_ASC',
  EstimatedFeeLabelDesc = 'estimatedFeeLabel_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  ExchangeRatePromoLabelAsc = 'exchangeRatePromoLabel_ASC',
  ExchangeRatePromoLabelDesc = 'exchangeRatePromoLabel_DESC',
  FeeLabelAsc = 'feeLabel_ASC',
  FeeLabelDesc = 'feeLabel_DESC',
  IncorrectCountrySelectedErrorMessageAsc = 'incorrectCountrySelectedErrorMessage_ASC',
  IncorrectCountrySelectedErrorMessageDesc = 'incorrectCountrySelectedErrorMessage_DESC',
  InstantDiscountLabelAsc = 'instantDiscountLabel_ASC',
  InstantDiscountLabelDesc = 'instantDiscountLabel_DESC',
  IsLiteAsc = 'isLite_ASC',
  IsLiteDesc = 'isLite_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerSelectErrorMessageAsc = 'partnerSelectErrorMessage_ASC',
  PartnerSelectErrorMessageDesc = 'partnerSelectErrorMessage_DESC',
  PartnersLabelAsc = 'partnersLabel_ASC',
  PartnersLabelDesc = 'partnersLabel_DESC',
  PartnersListDefaultValueAsc = 'partnersListDefaultValue_ASC',
  PartnersListDefaultValueDesc = 'partnersListDefaultValue_DESC',
  PayoutMethodsLabelAsc = 'payoutMethodsLabel_ASC',
  PayoutMethodsLabelDesc = 'payoutMethodsLabel_DESC',
  PhoneInputInfoMoreInfoLabelAsc = 'phoneInputInfoMoreInfoLabel_ASC',
  PhoneInputInfoMoreInfoLabelDesc = 'phoneInputInfoMoreInfoLabel_DESC',
  PhoneInputInfoPopupTextAsc = 'phoneInputInfoPopupText_ASC',
  PhoneInputInfoPopupTextDesc = 'phoneInputInfoPopupText_DESC',
  PhoneNumberInputErrorMessageAsc = 'phoneNumberInputErrorMessage_ASC',
  PhoneNumberInputErrorMessageDesc = 'phoneNumberInputErrorMessage_DESC',
  PhoneNumberInvalidErrorMessageAsc = 'phoneNumberInvalidErrorMessage_ASC',
  PhoneNumberInvalidErrorMessageDesc = 'phoneNumberInvalidErrorMessage_DESC',
  PhoneValidatorLabelAsc = 'phoneValidatorLabel_ASC',
  PhoneValidatorLabelDesc = 'phoneValidatorLabel_DESC',
  PhoneValidatorPlaceholderAsc = 'phoneValidatorPlaceholder_ASC',
  PhoneValidatorPlaceholderDesc = 'phoneValidatorPlaceholder_DESC',
  ReceiveLabelAsc = 'receiveLabel_ASC',
  ReceiveLabelDesc = 'receiveLabel_DESC',
  RecipientGetsLabelAsc = 'recipientGetsLabel_ASC',
  RecipientGetsLabelDesc = 'recipientGetsLabel_DESC',
  SendLabelAsc = 'sendLabel_ASC',
  SendLabelDesc = 'sendLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopUpAmountsLabelAsc = 'topUpAmountsLabel_ASC',
  TopUpAmountsLabelDesc = 'topUpAmountsLabel_DESC',
  TopUpAmountsPlaceholderAsc = 'topUpAmountsPlaceholder_ASC',
  TopUpAmountsPlaceholderDesc = 'topUpAmountsPlaceholder_DESC',
  TopUpAmountsSelectErrorMessageAsc = 'topUpAmountsSelectErrorMessage_ASC',
  TopUpAmountsSelectErrorMessageDesc = 'topUpAmountsSelectErrorMessage_DESC',
  TopUpAmountsSelectRetrievalErrorMessageAsc = 'topUpAmountsSelectRetrievalErrorMessage_ASC',
  TopUpAmountsSelectRetrievalErrorMessageDesc = 'topUpAmountsSelectRetrievalErrorMessage_DESC',
  TopUpAmountsTitleAsc = 'topUpAmountsTitle_ASC',
  TopUpAmountsTitleDesc = 'topUpAmountsTitle_DESC',
  TotalToPayLabelAsc = 'totalToPayLabel_ASC',
  TotalToPayLabelDesc = 'totalToPayLabel_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  UnavailableCorridorErrorMessageAsc = 'unavailableCorridorErrorMessage_ASC',
  UnavailableCorridorErrorMessageDesc = 'unavailableCorridorErrorMessage_DESC',
}

export enum LinkLinkingCollectionsCardCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsContainedBannerCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextBodyColorAsc = 'textBodyColor_ASC',
  TextBodyColorDesc = 'textBodyColor_DESC',
  TextHeadingColorAsc = 'textHeadingColor_ASC',
  TextHeadingColorDesc = 'textHeadingColor_DESC',
}

export enum LinkLinkingCollectionsCountryCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsFaqHeroCollectionOrder {
  AllCountriesAsc = 'allCountries_ASC',
  AllCountriesDesc = 'allCountries_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrimaryBackgroundColorAsc = 'primaryBackgroundColor_ASC',
  PrimaryBackgroundColorDesc = 'primaryBackgroundColor_DESC',
  SecondaryBackgroundColorAsc = 'secondaryBackgroundColor_ASC',
  SecondaryBackgroundColorDesc = 'secondaryBackgroundColor_DESC',
  SendToTextAsc = 'sendToText_ASC',
  SendToTextDesc = 'sendToText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

export enum LinkLinkingCollectionsFaqModuleCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsFooterNavigationGroupCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsFullWidthBannerCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BorderColorAsc = 'borderColor_ASC',
  BorderColorDesc = 'borderColor_DESC',
  ContentWidthAsc = 'contentWidth_ASC',
  ContentWidthDesc = 'contentWidth_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SecondBorderColorAsc = 'secondBorderColor_ASC',
  SecondBorderColorDesc = 'secondBorderColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
  TextVerticalAlignAsc = 'textVerticalAlign_ASC',
  TextVerticalAlignDesc = 'textVerticalAlign_DESC',
}

export enum LinkLinkingCollectionsHeroSlotCollectionOrder {
  ButtonTitleAsc = 'buttonTitle_ASC',
  ButtonTitleDesc = 'buttonTitle_DESC',
  DividerLabelAsc = 'dividerLabel_ASC',
  DividerLabelDesc = 'dividerLabel_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  LoginInLinkTitleAsc = 'loginInLinkTitle_ASC',
  LoginInLinkTitleDesc = 'loginInLinkTitle_DESC',
  LoginInTextAsc = 'loginInText_ASC',
  LoginInTextDesc = 'loginInText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  QrCodeLabelAsc = 'qrCodeLabel_ASC',
  QrCodeLabelDesc = 'qrCodeLabel_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsLayoutFooterCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsLayoutHeaderCollectionOrder {
  LanguageSwitcherOptionAsc = 'languageSwitcherOption_ASC',
  LanguageSwitcherOptionDesc = 'languageSwitcherOption_DESC',
  LastLoginAsc = 'lastLogin_ASC',
  LastLoginDesc = 'lastLogin_DESC',
  MenuLabelAsc = 'menuLabel_ASC',
  MenuLabelDesc = 'menuLabel_DESC',
  MyAccountAsc = 'myAccount_ASC',
  MyAccountDesc = 'myAccount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SendFromAsc = 'sendFrom_ASC',
  SendFromDesc = 'sendFrom_DESC',
  SkipToMainContentAsc = 'skipToMainContent_ASC',
  SkipToMainContentDesc = 'skipToMainContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WelcomeAsc = 'welcome_ASC',
  WelcomeDesc = 'welcome_DESC',
}

export enum LinkLinkingCollectionsLinkGroupCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsMobileAppLeadingLinksCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileAppButtonTextAsc = 'mobileAppButtonText_ASC',
  MobileAppButtonTextDesc = 'mobileAppButtonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsNotFoundContentCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsPayoutMethodCollectionOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

export enum LinkLinkingCollectionsPromotionalBannerCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsStepByStepCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsTextCardCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

export enum LinkOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountries = Entry &
  _Node & {
    __typename?: 'ListOfCountries';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    buttonLabelExpandedList?: Maybe<Scalars['String']>;
    buttonLabelHiddenList?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    countriesCollection?: Maybe<ListOfCountriesCountriesCollection>;
    expandListText?: Maybe<Scalars['String']>;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<ListOfCountriesLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    subheader?: Maybe<Scalars['String']>;
    sys: Sys;
    topCountries?: Maybe<PopularCountries>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesButtonLabelExpandedListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesButtonLabelHiddenListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<ListOfCountriesCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesExpandListTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/listOfCountries) */
export type ListOfCountriesTopCountriesArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PopularCountriesFilter>;
};

export type ListOfCountriesCollection = {
  __typename?: 'ListOfCountriesCollection';
  items: Array<Maybe<ListOfCountries>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListOfCountriesCountriesCollection = {
  __typename?: 'ListOfCountriesCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ListOfCountriesCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ListOfCountriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ListOfCountriesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ListOfCountriesFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  buttonLabelExpandedList?: InputMaybe<Scalars['String']>;
  buttonLabelExpandedList_contains?: InputMaybe<Scalars['String']>;
  buttonLabelExpandedList_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLabelExpandedList_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLabelExpandedList_not?: InputMaybe<Scalars['String']>;
  buttonLabelExpandedList_not_contains?: InputMaybe<Scalars['String']>;
  buttonLabelExpandedList_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  buttonLabelHiddenList?: InputMaybe<Scalars['String']>;
  buttonLabelHiddenList_contains?: InputMaybe<Scalars['String']>;
  buttonLabelHiddenList_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLabelHiddenList_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLabelHiddenList_not?: InputMaybe<Scalars['String']>;
  buttonLabelHiddenList_not_contains?: InputMaybe<Scalars['String']>;
  buttonLabelHiddenList_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries?: InputMaybe<CfCountryNestedFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  expandListText?: InputMaybe<Scalars['String']>;
  expandListText_contains?: InputMaybe<Scalars['String']>;
  expandListText_exists?: InputMaybe<Scalars['Boolean']>;
  expandListText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  expandListText_not?: InputMaybe<Scalars['String']>;
  expandListText_not_contains?: InputMaybe<Scalars['String']>;
  expandListText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  subheader?: InputMaybe<Scalars['String']>;
  subheader_contains?: InputMaybe<Scalars['String']>;
  subheader_exists?: InputMaybe<Scalars['Boolean']>;
  subheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subheader_not?: InputMaybe<Scalars['String']>;
  subheader_not_contains?: InputMaybe<Scalars['String']>;
  subheader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  topCountries?: InputMaybe<CfPopularCountriesNestedFilter>;
  topCountries_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ListOfCountriesLinkingCollections = {
  __typename?: 'ListOfCountriesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ListOfCountriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ListOfCountriesOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ButtonLabelExpandedListAsc = 'buttonLabelExpandedList_ASC',
  ButtonLabelExpandedListDesc = 'buttonLabelExpandedList_DESC',
  ButtonLabelHiddenListAsc = 'buttonLabelHiddenList_ASC',
  ButtonLabelHiddenListDesc = 'buttonLabelHiddenList_DESC',
  ExpandListTextAsc = 'expandListText_ASC',
  ExpandListTextDesc = 'expandListText_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/messages) */
export type Messages = Entry &
  _Node & {
    __typename?: 'Messages';
    _id: Scalars['ID'];
    all?: Maybe<Scalars['String']>;
    chooseYourRegion?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    countries?: Maybe<Scalars['String']>;
    international?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<MessagesLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/messages) */
export type MessagesAllArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/messages) */
export type MessagesChooseYourRegionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/messages) */
export type MessagesCountriesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/messages) */
export type MessagesInternationalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/messages) */
export type MessagesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Here is list of all possible messages for cms project, we can keep here error messages, warning messages, etc. with translations to other languages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/messages) */
export type MessagesNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MessagesCollection = {
  __typename?: 'MessagesCollection';
  items: Array<Maybe<Messages>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MessagesFilter = {
  AND?: InputMaybe<Array<InputMaybe<MessagesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MessagesFilter>>>;
  all?: InputMaybe<Scalars['String']>;
  all_contains?: InputMaybe<Scalars['String']>;
  all_exists?: InputMaybe<Scalars['Boolean']>;
  all_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  all_not?: InputMaybe<Scalars['String']>;
  all_not_contains?: InputMaybe<Scalars['String']>;
  all_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chooseYourRegion?: InputMaybe<Scalars['String']>;
  chooseYourRegion_contains?: InputMaybe<Scalars['String']>;
  chooseYourRegion_exists?: InputMaybe<Scalars['Boolean']>;
  chooseYourRegion_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chooseYourRegion_not?: InputMaybe<Scalars['String']>;
  chooseYourRegion_not_contains?: InputMaybe<Scalars['String']>;
  chooseYourRegion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries?: InputMaybe<Scalars['String']>;
  countries_contains?: InputMaybe<Scalars['String']>;
  countries_exists?: InputMaybe<Scalars['Boolean']>;
  countries_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countries_not?: InputMaybe<Scalars['String']>;
  countries_not_contains?: InputMaybe<Scalars['String']>;
  countries_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  international?: InputMaybe<Scalars['String']>;
  international_contains?: InputMaybe<Scalars['String']>;
  international_exists?: InputMaybe<Scalars['Boolean']>;
  international_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  international_not?: InputMaybe<Scalars['String']>;
  international_not_contains?: InputMaybe<Scalars['String']>;
  international_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type MessagesLinkingCollections = {
  __typename?: 'MessagesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MessagesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MessagesOrder {
  AllAsc = 'all_ASC',
  AllDesc = 'all_DESC',
  ChooseYourRegionAsc = 'chooseYourRegion_ASC',
  ChooseYourRegionDesc = 'chooseYourRegion_DESC',
  CountriesAsc = 'countries_ASC',
  CountriesDesc = 'countries_DESC',
  InternationalAsc = 'international_ASC',
  InternationalDesc = 'international_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinks = Entry &
  _Node & {
    __typename?: 'MobileAppLeadingLinks';
    _id: Scalars['ID'];
    androidLink?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    desktopLink?: Maybe<Link>;
    iosLink?: Maybe<Link>;
    label?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<MobileAppLeadingLinksLinkingCollections>;
    mobileAppButtonText?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    qrCode?: Maybe<MobileAppQrCode>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksAndroidLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksDesktopLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksIosLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksMobileAppButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppLeadingLinks) */
export type MobileAppLeadingLinksQrCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<MobileAppQrCodeFilter>;
};

export type MobileAppLeadingLinksCollection = {
  __typename?: 'MobileAppLeadingLinksCollection';
  items: Array<Maybe<MobileAppLeadingLinks>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MobileAppLeadingLinksFilter = {
  AND?: InputMaybe<Array<InputMaybe<MobileAppLeadingLinksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MobileAppLeadingLinksFilter>>>;
  androidLink?: InputMaybe<CfLinkNestedFilter>;
  androidLink_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  desktopLink?: InputMaybe<CfLinkNestedFilter>;
  desktopLink_exists?: InputMaybe<Scalars['Boolean']>;
  iosLink?: InputMaybe<CfLinkNestedFilter>;
  iosLink_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppButtonText?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_contains?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  mobileAppButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppButtonText_not?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_not_contains?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qrCode?: InputMaybe<CfMobileAppQrCodeNestedFilter>;
  qrCode_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type MobileAppLeadingLinksLinkingCollections = {
  __typename?: 'MobileAppLeadingLinksLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
  linkCollection?: Maybe<LinkCollection>;
};

export type MobileAppLeadingLinksLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type MobileAppLeadingLinksLinkingCollectionsLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MobileAppLeadingLinksLinkingCollectionsLayoutHeaderCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type MobileAppLeadingLinksLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MobileAppLeadingLinksLinkingCollectionsLinkCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MobileAppLeadingLinksLinkingCollectionsLayoutHeaderCollectionOrder {
  LanguageSwitcherOptionAsc = 'languageSwitcherOption_ASC',
  LanguageSwitcherOptionDesc = 'languageSwitcherOption_DESC',
  LastLoginAsc = 'lastLogin_ASC',
  LastLoginDesc = 'lastLogin_DESC',
  MenuLabelAsc = 'menuLabel_ASC',
  MenuLabelDesc = 'menuLabel_DESC',
  MyAccountAsc = 'myAccount_ASC',
  MyAccountDesc = 'myAccount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SendFromAsc = 'sendFrom_ASC',
  SendFromDesc = 'sendFrom_DESC',
  SkipToMainContentAsc = 'skipToMainContent_ASC',
  SkipToMainContentDesc = 'skipToMainContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WelcomeAsc = 'welcome_ASC',
  WelcomeDesc = 'welcome_DESC',
}

export enum MobileAppLeadingLinksLinkingCollectionsLinkCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum MobileAppLeadingLinksOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileAppButtonTextAsc = 'mobileAppButtonText_ASC',
  MobileAppButtonTextDesc = 'mobileAppButtonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppQrCode) */
export type MobileAppQrCode = Entry &
  _Node & {
    __typename?: 'MobileAppQrCode';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    downloadButtonText?: Maybe<Scalars['String']>;
    imageUrl?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<MobileAppQrCodeLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppQrCode) */
export type MobileAppQrCodeDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppQrCode) */
export type MobileAppQrCodeDownloadButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppQrCode) */
export type MobileAppQrCodeImageUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppQrCode) */
export type MobileAppQrCodeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/mobileAppQrCode) */
export type MobileAppQrCodeNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MobileAppQrCodeCollection = {
  __typename?: 'MobileAppQrCodeCollection';
  items: Array<Maybe<MobileAppQrCode>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MobileAppQrCodeFilter = {
  AND?: InputMaybe<Array<InputMaybe<MobileAppQrCodeFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MobileAppQrCodeFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  downloadButtonText?: InputMaybe<Scalars['String']>;
  downloadButtonText_contains?: InputMaybe<Scalars['String']>;
  downloadButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  downloadButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  downloadButtonText_not?: InputMaybe<Scalars['String']>;
  downloadButtonText_not_contains?: InputMaybe<Scalars['String']>;
  downloadButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageUrl?: InputMaybe<Scalars['String']>;
  imageUrl_contains?: InputMaybe<Scalars['String']>;
  imageUrl_exists?: InputMaybe<Scalars['Boolean']>;
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageUrl_not?: InputMaybe<Scalars['String']>;
  imageUrl_not_contains?: InputMaybe<Scalars['String']>;
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type MobileAppQrCodeLinkingCollections = {
  __typename?: 'MobileAppQrCodeLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
  mobileAppLeadingLinksCollection?: Maybe<MobileAppLeadingLinksCollection>;
};

export type MobileAppQrCodeLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MobileAppQrCodeLinkingCollectionsBetterWayTopCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type MobileAppQrCodeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type MobileAppQrCodeLinkingCollectionsMobileAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<MobileAppQrCodeLinkingCollectionsMobileAppLeadingLinksCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MobileAppQrCodeLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum MobileAppQrCodeLinkingCollectionsMobileAppLeadingLinksCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileAppButtonTextAsc = 'mobileAppButtonText_ASC',
  MobileAppButtonTextDesc = 'mobileAppButtonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MobileAppQrCodeOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DownloadButtonTextAsc = 'downloadButtonText_ASC',
  DownloadButtonTextDesc = 'downloadButtonText_DESC',
  ImageUrlAsc = 'imageUrl_ASC',
  ImageUrlDesc = 'imageUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordion) */
export type NestedAccordion = Entry &
  _Node & {
    __typename?: 'NestedAccordion';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<NestedAccordionLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    nestedAccordionItemsCollection?: Maybe<NestedAccordionNestedAccordionItemsCollection>;
    popularQuestions?: Maybe<FaqPopularQuestions>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordion) */
export type NestedAccordionBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordion) */
export type NestedAccordionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordion) */
export type NestedAccordionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordion) */
export type NestedAccordionNestedAccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<NestedAccordionNestedAccordionItemsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NestedAccordionItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordion) */
export type NestedAccordionPopularQuestionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FaqPopularQuestionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordion) */
export type NestedAccordionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NestedAccordionCollection = {
  __typename?: 'NestedAccordionCollection';
  items: Array<Maybe<NestedAccordion>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NestedAccordionFilter = {
  AND?: InputMaybe<Array<InputMaybe<NestedAccordionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NestedAccordionFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nestedAccordionItems?: InputMaybe<CfNestedAccordionItemsNestedFilter>;
  nestedAccordionItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  popularQuestions?: InputMaybe<CfFaqPopularQuestionsNestedFilter>;
  popularQuestions_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItems = Entry &
  _Node & {
    __typename?: 'NestedAccordionItems';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    countriesCollection?: Maybe<NestedAccordionItemsCountriesCollection>;
    description?: Maybe<Scalars['String']>;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<NestedAccordionItemsLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    nestedAccordionLinksCollection?: Maybe<NestedAccordionItemsNestedAccordionLinksCollection>;
    subtitleCollection?: Maybe<NestedAccordionItemsSubtitleCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<NestedAccordionItemsCountriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsNestedAccordionLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<NestedAccordionItemsNestedAccordionLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsSubtitleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<NestedAccordionItemsSubtitleCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NestedAccordionTitleAndDescriptionFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionItems) */
export type NestedAccordionItemsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NestedAccordionItemsCollection = {
  __typename?: 'NestedAccordionItemsCollection';
  items: Array<Maybe<NestedAccordionItems>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NestedAccordionItemsCountriesCollection = {
  __typename?: 'NestedAccordionItemsCountriesCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NestedAccordionItemsCountriesCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type NestedAccordionItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<NestedAccordionItemsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NestedAccordionItemsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countries?: InputMaybe<CfCountryNestedFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nestedAccordionLinks?: InputMaybe<CfPageNestedFilter>;
  nestedAccordionLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<CfNestedAccordionTitleAndDescriptionNestedFilter>;
  subtitleCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NestedAccordionItemsLinkingCollections = {
  __typename?: 'NestedAccordionItemsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  nestedAccordionCollection?: Maybe<NestedAccordionCollection>;
};

export type NestedAccordionItemsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NestedAccordionItemsLinkingCollectionsNestedAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<NestedAccordionItemsLinkingCollectionsNestedAccordionCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NestedAccordionItemsLinkingCollectionsNestedAccordionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type NestedAccordionItemsNestedAccordionLinksCollection = {
  __typename?: 'NestedAccordionItemsNestedAccordionLinksCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NestedAccordionItemsNestedAccordionLinksCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum NestedAccordionItemsOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type NestedAccordionItemsSubtitleCollection = {
  __typename?: 'NestedAccordionItemsSubtitleCollection';
  items: Array<Maybe<NestedAccordionTitleAndDescription>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NestedAccordionItemsSubtitleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type NestedAccordionLinkingCollections = {
  __typename?: 'NestedAccordionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type NestedAccordionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NestedAccordionNestedAccordionItemsCollection = {
  __typename?: 'NestedAccordionNestedAccordionItemsCollection';
  items: Array<Maybe<NestedAccordionItems>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NestedAccordionNestedAccordionItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum NestedAccordionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionTitleAndDescription) */
export type NestedAccordionTitleAndDescription = Entry &
  _Node & {
    __typename?: 'NestedAccordionTitleAndDescription';
    _id: Scalars['ID'];
    content?: Maybe<NestedAccordionTitleAndDescriptionContent>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<NestedAccordionTitleAndDescriptionLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionTitleAndDescription) */
export type NestedAccordionTitleAndDescriptionContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionTitleAndDescription) */
export type NestedAccordionTitleAndDescriptionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionTitleAndDescription) */
export type NestedAccordionTitleAndDescriptionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionTitleAndDescription) */
export type NestedAccordionTitleAndDescriptionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/nestedAccordionTitleAndDescription) */
export type NestedAccordionTitleAndDescriptionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NestedAccordionTitleAndDescriptionCollection = {
  __typename?: 'NestedAccordionTitleAndDescriptionCollection';
  items: Array<Maybe<NestedAccordionTitleAndDescription>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NestedAccordionTitleAndDescriptionContent = {
  __typename?: 'NestedAccordionTitleAndDescriptionContent';
  json: Scalars['JSON'];
  links: NestedAccordionTitleAndDescriptionContentLinks;
};

export type NestedAccordionTitleAndDescriptionContentAssets = {
  __typename?: 'NestedAccordionTitleAndDescriptionContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NestedAccordionTitleAndDescriptionContentEntries = {
  __typename?: 'NestedAccordionTitleAndDescriptionContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NestedAccordionTitleAndDescriptionContentLinks = {
  __typename?: 'NestedAccordionTitleAndDescriptionContentLinks';
  assets: NestedAccordionTitleAndDescriptionContentAssets;
  entries: NestedAccordionTitleAndDescriptionContentEntries;
  resources: NestedAccordionTitleAndDescriptionContentResources;
};

export type NestedAccordionTitleAndDescriptionContentResources = {
  __typename?: 'NestedAccordionTitleAndDescriptionContentResources';
  block: Array<NestedAccordionTitleAndDescriptionContentResourcesBlock>;
  hyperlink: Array<NestedAccordionTitleAndDescriptionContentResourcesHyperlink>;
  inline: Array<NestedAccordionTitleAndDescriptionContentResourcesInline>;
};

export type NestedAccordionTitleAndDescriptionContentResourcesBlock = ResourceLink & {
  __typename?: 'NestedAccordionTitleAndDescriptionContentResourcesBlock';
  sys: ResourceSys;
};

export type NestedAccordionTitleAndDescriptionContentResourcesHyperlink = ResourceLink & {
  __typename?: 'NestedAccordionTitleAndDescriptionContentResourcesHyperlink';
  sys: ResourceSys;
};

export type NestedAccordionTitleAndDescriptionContentResourcesInline = ResourceLink & {
  __typename?: 'NestedAccordionTitleAndDescriptionContentResourcesInline';
  sys: ResourceSys;
};

export type NestedAccordionTitleAndDescriptionFilter = {
  AND?: InputMaybe<Array<InputMaybe<NestedAccordionTitleAndDescriptionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NestedAccordionTitleAndDescriptionFilter>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NestedAccordionTitleAndDescriptionLinkingCollections = {
  __typename?: 'NestedAccordionTitleAndDescriptionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  nestedAccordionItemsCollection?: Maybe<NestedAccordionItemsCollection>;
};

export type NestedAccordionTitleAndDescriptionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NestedAccordionTitleAndDescriptionLinkingCollectionsNestedAccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<NestedAccordionTitleAndDescriptionLinkingCollectionsNestedAccordionItemsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NestedAccordionTitleAndDescriptionLinkingCollectionsNestedAccordionItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum NestedAccordionTitleAndDescriptionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundContent) */
export type NotFoundContent = Entry &
  _Node & {
    __typename?: 'NotFoundContent';
    _id: Scalars['ID'];
    buttonsCollection?: Maybe<NotFoundContentButtonsCollection>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<NotFoundContentLinkingCollections>;
    message?: Maybe<NotFoundContentMessage>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundContent) */
export type NotFoundContentButtonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundContentButtonsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundContent) */
export type NotFoundContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundContent) */
export type NotFoundContentMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Module with the content in the not found page (404) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundContent) */
export type NotFoundContentNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NotFoundContentButtonsCollection = {
  __typename?: 'NotFoundContentButtonsCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NotFoundContentButtonsCollectionOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GaTrackNameAsc = 'gaTrackName_ASC',
  GaTrackNameDesc = 'gaTrackName_DESC',
  HighlightedAsc = 'highlighted_ASC',
  HighlightedDesc = 'highlighted_DESC',
  IsDisplayedWhenLoggedInAsc = 'isDisplayedWhenLoggedIn_ASC',
  IsDisplayedWhenLoggedInDesc = 'isDisplayedWhenLoggedIn_DESC',
  IsInternalAsc = 'isInternal_ASC',
  IsInternalDesc = 'isInternal_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MobileMenuToggleAsc = 'mobileMenuToggle_ASC',
  MobileMenuToggleDesc = 'mobileMenuToggle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  RedirectToLegacyProjectAsc = 'redirectToLegacyProject_ASC',
  RedirectToLegacyProjectDesc = 'redirectToLegacyProject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineAsc = 'underline_ASC',
  UnderlineDesc = 'underline_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export type NotFoundContentCollection = {
  __typename?: 'NotFoundContentCollection';
  items: Array<Maybe<NotFoundContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NotFoundContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<NotFoundContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NotFoundContentFilter>>>;
  buttons?: InputMaybe<CfLinkNestedFilter>;
  buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  message_contains?: InputMaybe<Scalars['String']>;
  message_exists?: InputMaybe<Scalars['Boolean']>;
  message_not_contains?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type NotFoundContentLinkingCollections = {
  __typename?: 'NotFoundContentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type NotFoundContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NotFoundContentMessage = {
  __typename?: 'NotFoundContentMessage';
  json: Scalars['JSON'];
  links: NotFoundContentMessageLinks;
};

export type NotFoundContentMessageAssets = {
  __typename?: 'NotFoundContentMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NotFoundContentMessageEntries = {
  __typename?: 'NotFoundContentMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NotFoundContentMessageLinks = {
  __typename?: 'NotFoundContentMessageLinks';
  assets: NotFoundContentMessageAssets;
  entries: NotFoundContentMessageEntries;
  resources: NotFoundContentMessageResources;
};

export type NotFoundContentMessageResources = {
  __typename?: 'NotFoundContentMessageResources';
  block: Array<NotFoundContentMessageResourcesBlock>;
  hyperlink: Array<NotFoundContentMessageResourcesHyperlink>;
  inline: Array<NotFoundContentMessageResourcesInline>;
};

export type NotFoundContentMessageResourcesBlock = ResourceLink & {
  __typename?: 'NotFoundContentMessageResourcesBlock';
  sys: ResourceSys;
};

export type NotFoundContentMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'NotFoundContentMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type NotFoundContentMessageResourcesInline = ResourceLink & {
  __typename?: 'NotFoundContentMessageResourcesInline';
  sys: ResourceSys;
};

export enum NotFoundContentOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundErrorPage) */
export type NotFoundErrorPage = Entry &
  _Node & {
    __typename?: 'NotFoundErrorPage';
    _id: Scalars['ID'];
    buildLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<NotFoundErrorPageDescription>;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<NotFoundErrorPageLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    subDescription?: Maybe<NotFoundErrorPageSubDescription>;
    sys: Sys;
  };

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageBuildLocalesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-processes] 404 page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/notFoundErrorPage) */
export type NotFoundErrorPageSubDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NotFoundErrorPageCollection = {
  __typename?: 'NotFoundErrorPageCollection';
  items: Array<Maybe<NotFoundErrorPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NotFoundErrorPageDescription = {
  __typename?: 'NotFoundErrorPageDescription';
  json: Scalars['JSON'];
  links: NotFoundErrorPageDescriptionLinks;
};

export type NotFoundErrorPageDescriptionAssets = {
  __typename?: 'NotFoundErrorPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NotFoundErrorPageDescriptionEntries = {
  __typename?: 'NotFoundErrorPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NotFoundErrorPageDescriptionLinks = {
  __typename?: 'NotFoundErrorPageDescriptionLinks';
  assets: NotFoundErrorPageDescriptionAssets;
  entries: NotFoundErrorPageDescriptionEntries;
  resources: NotFoundErrorPageDescriptionResources;
};

export type NotFoundErrorPageDescriptionResources = {
  __typename?: 'NotFoundErrorPageDescriptionResources';
  block: Array<NotFoundErrorPageDescriptionResourcesBlock>;
  hyperlink: Array<NotFoundErrorPageDescriptionResourcesHyperlink>;
  inline: Array<NotFoundErrorPageDescriptionResourcesInline>;
};

export type NotFoundErrorPageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'NotFoundErrorPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type NotFoundErrorPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'NotFoundErrorPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type NotFoundErrorPageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'NotFoundErrorPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type NotFoundErrorPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<NotFoundErrorPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NotFoundErrorPageFilter>>>;
  buildLocales_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subDescription_contains?: InputMaybe<Scalars['String']>;
  subDescription_exists?: InputMaybe<Scalars['Boolean']>;
  subDescription_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
};

export type NotFoundErrorPageLinkingCollections = {
  __typename?: 'NotFoundErrorPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type NotFoundErrorPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NotFoundErrorPageOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type NotFoundErrorPageSubDescription = {
  __typename?: 'NotFoundErrorPageSubDescription';
  json: Scalars['JSON'];
  links: NotFoundErrorPageSubDescriptionLinks;
};

export type NotFoundErrorPageSubDescriptionAssets = {
  __typename?: 'NotFoundErrorPageSubDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NotFoundErrorPageSubDescriptionEntries = {
  __typename?: 'NotFoundErrorPageSubDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NotFoundErrorPageSubDescriptionLinks = {
  __typename?: 'NotFoundErrorPageSubDescriptionLinks';
  assets: NotFoundErrorPageSubDescriptionAssets;
  entries: NotFoundErrorPageSubDescriptionEntries;
  resources: NotFoundErrorPageSubDescriptionResources;
};

export type NotFoundErrorPageSubDescriptionResources = {
  __typename?: 'NotFoundErrorPageSubDescriptionResources';
  block: Array<NotFoundErrorPageSubDescriptionResourcesBlock>;
  hyperlink: Array<NotFoundErrorPageSubDescriptionResourcesHyperlink>;
  inline: Array<NotFoundErrorPageSubDescriptionResourcesInline>;
};

export type NotFoundErrorPageSubDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'NotFoundErrorPageSubDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type NotFoundErrorPageSubDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'NotFoundErrorPageSubDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type NotFoundErrorPageSubDescriptionResourcesInline = ResourceLink & {
  __typename?: 'NotFoundErrorPageSubDescriptionResourcesInline';
  sys: ResourceSys;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type Page = Entry &
  _Node & {
    __typename?: 'Page';
    _id: Scalars['ID'];
    analyticsPageName?: Maybe<Scalars['String']>;
    analyticsPageType?: Maybe<Scalars['String']>;
    author?: Maybe<Person>;
    banner?: Maybe<Asset>;
    content?: Maybe<PageContent>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    footer?: Maybe<LayoutFooter>;
    headerPosition?: Maybe<Scalars['String']>;
    headerTheme?: Maybe<Scalars['String']>;
    heading?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<PageLinkingCollections>;
    modulesCollection?: Maybe<PageModulesCollection>;
    name?: Maybe<Scalars['String']>;
    noIndex?: Maybe<Scalars['Boolean']>;
    ogImage?: Maybe<Asset>;
    publishedDate?: Maybe<Scalars['DateTime']>;
    regions?: Maybe<Array<Maybe<Scalars['String']>>>;
    relatedPagesCollection?: Maybe<PageRelatedPagesCollection>;
    sitemap?: Maybe<Scalars['Boolean']>;
    sitemapTitle?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    sys: Sys;
    timeToRead?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    updatedDate?: Maybe<Scalars['DateTime']>;
  };

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageAnalyticsPageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageAnalyticsPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PersonFilter>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageBannerArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageFooterArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageHeaderPositionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageHeaderThemeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageOgImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PagePublishedDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageRegionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageRelatedPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageRelatedPagesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageFilter>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageSitemapArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageSitemapTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageTimeToReadArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Page template to build any kind of page [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/page) */
export type PageUpdatedDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PageCollection = {
  __typename?: 'PageCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageContent = {
  __typename?: 'PageContent';
  json: Scalars['JSON'];
  links: PageContentLinks;
};

export type PageContentAssets = {
  __typename?: 'PageContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PageContentEntries = {
  __typename?: 'PageContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PageContentLinks = {
  __typename?: 'PageContentLinks';
  assets: PageContentAssets;
  entries: PageContentEntries;
  resources: PageContentResources;
};

export type PageContentResources = {
  __typename?: 'PageContentResources';
  block: Array<PageContentResourcesBlock>;
  hyperlink: Array<PageContentResourcesHyperlink>;
  inline: Array<PageContentResourcesInline>;
};

export type PageContentResourcesBlock = ResourceLink & {
  __typename?: 'PageContentResourcesBlock';
  sys: ResourceSys;
};

export type PageContentResourcesHyperlink = ResourceLink & {
  __typename?: 'PageContentResourcesHyperlink';
  sys: ResourceSys;
};

export type PageContentResourcesInline = ResourceLink & {
  __typename?: 'PageContentResourcesInline';
  sys: ResourceSys;
};

export type PageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageName_not?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType?: InputMaybe<Scalars['String']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType_not?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  author?: InputMaybe<CfPersonNestedFilter>;
  author_exists?: InputMaybe<Scalars['Boolean']>;
  banner_exists?: InputMaybe<Scalars['Boolean']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footer?: InputMaybe<CfLayoutFooterNestedFilter>;
  footer_exists?: InputMaybe<Scalars['Boolean']>;
  headerPosition?: InputMaybe<Scalars['String']>;
  headerPosition_contains?: InputMaybe<Scalars['String']>;
  headerPosition_exists?: InputMaybe<Scalars['Boolean']>;
  headerPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerPosition_not?: InputMaybe<Scalars['String']>;
  headerPosition_not_contains?: InputMaybe<Scalars['String']>;
  headerPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerTheme?: InputMaybe<Scalars['String']>;
  headerTheme_contains?: InputMaybe<Scalars['String']>;
  headerTheme_exists?: InputMaybe<Scalars['Boolean']>;
  headerTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerTheme_not?: InputMaybe<Scalars['String']>;
  headerTheme_not_contains?: InputMaybe<Scalars['String']>;
  headerTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
  ogImage_exists?: InputMaybe<Scalars['Boolean']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']>;
  publishedDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishedDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishedDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishedDate_not?: InputMaybe<Scalars['DateTime']>;
  publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  regions_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regions_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regions_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regions_exists?: InputMaybe<Scalars['Boolean']>;
  relatedPages?: InputMaybe<CfPageNestedFilter>;
  relatedPagesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle?: InputMaybe<Scalars['String']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemap_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  timeToRead?: InputMaybe<Scalars['String']>;
  timeToRead_contains?: InputMaybe<Scalars['String']>;
  timeToRead_exists?: InputMaybe<Scalars['Boolean']>;
  timeToRead_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  timeToRead_not?: InputMaybe<Scalars['String']>;
  timeToRead_not_contains?: InputMaybe<Scalars['String']>;
  timeToRead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updatedDate?: InputMaybe<Scalars['DateTime']>;
  updatedDate_exists?: InputMaybe<Scalars['Boolean']>;
  updatedDate_gt?: InputMaybe<Scalars['DateTime']>;
  updatedDate_gte?: InputMaybe<Scalars['DateTime']>;
  updatedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedDate_lt?: InputMaybe<Scalars['DateTime']>;
  updatedDate_lte?: InputMaybe<Scalars['DateTime']>;
  updatedDate_not?: InputMaybe<Scalars['DateTime']>;
  updatedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type PageLinkingCollections = {
  __typename?: 'PageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
  nestedAccordionItemsCollection?: Maybe<NestedAccordionItemsCollection>;
  pageCollection?: Maybe<PageCollection>;
  searchCollection?: Maybe<SearchCollection>;
  webFormCollection?: Maybe<WebFormCollection>;
};

export type PageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsFaqTopicsModuleItemsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsNestedAccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsNestedAccordionItemsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsSearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsSearchCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PageLinkingCollectionsWebFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsWebFormCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PageLinkingCollectionsFaqTopicsModuleItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLinkingCollectionsNestedAccordionItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLinkingCollectionsPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum PageLinkingCollectionsSearchCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InputLabelAsc = 'inputLabel_ASC',
  InputLabelDesc = 'inputLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResultListLabelAsc = 'resultListLabel_ASC',
  ResultListLabelDesc = 'resultListLabel_DESC',
  SuggestionListLabelAsc = 'suggestionListLabel_ASC',
  SuggestionListLabelDesc = 'suggestionListLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PageLinkingCollectionsWebFormCollectionOrder {
  DisclaimerAsc = 'disclaimer_ASC',
  DisclaimerDesc = 'disclaimer_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubmitButtonLabelAsc = 'submitButtonLabel_ASC',
  SubmitButtonLabelDesc = 'submitButtonLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageModulesCollection = {
  __typename?: 'PageModulesCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PageOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export type PageRelatedPagesCollection = {
  __typename?: 'PageRelatedPagesCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PageRelatedPagesCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSection = Entry &
  _Node & {
    __typename?: 'PageSection';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundGradient?: Maybe<Scalars['String']>;
    backgroundType?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    countriesFromCollection?: Maybe<PageSectionCountriesFromCollection>;
    enablePadding?: Maybe<Scalars['Boolean']>;
    linkedFrom?: Maybe<PageSectionLinkingCollections>;
    modulesCollection?: Maybe<PageSectionModulesCollection>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionBackgroundGradientArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionBackgroundTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionCountriesFromCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PageSectionCountriesFromCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionEnablePaddingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionModulesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageSectionModulesFilter>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Subsection of page combining multiple different entries in <section> tag with customizable background. This content type allows you to have different body text based on chosen Send From country</section> [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pageSection) */
export type PageSectionSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PageSectionCollection = {
  __typename?: 'PageSectionCollection';
  items: Array<Maybe<PageSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageSectionCountriesFromCollection = {
  __typename?: 'PageSectionCountriesFromCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PageSectionCountriesFromCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageSectionFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundGradient?: InputMaybe<Scalars['String']>;
  backgroundGradient_contains?: InputMaybe<Scalars['String']>;
  backgroundGradient_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundGradient_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundGradient_not?: InputMaybe<Scalars['String']>;
  backgroundGradient_not_contains?: InputMaybe<Scalars['String']>;
  backgroundGradient_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundType?: InputMaybe<Scalars['String']>;
  backgroundType_contains?: InputMaybe<Scalars['String']>;
  backgroundType_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundType_not?: InputMaybe<Scalars['String']>;
  backgroundType_not_contains?: InputMaybe<Scalars['String']>;
  backgroundType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesFrom?: InputMaybe<CfCountryNestedFilter>;
  countriesFromCollection_exists?: InputMaybe<Scalars['Boolean']>;
  enablePadding?: InputMaybe<Scalars['Boolean']>;
  enablePadding_exists?: InputMaybe<Scalars['Boolean']>;
  enablePadding_not?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<CfmodulesMultiTypeNestedFilter>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type PageSectionLinkingCollections = {
  __typename?: 'PageSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PageSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PageSectionModulesCollection = {
  __typename?: 'PageSectionModulesCollection';
  items: Array<Maybe<PageSectionModulesItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageSectionModulesFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageSectionModulesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageSectionModulesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PageSectionModulesItem =
  | BodyText
  | ComparisonTable
  | IconWithText
  | StepByStep
  | TextWithColor
  | Video
  | WhyChoose;

export enum PageSectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethod = Entry &
  _Node & {
    __typename?: 'PayoutMethod';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    cornerGraphic?: Maybe<Asset>;
    correspondentsTitle?: Maybe<Scalars['String']>;
    countriesFromCollection?: Maybe<PayoutMethodCountriesFromCollection>;
    countriesToCollection?: Maybe<PayoutMethodCountriesToCollection>;
    ctaButtonBaseUrl?: Maybe<Scalars['String']>;
    ctaButtonLabel?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    exchangeRateLabel?: Maybe<Scalars['String']>;
    feesLabel?: Maybe<Scalars['String']>;
    hideExchangeRate?: Maybe<Scalars['Boolean']>;
    icon?: Maybe<Asset>;
    linkedFrom?: Maybe<PayoutMethodLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    payoutMethodId?: Maybe<Scalars['String']>;
    receiveMethodDescription?: Maybe<Scalars['String']>;
    receiveMethodLink?: Maybe<Link>;
    slug?: Maybe<Scalars['String']>;
    supportLinkText?: Maybe<Scalars['String']>;
    sys: Sys;
    transferTimeLabel?: Maybe<Scalars['String']>;
    transferTimeTooltip?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodCornerGraphicArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodCorrespondentsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodCountriesFromCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PayoutMethodCountriesFromCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodCountriesToCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PayoutMethodCountriesToCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodCtaButtonBaseUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodCtaButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodExchangeRateLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodFeesLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodHideExchangeRateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodPayoutMethodIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodReceiveMethodDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodReceiveMethodLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodSupportLinkTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodTransferTimeLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/payoutMethod) */
export type PayoutMethodTransferTimeTooltipArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PayoutMethodCollection = {
  __typename?: 'PayoutMethodCollection';
  items: Array<Maybe<PayoutMethod>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PayoutMethodCountriesFromCollection = {
  __typename?: 'PayoutMethodCountriesFromCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PayoutMethodCountriesFromCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PayoutMethodCountriesToCollection = {
  __typename?: 'PayoutMethodCountriesToCollection';
  items: Array<Maybe<Country>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PayoutMethodCountriesToCollectionOrder {
  ArticleAsc = 'article_ASC',
  ArticleDesc = 'article_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrepositionFromAsc = 'prepositionFrom_ASC',
  PrepositionFromDesc = 'prepositionFrom_DESC',
  PrepositionInAsc = 'prepositionIn_ASC',
  PrepositionInDesc = 'prepositionIn_DESC',
  PrepositionToAsc = 'prepositionTo_ASC',
  PrepositionToDesc = 'prepositionTo_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PayoutMethodFilter = {
  AND?: InputMaybe<Array<InputMaybe<PayoutMethodFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PayoutMethodFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cornerGraphic_exists?: InputMaybe<Scalars['Boolean']>;
  correspondentsTitle?: InputMaybe<Scalars['String']>;
  correspondentsTitle_contains?: InputMaybe<Scalars['String']>;
  correspondentsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  correspondentsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  correspondentsTitle_not?: InputMaybe<Scalars['String']>;
  correspondentsTitle_not_contains?: InputMaybe<Scalars['String']>;
  correspondentsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countriesFrom?: InputMaybe<CfCountryNestedFilter>;
  countriesFromCollection_exists?: InputMaybe<Scalars['Boolean']>;
  countriesTo?: InputMaybe<CfCountryNestedFilter>;
  countriesToCollection_exists?: InputMaybe<Scalars['Boolean']>;
  ctaButtonBaseUrl?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_contains?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_exists?: InputMaybe<Scalars['Boolean']>;
  ctaButtonBaseUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaButtonBaseUrl_not?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_not_contains?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaButtonLabel?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_contains?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ctaButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaButtonLabel_not?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_not_contains?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']>;
  exchangeRateLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  feesLabel?: InputMaybe<Scalars['String']>;
  feesLabel_contains?: InputMaybe<Scalars['String']>;
  feesLabel_exists?: InputMaybe<Scalars['Boolean']>;
  feesLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  feesLabel_not?: InputMaybe<Scalars['String']>;
  feesLabel_not_contains?: InputMaybe<Scalars['String']>;
  feesLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hideExchangeRate?: InputMaybe<Scalars['Boolean']>;
  hideExchangeRate_exists?: InputMaybe<Scalars['Boolean']>;
  hideExchangeRate_not?: InputMaybe<Scalars['Boolean']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveMethodDescription?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_contains?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_exists?: InputMaybe<Scalars['Boolean']>;
  receiveMethodDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveMethodDescription_not?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_not_contains?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveMethodLink?: InputMaybe<CfLinkNestedFilter>;
  receiveMethodLink_exists?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supportLinkText?: InputMaybe<Scalars['String']>;
  supportLinkText_contains?: InputMaybe<Scalars['String']>;
  supportLinkText_exists?: InputMaybe<Scalars['Boolean']>;
  supportLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supportLinkText_not?: InputMaybe<Scalars['String']>;
  supportLinkText_not_contains?: InputMaybe<Scalars['String']>;
  supportLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  transferTimeLabel?: InputMaybe<Scalars['String']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  transferTimeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeLabel_not?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeTooltip?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_contains?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_exists?: InputMaybe<Scalars['Boolean']>;
  transferTimeTooltip_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeTooltip_not?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_not_contains?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PayoutMethodLinkingCollections = {
  __typename?: 'PayoutMethodLinkingCollections';
  calculatorCollection?: Maybe<CalculatorCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type PayoutMethodLinkingCollectionsCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PayoutMethodLinkingCollectionsCalculatorCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PayoutMethodLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PayoutMethodLinkingCollectionsCalculatorCollectionOrder {
  CountriesSearchPlaceholderAsc = 'countriesSearchPlaceholder_ASC',
  CountriesSearchPlaceholderDesc = 'countriesSearchPlaceholder_DESC',
  EstimatedFeeLabelAsc = 'estimatedFeeLabel_ASC',
  EstimatedFeeLabelDesc = 'estimatedFeeLabel_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  ExchangeRatePromoLabelAsc = 'exchangeRatePromoLabel_ASC',
  ExchangeRatePromoLabelDesc = 'exchangeRatePromoLabel_DESC',
  FeeLabelAsc = 'feeLabel_ASC',
  FeeLabelDesc = 'feeLabel_DESC',
  IncorrectCountrySelectedErrorMessageAsc = 'incorrectCountrySelectedErrorMessage_ASC',
  IncorrectCountrySelectedErrorMessageDesc = 'incorrectCountrySelectedErrorMessage_DESC',
  InstantDiscountLabelAsc = 'instantDiscountLabel_ASC',
  InstantDiscountLabelDesc = 'instantDiscountLabel_DESC',
  IsLiteAsc = 'isLite_ASC',
  IsLiteDesc = 'isLite_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerSelectErrorMessageAsc = 'partnerSelectErrorMessage_ASC',
  PartnerSelectErrorMessageDesc = 'partnerSelectErrorMessage_DESC',
  PartnersLabelAsc = 'partnersLabel_ASC',
  PartnersLabelDesc = 'partnersLabel_DESC',
  PartnersListDefaultValueAsc = 'partnersListDefaultValue_ASC',
  PartnersListDefaultValueDesc = 'partnersListDefaultValue_DESC',
  PayoutMethodsLabelAsc = 'payoutMethodsLabel_ASC',
  PayoutMethodsLabelDesc = 'payoutMethodsLabel_DESC',
  PhoneInputInfoMoreInfoLabelAsc = 'phoneInputInfoMoreInfoLabel_ASC',
  PhoneInputInfoMoreInfoLabelDesc = 'phoneInputInfoMoreInfoLabel_DESC',
  PhoneInputInfoPopupTextAsc = 'phoneInputInfoPopupText_ASC',
  PhoneInputInfoPopupTextDesc = 'phoneInputInfoPopupText_DESC',
  PhoneNumberInputErrorMessageAsc = 'phoneNumberInputErrorMessage_ASC',
  PhoneNumberInputErrorMessageDesc = 'phoneNumberInputErrorMessage_DESC',
  PhoneNumberInvalidErrorMessageAsc = 'phoneNumberInvalidErrorMessage_ASC',
  PhoneNumberInvalidErrorMessageDesc = 'phoneNumberInvalidErrorMessage_DESC',
  PhoneValidatorLabelAsc = 'phoneValidatorLabel_ASC',
  PhoneValidatorLabelDesc = 'phoneValidatorLabel_DESC',
  PhoneValidatorPlaceholderAsc = 'phoneValidatorPlaceholder_ASC',
  PhoneValidatorPlaceholderDesc = 'phoneValidatorPlaceholder_DESC',
  ReceiveLabelAsc = 'receiveLabel_ASC',
  ReceiveLabelDesc = 'receiveLabel_DESC',
  RecipientGetsLabelAsc = 'recipientGetsLabel_ASC',
  RecipientGetsLabelDesc = 'recipientGetsLabel_DESC',
  SendLabelAsc = 'sendLabel_ASC',
  SendLabelDesc = 'sendLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopUpAmountsLabelAsc = 'topUpAmountsLabel_ASC',
  TopUpAmountsLabelDesc = 'topUpAmountsLabel_DESC',
  TopUpAmountsPlaceholderAsc = 'topUpAmountsPlaceholder_ASC',
  TopUpAmountsPlaceholderDesc = 'topUpAmountsPlaceholder_DESC',
  TopUpAmountsSelectErrorMessageAsc = 'topUpAmountsSelectErrorMessage_ASC',
  TopUpAmountsSelectErrorMessageDesc = 'topUpAmountsSelectErrorMessage_DESC',
  TopUpAmountsSelectRetrievalErrorMessageAsc = 'topUpAmountsSelectRetrievalErrorMessage_ASC',
  TopUpAmountsSelectRetrievalErrorMessageDesc = 'topUpAmountsSelectRetrievalErrorMessage_DESC',
  TopUpAmountsTitleAsc = 'topUpAmountsTitle_ASC',
  TopUpAmountsTitleDesc = 'topUpAmountsTitle_DESC',
  TotalToPayLabelAsc = 'totalToPayLabel_ASC',
  TotalToPayLabelDesc = 'totalToPayLabel_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  UnavailableCorridorErrorMessageAsc = 'unavailableCorridorErrorMessage_ASC',
  UnavailableCorridorErrorMessageDesc = 'unavailableCorridorErrorMessage_DESC',
}

export enum PayoutMethodOrder {
  CorrespondentsTitleAsc = 'correspondentsTitle_ASC',
  CorrespondentsTitleDesc = 'correspondentsTitle_DESC',
  CtaButtonBaseUrlAsc = 'ctaButtonBaseUrl_ASC',
  CtaButtonBaseUrlDesc = 'ctaButtonBaseUrl_DESC',
  CtaButtonLabelAsc = 'ctaButtonLabel_ASC',
  CtaButtonLabelDesc = 'ctaButtonLabel_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExchangeRateLabelAsc = 'exchangeRateLabel_ASC',
  ExchangeRateLabelDesc = 'exchangeRateLabel_DESC',
  FeesLabelAsc = 'feesLabel_ASC',
  FeesLabelDesc = 'feesLabel_DESC',
  HideExchangeRateAsc = 'hideExchangeRate_ASC',
  HideExchangeRateDesc = 'hideExchangeRate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PayoutMethodIdAsc = 'payoutMethodId_ASC',
  PayoutMethodIdDesc = 'payoutMethodId_DESC',
  ReceiveMethodDescriptionAsc = 'receiveMethodDescription_ASC',
  ReceiveMethodDescriptionDesc = 'receiveMethodDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SupportLinkTextAsc = 'supportLinkText_ASC',
  SupportLinkTextDesc = 'supportLinkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TransferTimeLabelAsc = 'transferTimeLabel_ASC',
  TransferTimeLabelDesc = 'transferTimeLabel_DESC',
  TransferTimeTooltipAsc = 'transferTimeTooltip_ASC',
  TransferTimeTooltipDesc = 'transferTimeTooltip_DESC',
}

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type Person = Entry &
  _Node & {
    __typename?: 'Person';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    image?: Maybe<Asset>;
    lastName?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<PersonLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type PersonDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type PersonFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type PersonImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type PersonLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type PersonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type PersonNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Used for displaying e.g. blog author [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/person) */
export type PersonSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PersonCollection = {
  __typename?: 'PersonCollection';
  items: Array<Maybe<Person>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PersonFilter = {
  AND?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PersonLinkingCollections = {
  __typename?: 'PersonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};

export type PersonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PersonLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PersonLinkingCollectionsPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PersonLinkingCollectionsPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

export enum PersonOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Sendwave Photo Cards for the Leadership Team Section [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/photoCard) */
export type PhotoCard = Entry &
  _Node & {
    __typename?: 'PhotoCard';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PhotoCardLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Sendwave Photo Cards for the Leadership Team Section [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/photoCard) */
export type PhotoCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Sendwave Photo Cards for the Leadership Team Section [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/photoCard) */
export type PhotoCardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PhotoCardCollection = {
  __typename?: 'PhotoCardCollection';
  items: Array<Maybe<PhotoCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PhotoCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<PhotoCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PhotoCardFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PhotoCardLinkingCollections = {
  __typename?: 'PhotoCardLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PhotoCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PhotoCardOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/popularCountries) */
export type PopularCountries = Entry &
  _Node & {
    __typename?: 'PopularCountries';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PopularCountriesLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    popularCountriesCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    popularCountriesHeading?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/popularCountries) */
export type PopularCountriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/popularCountries) */
export type PopularCountriesNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/popularCountries) */
export type PopularCountriesPopularCountriesCodesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/popularCountries) */
export type PopularCountriesPopularCountriesHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PopularCountriesCollection = {
  __typename?: 'PopularCountriesCollection';
  items: Array<Maybe<PopularCountries>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PopularCountriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<PopularCountriesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PopularCountriesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  popularCountriesCodes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  popularCountriesCodes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  popularCountriesCodes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  popularCountriesCodes_exists?: InputMaybe<Scalars['Boolean']>;
  popularCountriesHeading?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_contains?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_exists?: InputMaybe<Scalars['Boolean']>;
  popularCountriesHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  popularCountriesHeading_not?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_not_contains?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type PopularCountriesLinkingCollections = {
  __typename?: 'PopularCountriesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  listOfCountriesCollection?: Maybe<ListOfCountriesCollection>;
};

export type PopularCountriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PopularCountriesLinkingCollectionsListOfCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PopularCountriesLinkingCollectionsListOfCountriesCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PopularCountriesLinkingCollectionsListOfCountriesCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  ButtonLabelExpandedListAsc = 'buttonLabelExpandedList_ASC',
  ButtonLabelExpandedListDesc = 'buttonLabelExpandedList_DESC',
  ButtonLabelHiddenListAsc = 'buttonLabelHiddenList_ASC',
  ButtonLabelHiddenListDesc = 'buttonLabelHiddenList_DESC',
  ExpandListTextAsc = 'expandListText_ASC',
  ExpandListTextDesc = 'expandListText_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PopularCountriesOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PopularCountriesHeadingAsc = 'popularCountriesHeading_ASC',
  PopularCountriesHeadingDesc = 'popularCountriesHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pricingCalculator) */
export type PricingCalculator = Entry &
  _Node & {
    __typename?: 'PricingCalculator';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PricingCalculatorLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pricingCalculator) */
export type PricingCalculatorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/pricingCalculator) */
export type PricingCalculatorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PricingCalculatorCollection = {
  __typename?: 'PricingCalculatorCollection';
  items: Array<Maybe<PricingCalculator>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PricingCalculatorFilter = {
  AND?: InputMaybe<Array<InputMaybe<PricingCalculatorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PricingCalculatorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PricingCalculatorLinkingCollections = {
  __typename?: 'PricingCalculatorLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type PricingCalculatorLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PricingCalculatorLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PricingCalculatorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PricingCalculatorLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PricingCalculatorOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoApplicability) */
export type PromoApplicability = Entry &
  _Node & {
    __typename?: 'PromoApplicability';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PromoApplicabilityLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    showForCorridors?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
    visitorTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoApplicability) */
export type PromoApplicabilityLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoApplicability) */
export type PromoApplicabilityNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoApplicability) */
export type PromoApplicabilityShowForCorridorsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoApplicability) */
export type PromoApplicabilityVisitorTypesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PromoApplicabilityCollection = {
  __typename?: 'PromoApplicabilityCollection';
  items: Array<Maybe<PromoApplicability>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PromoApplicabilityFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromoApplicabilityFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromoApplicabilityFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']>;
};

export type PromoApplicabilityLinkingCollections = {
  __typename?: 'PromoApplicabilityLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  promoMessageCollection?: Maybe<PromoMessageCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  promotionalBannerDisclaimerCollection?: Maybe<PromotionalBannerDisclaimerCollection>;
};

export type PromoApplicabilityLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PromoApplicabilityLinkingCollectionsPromoMessageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromoApplicabilityLinkingCollectionsPromoMessageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PromoApplicabilityLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromoApplicabilityLinkingCollectionsPromotionalBannerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PromoApplicabilityLinkingCollectionsPromotionalBannerDisclaimerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromoApplicabilityLinkingCollectionsPromotionalBannerDisclaimerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PromoApplicabilityLinkingCollectionsPromoMessageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromoApplicabilityLinkingCollectionsPromotionalBannerCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromoApplicabilityLinkingCollectionsPromotionalBannerDisclaimerCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromoApplicabilityOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoMessage) */
export type PromoMessage = Entry &
  _Node & {
    __typename?: 'PromoMessage';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PromoMessageLinkingCollections>;
    message?: Maybe<PromoMessageMessage>;
    name?: Maybe<Scalars['String']>;
    promoApplicability?: Maybe<PromoApplicability>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoMessage) */
export type PromoMessageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoMessage) */
export type PromoMessageMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoMessage) */
export type PromoMessageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promoMessage) */
export type PromoMessagePromoApplicabilityArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

export type PromoMessageCollection = {
  __typename?: 'PromoMessageCollection';
  items: Array<Maybe<PromoMessage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PromoMessageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromoMessageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromoMessageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  message_contains?: InputMaybe<Scalars['String']>;
  message_exists?: InputMaybe<Scalars['Boolean']>;
  message_not_contains?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promoApplicability?: InputMaybe<CfPromoApplicabilityNestedFilter>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type PromoMessageLinkingCollections = {
  __typename?: 'PromoMessageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PromoMessageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PromoMessageMessage = {
  __typename?: 'PromoMessageMessage';
  json: Scalars['JSON'];
  links: PromoMessageMessageLinks;
};

export type PromoMessageMessageAssets = {
  __typename?: 'PromoMessageMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PromoMessageMessageEntries = {
  __typename?: 'PromoMessageMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PromoMessageMessageLinks = {
  __typename?: 'PromoMessageMessageLinks';
  assets: PromoMessageMessageAssets;
  entries: PromoMessageMessageEntries;
  resources: PromoMessageMessageResources;
};

export type PromoMessageMessageResources = {
  __typename?: 'PromoMessageMessageResources';
  block: Array<PromoMessageMessageResourcesBlock>;
  hyperlink: Array<PromoMessageMessageResourcesHyperlink>;
  inline: Array<PromoMessageMessageResourcesInline>;
};

export type PromoMessageMessageResourcesBlock = ResourceLink & {
  __typename?: 'PromoMessageMessageResourcesBlock';
  sys: ResourceSys;
};

export type PromoMessageMessageResourcesHyperlink = ResourceLink & {
  __typename?: 'PromoMessageMessageResourcesHyperlink';
  sys: ResourceSys;
};

export type PromoMessageMessageResourcesInline = ResourceLink & {
  __typename?: 'PromoMessageMessageResourcesInline';
  sys: ResourceSys;
};

export enum PromoMessageOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBanner = Entry &
  _Node & {
    __typename?: 'PromotionalBanner';
    _id: Scalars['ID'];
    abTestVersionsCollection?: Maybe<PromotionalBannerAbTestVersionsCollection>;
    addToInitialHtml?: Maybe<Scalars['Boolean']>;
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    button?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<PromotionalBannerDescription>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<PromotionalBannerLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    promoApplicability?: Maybe<PromoApplicability>;
    promoIcon?: Maybe<Asset>;
    showForCorridors?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
    visitorTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerAbTestVersionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<PromotionalBannerAbTestVersionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromotionalBannerFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerAddToInitialHtmlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerButtonArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerPromoApplicabilityArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerPromoIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerShowForCorridorsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBanner) */
export type PromotionalBannerVisitorTypesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PromotionalBannerAbTestVersionsCollection = {
  __typename?: 'PromotionalBannerAbTestVersionsCollection';
  items: Array<Maybe<PromotionalBanner>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PromotionalBannerAbTestVersionsCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PromotionalBannerCollection = {
  __typename?: 'PromotionalBannerCollection';
  items: Array<Maybe<PromotionalBanner>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PromotionalBannerDescription = {
  __typename?: 'PromotionalBannerDescription';
  json: Scalars['JSON'];
  links: PromotionalBannerDescriptionLinks;
};

export type PromotionalBannerDescriptionAssets = {
  __typename?: 'PromotionalBannerDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PromotionalBannerDescriptionEntries = {
  __typename?: 'PromotionalBannerDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PromotionalBannerDescriptionLinks = {
  __typename?: 'PromotionalBannerDescriptionLinks';
  assets: PromotionalBannerDescriptionAssets;
  entries: PromotionalBannerDescriptionEntries;
  resources: PromotionalBannerDescriptionResources;
};

export type PromotionalBannerDescriptionResources = {
  __typename?: 'PromotionalBannerDescriptionResources';
  block: Array<PromotionalBannerDescriptionResourcesBlock>;
  hyperlink: Array<PromotionalBannerDescriptionResourcesHyperlink>;
  inline: Array<PromotionalBannerDescriptionResourcesInline>;
};

export type PromotionalBannerDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'PromotionalBannerDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type PromotionalBannerDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'PromotionalBannerDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type PromotionalBannerDescriptionResourcesInline = ResourceLink & {
  __typename?: 'PromotionalBannerDescriptionResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimer = Entry &
  _Node & {
    __typename?: 'PromotionalBannerDisclaimer';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<PromotionalBannerDisclaimerLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    promoApplicability?: Maybe<PromoApplicability>;
    showForCorridors?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
    visitorTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerPromoApplicabilityArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerShowForCorridorsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/promotionalBannerDisclaimer) */
export type PromotionalBannerDisclaimerVisitorTypesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PromotionalBannerDisclaimerCollection = {
  __typename?: 'PromotionalBannerDisclaimerCollection';
  items: Array<Maybe<PromotionalBannerDisclaimer>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PromotionalBannerDisclaimerFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromotionalBannerDisclaimerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromotionalBannerDisclaimerFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promoApplicability?: InputMaybe<CfPromoApplicabilityNestedFilter>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']>;
};

export type PromotionalBannerDisclaimerLinkingCollections = {
  __typename?: 'PromotionalBannerDisclaimerLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type PromotionalBannerDisclaimerLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromotionalBannerDisclaimerLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PromotionalBannerDisclaimerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PromotionalBannerDisclaimerLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PromotionalBannerDisclaimerOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PromotionalBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromotionalBannerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PromotionalBannerFilter>>>;
  abTestVersions?: InputMaybe<CfPromotionalBannerNestedFilter>;
  abTestVersionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  addToInitialHtml?: InputMaybe<Scalars['Boolean']>;
  addToInitialHtml_exists?: InputMaybe<Scalars['Boolean']>;
  addToInitialHtml_not?: InputMaybe<Scalars['Boolean']>;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  button?: InputMaybe<CfLinkNestedFilter>;
  button_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promoApplicability?: InputMaybe<CfPromoApplicabilityNestedFilter>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']>;
  promoIcon_exists?: InputMaybe<Scalars['Boolean']>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']>;
};

export type PromotionalBannerLinkingCollections = {
  __typename?: 'PromotionalBannerLinkingCollections';
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
};

export type PromotionalBannerLinkingCollectionsCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromotionalBannerLinkingCollectionsCalculatorPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PromotionalBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PromotionalBannerLinkingCollectionsPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PromotionalBannerLinkingCollectionsPromotionalBannerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PromotionalBannerLinkingCollectionsCalculatorPageCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsTopPageAsc = 'isTopPage_ASC',
  IsTopPageDesc = 'isTopPage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PromotionalBannerLinkingCollectionsPromotionalBannerCollectionOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PromotionalBannerOrder {
  AddToInitialHtmlAsc = 'addToInitialHtml_ASC',
  AddToInitialHtmlDesc = 'addToInitialHtml_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Query = {
  __typename?: 'Query';
  _node?: Maybe<_Node>;
  accordion?: Maybe<Accordion>;
  accordionCollection?: Maybe<AccordionCollection>;
  accordionItem?: Maybe<AccordionItem>;
  accordionItemCollection?: Maybe<AccordionItemCollection>;
  appBanner?: Maybe<AppBanner>;
  appBannerCollection?: Maybe<AppBannerCollection>;
  appBannerSection?: Maybe<AppBannerSection>;
  appBannerSectionCollection?: Maybe<AppBannerSectionCollection>;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  betterWayTop?: Maybe<BetterWayTop>;
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  bodyText?: Maybe<BodyText>;
  bodyTextCollection?: Maybe<BodyTextCollection>;
  calculator?: Maybe<Calculator>;
  calculatorAppLeadingLinks?: Maybe<CalculatorAppLeadingLinks>;
  calculatorAppLeadingLinksCollection?: Maybe<CalculatorAppLeadingLinksCollection>;
  calculatorCollection?: Maybe<CalculatorCollection>;
  calculatorPage?: Maybe<CalculatorPage>;
  calculatorPageCollection?: Maybe<CalculatorPageCollection>;
  card?: Maybe<Card>;
  cardCollection?: Maybe<CardCollection>;
  cards?: Maybe<Cards>;
  cardsCollection?: Maybe<CardsCollection>;
  cexCurrency?: Maybe<CexCurrency>;
  cexCurrencyCollection?: Maybe<CexCurrencyCollection>;
  cexCurrencyCorridor?: Maybe<CexCurrencyCorridor>;
  cexCurrencyCorridorCollection?: Maybe<CexCurrencyCorridorCollection>;
  cexSearch?: Maybe<CexSearch>;
  cexSearchCollection?: Maybe<CexSearchCollection>;
  comparisonTable?: Maybe<ComparisonTable>;
  comparisonTableCell?: Maybe<ComparisonTableCell>;
  comparisonTableCellCollection?: Maybe<ComparisonTableCellCollection>;
  comparisonTableCollection?: Maybe<ComparisonTableCollection>;
  comparisonTableRow?: Maybe<ComparisonTableRow>;
  comparisonTableRowCollection?: Maybe<ComparisonTableRowCollection>;
  containedBanner?: Maybe<ContainedBanner>;
  containedBannerCollection?: Maybe<ContainedBannerCollection>;
  correspondents?: Maybe<Correspondents>;
  correspondentsCollection?: Maybe<CorrespondentsCollection>;
  countriesGrid?: Maybe<CountriesGrid>;
  countriesGridCollection?: Maybe<CountriesGridCollection>;
  country?: Maybe<Country>;
  countryCollection?: Maybe<CountryCollection>;
  entryCollection?: Maybe<EntryCollection>;
  exchangeCalculator?: Maybe<ExchangeCalculator>;
  exchangeCalculatorCollection?: Maybe<ExchangeCalculatorCollection>;
  faqHero?: Maybe<FaqHero>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
  faqItem?: Maybe<FaqItem>;
  faqItemCollection?: Maybe<FaqItemCollection>;
  faqModule?: Maybe<FaqModule>;
  faqModuleCollection?: Maybe<FaqModuleCollection>;
  faqModuleItem?: Maybe<FaqModuleItem>;
  faqModuleItemCollection?: Maybe<FaqModuleItemCollection>;
  faqPopularQuestions?: Maybe<FaqPopularQuestions>;
  faqPopularQuestionsCollection?: Maybe<FaqPopularQuestionsCollection>;
  faqSection?: Maybe<FaqSection>;
  faqSectionCollection?: Maybe<FaqSectionCollection>;
  faqTitleAndDescription?: Maybe<FaqTitleAndDescription>;
  faqTitleAndDescriptionCollection?: Maybe<FaqTitleAndDescriptionCollection>;
  faqTopic?: Maybe<FaqTopic>;
  faqTopicCollection?: Maybe<FaqTopicCollection>;
  faqTopicsModule?: Maybe<FaqTopicsModule>;
  faqTopicsModuleCollection?: Maybe<FaqTopicsModuleCollection>;
  faqTopicsModuleItems?: Maybe<FaqTopicsModuleItems>;
  faqTopicsModuleItemsCollection?: Maybe<FaqTopicsModuleItemsCollection>;
  featureFlag?: Maybe<FeatureFlag>;
  featureFlagCollection?: Maybe<FeatureFlagCollection>;
  featureFlags?: Maybe<FeatureFlags>;
  featureFlagsCollection?: Maybe<FeatureFlagsCollection>;
  footer?: Maybe<Footer>;
  footerCollection?: Maybe<FooterCollection>;
  footerNavigationGroup?: Maybe<FooterNavigationGroup>;
  footerNavigationGroupCollection?: Maybe<FooterNavigationGroupCollection>;
  footerNavigationGroupExtras?: Maybe<FooterNavigationGroupExtras>;
  footerNavigationGroupExtrasCollection?: Maybe<FooterNavigationGroupExtrasCollection>;
  fullWidthBanner?: Maybe<FullWidthBanner>;
  fullWidthBannerCollection?: Maybe<FullWidthBannerCollection>;
  genericErrorPage?: Maybe<GenericErrorPage>;
  genericErrorPageCollection?: Maybe<GenericErrorPageCollection>;
  heroSlot?: Maybe<HeroSlot>;
  heroSlotCollection?: Maybe<HeroSlotCollection>;
  heroTextVariation?: Maybe<HeroTextVariation>;
  heroTextVariationCollection?: Maybe<HeroTextVariationCollection>;
  iconWithText?: Maybe<IconWithText>;
  iconWithTextCollection?: Maybe<IconWithTextCollection>;
  image?: Maybe<Image>;
  imageCollection?: Maybe<ImageCollection>;
  imageGrid?: Maybe<ImageGrid>;
  imageGridCollection?: Maybe<ImageGridCollection>;
  imageGridItem?: Maybe<ImageGridItem>;
  imageGridItemCollection?: Maybe<ImageGridItemCollection>;
  infoCards?: Maybe<InfoCards>;
  infoCardsCollection?: Maybe<InfoCardsCollection>;
  layoutFooter?: Maybe<LayoutFooter>;
  layoutFooterCollection?: Maybe<LayoutFooterCollection>;
  layoutHeader?: Maybe<LayoutHeader>;
  layoutHeaderCollection?: Maybe<LayoutHeaderCollection>;
  leadershipTeam?: Maybe<LeadershipTeam>;
  leadershipTeamCollection?: Maybe<LeadershipTeamCollection>;
  leadershipTeamEntry?: Maybe<LeadershipTeamEntry>;
  leadershipTeamEntryCollection?: Maybe<LeadershipTeamEntryCollection>;
  legalMessaging?: Maybe<LegalMessaging>;
  legalMessagingCollection?: Maybe<LegalMessagingCollection>;
  link?: Maybe<Link>;
  linkCollection?: Maybe<LinkCollection>;
  linkGroup?: Maybe<LinkGroup>;
  linkGroupCollection?: Maybe<LinkGroupCollection>;
  listOfCountries?: Maybe<ListOfCountries>;
  listOfCountriesCollection?: Maybe<ListOfCountriesCollection>;
  messages?: Maybe<Messages>;
  messagesCollection?: Maybe<MessagesCollection>;
  mobileAppLeadingLinks?: Maybe<MobileAppLeadingLinks>;
  mobileAppLeadingLinksCollection?: Maybe<MobileAppLeadingLinksCollection>;
  mobileAppQrCode?: Maybe<MobileAppQrCode>;
  mobileAppQrCodeCollection?: Maybe<MobileAppQrCodeCollection>;
  nestedAccordion?: Maybe<NestedAccordion>;
  nestedAccordionCollection?: Maybe<NestedAccordionCollection>;
  nestedAccordionItems?: Maybe<NestedAccordionItems>;
  nestedAccordionItemsCollection?: Maybe<NestedAccordionItemsCollection>;
  nestedAccordionTitleAndDescription?: Maybe<NestedAccordionTitleAndDescription>;
  nestedAccordionTitleAndDescriptionCollection?: Maybe<NestedAccordionTitleAndDescriptionCollection>;
  notFoundContent?: Maybe<NotFoundContent>;
  notFoundContentCollection?: Maybe<NotFoundContentCollection>;
  notFoundErrorPage?: Maybe<NotFoundErrorPage>;
  notFoundErrorPageCollection?: Maybe<NotFoundErrorPageCollection>;
  page?: Maybe<Page>;
  pageCollection?: Maybe<PageCollection>;
  pageSection?: Maybe<PageSection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
  payoutMethod?: Maybe<PayoutMethod>;
  payoutMethodCollection?: Maybe<PayoutMethodCollection>;
  person?: Maybe<Person>;
  personCollection?: Maybe<PersonCollection>;
  photoCard?: Maybe<PhotoCard>;
  photoCardCollection?: Maybe<PhotoCardCollection>;
  popularCountries?: Maybe<PopularCountries>;
  popularCountriesCollection?: Maybe<PopularCountriesCollection>;
  pricingCalculator?: Maybe<PricingCalculator>;
  pricingCalculatorCollection?: Maybe<PricingCalculatorCollection>;
  promoApplicability?: Maybe<PromoApplicability>;
  promoApplicabilityCollection?: Maybe<PromoApplicabilityCollection>;
  promoMessage?: Maybe<PromoMessage>;
  promoMessageCollection?: Maybe<PromoMessageCollection>;
  promotionalBanner?: Maybe<PromotionalBanner>;
  promotionalBannerCollection?: Maybe<PromotionalBannerCollection>;
  promotionalBannerDisclaimer?: Maybe<PromotionalBannerDisclaimer>;
  promotionalBannerDisclaimerCollection?: Maybe<PromotionalBannerDisclaimerCollection>;
  redirect?: Maybe<Redirect>;
  redirectCollection?: Maybe<RedirectCollection>;
  regionCode?: Maybe<RegionCode>;
  regionCodeCollection?: Maybe<RegionCodeCollection>;
  reviewsCard?: Maybe<ReviewsCard>;
  reviewsCardCollection?: Maybe<ReviewsCardCollection>;
  reviewsSection?: Maybe<ReviewsSection>;
  reviewsSectionCollection?: Maybe<ReviewsSectionCollection>;
  reviewsTimeDictionary?: Maybe<ReviewsTimeDictionary>;
  reviewsTimeDictionaryCollection?: Maybe<ReviewsTimeDictionaryCollection>;
  robotsConfiguration?: Maybe<RobotsConfiguration>;
  robotsConfigurationCollection?: Maybe<RobotsConfigurationCollection>;
  search?: Maybe<Search>;
  searchCollection?: Maybe<SearchCollection>;
  sendToCountryModule?: Maybe<SendToCountryModule>;
  sendToCountryModuleCollection?: Maybe<SendToCountryModuleCollection>;
  seoContactPoint?: Maybe<SeoContactPoint>;
  seoContactPointCollection?: Maybe<SeoContactPointCollection>;
  seoOrganizationStructuredData?: Maybe<SeoOrganizationStructuredData>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
  seoSameAs?: Maybe<SeoSameAs>;
  seoSameAsCollection?: Maybe<SeoSameAsCollection>;
  sitemap?: Maybe<Sitemap>;
  sitemapCollection?: Maybe<SitemapCollection>;
  step?: Maybe<Step>;
  stepByStep?: Maybe<StepByStep>;
  stepByStepCollection?: Maybe<StepByStepCollection>;
  stepCollection?: Maybe<StepCollection>;
  textCard?: Maybe<TextCard>;
  textCardCollection?: Maybe<TextCardCollection>;
  textMeTheAppModule?: Maybe<TextMeTheAppModule>;
  textMeTheAppModuleCollection?: Maybe<TextMeTheAppModuleCollection>;
  textSection?: Maybe<TextSection>;
  textSectionCollection?: Maybe<TextSectionCollection>;
  textSectionV2?: Maybe<TextSectionV2>;
  textSectionV2Collection?: Maybe<TextSectionV2Collection>;
  textWithColor?: Maybe<TextWithColor>;
  textWithColorCollection?: Maybe<TextWithColorCollection>;
  trustpilotWidget?: Maybe<TrustpilotWidget>;
  trustpilotWidgetCollection?: Maybe<TrustpilotWidgetCollection>;
  video?: Maybe<Video>;
  videoCollection?: Maybe<VideoCollection>;
  webForm?: Maybe<WebForm>;
  webFormCollection?: Maybe<WebFormCollection>;
  whyChoose?: Maybe<WhyChoose>;
  whyChooseCollection?: Maybe<WhyChooseCollection>;
  whyChooseReason?: Maybe<WhyChooseReason>;
  whyChooseReasonCollection?: Maybe<WhyChooseReasonCollection>;
};

export type Query_NodeArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAccordionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionFilter>;
};

export type QueryAccordionItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAccordionItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AccordionItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionItemFilter>;
};

export type QueryAppBannerArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAppBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AppBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppBannerFilter>;
};

export type QueryAppBannerSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAppBannerSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AppBannerSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AppBannerSectionFilter>;
};

export type QueryAssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilter>;
};

export type QueryBetterWayTopArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BetterWayTopOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BetterWayTopFilter>;
};

export type QueryBodyTextArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBodyTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BodyTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BodyTextFilter>;
};

export type QueryCalculatorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCalculatorAppLeadingLinksArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCalculatorAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalculatorAppLeadingLinksOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalculatorAppLeadingLinksFilter>;
};

export type QueryCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalculatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalculatorFilter>;
};

export type QueryCalculatorPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCalculatorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalculatorPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CalculatorPageFilter>;
};

export type QueryCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardFilter>;
};

export type QueryCardsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CardsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardsFilter>;
};

export type QueryCexCurrencyArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCexCurrencyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CexCurrencyOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CexCurrencyFilter>;
};

export type QueryCexCurrencyCorridorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCexCurrencyCorridorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CexCurrencyCorridorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CexCurrencyCorridorFilter>;
};

export type QueryCexSearchArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCexSearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CexSearchOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CexSearchFilter>;
};

export type QueryComparisonTableArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryComparisonTableCellArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryComparisonTableCellCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableCellOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableCellFilter>;
};

export type QueryComparisonTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableFilter>;
};

export type QueryComparisonTableRowArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryComparisonTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ComparisonTableRowOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComparisonTableRowFilter>;
};

export type QueryContainedBannerArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryContainedBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContainedBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContainedBannerFilter>;
};

export type QueryCorrespondentsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCorrespondentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CorrespondentsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CorrespondentsFilter>;
};

export type QueryCountriesGridArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCountriesGridCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CountriesGridOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountriesGridFilter>;
};

export type QueryCountryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCountryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CountryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryFilter>;
};

export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntryFilter>;
};

export type QueryExchangeCalculatorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryExchangeCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ExchangeCalculatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExchangeCalculatorFilter>;
};

export type QueryFaqHeroArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqHeroOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqHeroFilter>;
};

export type QueryFaqItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemFilter>;
};

export type QueryFaqModuleArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqModuleFilter>;
};

export type QueryFaqModuleItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqModuleItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqModuleItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqModuleItemFilter>;
};

export type QueryFaqPopularQuestionsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqPopularQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqPopularQuestionsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqPopularQuestionsFilter>;
};

export type QueryFaqSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqSectionFilter>;
};

export type QueryFaqTitleAndDescriptionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqTitleAndDescriptionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqTitleAndDescriptionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqTitleAndDescriptionFilter>;
};

export type QueryFaqTopicArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqTopicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqTopicFilter>;
};

export type QueryFaqTopicsModuleArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqTopicsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicsModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqTopicsModuleFilter>;
};

export type QueryFaqTopicsModuleItemsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqTopicsModuleItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqTopicsModuleItemsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqTopicsModuleItemsFilter>;
};

export type QueryFeatureFlagArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFeatureFlagCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeatureFlagOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureFlagFilter>;
};

export type QueryFeatureFlagsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFeatureFlagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeatureFlagsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeatureFlagsFilter>;
};

export type QueryFooterArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterFilter>;
};

export type QueryFooterNavigationGroupArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFooterNavigationGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterNavigationGroupFilter>;
};

export type QueryFooterNavigationGroupExtrasArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFooterNavigationGroupExtrasCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterNavigationGroupExtrasOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterNavigationGroupExtrasFilter>;
};

export type QueryFullWidthBannerArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFullWidthBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FullWidthBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FullWidthBannerFilter>;
};

export type QueryGenericErrorPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGenericErrorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GenericErrorPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GenericErrorPageFilter>;
};

export type QueryHeroSlotArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHeroSlotCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeroSlotOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeroSlotFilter>;
};

export type QueryHeroTextVariationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHeroTextVariationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeroTextVariationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeroTextVariationFilter>;
};

export type QueryIconWithTextArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryIconWithTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<IconWithTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IconWithTextFilter>;
};

export type QueryImageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageFilter>;
};

export type QueryImageGridArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryImageGridCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageGridOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageGridFilter>;
};

export type QueryImageGridItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryImageGridItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ImageGridItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageGridItemFilter>;
};

export type QueryInfoCardsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInfoCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<InfoCardsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InfoCardsFilter>;
};

export type QueryLayoutFooterArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLayoutFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LayoutFooterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LayoutFooterFilter>;
};

export type QueryLayoutHeaderArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLayoutHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LayoutHeaderOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LayoutHeaderFilter>;
};

export type QueryLeadershipTeamArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLeadershipTeamCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LeadershipTeamOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeadershipTeamFilter>;
};

export type QueryLeadershipTeamEntryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLeadershipTeamEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LeadershipTeamEntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeadershipTeamEntryFilter>;
};

export type QueryLegalMessagingArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLegalMessagingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LegalMessagingOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegalMessagingFilter>;
};

export type QueryLinkArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};

export type QueryLinkGroupArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLinkGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LinkGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkGroupFilter>;
};

export type QueryListOfCountriesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryListOfCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ListOfCountriesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ListOfCountriesFilter>;
};

export type QueryMessagesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMessagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MessagesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessagesFilter>;
};

export type QueryMobileAppLeadingLinksArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMobileAppLeadingLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MobileAppLeadingLinksOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MobileAppLeadingLinksFilter>;
};

export type QueryMobileAppQrCodeArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMobileAppQrCodeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MobileAppQrCodeOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MobileAppQrCodeFilter>;
};

export type QueryNestedAccordionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryNestedAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NestedAccordionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NestedAccordionFilter>;
};

export type QueryNestedAccordionItemsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryNestedAccordionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NestedAccordionItemsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NestedAccordionItemsFilter>;
};

export type QueryNestedAccordionTitleAndDescriptionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryNestedAccordionTitleAndDescriptionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<NestedAccordionTitleAndDescriptionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NestedAccordionTitleAndDescriptionFilter>;
};

export type QueryNotFoundContentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryNotFoundContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotFoundContentFilter>;
};

export type QueryNotFoundErrorPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryNotFoundErrorPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NotFoundErrorPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotFoundErrorPageFilter>;
};

export type QueryPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageFilter>;
};

export type QueryPageSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageSectionFilter>;
};

export type QueryPayoutMethodArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPayoutMethodCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PayoutMethodOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayoutMethodFilter>;
};

export type QueryPersonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PersonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PersonFilter>;
};

export type QueryPhotoCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPhotoCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PhotoCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PhotoCardFilter>;
};

export type QueryPopularCountriesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPopularCountriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PopularCountriesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopularCountriesFilter>;
};

export type QueryPricingCalculatorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPricingCalculatorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PricingCalculatorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PricingCalculatorFilter>;
};

export type QueryPromoApplicabilityArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPromoApplicabilityCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PromoApplicabilityOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromoApplicabilityFilter>;
};

export type QueryPromoMessageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPromoMessageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PromoMessageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromoMessageFilter>;
};

export type QueryPromotionalBannerArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPromotionalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PromotionalBannerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromotionalBannerFilter>;
};

export type QueryPromotionalBannerDisclaimerArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPromotionalBannerDisclaimerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PromotionalBannerDisclaimerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromotionalBannerDisclaimerFilter>;
};

export type QueryRedirectArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRedirectCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RedirectOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RedirectFilter>;
};

export type QueryRegionCodeArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRegionCodeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RegionCodeOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionCodeFilter>;
};

export type QueryReviewsCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryReviewsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ReviewsCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewsCardFilter>;
};

export type QueryReviewsSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryReviewsSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ReviewsSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewsSectionFilter>;
};

export type QueryReviewsTimeDictionaryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryReviewsTimeDictionaryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ReviewsTimeDictionaryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewsTimeDictionaryFilter>;
};

export type QueryRobotsConfigurationArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRobotsConfigurationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RobotsConfigurationOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RobotsConfigurationFilter>;
};

export type QuerySearchArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SearchOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SearchFilter>;
};

export type QuerySendToCountryModuleArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySendToCountryModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SendToCountryModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SendToCountryModuleFilter>;
};

export type QuerySeoContactPointArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySeoContactPointCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeoContactPointOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoContactPointFilter>;
};

export type QuerySeoOrganizationStructuredDataArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeoOrganizationStructuredDataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoOrganizationStructuredDataFilter>;
};

export type QuerySeoSameAsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySeoSameAsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeoSameAsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoSameAsFilter>;
};

export type QuerySitemapArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySitemapCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitemapOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitemapFilter>;
};

export type QueryStepArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryStepByStepArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryStepByStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<StepByStepOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StepByStepFilter>;
};

export type QueryStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<StepOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StepFilter>;
};

export type QueryTextCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTextCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TextCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TextCardFilter>;
};

export type QueryTextMeTheAppModuleArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTextMeTheAppModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TextMeTheAppModuleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TextMeTheAppModuleFilter>;
};

export type QueryTextSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTextSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TextSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TextSectionFilter>;
};

export type QueryTextSectionV2Args = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTextSectionV2CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TextSectionV2Order>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TextSectionV2Filter>;
};

export type QueryTextWithColorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTextWithColorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TextWithColorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TextWithColorFilter>;
};

export type QueryTrustpilotWidgetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTrustpilotWidgetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TrustpilotWidgetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrustpilotWidgetFilter>;
};

export type QueryVideoArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoFilter>;
};

export type QueryWebFormArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWebFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<WebFormOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WebFormFilter>;
};

export type QueryWhyChooseArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWhyChooseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<WhyChooseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WhyChooseFilter>;
};

export type QueryWhyChooseReasonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWhyChooseReasonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<WhyChooseReasonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WhyChooseReasonFilter>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type Redirect = Entry &
  _Node & {
    __typename?: 'Redirect';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    destination?: Maybe<Scalars['String']>;
    from?: Maybe<Array<Maybe<Scalars['String']>>>;
    fromRegex?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<RedirectLinkingCollections>;
    locale?: Maybe<Scalars['Boolean']>;
    locales?: Maybe<Array<Maybe<Scalars['String']>>>;
    name?: Maybe<Scalars['String']>;
    permanent?: Maybe<Scalars['Boolean']>;
    redirectToParentPage?: Maybe<Scalars['Boolean']>;
    shouldKeepParameters?: Maybe<Scalars['Boolean']>;
    source?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    sys: Sys;
    to?: Maybe<Scalars['String']>;
    toRegex?: Maybe<Scalars['String']>;
  };

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectDestinationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectFromArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectFromRegexArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectLocaleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectLocalesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectPermanentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectRedirectToParentPageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectShouldKeepParametersArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectSourceArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectStatusArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectToArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This content type is created to redirect pages. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/redirect) */
export type RedirectToRegexArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type RedirectCollection = {
  __typename?: 'RedirectCollection';
  items: Array<Maybe<Redirect>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RedirectFilter = {
  AND?: InputMaybe<Array<InputMaybe<RedirectFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RedirectFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  destination?: InputMaybe<Scalars['String']>;
  destination_contains?: InputMaybe<Scalars['String']>;
  destination_exists?: InputMaybe<Scalars['Boolean']>;
  destination_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  destination_not?: InputMaybe<Scalars['String']>;
  destination_not_contains?: InputMaybe<Scalars['String']>;
  destination_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fromRegex?: InputMaybe<Scalars['String']>;
  fromRegex_contains?: InputMaybe<Scalars['String']>;
  fromRegex_exists?: InputMaybe<Scalars['Boolean']>;
  fromRegex_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fromRegex_not?: InputMaybe<Scalars['String']>;
  fromRegex_not_contains?: InputMaybe<Scalars['String']>;
  fromRegex_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from_exists?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['Boolean']>;
  locale_exists?: InputMaybe<Scalars['Boolean']>;
  locale_not?: InputMaybe<Scalars['Boolean']>;
  locales_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locales_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locales_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locales_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permanent?: InputMaybe<Scalars['Boolean']>;
  permanent_exists?: InputMaybe<Scalars['Boolean']>;
  permanent_not?: InputMaybe<Scalars['Boolean']>;
  redirectToParentPage?: InputMaybe<Scalars['Boolean']>;
  redirectToParentPage_exists?: InputMaybe<Scalars['Boolean']>;
  redirectToParentPage_not?: InputMaybe<Scalars['Boolean']>;
  shouldKeepParameters?: InputMaybe<Scalars['Boolean']>;
  shouldKeepParameters_exists?: InputMaybe<Scalars['Boolean']>;
  shouldKeepParameters_not?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  source_contains?: InputMaybe<Scalars['String']>;
  source_exists?: InputMaybe<Scalars['Boolean']>;
  source_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source_not?: InputMaybe<Scalars['String']>;
  source_not_contains?: InputMaybe<Scalars['String']>;
  source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
  status_contains?: InputMaybe<Scalars['String']>;
  status_exists?: InputMaybe<Scalars['Boolean']>;
  status_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status_not?: InputMaybe<Scalars['String']>;
  status_not_contains?: InputMaybe<Scalars['String']>;
  status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  to?: InputMaybe<Scalars['String']>;
  toRegex?: InputMaybe<Scalars['String']>;
  toRegex_contains?: InputMaybe<Scalars['String']>;
  toRegex_exists?: InputMaybe<Scalars['Boolean']>;
  toRegex_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  toRegex_not?: InputMaybe<Scalars['String']>;
  toRegex_not_contains?: InputMaybe<Scalars['String']>;
  toRegex_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  to_contains?: InputMaybe<Scalars['String']>;
  to_exists?: InputMaybe<Scalars['Boolean']>;
  to_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  to_not?: InputMaybe<Scalars['String']>;
  to_not_contains?: InputMaybe<Scalars['String']>;
  to_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RedirectLinkingCollections = {
  __typename?: 'RedirectLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RedirectLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum RedirectOrder {
  DestinationAsc = 'destination_ASC',
  DestinationDesc = 'destination_DESC',
  FromRegexAsc = 'fromRegex_ASC',
  FromRegexDesc = 'fromRegex_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PermanentAsc = 'permanent_ASC',
  PermanentDesc = 'permanent_DESC',
  RedirectToParentPageAsc = 'redirectToParentPage_ASC',
  RedirectToParentPageDesc = 'redirectToParentPage_DESC',
  ShouldKeepParametersAsc = 'shouldKeepParameters_ASC',
  ShouldKeepParametersDesc = 'shouldKeepParameters_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ToRegexAsc = 'toRegex_ASC',
  ToRegexDesc = 'toRegex_DESC',
  ToAsc = 'to_ASC',
  ToDesc = 'to_DESC',
}

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCode = Entry &
  _Node & {
    __typename?: 'RegionCode';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    daDk?: Maybe<Scalars['Boolean']>;
    en?: Maybe<Scalars['Boolean']>;
    enAu?: Maybe<Scalars['Boolean']>;
    enCa?: Maybe<Scalars['Boolean']>;
    enGb?: Maybe<Scalars['Boolean']>;
    enMy?: Maybe<Scalars['Boolean']>;
    enNz?: Maybe<Scalars['Boolean']>;
    enSe?: Maybe<Scalars['Boolean']>;
    enUs?: Maybe<Scalars['Boolean']>;
    esUs?: Maybe<Scalars['Boolean']>;
    frCa?: Maybe<Scalars['Boolean']>;
    linkedFrom?: Maybe<RegionCodeLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeDaDkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnAuArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnCaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnGbArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnMyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnNzArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnSeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEnUsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeEsUsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeFrCaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** List of region codes booleans to make dynamic title [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/regionCode) */
export type RegionCodeNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type RegionCodeCollection = {
  __typename?: 'RegionCodeCollection';
  items: Array<Maybe<RegionCode>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RegionCodeFilter = {
  AND?: InputMaybe<Array<InputMaybe<RegionCodeFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RegionCodeFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  daDk?: InputMaybe<Scalars['Boolean']>;
  daDk_exists?: InputMaybe<Scalars['Boolean']>;
  daDk_not?: InputMaybe<Scalars['Boolean']>;
  en?: InputMaybe<Scalars['Boolean']>;
  enAu?: InputMaybe<Scalars['Boolean']>;
  enAu_exists?: InputMaybe<Scalars['Boolean']>;
  enAu_not?: InputMaybe<Scalars['Boolean']>;
  enCa?: InputMaybe<Scalars['Boolean']>;
  enCa_exists?: InputMaybe<Scalars['Boolean']>;
  enCa_not?: InputMaybe<Scalars['Boolean']>;
  enGb?: InputMaybe<Scalars['Boolean']>;
  enGb_exists?: InputMaybe<Scalars['Boolean']>;
  enGb_not?: InputMaybe<Scalars['Boolean']>;
  enMy?: InputMaybe<Scalars['Boolean']>;
  enMy_exists?: InputMaybe<Scalars['Boolean']>;
  enMy_not?: InputMaybe<Scalars['Boolean']>;
  enNz?: InputMaybe<Scalars['Boolean']>;
  enNz_exists?: InputMaybe<Scalars['Boolean']>;
  enNz_not?: InputMaybe<Scalars['Boolean']>;
  enSe?: InputMaybe<Scalars['Boolean']>;
  enSe_exists?: InputMaybe<Scalars['Boolean']>;
  enSe_not?: InputMaybe<Scalars['Boolean']>;
  enUs?: InputMaybe<Scalars['Boolean']>;
  enUs_exists?: InputMaybe<Scalars['Boolean']>;
  enUs_not?: InputMaybe<Scalars['Boolean']>;
  en_exists?: InputMaybe<Scalars['Boolean']>;
  en_not?: InputMaybe<Scalars['Boolean']>;
  esUs?: InputMaybe<Scalars['Boolean']>;
  esUs_exists?: InputMaybe<Scalars['Boolean']>;
  esUs_not?: InputMaybe<Scalars['Boolean']>;
  frCa?: InputMaybe<Scalars['Boolean']>;
  frCa_exists?: InputMaybe<Scalars['Boolean']>;
  frCa_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type RegionCodeLinkingCollections = {
  __typename?: 'RegionCodeLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RegionCodeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum RegionCodeOrder {
  DaDkAsc = 'daDk_ASC',
  DaDkDesc = 'daDk_DESC',
  EnAuAsc = 'enAu_ASC',
  EnAuDesc = 'enAu_DESC',
  EnCaAsc = 'enCa_ASC',
  EnCaDesc = 'enCa_DESC',
  EnGbAsc = 'enGb_ASC',
  EnGbDesc = 'enGb_DESC',
  EnMyAsc = 'enMy_ASC',
  EnMyDesc = 'enMy_DESC',
  EnNzAsc = 'enNz_ASC',
  EnNzDesc = 'enNz_DESC',
  EnSeAsc = 'enSe_ASC',
  EnSeDesc = 'enSe_DESC',
  EnUsAsc = 'enUs_ASC',
  EnUsDesc = 'enUs_DESC',
  EnAsc = 'en_ASC',
  EnDesc = 'en_DESC',
  EsUsAsc = 'esUs_ASC',
  EsUsDesc = 'esUs_DESC',
  FrCaAsc = 'frCa_ASC',
  FrCaDesc = 'frCa_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String'];
  urn: Scalars['String'];
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCard = Entry &
  _Node & {
    __typename?: 'ReviewsCard';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    country?: Maybe<Country>;
    description?: Maybe<Scalars['String']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<ReviewsCardLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    ratings?: Maybe<Scalars['Int']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
  };

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCardCountryArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CountryFilter>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCardDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCardImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCardRatingsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15480&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsCard) */
export type ReviewsCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ReviewsCardCollection = {
  __typename?: 'ReviewsCardCollection';
  items: Array<Maybe<ReviewsCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReviewsCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReviewsCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewsCardFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  country?: InputMaybe<CfCountryNestedFilter>;
  country_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ratings?: InputMaybe<Scalars['Int']>;
  ratings_exists?: InputMaybe<Scalars['Boolean']>;
  ratings_gt?: InputMaybe<Scalars['Int']>;
  ratings_gte?: InputMaybe<Scalars['Int']>;
  ratings_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  ratings_lt?: InputMaybe<Scalars['Int']>;
  ratings_lte?: InputMaybe<Scalars['Int']>;
  ratings_not?: InputMaybe<Scalars['Int']>;
  ratings_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReviewsCardLinkingCollections = {
  __typename?: 'ReviewsCardLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  reviewsSectionCollection?: Maybe<ReviewsSectionCollection>;
};

export type ReviewsCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ReviewsCardLinkingCollectionsReviewsSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ReviewsCardLinkingCollectionsReviewsSectionCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ReviewsCardLinkingCollectionsReviewsSectionCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ReviewsCardOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RatingsAsc = 'ratings_ASC',
  RatingsDesc = 'ratings_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSection = Entry &
  _Node & {
    __typename?: 'ReviewsSection';
    _id: Scalars['ID'];
    backgroundImage?: Maybe<Asset>;
    backgroundImageMobile?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<ReviewsSectionLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    reviewsCardCollection?: Maybe<ReviewsSectionReviewsCardCollection>;
    sys: Sys;
    trustpilotWidget?: Maybe<TrustpilotWidget>;
  };

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSectionBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSectionBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSectionHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSectionReviewsCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<ReviewsSectionReviewsCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewsCardFilter>;
};

/** [web-cms-sw] https://www.figma.com/file/UrYpEkrAxmls13ut84AIos/Sendwave?node-id=857%3A15453&mode=dev [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsSection) */
export type ReviewsSectionTrustpilotWidgetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<TrustpilotWidgetFilter>;
};

export type ReviewsSectionCollection = {
  __typename?: 'ReviewsSectionCollection';
  items: Array<Maybe<ReviewsSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReviewsSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReviewsSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewsSectionFilter>>>;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reviewsCard?: InputMaybe<CfReviewsCardNestedFilter>;
  reviewsCardCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  trustpilotWidget?: InputMaybe<CfTrustpilotWidgetNestedFilter>;
  trustpilotWidget_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ReviewsSectionLinkingCollections = {
  __typename?: 'ReviewsSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ReviewsSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ReviewsSectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ReviewsSectionReviewsCardCollection = {
  __typename?: 'ReviewsSectionReviewsCardCollection';
  items: Array<Maybe<ReviewsCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ReviewsSectionReviewsCardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RatingsAsc = 'ratings_ASC',
  RatingsDesc = 'ratings_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionary = Entry &
  _Node & {
    __typename?: 'ReviewsTimeDictionary';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    fewDaysAgo?: Maybe<Scalars['String']>;
    fewMonthsAgo?: Maybe<Scalars['String']>;
    fewWeeksAgo?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<ReviewsTimeDictionaryLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    oneDayAgo?: Maybe<Scalars['String']>;
    oneMonthAgo?: Maybe<Scalars['String']>;
    oneWeekAgo?: Maybe<Scalars['String']>;
    sys: Sys;
    today?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryFewDaysAgoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryFewMonthsAgoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryFewWeeksAgoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryOneDayAgoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryOneMonthAgoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryOneWeekAgoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/reviewsTimeDictionary) */
export type ReviewsTimeDictionaryTodayArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ReviewsTimeDictionaryCollection = {
  __typename?: 'ReviewsTimeDictionaryCollection';
  items: Array<Maybe<ReviewsTimeDictionary>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReviewsTimeDictionaryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReviewsTimeDictionaryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewsTimeDictionaryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fewDaysAgo?: InputMaybe<Scalars['String']>;
  fewDaysAgo_contains?: InputMaybe<Scalars['String']>;
  fewDaysAgo_exists?: InputMaybe<Scalars['Boolean']>;
  fewDaysAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fewDaysAgo_not?: InputMaybe<Scalars['String']>;
  fewDaysAgo_not_contains?: InputMaybe<Scalars['String']>;
  fewDaysAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fewMonthsAgo?: InputMaybe<Scalars['String']>;
  fewMonthsAgo_contains?: InputMaybe<Scalars['String']>;
  fewMonthsAgo_exists?: InputMaybe<Scalars['Boolean']>;
  fewMonthsAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fewMonthsAgo_not?: InputMaybe<Scalars['String']>;
  fewMonthsAgo_not_contains?: InputMaybe<Scalars['String']>;
  fewMonthsAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fewWeeksAgo?: InputMaybe<Scalars['String']>;
  fewWeeksAgo_contains?: InputMaybe<Scalars['String']>;
  fewWeeksAgo_exists?: InputMaybe<Scalars['Boolean']>;
  fewWeeksAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fewWeeksAgo_not?: InputMaybe<Scalars['String']>;
  fewWeeksAgo_not_contains?: InputMaybe<Scalars['String']>;
  fewWeeksAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  oneDayAgo?: InputMaybe<Scalars['String']>;
  oneDayAgo_contains?: InputMaybe<Scalars['String']>;
  oneDayAgo_exists?: InputMaybe<Scalars['Boolean']>;
  oneDayAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  oneDayAgo_not?: InputMaybe<Scalars['String']>;
  oneDayAgo_not_contains?: InputMaybe<Scalars['String']>;
  oneDayAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  oneMonthAgo?: InputMaybe<Scalars['String']>;
  oneMonthAgo_contains?: InputMaybe<Scalars['String']>;
  oneMonthAgo_exists?: InputMaybe<Scalars['Boolean']>;
  oneMonthAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  oneMonthAgo_not?: InputMaybe<Scalars['String']>;
  oneMonthAgo_not_contains?: InputMaybe<Scalars['String']>;
  oneMonthAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  oneWeekAgo?: InputMaybe<Scalars['String']>;
  oneWeekAgo_contains?: InputMaybe<Scalars['String']>;
  oneWeekAgo_exists?: InputMaybe<Scalars['Boolean']>;
  oneWeekAgo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  oneWeekAgo_not?: InputMaybe<Scalars['String']>;
  oneWeekAgo_not_contains?: InputMaybe<Scalars['String']>;
  oneWeekAgo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  today?: InputMaybe<Scalars['String']>;
  today_contains?: InputMaybe<Scalars['String']>;
  today_exists?: InputMaybe<Scalars['Boolean']>;
  today_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  today_not?: InputMaybe<Scalars['String']>;
  today_not_contains?: InputMaybe<Scalars['String']>;
  today_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReviewsTimeDictionaryLinkingCollections = {
  __typename?: 'ReviewsTimeDictionaryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ReviewsTimeDictionaryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ReviewsTimeDictionaryOrder {
  FewDaysAgoAsc = 'fewDaysAgo_ASC',
  FewDaysAgoDesc = 'fewDaysAgo_DESC',
  FewMonthsAgoAsc = 'fewMonthsAgo_ASC',
  FewMonthsAgoDesc = 'fewMonthsAgo_DESC',
  FewWeeksAgoAsc = 'fewWeeksAgo_ASC',
  FewWeeksAgoDesc = 'fewWeeksAgo_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OneDayAgoAsc = 'oneDayAgo_ASC',
  OneDayAgoDesc = 'oneDayAgo_DESC',
  OneMonthAgoAsc = 'oneMonthAgo_ASC',
  OneMonthAgoDesc = 'oneMonthAgo_DESC',
  OneWeekAgoAsc = 'oneWeekAgo_ASC',
  OneWeekAgoDesc = 'oneWeekAgo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TodayAsc = 'today_ASC',
  TodayDesc = 'today_DESC',
}

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfiguration = Entry &
  _Node & {
    __typename?: 'RobotsConfiguration';
    _id: Scalars['ID'];
    allow?: Maybe<Array<Maybe<Scalars['String']>>>;
    blogSitemapUrl?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    disallow?: Maybe<Array<Maybe<Scalars['String']>>>;
    linkedFrom?: Maybe<RobotsConfigurationLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sitemaps?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
    userAgent?: Maybe<Scalars['String']>;
  };

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfigurationAllowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfigurationBlogSitemapUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfigurationDisallowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfigurationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfigurationNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfigurationSitemapsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Configuration for robots.txt file. For more information https://moz.com/learn/seo/robotstxt [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/robotsConfiguration) */
export type RobotsConfigurationUserAgentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type RobotsConfigurationCollection = {
  __typename?: 'RobotsConfigurationCollection';
  items: Array<Maybe<RobotsConfiguration>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RobotsConfigurationFilter = {
  AND?: InputMaybe<Array<InputMaybe<RobotsConfigurationFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RobotsConfigurationFilter>>>;
  allow_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  allow_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  allow_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  allow_exists?: InputMaybe<Scalars['Boolean']>;
  blogSitemapUrl?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_contains?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_exists?: InputMaybe<Scalars['Boolean']>;
  blogSitemapUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  blogSitemapUrl_not?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_not_contains?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  disallow_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  disallow_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  disallow_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  disallow_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemaps_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemaps_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemaps_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemaps_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  userAgent?: InputMaybe<Scalars['String']>;
  userAgent_contains?: InputMaybe<Scalars['String']>;
  userAgent_exists?: InputMaybe<Scalars['Boolean']>;
  userAgent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userAgent_not?: InputMaybe<Scalars['String']>;
  userAgent_not_contains?: InputMaybe<Scalars['String']>;
  userAgent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RobotsConfigurationLinkingCollections = {
  __typename?: 'RobotsConfigurationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type RobotsConfigurationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum RobotsConfigurationOrder {
  BlogSitemapUrlAsc = 'blogSitemapUrl_ASC',
  BlogSitemapUrlDesc = 'blogSitemapUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UserAgentAsc = 'userAgent_ASC',
  UserAgentDesc = 'userAgent_DESC',
}

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type Search = Entry &
  _Node & {
    __typename?: 'Search';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    heading?: Maybe<Scalars['String']>;
    inputLabel?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<SearchLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    resultListLabel?: Maybe<Scalars['String']>;
    suggestionListCollection?: Maybe<SearchSuggestionListCollection>;
    suggestionListLabel?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type SearchHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type SearchInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type SearchLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type SearchNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type SearchResultListLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type SearchSuggestionListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SearchSuggestionListCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageFilter>;
};

/** [web-cms] Search module [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/search) */
export type SearchSuggestionListLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SearchCollection = {
  __typename?: 'SearchCollection';
  items: Array<Maybe<Search>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SearchFilter = {
  AND?: InputMaybe<Array<InputMaybe<SearchFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SearchFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inputLabel?: InputMaybe<Scalars['String']>;
  inputLabel_contains?: InputMaybe<Scalars['String']>;
  inputLabel_exists?: InputMaybe<Scalars['Boolean']>;
  inputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inputLabel_not?: InputMaybe<Scalars['String']>;
  inputLabel_not_contains?: InputMaybe<Scalars['String']>;
  inputLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resultListLabel?: InputMaybe<Scalars['String']>;
  resultListLabel_contains?: InputMaybe<Scalars['String']>;
  resultListLabel_exists?: InputMaybe<Scalars['Boolean']>;
  resultListLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resultListLabel_not?: InputMaybe<Scalars['String']>;
  resultListLabel_not_contains?: InputMaybe<Scalars['String']>;
  resultListLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suggestionList?: InputMaybe<CfPageNestedFilter>;
  suggestionListCollection_exists?: InputMaybe<Scalars['Boolean']>;
  suggestionListLabel?: InputMaybe<Scalars['String']>;
  suggestionListLabel_contains?: InputMaybe<Scalars['String']>;
  suggestionListLabel_exists?: InputMaybe<Scalars['Boolean']>;
  suggestionListLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suggestionListLabel_not?: InputMaybe<Scalars['String']>;
  suggestionListLabel_not_contains?: InputMaybe<Scalars['String']>;
  suggestionListLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type SearchLinkingCollections = {
  __typename?: 'SearchLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqHeroCollection?: Maybe<FaqHeroCollection>;
};

export type SearchLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<SearchLinkingCollectionsBetterWayTopCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SearchLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SearchLinkingCollectionsFaqHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<SearchLinkingCollectionsFaqHeroCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SearchLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum SearchLinkingCollectionsFaqHeroCollectionOrder {
  AllCountriesAsc = 'allCountries_ASC',
  AllCountriesDesc = 'allCountries_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrimaryBackgroundColorAsc = 'primaryBackgroundColor_ASC',
  PrimaryBackgroundColorDesc = 'primaryBackgroundColor_DESC',
  SecondaryBackgroundColorAsc = 'secondaryBackgroundColor_ASC',
  SecondaryBackgroundColorDesc = 'secondaryBackgroundColor_DESC',
  SendToTextAsc = 'sendToText_ASC',
  SendToTextDesc = 'sendToText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

export enum SearchOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InputLabelAsc = 'inputLabel_ASC',
  InputLabelDesc = 'inputLabel_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ResultListLabelAsc = 'resultListLabel_ASC',
  ResultListLabelDesc = 'resultListLabel_DESC',
  SuggestionListLabelAsc = 'suggestionListLabel_ASC',
  SuggestionListLabelDesc = 'suggestionListLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SearchSuggestionListCollection = {
  __typename?: 'SearchSuggestionListCollection';
  items: Array<Maybe<Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SearchSuggestionListCollectionOrder {
  AnalyticsPageNameAsc = 'analyticsPageName_ASC',
  AnalyticsPageNameDesc = 'analyticsPageName_DESC',
  AnalyticsPageTypeAsc = 'analyticsPageType_ASC',
  AnalyticsPageTypeDesc = 'analyticsPageType_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderPositionAsc = 'headerPosition_ASC',
  HeaderPositionDesc = 'headerPosition_DESC',
  HeaderThemeAsc = 'headerTheme_ASC',
  HeaderThemeDesc = 'headerTheme_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SitemapTitleAsc = 'sitemapTitle_ASC',
  SitemapTitleDesc = 'sitemapTitle_DESC',
  SitemapAsc = 'sitemap_ASC',
  SitemapDesc = 'sitemap_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedDateAsc = 'updatedDate_ASC',
  UpdatedDateDesc = 'updatedDate_DESC',
}

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sendToCountryModule) */
export type SendToCountryModule = Entry &
  _Node & {
    __typename?: 'SendToCountryModule';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    continueButtonText?: Maybe<Scalars['String']>;
    description?: Maybe<SendToCountryModuleDescription>;
    linkedFrom?: Maybe<SendToCountryModuleLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    searchPlaceholder?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sendToCountryModule) */
export type SendToCountryModuleContinueButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sendToCountryModule) */
export type SendToCountryModuleDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sendToCountryModule) */
export type SendToCountryModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sendToCountryModule) */
export type SendToCountryModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Module with destination country search field, supposed to be used instead of lite calculator on home page (if a/b test results will be good) [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sendToCountryModule) */
export type SendToCountryModuleSearchPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SendToCountryModuleCollection = {
  __typename?: 'SendToCountryModuleCollection';
  items: Array<Maybe<SendToCountryModule>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SendToCountryModuleDescription = {
  __typename?: 'SendToCountryModuleDescription';
  json: Scalars['JSON'];
  links: SendToCountryModuleDescriptionLinks;
};

export type SendToCountryModuleDescriptionAssets = {
  __typename?: 'SendToCountryModuleDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SendToCountryModuleDescriptionEntries = {
  __typename?: 'SendToCountryModuleDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SendToCountryModuleDescriptionLinks = {
  __typename?: 'SendToCountryModuleDescriptionLinks';
  assets: SendToCountryModuleDescriptionAssets;
  entries: SendToCountryModuleDescriptionEntries;
  resources: SendToCountryModuleDescriptionResources;
};

export type SendToCountryModuleDescriptionResources = {
  __typename?: 'SendToCountryModuleDescriptionResources';
  block: Array<SendToCountryModuleDescriptionResourcesBlock>;
  hyperlink: Array<SendToCountryModuleDescriptionResourcesHyperlink>;
  inline: Array<SendToCountryModuleDescriptionResourcesInline>;
};

export type SendToCountryModuleDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'SendToCountryModuleDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type SendToCountryModuleDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'SendToCountryModuleDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type SendToCountryModuleDescriptionResourcesInline = ResourceLink & {
  __typename?: 'SendToCountryModuleDescriptionResourcesInline';
  sys: ResourceSys;
};

export type SendToCountryModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<SendToCountryModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SendToCountryModuleFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  continueButtonText?: InputMaybe<Scalars['String']>;
  continueButtonText_contains?: InputMaybe<Scalars['String']>;
  continueButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  continueButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  continueButtonText_not?: InputMaybe<Scalars['String']>;
  continueButtonText_not_contains?: InputMaybe<Scalars['String']>;
  continueButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchPlaceholder?: InputMaybe<Scalars['String']>;
  searchPlaceholder_contains?: InputMaybe<Scalars['String']>;
  searchPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  searchPlaceholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchPlaceholder_not?: InputMaybe<Scalars['String']>;
  searchPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  searchPlaceholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type SendToCountryModuleLinkingCollections = {
  __typename?: 'SendToCountryModuleLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type SendToCountryModuleLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SendToCountryModuleLinkingCollectionsBetterWayTopCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SendToCountryModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SendToCountryModuleLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum SendToCountryModuleOrder {
  ContinueButtonTextAsc = 'continueButtonText_ASC',
  ContinueButtonTextDesc = 'continueButtonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SearchPlaceholderAsc = 'searchPlaceholder_ASC',
  SearchPlaceholderDesc = 'searchPlaceholder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoContactPoint) */
export type SeoContactPoint = Entry &
  _Node & {
    __typename?: 'SeoContactPoint';
    _id: Scalars['ID'];
    areaServed?: Maybe<Scalars['String']>;
    contactType?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SeoContactPointLinkingCollections>;
    sys: Sys;
    telephone?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoContactPoint) */
export type SeoContactPointAreaServedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoContactPoint) */
export type SeoContactPointContactTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoContactPoint) */
export type SeoContactPointLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoContactPoint) */
export type SeoContactPointTelephoneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SeoContactPointCollection = {
  __typename?: 'SeoContactPointCollection';
  items: Array<Maybe<SeoContactPoint>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeoContactPointFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoContactPointFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoContactPointFilter>>>;
  areaServed?: InputMaybe<Scalars['String']>;
  areaServed_contains?: InputMaybe<Scalars['String']>;
  areaServed_exists?: InputMaybe<Scalars['Boolean']>;
  areaServed_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  areaServed_not?: InputMaybe<Scalars['String']>;
  areaServed_not_contains?: InputMaybe<Scalars['String']>;
  areaServed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactType?: InputMaybe<Scalars['String']>;
  contactType_contains?: InputMaybe<Scalars['String']>;
  contactType_exists?: InputMaybe<Scalars['Boolean']>;
  contactType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactType_not?: InputMaybe<Scalars['String']>;
  contactType_not_contains?: InputMaybe<Scalars['String']>;
  contactType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  telephone?: InputMaybe<Scalars['String']>;
  telephone_contains?: InputMaybe<Scalars['String']>;
  telephone_exists?: InputMaybe<Scalars['Boolean']>;
  telephone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  telephone_not?: InputMaybe<Scalars['String']>;
  telephone_not_contains?: InputMaybe<Scalars['String']>;
  telephone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SeoContactPointLinkingCollections = {
  __typename?: 'SeoContactPointLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
};

export type SeoContactPointLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SeoContactPointLinkingCollectionsSeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoContactPointLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SeoContactPointLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder {
  AddressCountryAsc = 'addressCountry_ASC',
  AddressCountryDesc = 'addressCountry_DESC',
  AddressLocalityAsc = 'addressLocality_ASC',
  AddressLocalityDesc = 'addressLocality_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum SeoContactPointOrder {
  AreaServedAsc = 'areaServed_ASC',
  AreaServedDesc = 'areaServed_DESC',
  ContactTypeAsc = 'contactType_ASC',
  ContactTypeDesc = 'contactType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TelephoneAsc = 'telephone_ASC',
  TelephoneDesc = 'telephone_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredData = Entry &
  _Node & {
    __typename?: 'SeoOrganizationStructuredData';
    _id: Scalars['ID'];
    addressCountry?: Maybe<Scalars['String']>;
    addressLocality?: Maybe<Scalars['String']>;
    contactPointCollection?: Maybe<SeoOrganizationStructuredDataContactPointCollection>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<SeoOrganizationStructuredDataLinkingCollections>;
    logoAsset?: Maybe<Asset>;
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    sameAsCollection?: Maybe<SeoOrganizationStructuredDataSameAsCollection>;
    streetAddress?: Maybe<Scalars['String']>;
    sys: Sys;
    url?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataAddressCountryArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataAddressLocalityArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataContactPointCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoOrganizationStructuredDataContactPointCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoContactPointFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataLogoAssetArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataLogoUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataPostalCodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataSameAsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoOrganizationStructuredDataSameAsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoSameAsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataStreetAddressArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoOrganizationStructuredData) */
export type SeoOrganizationStructuredDataUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SeoOrganizationStructuredDataCollection = {
  __typename?: 'SeoOrganizationStructuredDataCollection';
  items: Array<Maybe<SeoOrganizationStructuredData>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeoOrganizationStructuredDataContactPointCollection = {
  __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
  items: Array<Maybe<SeoContactPoint>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SeoOrganizationStructuredDataContactPointCollectionOrder {
  AreaServedAsc = 'areaServed_ASC',
  AreaServedDesc = 'areaServed_DESC',
  ContactTypeAsc = 'contactType_ASC',
  ContactTypeDesc = 'contactType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TelephoneAsc = 'telephone_ASC',
  TelephoneDesc = 'telephone_DESC',
}

export type SeoOrganizationStructuredDataFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoOrganizationStructuredDataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoOrganizationStructuredDataFilter>>>;
  addressCountry?: InputMaybe<Scalars['String']>;
  addressCountry_contains?: InputMaybe<Scalars['String']>;
  addressCountry_exists?: InputMaybe<Scalars['Boolean']>;
  addressCountry_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  addressCountry_not?: InputMaybe<Scalars['String']>;
  addressCountry_not_contains?: InputMaybe<Scalars['String']>;
  addressCountry_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  addressLocality?: InputMaybe<Scalars['String']>;
  addressLocality_contains?: InputMaybe<Scalars['String']>;
  addressLocality_exists?: InputMaybe<Scalars['Boolean']>;
  addressLocality_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  addressLocality_not?: InputMaybe<Scalars['String']>;
  addressLocality_not_contains?: InputMaybe<Scalars['String']>;
  addressLocality_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactPoint?: InputMaybe<CfSeoContactPointNestedFilter>;
  contactPointCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_exists?: InputMaybe<Scalars['Boolean']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  logoAsset_exists?: InputMaybe<Scalars['Boolean']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  logoUrl_contains?: InputMaybe<Scalars['String']>;
  logoUrl_exists?: InputMaybe<Scalars['Boolean']>;
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logoUrl_not?: InputMaybe<Scalars['String']>;
  logoUrl_not_contains?: InputMaybe<Scalars['String']>;
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postalCode?: InputMaybe<Scalars['String']>;
  postalCode_contains?: InputMaybe<Scalars['String']>;
  postalCode_exists?: InputMaybe<Scalars['Boolean']>;
  postalCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postalCode_not?: InputMaybe<Scalars['String']>;
  postalCode_not_contains?: InputMaybe<Scalars['String']>;
  postalCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sameAs?: InputMaybe<CfSeoSameAsNestedFilter>;
  sameAsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  streetAddress_contains?: InputMaybe<Scalars['String']>;
  streetAddress_exists?: InputMaybe<Scalars['Boolean']>;
  streetAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  streetAddress_not?: InputMaybe<Scalars['String']>;
  streetAddress_not_contains?: InputMaybe<Scalars['String']>;
  streetAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SeoOrganizationStructuredDataLinkingCollections = {
  __typename?: 'SeoOrganizationStructuredDataLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SeoOrganizationStructuredDataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SeoOrganizationStructuredDataOrder {
  AddressCountryAsc = 'addressCountry_ASC',
  AddressCountryDesc = 'addressCountry_DESC',
  AddressLocalityAsc = 'addressLocality_ASC',
  AddressLocalityDesc = 'addressLocality_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type SeoOrganizationStructuredDataSameAsCollection = {
  __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
  items: Array<Maybe<SeoSameAs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SeoOrganizationStructuredDataSameAsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoSameAs) */
export type SeoSameAs = Entry &
  _Node & {
    __typename?: 'SeoSameAs';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SeoSameAsLinkingCollections>;
    sys: Sys;
    value?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoSameAs) */
export type SeoSameAsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/seoSameAs) */
export type SeoSameAsValueArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SeoSameAsCollection = {
  __typename?: 'SeoSameAsCollection';
  items: Array<Maybe<SeoSameAs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeoSameAsFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoSameAsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoSameAsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SeoSameAsLinkingCollections = {
  __typename?: 'SeoSameAsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  seoOrganizationStructuredDataCollection?: Maybe<SeoOrganizationStructuredDataCollection>;
};

export type SeoSameAsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SeoSameAsLinkingCollectionsSeoOrganizationStructuredDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoSameAsLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SeoSameAsLinkingCollectionsSeoOrganizationStructuredDataCollectionOrder {
  AddressCountryAsc = 'addressCountry_ASC',
  AddressCountryDesc = 'addressCountry_DESC',
  AddressLocalityAsc = 'addressLocality_ASC',
  AddressLocalityDesc = 'addressLocality_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum SeoSameAsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sitemap) */
export type Sitemap = Entry &
  _Node & {
    __typename?: 'Sitemap';
    _id: Scalars['ID'];
    blogSitemapUrl?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SitemapLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sitemapXml?: Maybe<Scalars['JSON']>;
    sys: Sys;
  };

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sitemap) */
export type SitemapBlogSitemapUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sitemap) */
export type SitemapLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sitemap) */
export type SitemapNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Sitemap settings used on HTML sitemap page and XML sitemap/sub-sitemaps files [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/sitemap) */
export type SitemapSitemapXmlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SitemapCollection = {
  __typename?: 'SitemapCollection';
  items: Array<Maybe<Sitemap>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SitemapFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitemapFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitemapFilter>>>;
  blogSitemapUrl?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_contains?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_exists?: InputMaybe<Scalars['Boolean']>;
  blogSitemapUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  blogSitemapUrl_not?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_not_contains?: InputMaybe<Scalars['String']>;
  blogSitemapUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemapXml_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type SitemapLinkingCollections = {
  __typename?: 'SitemapLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SitemapLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SitemapOrder {
  BlogSitemapUrlAsc = 'blogSitemapUrl_ASC',
  BlogSitemapUrlDesc = 'blogSitemapUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type Step = Entry &
  _Node & {
    __typename?: 'Step';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<StepDescription>;
    header?: Maybe<Scalars['String']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<StepLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * [web-cms] Card with header, description and icon
 * https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128&amp;t=JhbkCvQ1c8Nzjqea-0 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/step)
 */
export type StepSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStep = Entry &
  _Node & {
    __typename?: 'StepByStep';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<StepByStepLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    stepsCollection?: Maybe<StepByStepStepsCollection>;
    sys: Sys;
  };

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Wrapper for steps with header https://www.figma.com/file/x5HY2DAxhpJTdASSIq3nyp/Pages-%26-Content-modules-%7C-Web?node-id=1903%3A36128 [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/stepByStep) */
export type StepByStepStepsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<StepByStepStepsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StepFilter>;
};

export type StepByStepCollection = {
  __typename?: 'StepByStepCollection';
  items: Array<Maybe<StepByStep>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type StepByStepFilter = {
  AND?: InputMaybe<Array<InputMaybe<StepByStepFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StepByStepFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  steps?: InputMaybe<CfStepNestedFilter>;
  stepsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type StepByStepLinkingCollections = {
  __typename?: 'StepByStepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type StepByStepLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type StepByStepLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<StepByStepLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum StepByStepLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum StepByStepOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StepByStepStepsCollection = {
  __typename?: 'StepByStepStepsCollection';
  items: Array<Maybe<Step>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum StepByStepStepsCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StepCollection = {
  __typename?: 'StepCollection';
  items: Array<Maybe<Step>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type StepDescription = {
  __typename?: 'StepDescription';
  json: Scalars['JSON'];
  links: StepDescriptionLinks;
};

export type StepDescriptionAssets = {
  __typename?: 'StepDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type StepDescriptionEntries = {
  __typename?: 'StepDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type StepDescriptionLinks = {
  __typename?: 'StepDescriptionLinks';
  assets: StepDescriptionAssets;
  entries: StepDescriptionEntries;
  resources: StepDescriptionResources;
};

export type StepDescriptionResources = {
  __typename?: 'StepDescriptionResources';
  block: Array<StepDescriptionResourcesBlock>;
  hyperlink: Array<StepDescriptionResourcesHyperlink>;
  inline: Array<StepDescriptionResourcesInline>;
};

export type StepDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'StepDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type StepDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'StepDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type StepDescriptionResourcesInline = ResourceLink & {
  __typename?: 'StepDescriptionResourcesInline';
  sys: ResourceSys;
};

export type StepFilter = {
  AND?: InputMaybe<Array<InputMaybe<StepFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StepFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type StepLinkingCollections = {
  __typename?: 'StepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  stepByStepCollection?: Maybe<StepByStepCollection>;
};

export type StepLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type StepLinkingCollectionsStepByStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<StepLinkingCollectionsStepByStepCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum StepLinkingCollectionsStepByStepCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum StepOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Sys = {
  __typename?: 'Sys';
  environmentId: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  spaceId: Scalars['String'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_exists?: InputMaybe<Scalars['Boolean']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCard = Entry &
  _Node & {
    __typename?: 'TextCard';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    content?: Maybe<TextCardContent>;
    contentAlignment?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<Asset>;
    link?: Maybe<Link>;
    linkedFrom?: Maybe<TextCardLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    textColor?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardContentAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textCard) */
export type TextCardTextColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TextCardCollection = {
  __typename?: 'TextCardCollection';
  items: Array<Maybe<TextCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TextCardContent = {
  __typename?: 'TextCardContent';
  json: Scalars['JSON'];
  links: TextCardContentLinks;
};

export type TextCardContentAssets = {
  __typename?: 'TextCardContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TextCardContentEntries = {
  __typename?: 'TextCardContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TextCardContentLinks = {
  __typename?: 'TextCardContentLinks';
  assets: TextCardContentAssets;
  entries: TextCardContentEntries;
  resources: TextCardContentResources;
};

export type TextCardContentResources = {
  __typename?: 'TextCardContentResources';
  block: Array<TextCardContentResourcesBlock>;
  hyperlink: Array<TextCardContentResourcesHyperlink>;
  inline: Array<TextCardContentResourcesInline>;
};

export type TextCardContentResourcesBlock = ResourceLink & {
  __typename?: 'TextCardContentResourcesBlock';
  sys: ResourceSys;
};

export type TextCardContentResourcesHyperlink = ResourceLink & {
  __typename?: 'TextCardContentResourcesHyperlink';
  sys: ResourceSys;
};

export type TextCardContentResourcesInline = ResourceLink & {
  __typename?: 'TextCardContentResourcesInline';
  sys: ResourceSys;
};

export type TextCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextCardFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentAlignment?: InputMaybe<Scalars['String']>;
  contentAlignment_contains?: InputMaybe<Scalars['String']>;
  contentAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  contentAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentAlignment_not?: InputMaybe<Scalars['String']>;
  contentAlignment_not_contains?: InputMaybe<Scalars['String']>;
  contentAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<CfLinkNestedFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  textColor?: InputMaybe<Scalars['String']>;
  textColor_contains?: InputMaybe<Scalars['String']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textColor_not?: InputMaybe<Scalars['String']>;
  textColor_not_contains?: InputMaybe<Scalars['String']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TextCardLinkingCollections = {
  __typename?: 'TextCardLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  infoCardsCollection?: Maybe<InfoCardsCollection>;
};

export type TextCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type TextCardLinkingCollectionsInfoCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<TextCardLinkingCollectionsInfoCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TextCardLinkingCollectionsInfoCardsCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TextCardOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ContentAlignmentAsc = 'contentAlignment_ASC',
  ContentAlignmentDesc = 'contentAlignment_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColorAsc = 'textColor_ASC',
  TextColorDesc = 'textColor_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModule = Entry &
  _Node & {
    __typename?: 'TextMeTheAppModule';
    _id: Scalars['ID'];
    changeDialCodeButtonText?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    errorNoPhoneProvided?: Maybe<Scalars['String']>;
    errorPhoneNumberInvalid?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<TextMeTheAppModuleLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    phoneInputLabel?: Maybe<Scalars['String']>;
    phoneInputPlaceholder?: Maybe<Scalars['String']>;
    smsContent?: Maybe<Scalars['String']>;
    smsLink?: Maybe<Scalars['String']>;
    submitButtonText?: Maybe<Scalars['String']>;
    successPopupMessageContent?: Maybe<Scalars['String']>;
    successPopupMessageTitle?: Maybe<Scalars['String']>;
    sys: Sys;
    toastSuccessIcon?: Maybe<Asset>;
    unexpectedError?: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleChangeDialCodeButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleErrorNoPhoneProvidedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleErrorPhoneNumberInvalidArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModulePhoneInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModulePhoneInputPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSmsContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSmsLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSubmitButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSuccessPopupMessageContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleSuccessPopupMessageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleToastSuccessIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textMeTheAppModule) */
export type TextMeTheAppModuleUnexpectedErrorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TextMeTheAppModuleCollection = {
  __typename?: 'TextMeTheAppModuleCollection';
  items: Array<Maybe<TextMeTheAppModule>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TextMeTheAppModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextMeTheAppModuleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextMeTheAppModuleFilter>>>;
  changeDialCodeButtonText?: InputMaybe<Scalars['String']>;
  changeDialCodeButtonText_contains?: InputMaybe<Scalars['String']>;
  changeDialCodeButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  changeDialCodeButtonText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  changeDialCodeButtonText_not?: InputMaybe<Scalars['String']>;
  changeDialCodeButtonText_not_contains?: InputMaybe<Scalars['String']>;
  changeDialCodeButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  errorNoPhoneProvided?: InputMaybe<Scalars['String']>;
  errorNoPhoneProvided_contains?: InputMaybe<Scalars['String']>;
  errorNoPhoneProvided_exists?: InputMaybe<Scalars['Boolean']>;
  errorNoPhoneProvided_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  errorNoPhoneProvided_not?: InputMaybe<Scalars['String']>;
  errorNoPhoneProvided_not_contains?: InputMaybe<Scalars['String']>;
  errorNoPhoneProvided_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  errorPhoneNumberInvalid?: InputMaybe<Scalars['String']>;
  errorPhoneNumberInvalid_contains?: InputMaybe<Scalars['String']>;
  errorPhoneNumberInvalid_exists?: InputMaybe<Scalars['Boolean']>;
  errorPhoneNumberInvalid_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  errorPhoneNumberInvalid_not?: InputMaybe<Scalars['String']>;
  errorPhoneNumberInvalid_not_contains?: InputMaybe<Scalars['String']>;
  errorPhoneNumberInvalid_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneInputLabel?: InputMaybe<Scalars['String']>;
  phoneInputLabel_contains?: InputMaybe<Scalars['String']>;
  phoneInputLabel_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneInputLabel_not?: InputMaybe<Scalars['String']>;
  phoneInputLabel_not_contains?: InputMaybe<Scalars['String']>;
  phoneInputLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneInputPlaceholder?: InputMaybe<Scalars['String']>;
  phoneInputPlaceholder_contains?: InputMaybe<Scalars['String']>;
  phoneInputPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputPlaceholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneInputPlaceholder_not?: InputMaybe<Scalars['String']>;
  phoneInputPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  phoneInputPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  smsContent?: InputMaybe<Scalars['String']>;
  smsContent_contains?: InputMaybe<Scalars['String']>;
  smsContent_exists?: InputMaybe<Scalars['Boolean']>;
  smsContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  smsContent_not?: InputMaybe<Scalars['String']>;
  smsContent_not_contains?: InputMaybe<Scalars['String']>;
  smsContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  smsLink?: InputMaybe<Scalars['String']>;
  smsLink_contains?: InputMaybe<Scalars['String']>;
  smsLink_exists?: InputMaybe<Scalars['Boolean']>;
  smsLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  smsLink_not?: InputMaybe<Scalars['String']>;
  smsLink_not_contains?: InputMaybe<Scalars['String']>;
  smsLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submitButtonText?: InputMaybe<Scalars['String']>;
  submitButtonText_contains?: InputMaybe<Scalars['String']>;
  submitButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  submitButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submitButtonText_not?: InputMaybe<Scalars['String']>;
  submitButtonText_not_contains?: InputMaybe<Scalars['String']>;
  submitButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  successPopupMessageContent?: InputMaybe<Scalars['String']>;
  successPopupMessageContent_contains?: InputMaybe<Scalars['String']>;
  successPopupMessageContent_exists?: InputMaybe<Scalars['Boolean']>;
  successPopupMessageContent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  successPopupMessageContent_not?: InputMaybe<Scalars['String']>;
  successPopupMessageContent_not_contains?: InputMaybe<Scalars['String']>;
  successPopupMessageContent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  successPopupMessageTitle?: InputMaybe<Scalars['String']>;
  successPopupMessageTitle_contains?: InputMaybe<Scalars['String']>;
  successPopupMessageTitle_exists?: InputMaybe<Scalars['Boolean']>;
  successPopupMessageTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  successPopupMessageTitle_not?: InputMaybe<Scalars['String']>;
  successPopupMessageTitle_not_contains?: InputMaybe<Scalars['String']>;
  successPopupMessageTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sys?: InputMaybe<SysFilter>;
  toastSuccessIcon_exists?: InputMaybe<Scalars['Boolean']>;
  unexpectedError?: InputMaybe<Scalars['String']>;
  unexpectedError_contains?: InputMaybe<Scalars['String']>;
  unexpectedError_exists?: InputMaybe<Scalars['Boolean']>;
  unexpectedError_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unexpectedError_not?: InputMaybe<Scalars['String']>;
  unexpectedError_not_contains?: InputMaybe<Scalars['String']>;
  unexpectedError_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TextMeTheAppModuleLinkingCollections = {
  __typename?: 'TextMeTheAppModuleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TextMeTheAppModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TextMeTheAppModuleOrder {
  ChangeDialCodeButtonTextAsc = 'changeDialCodeButtonText_ASC',
  ChangeDialCodeButtonTextDesc = 'changeDialCodeButtonText_DESC',
  ErrorNoPhoneProvidedAsc = 'errorNoPhoneProvided_ASC',
  ErrorNoPhoneProvidedDesc = 'errorNoPhoneProvided_DESC',
  ErrorPhoneNumberInvalidAsc = 'errorPhoneNumberInvalid_ASC',
  ErrorPhoneNumberInvalidDesc = 'errorPhoneNumberInvalid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneInputLabelAsc = 'phoneInputLabel_ASC',
  PhoneInputLabelDesc = 'phoneInputLabel_DESC',
  PhoneInputPlaceholderAsc = 'phoneInputPlaceholder_ASC',
  PhoneInputPlaceholderDesc = 'phoneInputPlaceholder_DESC',
  SmsContentAsc = 'smsContent_ASC',
  SmsContentDesc = 'smsContent_DESC',
  SmsLinkAsc = 'smsLink_ASC',
  SmsLinkDesc = 'smsLink_DESC',
  SubmitButtonTextAsc = 'submitButtonText_ASC',
  SubmitButtonTextDesc = 'submitButtonText_DESC',
  SuccessPopupMessageContentAsc = 'successPopupMessageContent_ASC',
  SuccessPopupMessageContentDesc = 'successPopupMessageContent_DESC',
  SuccessPopupMessageTitleAsc = 'successPopupMessageTitle_ASC',
  SuccessPopupMessageTitleDesc = 'successPopupMessageTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnexpectedErrorAsc = 'unexpectedError_ASC',
  UnexpectedErrorDesc = 'unexpectedError_DESC',
}

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSection = Entry &
  _Node & {
    __typename?: 'TextSection';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    backgroundColor?: Maybe<Scalars['String']>;
    content?: Maybe<TextSectionContent>;
    contentfulMetadata: ContentfulMetadata;
    imageCollection?: Maybe<AssetCollection>;
    isOrderReversed?: Maybe<Scalars['Boolean']>;
    linkedFrom?: Maybe<TextSectionLinkingCollections>;
    maxWidth?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
  };

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionAllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionAllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionIsOrderReversedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionMaxWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSection) */
export type TextSectionSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TextSectionCollection = {
  __typename?: 'TextSectionCollection';
  items: Array<Maybe<TextSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TextSectionContent = {
  __typename?: 'TextSectionContent';
  json: Scalars['JSON'];
  links: TextSectionContentLinks;
};

export type TextSectionContentAssets = {
  __typename?: 'TextSectionContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TextSectionContentEntries = {
  __typename?: 'TextSectionContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TextSectionContentLinks = {
  __typename?: 'TextSectionContentLinks';
  assets: TextSectionContentAssets;
  entries: TextSectionContentEntries;
  resources: TextSectionContentResources;
};

export type TextSectionContentResources = {
  __typename?: 'TextSectionContentResources';
  block: Array<TextSectionContentResourcesBlock>;
  hyperlink: Array<TextSectionContentResourcesHyperlink>;
  inline: Array<TextSectionContentResourcesInline>;
};

export type TextSectionContentResourcesBlock = ResourceLink & {
  __typename?: 'TextSectionContentResourcesBlock';
  sys: ResourceSys;
};

export type TextSectionContentResourcesHyperlink = ResourceLink & {
  __typename?: 'TextSectionContentResourcesHyperlink';
  sys: ResourceSys;
};

export type TextSectionContentResourcesInline = ResourceLink & {
  __typename?: 'TextSectionContentResourcesInline';
  sys: ResourceSys;
};

export type TextSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextSectionFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  imageCollection_exists?: InputMaybe<Scalars['Boolean']>;
  isOrderReversed?: InputMaybe<Scalars['Boolean']>;
  isOrderReversed_exists?: InputMaybe<Scalars['Boolean']>;
  isOrderReversed_not?: InputMaybe<Scalars['Boolean']>;
  maxWidth?: InputMaybe<Scalars['String']>;
  maxWidth_contains?: InputMaybe<Scalars['String']>;
  maxWidth_exists?: InputMaybe<Scalars['Boolean']>;
  maxWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maxWidth_not?: InputMaybe<Scalars['String']>;
  maxWidth_not_contains?: InputMaybe<Scalars['String']>;
  maxWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type TextSectionLinkingCollections = {
  __typename?: 'TextSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TextSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TextSectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  IsOrderReversedAsc = 'isOrderReversed_ASC',
  IsOrderReversedDesc = 'isOrderReversed_DESC',
  MaxWidthAsc = 'maxWidth_ASC',
  MaxWidthDesc = 'maxWidth_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2 = Entry &
  _Node & {
    __typename?: 'TextSectionV2';
    _id: Scalars['ID'];
    allReceiveCountriesExcept?: Maybe<Scalars['Boolean']>;
    allSendCountriesExcept?: Maybe<Scalars['Boolean']>;
    backgroundColor?: Maybe<Scalars['String']>;
    columnAlignment?: Maybe<Scalars['String']>;
    content?: Maybe<TextSectionV2Content>;
    contentAlign?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    imageAlignment?: Maybe<Scalars['String']>;
    imageCollection?: Maybe<AssetCollection>;
    imageColumnSize?: Maybe<Scalars['Int']>;
    imageMobile?: Maybe<Asset>;
    imagePosition?: Maybe<Scalars['String']>;
    imagePositionMobile?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<TextSectionV2LinkingCollections>;
    maxWidth?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sys: Sys;
    textColumnSize?: Maybe<Scalars['Int']>;
  };

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2AllReceiveCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2AllSendCountriesExceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2BackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ColumnAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ContentAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ImageAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ImageColumnSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ImagePositionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ImagePositionMobileArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2LinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2MaxWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2NameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2ReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2SendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Content as rich text editor with optional image as 2 column layout [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textSectionV2) */
export type TextSectionV2TextColumnSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TextSectionV2Collection = {
  __typename?: 'TextSectionV2Collection';
  items: Array<Maybe<TextSectionV2>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TextSectionV2Content = {
  __typename?: 'TextSectionV2Content';
  json: Scalars['JSON'];
  links: TextSectionV2ContentLinks;
};

export type TextSectionV2ContentAssets = {
  __typename?: 'TextSectionV2ContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TextSectionV2ContentEntries = {
  __typename?: 'TextSectionV2ContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TextSectionV2ContentLinks = {
  __typename?: 'TextSectionV2ContentLinks';
  assets: TextSectionV2ContentAssets;
  entries: TextSectionV2ContentEntries;
  resources: TextSectionV2ContentResources;
};

export type TextSectionV2ContentResources = {
  __typename?: 'TextSectionV2ContentResources';
  block: Array<TextSectionV2ContentResourcesBlock>;
  hyperlink: Array<TextSectionV2ContentResourcesHyperlink>;
  inline: Array<TextSectionV2ContentResourcesInline>;
};

export type TextSectionV2ContentResourcesBlock = ResourceLink & {
  __typename?: 'TextSectionV2ContentResourcesBlock';
  sys: ResourceSys;
};

export type TextSectionV2ContentResourcesHyperlink = ResourceLink & {
  __typename?: 'TextSectionV2ContentResourcesHyperlink';
  sys: ResourceSys;
};

export type TextSectionV2ContentResourcesInline = ResourceLink & {
  __typename?: 'TextSectionV2ContentResourcesInline';
  sys: ResourceSys;
};

export type TextSectionV2Filter = {
  AND?: InputMaybe<Array<InputMaybe<TextSectionV2Filter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextSectionV2Filter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  columnAlignment?: InputMaybe<Scalars['String']>;
  columnAlignment_contains?: InputMaybe<Scalars['String']>;
  columnAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  columnAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  columnAlignment_not?: InputMaybe<Scalars['String']>;
  columnAlignment_not_contains?: InputMaybe<Scalars['String']>;
  columnAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentAlign?: InputMaybe<Scalars['String']>;
  contentAlign_contains?: InputMaybe<Scalars['String']>;
  contentAlign_exists?: InputMaybe<Scalars['Boolean']>;
  contentAlign_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentAlign_not?: InputMaybe<Scalars['String']>;
  contentAlign_not_contains?: InputMaybe<Scalars['String']>;
  contentAlign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  imageAlignment?: InputMaybe<Scalars['String']>;
  imageAlignment_contains?: InputMaybe<Scalars['String']>;
  imageAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  imageAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageAlignment_not?: InputMaybe<Scalars['String']>;
  imageAlignment_not_contains?: InputMaybe<Scalars['String']>;
  imageAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageCollection_exists?: InputMaybe<Scalars['Boolean']>;
  imageColumnSize?: InputMaybe<Scalars['Int']>;
  imageColumnSize_exists?: InputMaybe<Scalars['Boolean']>;
  imageColumnSize_gt?: InputMaybe<Scalars['Int']>;
  imageColumnSize_gte?: InputMaybe<Scalars['Int']>;
  imageColumnSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  imageColumnSize_lt?: InputMaybe<Scalars['Int']>;
  imageColumnSize_lte?: InputMaybe<Scalars['Int']>;
  imageColumnSize_not?: InputMaybe<Scalars['Int']>;
  imageColumnSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  imageMobile_exists?: InputMaybe<Scalars['Boolean']>;
  imagePosition?: InputMaybe<Scalars['String']>;
  imagePositionMobile?: InputMaybe<Scalars['String']>;
  imagePositionMobile_contains?: InputMaybe<Scalars['String']>;
  imagePositionMobile_exists?: InputMaybe<Scalars['Boolean']>;
  imagePositionMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagePositionMobile_not?: InputMaybe<Scalars['String']>;
  imagePositionMobile_not_contains?: InputMaybe<Scalars['String']>;
  imagePositionMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagePosition_contains?: InputMaybe<Scalars['String']>;
  imagePosition_exists?: InputMaybe<Scalars['Boolean']>;
  imagePosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagePosition_not?: InputMaybe<Scalars['String']>;
  imagePosition_not_contains?: InputMaybe<Scalars['String']>;
  imagePosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maxWidth?: InputMaybe<Scalars['String']>;
  maxWidth_contains?: InputMaybe<Scalars['String']>;
  maxWidth_exists?: InputMaybe<Scalars['Boolean']>;
  maxWidth_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  maxWidth_not?: InputMaybe<Scalars['String']>;
  maxWidth_not_contains?: InputMaybe<Scalars['String']>;
  maxWidth_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  textColumnSize?: InputMaybe<Scalars['Int']>;
  textColumnSize_exists?: InputMaybe<Scalars['Boolean']>;
  textColumnSize_gt?: InputMaybe<Scalars['Int']>;
  textColumnSize_gte?: InputMaybe<Scalars['Int']>;
  textColumnSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  textColumnSize_lt?: InputMaybe<Scalars['Int']>;
  textColumnSize_lte?: InputMaybe<Scalars['Int']>;
  textColumnSize_not?: InputMaybe<Scalars['Int']>;
  textColumnSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type TextSectionV2LinkingCollections = {
  __typename?: 'TextSectionV2LinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TextSectionV2LinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TextSectionV2Order {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ColumnAlignmentAsc = 'columnAlignment_ASC',
  ColumnAlignmentDesc = 'columnAlignment_DESC',
  ContentAlignAsc = 'contentAlign_ASC',
  ContentAlignDesc = 'contentAlign_DESC',
  ImageAlignmentAsc = 'imageAlignment_ASC',
  ImageAlignmentDesc = 'imageAlignment_DESC',
  ImageColumnSizeAsc = 'imageColumnSize_ASC',
  ImageColumnSizeDesc = 'imageColumnSize_DESC',
  ImagePositionMobileAsc = 'imagePositionMobile_ASC',
  ImagePositionMobileDesc = 'imagePositionMobile_DESC',
  ImagePositionAsc = 'imagePosition_ASC',
  ImagePositionDesc = 'imagePosition_DESC',
  MaxWidthAsc = 'maxWidth_ASC',
  MaxWidthDesc = 'maxWidth_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextColumnSizeAsc = 'textColumnSize_ASC',
  TextColumnSizeDesc = 'textColumnSize_DESC',
}

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColor = Entry &
  _Node & {
    __typename?: 'TextWithColor';
    _id: Scalars['ID'];
    align?: Maybe<Scalars['String']>;
    backgroundColor?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<Asset>;
    inline?: Maybe<Scalars['Boolean']>;
    linkedFrom?: Maybe<TextWithColorLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    text?: Maybe<TextWithColorText>;
  };

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorAlignArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorInlineArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] Rich text editor with options to specify text alignment, display, color, background color, and optional icon. [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/textWithColor) */
export type TextWithColorTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TextWithColorCollection = {
  __typename?: 'TextWithColorCollection';
  items: Array<Maybe<TextWithColor>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TextWithColorFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextWithColorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextWithColorFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  inline?: InputMaybe<Scalars['Boolean']>;
  inline_exists?: InputMaybe<Scalars['Boolean']>;
  inline_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
};

export type TextWithColorLinkingCollections = {
  __typename?: 'TextWithColorLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  iconWithTextCollection?: Maybe<IconWithTextCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type TextWithColorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type TextWithColorLinkingCollectionsIconWithTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<TextWithColorLinkingCollectionsIconWithTextCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type TextWithColorLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<TextWithColorLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TextWithColorLinkingCollectionsIconWithTextCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TextWithColorLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TextWithColorOrder {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  InlineAsc = 'inline_ASC',
  InlineDesc = 'inline_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TextWithColorText = {
  __typename?: 'TextWithColorText';
  json: Scalars['JSON'];
  links: TextWithColorTextLinks;
};

export type TextWithColorTextAssets = {
  __typename?: 'TextWithColorTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TextWithColorTextEntries = {
  __typename?: 'TextWithColorTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TextWithColorTextLinks = {
  __typename?: 'TextWithColorTextLinks';
  assets: TextWithColorTextAssets;
  entries: TextWithColorTextEntries;
  resources: TextWithColorTextResources;
};

export type TextWithColorTextResources = {
  __typename?: 'TextWithColorTextResources';
  block: Array<TextWithColorTextResourcesBlock>;
  hyperlink: Array<TextWithColorTextResourcesHyperlink>;
  inline: Array<TextWithColorTextResourcesInline>;
};

export type TextWithColorTextResourcesBlock = ResourceLink & {
  __typename?: 'TextWithColorTextResourcesBlock';
  sys: ResourceSys;
};

export type TextWithColorTextResourcesHyperlink = ResourceLink & {
  __typename?: 'TextWithColorTextResourcesHyperlink';
  sys: ResourceSys;
};

export type TextWithColorTextResourcesInline = ResourceLink & {
  __typename?: 'TextWithColorTextResourcesInline';
  sys: ResourceSys;
};

/** This is a placeholder model to display the Trustpilot widget found here https://widgets-trustboxinstructions.b2b.trustpilot.com/#/trustbox/eyJjb2RlSGVhZCI6IjwhLS0gVHJ1c3RCb3ggc2NyaXB0IC0tPlxuPHNjcmlwdCB0eXBlPVwidGV4dC9qYXZhc2NyaXB0XCIgc3JjPVwiLy93aWRnZXQudHJ1c3RwaWxvdC5jb20vYm9vdHN0cmFwL3Y1L3RwLndpZGdldC5ib290c3RyYXAubWluLmpzXCIgYXN5bmM%252BPC9zY3JpcHQ%252BXG48IS0tIEVuZCBUcnVzdEJveCBzY3JpcHQgLS0%252BIiwiY29kZUJvZHkiOiI8IS0tIFRydXN0Qm94IHdpZGdldCAtIE1pY3JvIFN0YXIgLS0%252BXG48ZGl2IGNsYXNzPVwidHJ1c [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/trustpilotWidget) */
export type TrustpilotWidget = Entry &
  _Node & {
    __typename?: 'TrustpilotWidget';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<TrustpilotWidgetLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
    url?: Maybe<Scalars['String']>;
  };

/** This is a placeholder model to display the Trustpilot widget found here https://widgets-trustboxinstructions.b2b.trustpilot.com/#/trustbox/eyJjb2RlSGVhZCI6IjwhLS0gVHJ1c3RCb3ggc2NyaXB0IC0tPlxuPHNjcmlwdCB0eXBlPVwidGV4dC9qYXZhc2NyaXB0XCIgc3JjPVwiLy93aWRnZXQudHJ1c3RwaWxvdC5jb20vYm9vdHN0cmFwL3Y1L3RwLndpZGdldC5ib290c3RyYXAubWluLmpzXCIgYXN5bmM%252BPC9zY3JpcHQ%252BXG48IS0tIEVuZCBUcnVzdEJveCBzY3JpcHQgLS0%252BIiwiY29kZUJvZHkiOiI8IS0tIFRydXN0Qm94IHdpZGdldCAtIE1pY3JvIFN0YXIgLS0%252BXG48ZGl2IGNsYXNzPVwidHJ1c [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/trustpilotWidget) */
export type TrustpilotWidgetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is a placeholder model to display the Trustpilot widget found here https://widgets-trustboxinstructions.b2b.trustpilot.com/#/trustbox/eyJjb2RlSGVhZCI6IjwhLS0gVHJ1c3RCb3ggc2NyaXB0IC0tPlxuPHNjcmlwdCB0eXBlPVwidGV4dC9qYXZhc2NyaXB0XCIgc3JjPVwiLy93aWRnZXQudHJ1c3RwaWxvdC5jb20vYm9vdHN0cmFwL3Y1L3RwLndpZGdldC5ib290c3RyYXAubWluLmpzXCIgYXN5bmM%252BPC9zY3JpcHQ%252BXG48IS0tIEVuZCBUcnVzdEJveCBzY3JpcHQgLS0%252BIiwiY29kZUJvZHkiOiI8IS0tIFRydXN0Qm94IHdpZGdldCAtIE1pY3JvIFN0YXIgLS0%252BXG48ZGl2IGNsYXNzPVwidHJ1c [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/trustpilotWidget) */
export type TrustpilotWidgetNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** This is a placeholder model to display the Trustpilot widget found here https://widgets-trustboxinstructions.b2b.trustpilot.com/#/trustbox/eyJjb2RlSGVhZCI6IjwhLS0gVHJ1c3RCb3ggc2NyaXB0IC0tPlxuPHNjcmlwdCB0eXBlPVwidGV4dC9qYXZhc2NyaXB0XCIgc3JjPVwiLy93aWRnZXQudHJ1c3RwaWxvdC5jb20vYm9vdHN0cmFwL3Y1L3RwLndpZGdldC5ib290c3RyYXAubWluLmpzXCIgYXN5bmM%252BPC9zY3JpcHQ%252BXG48IS0tIEVuZCBUcnVzdEJveCBzY3JpcHQgLS0%252BIiwiY29kZUJvZHkiOiI8IS0tIFRydXN0Qm94IHdpZGdldCAtIE1pY3JvIFN0YXIgLS0%252BXG48ZGl2IGNsYXNzPVwidHJ1c [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/trustpilotWidget) */
export type TrustpilotWidgetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TrustpilotWidgetCollection = {
  __typename?: 'TrustpilotWidgetCollection';
  items: Array<Maybe<TrustpilotWidget>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TrustpilotWidgetFilter = {
  AND?: InputMaybe<Array<InputMaybe<TrustpilotWidgetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TrustpilotWidgetFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TrustpilotWidgetLinkingCollections = {
  __typename?: 'TrustpilotWidgetLinkingCollections';
  betterWayTopCollection?: Maybe<BetterWayTopCollection>;
  entryCollection?: Maybe<EntryCollection>;
  reviewsSectionCollection?: Maybe<ReviewsSectionCollection>;
};

export type TrustpilotWidgetLinkingCollectionsBetterWayTopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<TrustpilotWidgetLinkingCollectionsBetterWayTopCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type TrustpilotWidgetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type TrustpilotWidgetLinkingCollectionsReviewsSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<
      InputMaybe<TrustpilotWidgetLinkingCollectionsReviewsSectionCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TrustpilotWidgetLinkingCollectionsBetterWayTopCollectionOrder {
  AllReceiveCountriesExceptAsc = 'allReceiveCountriesExcept_ASC',
  AllReceiveCountriesExceptDesc = 'allReceiveCountriesExcept_DESC',
  AllSendCountriesExceptAsc = 'allSendCountriesExcept_ASC',
  AllSendCountriesExceptDesc = 'allSendCountriesExcept_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeadingColorAsc = 'headingColor_ASC',
  HeadingColorDesc = 'headingColor_DESC',
  HideDescriptionOnMobileAsc = 'hideDescriptionOnMobile_ASC',
  HideDescriptionOnMobileDesc = 'hideDescriptionOnMobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TrustpilotMessageAsc = 'trustpilotMessage_ASC',
  TrustpilotMessageDesc = 'trustpilotMessage_DESC',
}

export enum TrustpilotWidgetLinkingCollectionsReviewsSectionCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TrustpilotWidgetOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type Video = Entry &
  _Node & {
    __typename?: 'Video';
    _id: Scalars['ID'];
    backgroundImage?: Maybe<Asset>;
    content?: Maybe<VideoContent>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<VideoLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    playIcon?: Maybe<Asset>;
    subheader?: Maybe<Scalars['String']>;
    sys: Sys;
    videoId?: Maybe<Scalars['String']>;
  };

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoPlayIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoSubheaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [web-cms] You Tube video id with background image and content [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/video) */
export type VideoVideoIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VideoContent = {
  __typename?: 'VideoContent';
  json: Scalars['JSON'];
  links: VideoContentLinks;
};

export type VideoContentAssets = {
  __typename?: 'VideoContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type VideoContentEntries = {
  __typename?: 'VideoContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type VideoContentLinks = {
  __typename?: 'VideoContentLinks';
  assets: VideoContentAssets;
  entries: VideoContentEntries;
  resources: VideoContentResources;
};

export type VideoContentResources = {
  __typename?: 'VideoContentResources';
  block: Array<VideoContentResourcesBlock>;
  hyperlink: Array<VideoContentResourcesHyperlink>;
  inline: Array<VideoContentResourcesInline>;
};

export type VideoContentResourcesBlock = ResourceLink & {
  __typename?: 'VideoContentResourcesBlock';
  sys: ResourceSys;
};

export type VideoContentResourcesHyperlink = ResourceLink & {
  __typename?: 'VideoContentResourcesHyperlink';
  sys: ResourceSys;
};

export type VideoContentResourcesInline = ResourceLink & {
  __typename?: 'VideoContentResourcesInline';
  sys: ResourceSys;
};

export type VideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  playIcon_exists?: InputMaybe<Scalars['Boolean']>;
  subheader?: InputMaybe<Scalars['String']>;
  subheader_contains?: InputMaybe<Scalars['String']>;
  subheader_exists?: InputMaybe<Scalars['Boolean']>;
  subheader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subheader_not?: InputMaybe<Scalars['String']>;
  subheader_not_contains?: InputMaybe<Scalars['String']>;
  subheader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  videoId?: InputMaybe<Scalars['String']>;
  videoId_contains?: InputMaybe<Scalars['String']>;
  videoId_exists?: InputMaybe<Scalars['Boolean']>;
  videoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoId_not?: InputMaybe<Scalars['String']>;
  videoId_not_contains?: InputMaybe<Scalars['String']>;
  videoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VideoLinkingCollections = {
  __typename?: 'VideoLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type VideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<VideoLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum VideoLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum VideoOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubheaderAsc = 'subheader_ASC',
  SubheaderDesc = 'subheader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebForm = Entry &
  _Node & {
    __typename?: 'WebForm';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<WebFormDescription>;
    disclaimer?: Maybe<Scalars['String']>;
    header?: Maybe<Scalars['String']>;
    jsonSchema?: Maybe<Scalars['JSON']>;
    linkedFrom?: Maybe<WebFormLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    receiveCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    sendCountryList?: Maybe<Array<Maybe<Scalars['String']>>>;
    submitButtonLabel?: Maybe<Scalars['String']>;
    successPage?: Maybe<Page>;
    sys: Sys;
    uiSchema?: Maybe<Scalars['JSON']>;
  };

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormJsonSchemaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormReceiveCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormSendCountryListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormSubmitButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormSuccessPageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PageFilter>;
};

/**
 * HTML form out of a JSON schema
 * https://react-jsonschema-form.readthedocs.io/
 * https://rjsf-team.github.io/react-jsonschema-form/ [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/webForm)
 */
export type WebFormUiSchemaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type WebFormCollection = {
  __typename?: 'WebFormCollection';
  items: Array<Maybe<WebForm>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type WebFormDescription = {
  __typename?: 'WebFormDescription';
  json: Scalars['JSON'];
  links: WebFormDescriptionLinks;
};

export type WebFormDescriptionAssets = {
  __typename?: 'WebFormDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type WebFormDescriptionEntries = {
  __typename?: 'WebFormDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type WebFormDescriptionLinks = {
  __typename?: 'WebFormDescriptionLinks';
  assets: WebFormDescriptionAssets;
  entries: WebFormDescriptionEntries;
  resources: WebFormDescriptionResources;
};

export type WebFormDescriptionResources = {
  __typename?: 'WebFormDescriptionResources';
  block: Array<WebFormDescriptionResourcesBlock>;
  hyperlink: Array<WebFormDescriptionResourcesHyperlink>;
  inline: Array<WebFormDescriptionResourcesInline>;
};

export type WebFormDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'WebFormDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type WebFormDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'WebFormDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type WebFormDescriptionResourcesInline = ResourceLink & {
  __typename?: 'WebFormDescriptionResourcesInline';
  sys: ResourceSys;
};

export type WebFormFilter = {
  AND?: InputMaybe<Array<InputMaybe<WebFormFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<WebFormFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  disclaimer?: InputMaybe<Scalars['String']>;
  disclaimer_contains?: InputMaybe<Scalars['String']>;
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>;
  disclaimer_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  disclaimer_not?: InputMaybe<Scalars['String']>;
  disclaimer_not_contains?: InputMaybe<Scalars['String']>;
  disclaimer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jsonSchema_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  submitButtonLabel?: InputMaybe<Scalars['String']>;
  submitButtonLabel_contains?: InputMaybe<Scalars['String']>;
  submitButtonLabel_exists?: InputMaybe<Scalars['Boolean']>;
  submitButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submitButtonLabel_not?: InputMaybe<Scalars['String']>;
  submitButtonLabel_not_contains?: InputMaybe<Scalars['String']>;
  submitButtonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  successPage?: InputMaybe<CfPageNestedFilter>;
  successPage_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  uiSchema_exists?: InputMaybe<Scalars['Boolean']>;
};

export type WebFormLinkingCollections = {
  __typename?: 'WebFormLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type WebFormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum WebFormOrder {
  DisclaimerAsc = 'disclaimer_ASC',
  DisclaimerDesc = 'disclaimer_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubmitButtonLabelAsc = 'submitButtonLabel_ASC',
  SubmitButtonLabelDesc = 'submitButtonLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChoose = Entry &
  _Node & {
    __typename?: 'WhyChoose';
    _id: Scalars['ID'];
    backgroundColor?: Maybe<Scalars['String']>;
    backgroundImage?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<WhyChooseLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    reasonsCollection?: Maybe<WhyChooseReasonsCollection>;
    secondBackgroundColor?: Maybe<Scalars['String']>;
    showHeader?: Maybe<Scalars['Boolean']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseReasonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<WhyChooseReasonsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WhyChooseReasonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseSecondBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChoose) */
export type WhyChooseShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type WhyChooseCollection = {
  __typename?: 'WhyChooseCollection';
  items: Array<Maybe<WhyChoose>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type WhyChooseFilter = {
  AND?: InputMaybe<Array<InputMaybe<WhyChooseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<WhyChooseFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reasons?: InputMaybe<CfWhyChooseReasonNestedFilter>;
  reasonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  secondBackgroundColor?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  secondBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondBackgroundColor_not?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  secondBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showHeader?: InputMaybe<Scalars['Boolean']>;
  showHeader_exists?: InputMaybe<Scalars['Boolean']>;
  showHeader_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type WhyChooseLinkingCollections = {
  __typename?: 'WhyChooseLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pageSectionCollection?: Maybe<PageSectionCollection>;
};

export type WhyChooseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type WhyChooseLinkingCollectionsPageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<WhyChooseLinkingCollectionsPageSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum WhyChooseLinkingCollectionsPageSectionCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BackgroundGradientAsc = 'backgroundGradient_ASC',
  BackgroundGradientDesc = 'backgroundGradient_DESC',
  BackgroundTypeAsc = 'backgroundType_ASC',
  BackgroundTypeDesc = 'backgroundType_DESC',
  EnablePaddingAsc = 'enablePadding_ASC',
  EnablePaddingDesc = 'enablePadding_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhyChooseOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChooseReason) */
export type WhyChooseReason = Entry &
  _Node & {
    __typename?: 'WhyChooseReason';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    header?: Maybe<Scalars['String']>;
    image?: Maybe<Asset>;
    imagePosition?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<WhyChooseReasonLinkingCollections>;
    name?: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChooseReason) */
export type WhyChooseReasonDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChooseReason) */
export type WhyChooseReasonHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChooseReason) */
export type WhyChooseReasonImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChooseReason) */
export type WhyChooseReasonImagePositionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChooseReason) */
export type WhyChooseReasonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pqe6664kagrv/content_types/whyChooseReason) */
export type WhyChooseReasonNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type WhyChooseReasonCollection = {
  __typename?: 'WhyChooseReasonCollection';
  items: Array<Maybe<WhyChooseReason>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type WhyChooseReasonFilter = {
  AND?: InputMaybe<Array<InputMaybe<WhyChooseReasonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<WhyChooseReasonFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagePosition?: InputMaybe<Scalars['String']>;
  imagePosition_contains?: InputMaybe<Scalars['String']>;
  imagePosition_exists?: InputMaybe<Scalars['Boolean']>;
  imagePosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagePosition_not?: InputMaybe<Scalars['String']>;
  imagePosition_not_contains?: InputMaybe<Scalars['String']>;
  imagePosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type WhyChooseReasonLinkingCollections = {
  __typename?: 'WhyChooseReasonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  whyChooseCollection?: Maybe<WhyChooseCollection>;
};

export type WhyChooseReasonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type WhyChooseReasonLinkingCollectionsWhyChooseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<
    Array<InputMaybe<WhyChooseReasonLinkingCollectionsWhyChooseCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum WhyChooseReasonLinkingCollectionsWhyChooseCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecondBackgroundColorAsc = 'secondBackgroundColor_ASC',
  SecondBackgroundColorDesc = 'secondBackgroundColor_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhyChooseReasonOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ImagePositionAsc = 'imagePosition_ASC',
  ImagePositionDesc = 'imagePosition_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type WhyChooseReasonsCollection = {
  __typename?: 'WhyChooseReasonsCollection';
  items: Array<Maybe<WhyChooseReason>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum WhyChooseReasonsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ImagePositionAsc = 'imagePosition_ASC',
  ImagePositionDesc = 'imagePosition_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type _Node = {
  _id: Scalars['ID'];
};

export type CfAccordionItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAccordionItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAccordionItemNestedFilter>>>;
  contentBackgroundColor?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_contains?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  contentBackgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentBackgroundColor_not?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  contentBackgroundColor_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCalculatorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCalculatorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCalculatorNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesSearchPlaceholder?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_contains?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  countriesSearchPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  countriesSearchPlaceholder_not?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  countriesSearchPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  estimatedFeeLabel?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_contains?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  estimatedFeeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  estimatedFeeLabel_not?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_not_contains?: InputMaybe<Scalars['String']>;
  estimatedFeeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']>;
  exchangeRateLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRatePromoLabel?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_contains?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_exists?: InputMaybe<Scalars['Boolean']>;
  exchangeRatePromoLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRatePromoLabel_not?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_not_contains?: InputMaybe<Scalars['String']>;
  exchangeRatePromoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  feeLabel?: InputMaybe<Scalars['String']>;
  feeLabel_contains?: InputMaybe<Scalars['String']>;
  feeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  feeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  feeLabel_not?: InputMaybe<Scalars['String']>;
  feeLabel_not_contains?: InputMaybe<Scalars['String']>;
  feeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generalErrorMessage?: InputMaybe<Scalars['String']>;
  generalErrorMessage_contains?: InputMaybe<Scalars['String']>;
  generalErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  generalErrorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generalErrorMessage_not?: InputMaybe<Scalars['String']>;
  generalErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  generalErrorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  incorrectCountrySelectedErrorMessage?: InputMaybe<Scalars['String']>;
  incorrectCountrySelectedErrorMessage_contains?: InputMaybe<Scalars['String']>;
  incorrectCountrySelectedErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  incorrectCountrySelectedErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  incorrectCountrySelectedErrorMessage_not?: InputMaybe<Scalars['String']>;
  incorrectCountrySelectedErrorMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  incorrectCountrySelectedErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  instantDiscountLabel?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_contains?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_exists?: InputMaybe<Scalars['Boolean']>;
  instantDiscountLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  instantDiscountLabel_not?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_not_contains?: InputMaybe<Scalars['String']>;
  instantDiscountLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  isLite?: InputMaybe<Scalars['Boolean']>;
  isLite_exists?: InputMaybe<Scalars['Boolean']>;
  isLite_not?: InputMaybe<Scalars['Boolean']>;
  loginLink_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partnerSelectErrorMessage?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_contains?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  partnerSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  partnerSelectErrorMessage_not?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  partnerSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  partnersLabel?: InputMaybe<Scalars['String']>;
  partnersLabel_contains?: InputMaybe<Scalars['String']>;
  partnersLabel_exists?: InputMaybe<Scalars['Boolean']>;
  partnersLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partnersLabel_not?: InputMaybe<Scalars['String']>;
  partnersLabel_not_contains?: InputMaybe<Scalars['String']>;
  partnersLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partnersListDefaultValue?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_contains?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_exists?: InputMaybe<Scalars['Boolean']>;
  partnersListDefaultValue_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  partnersListDefaultValue_not?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_not_contains?: InputMaybe<Scalars['String']>;
  partnersListDefaultValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  payoutMethodsLabel?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_contains?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_exists?: InputMaybe<Scalars['Boolean']>;
  payoutMethodsLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodsLabel_not?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_not_contains?: InputMaybe<Scalars['String']>;
  payoutMethodsLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodsListCollection_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputInfoMoreInfoLabel?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputInfoMoreInfoLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneInputInfoMoreInfoLabel_not?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_not_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoMoreInfoLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneInputInfoPopupText?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_exists?: InputMaybe<Scalars['Boolean']>;
  phoneInputInfoPopupText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneInputInfoPopupText_not?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_not_contains?: InputMaybe<Scalars['String']>;
  phoneInputInfoPopupText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInputErrorMessage?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  phoneNumberInputErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInputErrorMessage_not?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInputErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInvalidErrorMessage?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  phoneNumberInvalidErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneNumberInvalidErrorMessage_not?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  phoneNumberInvalidErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneValidatorLabel?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_exists?: InputMaybe<Scalars['Boolean']>;
  phoneValidatorLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneValidatorLabel_not?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_not_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneValidatorPlaceholder?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  phoneValidatorPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  phoneValidatorPlaceholder_not?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  phoneValidatorPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  promotionalTermsLink_exists?: InputMaybe<Scalars['Boolean']>;
  receiveLabel?: InputMaybe<Scalars['String']>;
  receiveLabel_contains?: InputMaybe<Scalars['String']>;
  receiveLabel_exists?: InputMaybe<Scalars['Boolean']>;
  receiveLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveLabel_not?: InputMaybe<Scalars['String']>;
  receiveLabel_not_contains?: InputMaybe<Scalars['String']>;
  receiveLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiverPayoutMethodUnavailableMessage?: InputMaybe<Scalars['String']>;
  receiverPayoutMethodUnavailableMessage_contains?: InputMaybe<
    Scalars['String']
  >;
  receiverPayoutMethodUnavailableMessage_exists?: InputMaybe<
    Scalars['Boolean']
  >;
  receiverPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiverPayoutMethodUnavailableMessage_not?: InputMaybe<Scalars['String']>;
  receiverPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  receiverPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  recipientGetsLabel?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_contains?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_exists?: InputMaybe<Scalars['Boolean']>;
  recipientGetsLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  recipientGetsLabel_not?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_not_contains?: InputMaybe<Scalars['String']>;
  recipientGetsLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendLabel?: InputMaybe<Scalars['String']>;
  sendLabel_contains?: InputMaybe<Scalars['String']>;
  sendLabel_exists?: InputMaybe<Scalars['Boolean']>;
  sendLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sendLabel_not?: InputMaybe<Scalars['String']>;
  sendLabel_not_contains?: InputMaybe<Scalars['String']>;
  sendLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  senderPayoutMethodUnavailableMessage?: InputMaybe<Scalars['String']>;
  senderPayoutMethodUnavailableMessage_contains?: InputMaybe<Scalars['String']>;
  senderPayoutMethodUnavailableMessage_exists?: InputMaybe<Scalars['Boolean']>;
  senderPayoutMethodUnavailableMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  senderPayoutMethodUnavailableMessage_not?: InputMaybe<Scalars['String']>;
  senderPayoutMethodUnavailableMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  senderPayoutMethodUnavailableMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  signUpLink_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  topUpAmountsLabel?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsLabel_not?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsPlaceholder?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsPlaceholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsPlaceholder_not?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectErrorMessage?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsSelectErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectErrorMessage_not?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectRetrievalErrorMessage_contains?: InputMaybe<
    Scalars['String']
  >;
  topUpAmountsSelectRetrievalErrorMessage_exists?: InputMaybe<
    Scalars['Boolean']
  >;
  topUpAmountsSelectRetrievalErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsSelectRetrievalErrorMessage_not?: InputMaybe<Scalars['String']>;
  topUpAmountsSelectRetrievalErrorMessage_not_contains?: InputMaybe<
    Scalars['String']
  >;
  topUpAmountsSelectRetrievalErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  topUpAmountsTitle?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  topUpAmountsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topUpAmountsTitle_not?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_not_contains?: InputMaybe<Scalars['String']>;
  topUpAmountsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  totalToPayLabel?: InputMaybe<Scalars['String']>;
  totalToPayLabel_contains?: InputMaybe<Scalars['String']>;
  totalToPayLabel_exists?: InputMaybe<Scalars['Boolean']>;
  totalToPayLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  totalToPayLabel_not?: InputMaybe<Scalars['String']>;
  totalToPayLabel_not_contains?: InputMaybe<Scalars['String']>;
  totalToPayLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeLabel?: InputMaybe<Scalars['String']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  transferTimeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeLabel_not?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unavailableCorridorErrorMessage?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_contains?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_exists?: InputMaybe<Scalars['Boolean']>;
  unavailableCorridorErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  unavailableCorridorErrorMessage_not?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_not_contains?: InputMaybe<Scalars['String']>;
  unavailableCorridorErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
};

export type CfCalculatorPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCalculatorPageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCalculatorPageNestedFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageName_not?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType?: InputMaybe<Scalars['String']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType_not?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buildLocales_exists?: InputMaybe<Scalars['Boolean']>;
  calculator_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footer_exists?: InputMaybe<Scalars['Boolean']>;
  isTopPage?: InputMaybe<Scalars['Boolean']>;
  isTopPage_exists?: InputMaybe<Scalars['Boolean']>;
  isTopPage_not?: InputMaybe<Scalars['Boolean']>;
  meta_exists?: InputMaybe<Scalars['Boolean']>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
  pricingCalculator_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle?: InputMaybe<Scalars['String']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemap_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCardNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfCexCurrencyNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCexCurrencyNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCexCurrencyNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  corridorGridTitle?: InputMaybe<Scalars['String']>;
  corridorGridTitle_contains?: InputMaybe<Scalars['String']>;
  corridorGridTitle_exists?: InputMaybe<Scalars['Boolean']>;
  corridorGridTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  corridorGridTitle_not?: InputMaybe<Scalars['String']>;
  corridorGridTitle_not_contains?: InputMaybe<Scalars['String']>;
  corridorGridTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryCode?: InputMaybe<Scalars['String']>;
  countryCode_contains?: InputMaybe<Scalars['String']>;
  countryCode_exists?: InputMaybe<Scalars['Boolean']>;
  countryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryCode_not?: InputMaybe<Scalars['String']>;
  countryCode_not_contains?: InputMaybe<Scalars['String']>;
  countryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryName?: InputMaybe<Scalars['String']>;
  countryName_contains?: InputMaybe<Scalars['String']>;
  countryName_exists?: InputMaybe<Scalars['Boolean']>;
  countryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryName_not?: InputMaybe<Scalars['String']>;
  countryName_not_contains?: InputMaybe<Scalars['String']>;
  countryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyCode?: InputMaybe<Scalars['String']>;
  currencyCode_contains?: InputMaybe<Scalars['String']>;
  currencyCode_exists?: InputMaybe<Scalars['Boolean']>;
  currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyCode_not?: InputMaybe<Scalars['String']>;
  currencyCode_not_contains?: InputMaybe<Scalars['String']>;
  currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyName?: InputMaybe<Scalars['String']>;
  currencyName_contains?: InputMaybe<Scalars['String']>;
  currencyName_exists?: InputMaybe<Scalars['Boolean']>;
  currencyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currencyName_not?: InputMaybe<Scalars['String']>;
  currencyName_not_contains?: InputMaybe<Scalars['String']>;
  currencyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  faqContent_contains?: InputMaybe<Scalars['String']>;
  faqContent_exists?: InputMaybe<Scalars['Boolean']>;
  faqContent_not_contains?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageSlug?: InputMaybe<Scalars['String']>;
  pageSlug_contains?: InputMaybe<Scalars['String']>;
  pageSlug_exists?: InputMaybe<Scalars['Boolean']>;
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageSlug_not?: InputMaybe<Scalars['String']>;
  pageSlug_not_contains?: InputMaybe<Scalars['String']>;
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  topCurrencyPairsCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfComparisonTableCellNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComparisonTableCellNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfComparisonTableCellNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['String']>;
  amount_contains?: InputMaybe<Scalars['String']>;
  amount_exists?: InputMaybe<Scalars['Boolean']>;
  amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  amount_not?: InputMaybe<Scalars['String']>;
  amount_not_contains?: InputMaybe<Scalars['String']>;
  amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fee?: InputMaybe<Scalars['String']>;
  fee_contains?: InputMaybe<Scalars['String']>;
  fee_exists?: InputMaybe<Scalars['Boolean']>;
  fee_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fee_not?: InputMaybe<Scalars['String']>;
  fee_not_contains?: InputMaybe<Scalars['String']>;
  fee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfComparisonTableRowNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComparisonTableRowNestedFilter>>>;
  ATPCollection_exists?: InputMaybe<Scalars['Boolean']>;
  BNKCollection_exists?: InputMaybe<Scalars['Boolean']>;
  CSHCollection_exists?: InputMaybe<Scalars['Boolean']>;
  MOBCollection_exists?: InputMaybe<Scalars['Boolean']>;
  OR?: InputMaybe<Array<InputMaybe<CfComparisonTableRowNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  provider_exists?: InputMaybe<Scalars['Boolean']>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfCountryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCountryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCountryNestedFilter>>>;
  article?: InputMaybe<Scalars['String']>;
  article_contains?: InputMaybe<Scalars['String']>;
  article_exists?: InputMaybe<Scalars['Boolean']>;
  article_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  article_not?: InputMaybe<Scalars['String']>;
  article_not_contains?: InputMaybe<Scalars['String']>;
  article_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calculatorReceiveCountry_exists?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  code_contains?: InputMaybe<Scalars['String']>;
  code_exists?: InputMaybe<Scalars['Boolean']>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code_not?: InputMaybe<Scalars['String']>;
  code_not_contains?: InputMaybe<Scalars['String']>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionFrom?: InputMaybe<Scalars['String']>;
  prepositionFrom_contains?: InputMaybe<Scalars['String']>;
  prepositionFrom_exists?: InputMaybe<Scalars['Boolean']>;
  prepositionFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionFrom_not?: InputMaybe<Scalars['String']>;
  prepositionFrom_not_contains?: InputMaybe<Scalars['String']>;
  prepositionFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionIn?: InputMaybe<Scalars['String']>;
  prepositionIn_contains?: InputMaybe<Scalars['String']>;
  prepositionIn_exists?: InputMaybe<Scalars['Boolean']>;
  prepositionIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionIn_not?: InputMaybe<Scalars['String']>;
  prepositionIn_not_contains?: InputMaybe<Scalars['String']>;
  prepositionIn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionTo?: InputMaybe<Scalars['String']>;
  prepositionTo_contains?: InputMaybe<Scalars['String']>;
  prepositionTo_exists?: InputMaybe<Scalars['Boolean']>;
  prepositionTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prepositionTo_not?: InputMaybe<Scalars['String']>;
  prepositionTo_not_contains?: InputMaybe<Scalars['String']>;
  prepositionTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFaqItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqItemNestedFilter>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFaqModuleItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqModuleItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqModuleItemNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  path?: InputMaybe<Scalars['String']>;
  path_contains?: InputMaybe<Scalars['String']>;
  path_exists?: InputMaybe<Scalars['Boolean']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  path_not?: InputMaybe<Scalars['String']>;
  path_not_contains?: InputMaybe<Scalars['String']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortOrder_contains?: InputMaybe<Scalars['String']>;
  sortOrder_exists?: InputMaybe<Scalars['Boolean']>;
  sortOrder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortOrder_not?: InputMaybe<Scalars['String']>;
  sortOrder_not_contains?: InputMaybe<Scalars['String']>;
  sortOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  umbracoId?: InputMaybe<Scalars['String']>;
  umbracoId_contains?: InputMaybe<Scalars['String']>;
  umbracoId_exists?: InputMaybe<Scalars['Boolean']>;
  umbracoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  umbracoId_not?: InputMaybe<Scalars['String']>;
  umbracoId_not_contains?: InputMaybe<Scalars['String']>;
  umbracoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFaqPopularQuestionsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqPopularQuestionsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqPopularQuestionsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFaqTitleAndDescriptionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqTitleAndDescriptionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqTitleAndDescriptionNestedFilter>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFaqTopicNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqTopicNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqTopicNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  faqItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFaqTopicsModuleItemsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqTopicsModuleItemsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqTopicsModuleItemsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitleCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topicLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfFeatureFlagNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFeatureFlagNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFeatureFlagNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['Boolean']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_not?: InputMaybe<Scalars['Boolean']>;
};

export type CfFooterNavigationGroupExtrasNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfFooterNavigationGroupExtrasNestedFilter>>
  >;
  OR?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupExtrasNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFooterNavigationGroupNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFooterNavigationGroupNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  extras_exists?: InputMaybe<Scalars['Boolean']>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfImageGridItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfImageGridItemNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfImageGridItemNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfLayoutFooterNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLayoutFooterNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLayoutFooterNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  copyrightText?: InputMaybe<Scalars['String']>;
  copyrightText_contains?: InputMaybe<Scalars['String']>;
  copyrightText_exists?: InputMaybe<Scalars['Boolean']>;
  copyrightText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not?: InputMaybe<Scalars['String']>;
  copyrightText_not_contains?: InputMaybe<Scalars['String']>;
  copyrightText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footerLogo_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  navigationCollection_exists?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  region_contains?: InputMaybe<Scalars['String']>;
  region_exists?: InputMaybe<Scalars['Boolean']>;
  region_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  region_not?: InputMaybe<Scalars['String']>;
  region_not_contains?: InputMaybe<Scalars['String']>;
  region_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialIconsLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfLeadershipTeamEntryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLeadershipTeamEntryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLeadershipTeamEntryNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  jobTitle_contains?: InputMaybe<Scalars['String']>;
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobTitle_not?: InputMaybe<Scalars['String']>;
  jobTitle_not_contains?: InputMaybe<Scalars['String']>;
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel?: InputMaybe<Scalars['String']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlighted?: InputMaybe<Scalars['Boolean']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']>;
  underline_contains?: InputMaybe<Scalars['String']>;
  underline_exists?: InputMaybe<Scalars['Boolean']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  underline_not?: InputMaybe<Scalars['String']>;
  underline_not_contains?: InputMaybe<Scalars['String']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant?: InputMaybe<Scalars['String']>;
  variant_contains?: InputMaybe<Scalars['String']>;
  variant_exists?: InputMaybe<Scalars['Boolean']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant_not?: InputMaybe<Scalars['String']>;
  variant_not_contains?: InputMaybe<Scalars['String']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfMobileAppLeadingLinksNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMobileAppLeadingLinksNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMobileAppLeadingLinksNestedFilter>>>;
  androidLink_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  desktopLink_exists?: InputMaybe<Scalars['Boolean']>;
  iosLink_exists?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppButtonText?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_contains?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  mobileAppButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppButtonText_not?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_not_contains?: InputMaybe<Scalars['String']>;
  mobileAppButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qrCode_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfMobileAppQrCodeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMobileAppQrCodeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfMobileAppQrCodeNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  downloadButtonText?: InputMaybe<Scalars['String']>;
  downloadButtonText_contains?: InputMaybe<Scalars['String']>;
  downloadButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  downloadButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  downloadButtonText_not?: InputMaybe<Scalars['String']>;
  downloadButtonText_not_contains?: InputMaybe<Scalars['String']>;
  downloadButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageUrl?: InputMaybe<Scalars['String']>;
  imageUrl_contains?: InputMaybe<Scalars['String']>;
  imageUrl_exists?: InputMaybe<Scalars['Boolean']>;
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageUrl_not?: InputMaybe<Scalars['String']>;
  imageUrl_not_contains?: InputMaybe<Scalars['String']>;
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfNestedAccordionItemsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfNestedAccordionItemsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfNestedAccordionItemsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  countriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nestedAccordionLinksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  subtitleCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfNestedAccordionTitleAndDescriptionNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfNestedAccordionTitleAndDescriptionNestedFilter>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<CfNestedAccordionTitleAndDescriptionNestedFilter>>
  >;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>;
  analyticsPageName?: InputMaybe<Scalars['String']>;
  analyticsPageName_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageName_not?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType?: InputMaybe<Scalars['String']>;
  analyticsPageType_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_exists?: InputMaybe<Scalars['Boolean']>;
  analyticsPageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  analyticsPageType_not?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_contains?: InputMaybe<Scalars['String']>;
  analyticsPageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  author_exists?: InputMaybe<Scalars['Boolean']>;
  banner_exists?: InputMaybe<Scalars['Boolean']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footer_exists?: InputMaybe<Scalars['Boolean']>;
  headerPosition?: InputMaybe<Scalars['String']>;
  headerPosition_contains?: InputMaybe<Scalars['String']>;
  headerPosition_exists?: InputMaybe<Scalars['Boolean']>;
  headerPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerPosition_not?: InputMaybe<Scalars['String']>;
  headerPosition_not_contains?: InputMaybe<Scalars['String']>;
  headerPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerTheme?: InputMaybe<Scalars['String']>;
  headerTheme_contains?: InputMaybe<Scalars['String']>;
  headerTheme_exists?: InputMaybe<Scalars['Boolean']>;
  headerTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerTheme_not?: InputMaybe<Scalars['String']>;
  headerTheme_not_contains?: InputMaybe<Scalars['String']>;
  headerTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modulesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  noIndex_exists?: InputMaybe<Scalars['Boolean']>;
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
  ogImage_exists?: InputMaybe<Scalars['Boolean']>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']>;
  publishedDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishedDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishedDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishedDate_not?: InputMaybe<Scalars['DateTime']>;
  publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  regions_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regions_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regions_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regions_exists?: InputMaybe<Scalars['Boolean']>;
  relatedPagesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle?: InputMaybe<Scalars['String']>;
  sitemapTitle_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sitemapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemapTitle_not?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_contains?: InputMaybe<Scalars['String']>;
  sitemapTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sitemap_exists?: InputMaybe<Scalars['Boolean']>;
  sitemap_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  timeToRead?: InputMaybe<Scalars['String']>;
  timeToRead_contains?: InputMaybe<Scalars['String']>;
  timeToRead_exists?: InputMaybe<Scalars['Boolean']>;
  timeToRead_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  timeToRead_not?: InputMaybe<Scalars['String']>;
  timeToRead_not_contains?: InputMaybe<Scalars['String']>;
  timeToRead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updatedDate?: InputMaybe<Scalars['DateTime']>;
  updatedDate_exists?: InputMaybe<Scalars['Boolean']>;
  updatedDate_gt?: InputMaybe<Scalars['DateTime']>;
  updatedDate_gte?: InputMaybe<Scalars['DateTime']>;
  updatedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedDate_lt?: InputMaybe<Scalars['DateTime']>;
  updatedDate_lte?: InputMaybe<Scalars['DateTime']>;
  updatedDate_not?: InputMaybe<Scalars['DateTime']>;
  updatedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type CfPayoutMethodNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPayoutMethodNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPayoutMethodNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cornerGraphic_exists?: InputMaybe<Scalars['Boolean']>;
  correspondentsTitle?: InputMaybe<Scalars['String']>;
  correspondentsTitle_contains?: InputMaybe<Scalars['String']>;
  correspondentsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  correspondentsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  correspondentsTitle_not?: InputMaybe<Scalars['String']>;
  correspondentsTitle_not_contains?: InputMaybe<Scalars['String']>;
  correspondentsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countriesFromCollection_exists?: InputMaybe<Scalars['Boolean']>;
  countriesToCollection_exists?: InputMaybe<Scalars['Boolean']>;
  ctaButtonBaseUrl?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_contains?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_exists?: InputMaybe<Scalars['Boolean']>;
  ctaButtonBaseUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaButtonBaseUrl_not?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_not_contains?: InputMaybe<Scalars['String']>;
  ctaButtonBaseUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaButtonLabel?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_contains?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ctaButtonLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaButtonLabel_not?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_not_contains?: InputMaybe<Scalars['String']>;
  ctaButtonLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_exists?: InputMaybe<Scalars['Boolean']>;
  exchangeRateLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exchangeRateLabel_not?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_contains?: InputMaybe<Scalars['String']>;
  exchangeRateLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  feesLabel?: InputMaybe<Scalars['String']>;
  feesLabel_contains?: InputMaybe<Scalars['String']>;
  feesLabel_exists?: InputMaybe<Scalars['Boolean']>;
  feesLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  feesLabel_not?: InputMaybe<Scalars['String']>;
  feesLabel_not_contains?: InputMaybe<Scalars['String']>;
  feesLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hideExchangeRate?: InputMaybe<Scalars['Boolean']>;
  hideExchangeRate_exists?: InputMaybe<Scalars['Boolean']>;
  hideExchangeRate_not?: InputMaybe<Scalars['Boolean']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId?: InputMaybe<Scalars['String']>;
  payoutMethodId_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_exists?: InputMaybe<Scalars['Boolean']>;
  payoutMethodId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  payoutMethodId_not?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_contains?: InputMaybe<Scalars['String']>;
  payoutMethodId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveMethodDescription?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_contains?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_exists?: InputMaybe<Scalars['Boolean']>;
  receiveMethodDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveMethodDescription_not?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_not_contains?: InputMaybe<Scalars['String']>;
  receiveMethodDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveMethodLink_exists?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supportLinkText?: InputMaybe<Scalars['String']>;
  supportLinkText_contains?: InputMaybe<Scalars['String']>;
  supportLinkText_exists?: InputMaybe<Scalars['Boolean']>;
  supportLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supportLinkText_not?: InputMaybe<Scalars['String']>;
  supportLinkText_not_contains?: InputMaybe<Scalars['String']>;
  supportLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  transferTimeLabel?: InputMaybe<Scalars['String']>;
  transferTimeLabel_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_exists?: InputMaybe<Scalars['Boolean']>;
  transferTimeLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeLabel_not?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_contains?: InputMaybe<Scalars['String']>;
  transferTimeLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeTooltip?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_contains?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_exists?: InputMaybe<Scalars['Boolean']>;
  transferTimeTooltip_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transferTimeTooltip_not?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_not_contains?: InputMaybe<Scalars['String']>;
  transferTimeTooltip_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfPersonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfPopularCountriesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPopularCountriesNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPopularCountriesNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  popularCountriesCodes_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  popularCountriesCodes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  popularCountriesCodes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  popularCountriesCodes_exists?: InputMaybe<Scalars['Boolean']>;
  popularCountriesHeading?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_contains?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_exists?: InputMaybe<Scalars['Boolean']>;
  popularCountriesHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  popularCountriesHeading_not?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_not_contains?: InputMaybe<Scalars['String']>;
  popularCountriesHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type CfPricingCalculatorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPricingCalculatorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPricingCalculatorNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfPromoApplicabilityNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPromoApplicabilityNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPromoApplicabilityNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfPromotionalBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPromotionalBannerNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPromotionalBannerNestedFilter>>>;
  abTestVersionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  addToInitialHtml?: InputMaybe<Scalars['Boolean']>;
  addToInitialHtml_exists?: InputMaybe<Scalars['Boolean']>;
  addToInitialHtml_not?: InputMaybe<Scalars['Boolean']>;
  backgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  button_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promoApplicability_exists?: InputMaybe<Scalars['Boolean']>;
  promoIcon_exists?: InputMaybe<Scalars['Boolean']>;
  showForCorridors_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  showForCorridors_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  visitorTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visitorTypes_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfReviewsCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfReviewsCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfReviewsCardNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  country_exists?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ratings?: InputMaybe<Scalars['Int']>;
  ratings_exists?: InputMaybe<Scalars['Boolean']>;
  ratings_gt?: InputMaybe<Scalars['Int']>;
  ratings_gte?: InputMaybe<Scalars['Int']>;
  ratings_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  ratings_lt?: InputMaybe<Scalars['Int']>;
  ratings_lte?: InputMaybe<Scalars['Int']>;
  ratings_not?: InputMaybe<Scalars['Int']>;
  ratings_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSearchNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSearchNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSearchNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars['String']>;
  heading_contains?: InputMaybe<Scalars['String']>;
  heading_exists?: InputMaybe<Scalars['Boolean']>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heading_not?: InputMaybe<Scalars['String']>;
  heading_not_contains?: InputMaybe<Scalars['String']>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inputLabel?: InputMaybe<Scalars['String']>;
  inputLabel_contains?: InputMaybe<Scalars['String']>;
  inputLabel_exists?: InputMaybe<Scalars['Boolean']>;
  inputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inputLabel_not?: InputMaybe<Scalars['String']>;
  inputLabel_not_contains?: InputMaybe<Scalars['String']>;
  inputLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resultListLabel?: InputMaybe<Scalars['String']>;
  resultListLabel_contains?: InputMaybe<Scalars['String']>;
  resultListLabel_exists?: InputMaybe<Scalars['Boolean']>;
  resultListLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resultListLabel_not?: InputMaybe<Scalars['String']>;
  resultListLabel_not_contains?: InputMaybe<Scalars['String']>;
  resultListLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suggestionListCollection_exists?: InputMaybe<Scalars['Boolean']>;
  suggestionListLabel?: InputMaybe<Scalars['String']>;
  suggestionListLabel_contains?: InputMaybe<Scalars['String']>;
  suggestionListLabel_exists?: InputMaybe<Scalars['Boolean']>;
  suggestionListLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suggestionListLabel_not?: InputMaybe<Scalars['String']>;
  suggestionListLabel_not_contains?: InputMaybe<Scalars['String']>;
  suggestionListLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfSeoContactPointNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoContactPointNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSeoContactPointNestedFilter>>>;
  areaServed?: InputMaybe<Scalars['String']>;
  areaServed_contains?: InputMaybe<Scalars['String']>;
  areaServed_exists?: InputMaybe<Scalars['Boolean']>;
  areaServed_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  areaServed_not?: InputMaybe<Scalars['String']>;
  areaServed_not_contains?: InputMaybe<Scalars['String']>;
  areaServed_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactType?: InputMaybe<Scalars['String']>;
  contactType_contains?: InputMaybe<Scalars['String']>;
  contactType_exists?: InputMaybe<Scalars['Boolean']>;
  contactType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactType_not?: InputMaybe<Scalars['String']>;
  contactType_not_contains?: InputMaybe<Scalars['String']>;
  contactType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  telephone?: InputMaybe<Scalars['String']>;
  telephone_contains?: InputMaybe<Scalars['String']>;
  telephone_exists?: InputMaybe<Scalars['Boolean']>;
  telephone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  telephone_not?: InputMaybe<Scalars['String']>;
  telephone_not_contains?: InputMaybe<Scalars['String']>;
  telephone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSeoSameAsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoSameAsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSeoSameAsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  value?: InputMaybe<Scalars['String']>;
  value_contains?: InputMaybe<Scalars['String']>;
  value_exists?: InputMaybe<Scalars['Boolean']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not?: InputMaybe<Scalars['String']>;
  value_not_contains?: InputMaybe<Scalars['String']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfStepNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfStepNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfStepNestedFilter>>>;
  allReceiveCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allReceiveCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_exists?: InputMaybe<Scalars['Boolean']>;
  allSendCountriesExcept_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  receiveCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  receiveCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sendCountryList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  sendCountryList_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfTextCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTextCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfTextCardNestedFilter>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentAlignment?: InputMaybe<Scalars['String']>;
  contentAlignment_contains?: InputMaybe<Scalars['String']>;
  contentAlignment_exists?: InputMaybe<Scalars['Boolean']>;
  contentAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentAlignment_not?: InputMaybe<Scalars['String']>;
  contentAlignment_not_contains?: InputMaybe<Scalars['String']>;
  contentAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  textColor?: InputMaybe<Scalars['String']>;
  textColor_contains?: InputMaybe<Scalars['String']>;
  textColor_exists?: InputMaybe<Scalars['Boolean']>;
  textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textColor_not?: InputMaybe<Scalars['String']>;
  textColor_not_contains?: InputMaybe<Scalars['String']>;
  textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTextWithColorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTextWithColorNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfTextWithColorNestedFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColor_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>;
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  backgroundColor_not?: InputMaybe<Scalars['String']>;
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>;
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  inline?: InputMaybe<Scalars['Boolean']>;
  inline_exists?: InputMaybe<Scalars['Boolean']>;
  inline_not?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
};

export type CfTrustpilotWidgetNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTrustpilotWidgetNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfTrustpilotWidgetNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfWhyChooseReasonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfWhyChooseReasonNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfWhyChooseReasonNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagePosition?: InputMaybe<Scalars['String']>;
  imagePosition_contains?: InputMaybe<Scalars['String']>;
  imagePosition_exists?: InputMaybe<Scalars['Boolean']>;
  imagePosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imagePosition_not?: InputMaybe<Scalars['String']>;
  imagePosition_not_contains?: InputMaybe<Scalars['String']>;
  imagePosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfloggedInMenuItemsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfloggedInMenuItemsMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfloggedInMenuItemsMultiTypeNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfmodulesMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfmodulesMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfmodulesMultiTypeNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfnotLoggedInMenuItemsMultiTypeNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfnotLoggedInMenuItemsMultiTypeNestedFilter>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<CfnotLoggedInMenuItemsMultiTypeNestedFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfreferFriendLinkMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfreferFriendLinkMultiTypeNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfreferFriendLinkMultiTypeNestedFilter>>>;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel?: InputMaybe<Scalars['String']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlighted?: InputMaybe<Scalars['Boolean']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']>;
  underline_contains?: InputMaybe<Scalars['String']>;
  underline_exists?: InputMaybe<Scalars['Boolean']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  underline_not?: InputMaybe<Scalars['String']>;
  underline_not_contains?: InputMaybe<Scalars['String']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant?: InputMaybe<Scalars['String']>;
  variant_contains?: InputMaybe<Scalars['String']>;
  variant_exists?: InputMaybe<Scalars['Boolean']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant_not?: InputMaybe<Scalars['String']>;
  variant_not_contains?: InputMaybe<Scalars['String']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfuserDropdownMenuItemsMultiTypeNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfuserDropdownMenuItemsMultiTypeNestedFilter>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<CfuserDropdownMenuItemsMultiTypeNestedFilter>>
  >;
  align?: InputMaybe<Scalars['String']>;
  align_contains?: InputMaybe<Scalars['String']>;
  align_exists?: InputMaybe<Scalars['Boolean']>;
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  align_not?: InputMaybe<Scalars['String']>;
  align_not_contains?: InputMaybe<Scalars['String']>;
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel?: InputMaybe<Scalars['String']>;
  ariaLabel_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_exists?: InputMaybe<Scalars['Boolean']>;
  ariaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ariaLabel_not?: InputMaybe<Scalars['String']>;
  ariaLabel_not_contains?: InputMaybe<Scalars['String']>;
  ariaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color?: InputMaybe<Scalars['String']>;
  color_contains?: InputMaybe<Scalars['String']>;
  color_exists?: InputMaybe<Scalars['Boolean']>;
  color_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  color_not?: InputMaybe<Scalars['String']>;
  color_not_contains?: InputMaybe<Scalars['String']>;
  color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  gaTrackName?: InputMaybe<Scalars['String']>;
  gaTrackName_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_exists?: InputMaybe<Scalars['Boolean']>;
  gaTrackName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gaTrackName_not?: InputMaybe<Scalars['String']>;
  gaTrackName_not_contains?: InputMaybe<Scalars['String']>;
  gaTrackName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  highlighted?: InputMaybe<Scalars['Boolean']>;
  highlighted_exists?: InputMaybe<Scalars['Boolean']>;
  highlighted_not?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_exists?: InputMaybe<Scalars['Boolean']>;
  isDisplayedWhenLoggedIn_not?: InputMaybe<Scalars['Boolean']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  isInternal_exists?: InputMaybe<Scalars['Boolean']>;
  isInternal_not?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  label_contains?: InputMaybe<Scalars['String']>;
  label_exists?: InputMaybe<Scalars['Boolean']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  label_not?: InputMaybe<Scalars['String']>;
  label_not_contains?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileAppLeadingLinks_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_exists?: InputMaybe<Scalars['Boolean']>;
  mobileMenuToggle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobileMenuToggle_not?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_contains?: InputMaybe<Scalars['String']>;
  mobileMenuToggle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noFollow?: InputMaybe<Scalars['Boolean']>;
  noFollow_exists?: InputMaybe<Scalars['Boolean']>;
  noFollow_not?: InputMaybe<Scalars['Boolean']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_exists?: InputMaybe<Scalars['Boolean']>;
  openInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_exists?: InputMaybe<Scalars['Boolean']>;
  redirectToLegacyProject_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  underline?: InputMaybe<Scalars['String']>;
  underline_contains?: InputMaybe<Scalars['String']>;
  underline_exists?: InputMaybe<Scalars['Boolean']>;
  underline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  underline_not?: InputMaybe<Scalars['String']>;
  underline_not_contains?: InputMaybe<Scalars['String']>;
  underline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant?: InputMaybe<Scalars['String']>;
  variant_contains?: InputMaybe<Scalars['String']>;
  variant_exists?: InputMaybe<Scalars['Boolean']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  variant_not?: InputMaybe<Scalars['String']>;
  variant_not_contains?: InputMaybe<Scalars['String']>;
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssetFragment = {
  __typename?: 'Asset';
  title?: string | null;
  width?: number | null;
  height?: number | null;
  alt?: string | null;
};

export type CoreImageFragment = {
  __typename?: 'Asset';
  url?: string | null;
  title?: string | null;
  width?: number | null;
  height?: number | null;
  alt?: string | null;
};

export type ImageXsFragment = {
  __typename?: 'Asset';
  url?: string | null;
  title?: string | null;
  width?: number | null;
  height?: number | null;
  alt?: string | null;
};

export type AccordionFragment = {
  __typename?: 'Accordion';
  name?: string | null;
  heading?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  items?: {
    __typename?: 'AccordionItemsCollection';
    items: Array<{
      __typename: 'AccordionItem';
      name?: string | null;
      title?: string | null;
      contentBackgroundColor?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: { __typename?: 'AccordionItemContent'; json: Document } | null;
    } | null>;
  } | null;
};

export type AccordionQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type AccordionQueryQuery = {
  __typename?: 'Query';
  Accordion?: {
    __typename?: 'AccordionCollection';
    items: Array<{
      __typename?: 'Accordion';
      name?: string | null;
      heading?: string | null;
      backgroundColor?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      items?: {
        __typename?: 'AccordionItemsCollection';
        items: Array<{
          __typename: 'AccordionItem';
          name?: string | null;
          title?: string | null;
          contentBackgroundColor?: string | null;
          sys: { __typename?: 'Sys'; id: string };
          content?: {
            __typename?: 'AccordionItemContent';
            json: Document;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type AppBannerSectionFragment = {
  __typename: 'AppBannerSection';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: {
    __typename?: 'AppBannerSectionContent';
    json: Document;
    links: {
      __typename?: 'AppBannerSectionContentLinks';
      assets: {
        __typename?: 'AppBannerSectionContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'AppBannerSectionContentEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'AppBannerSection';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  imageCollection?: {
    __typename?: 'AssetCollection';
    items: Array<{
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null>;
  } | null;
  imageMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  linkDesktop?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  appStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    openInNewTab?: boolean | null;
    isInternal?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  playStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    openInNewTab?: boolean | null;
    isInternal?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type AppBannerSectionQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type AppBannerSectionQueryQuery = {
  __typename?: 'Query';
  AppBannerSection?: {
    __typename?: 'AppBannerSectionCollection';
    items: Array<{
      __typename: 'AppBannerSection';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: {
        __typename?: 'AppBannerSectionContent';
        json: Document;
        links: {
          __typename?: 'AppBannerSectionContentLinks';
          assets: {
            __typename?: 'AppBannerSectionContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'AppBannerSectionContentEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'AppBannerSection';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      imageCollection?: {
        __typename?: 'AssetCollection';
        items: Array<{
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null>;
      } | null;
      imageMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      linkDesktop?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      appStoreIconLink?: {
        __typename?: 'Link';
        url?: string | null;
        openInNewTab?: boolean | null;
        isInternal?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      playStoreIconLink?: {
        __typename?: 'Link';
        url?: string | null;
        openInNewTab?: boolean | null;
        isInternal?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type AppBannerFragment = {
  __typename: 'AppBanner';
  name?: string | null;
  bannerDescription?: string | null;
  bannerTitle?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  appStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  playStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type AppBannerQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type AppBannerQueryQuery = {
  __typename?: 'Query';
  AppBanner?: {
    __typename: 'AppBanner';
    name?: string | null;
    bannerDescription?: string | null;
    bannerTitle?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    appStoreIconLink?: {
      __typename?: 'Link';
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null;
    playStoreIconLink?: {
      __typename?: 'Link';
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type BodyTextFragment = {
  __typename: 'BodyText';
  name?: string | null;
  headingAlign?: string | null;
  contentAlign?: string | null;
  listIndent?: number | null;
  paragraphIndent?: number | null;
  isPageSection?: boolean | null;
  sys: { __typename?: 'Sys'; id: string };
  bodyTextHeading?: { __typename?: 'BodyTextHeading'; json: Document } | null;
  bodyTextContent?: {
    __typename?: 'BodyTextContent';
    json: Document;
    links: {
      __typename?: 'BodyTextContentLinks';
      assets: {
        __typename?: 'BodyTextContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'BodyTextContentEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  bodyTextContentTwo?: {
    __typename?: 'BodyTextContentTwo';
    json: Document;
    links: {
      __typename?: 'BodyTextContentTwoLinks';
      assets: {
        __typename?: 'BodyTextContentTwoAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'BodyTextContentTwoEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
};

export type BodyTextQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type BodyTextQueryQuery = {
  __typename?: 'Query';
  BodyText?: {
    __typename?: 'BodyTextCollection';
    items: Array<{
      __typename: 'BodyText';
      name?: string | null;
      headingAlign?: string | null;
      contentAlign?: string | null;
      listIndent?: number | null;
      paragraphIndent?: number | null;
      isPageSection?: boolean | null;
      sys: { __typename?: 'Sys'; id: string };
      bodyTextHeading?: {
        __typename?: 'BodyTextHeading';
        json: Document;
      } | null;
      bodyTextContent?: {
        __typename?: 'BodyTextContent';
        json: Document;
        links: {
          __typename?: 'BodyTextContentLinks';
          assets: {
            __typename?: 'BodyTextContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'BodyTextContentEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      bodyTextContentTwo?: {
        __typename?: 'BodyTextContentTwo';
        json: Document;
        links: {
          __typename?: 'BodyTextContentTwoLinks';
          assets: {
            __typename?: 'BodyTextContentTwoAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'BodyTextContentTwoEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CardFragment = {
  __typename: 'Card';
  name?: string | null;
  payoutMethodId?: string | null;
  header?: string | null;
  description?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type CardsFragment = {
  __typename: 'Cards';
  name?: string | null;
  header?: string | null;
  description?: string | null;
  carouselText?: string | null;
  paymentText?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  cardsCollection?: {
    __typename?: 'CardsCardsCollection';
    items: Array<{
      __typename: 'Card';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  paymentIcons?: {
    __typename?: 'Asset';
    title?: string | null;
    url?: string | null;
  } | null;
};

export type CardsQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type CardsQueryQuery = {
  __typename?: 'Query';
  Cards?: {
    __typename?: 'CardsCollection';
    items: Array<{
      __typename: 'Cards';
      name?: string | null;
      header?: string | null;
      description?: string | null;
      carouselText?: string | null;
      paymentText?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      cardsCollection?: {
        __typename?: 'CardsCardsCollection';
        items: Array<{
          __typename: 'Card';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      paymentIcons?: {
        __typename?: 'Asset';
        title?: string | null;
        url?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CardQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type CardQueryQuery = {
  __typename?: 'Query';
  Card?: {
    __typename?: 'CardCollection';
    items: Array<{
      __typename: 'Card';
      name?: string | null;
      payoutMethodId?: string | null;
      header?: string | null;
      description?: string | null;
      backgroundColor?: string | null;
      sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
      backgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ContainedBannerFragment = {
  __typename: 'ContainedBanner';
  name?: string | null;
  textBodyColor?: string | null;
  textHeadingColor?: string | null;
  textAlign?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  desktopBanner?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  mobileBanner?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  text?: { __typename?: 'ContainedBannerText'; json: Document } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type ContainedBannerQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type ContainedBannerQueryQuery = {
  __typename?: 'Query';
  ContainedBanner?: {
    __typename: 'ContainedBanner';
    name?: string | null;
    textBodyColor?: string | null;
    textHeadingColor?: string | null;
    textAlign?: string | null;
    backgroundColor?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    desktopBanner?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    mobileBanner?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    text?: { __typename?: 'ContainedBannerText'; json: Document } | null;
    link?: {
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CountriesGridFragment = {
  __typename: 'CountriesGrid';
  name?: string | null;
  moneyTransfers?: string | null;
  sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
};

export type CountriesGridQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type CountriesGridQueryQuery = {
  __typename?: 'Query';
  CountriesGrid?: {
    __typename: 'CountriesGrid';
    name?: string | null;
    moneyTransfers?: string | null;
    sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
  } | null;
};

export type ExchangeCalculatorFragment = {
  __typename?: 'ExchangeCalculator';
  name?: string | null;
  heading?: string | null;
  title?: string | null;
  description?: string | null;
  sendLabel?: string | null;
  receiveLabel?: string | null;
  limitReachedError?: string | null;
  exchangeRate?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  mobile?: { __typename?: 'Asset'; url?: string | null } | null;
  bank?: { __typename?: 'Asset'; url?: string | null } | null;
  cash?: { __typename?: 'Asset'; url?: string | null } | null;
};

export type FaqHeroFragment = {
  __typename?: 'FaqHero';
  name?: string | null;
  heading?: string | null;
  textColor?: string | null;
  primaryBackgroundColor?: string | null;
  secondaryBackgroundColor?: string | null;
  sendToText?: string | null;
  allCountries?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  footerImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  search?: {
    __typename: 'Search';
    name?: string | null;
    heading?: string | null;
    inputLabel?: string | null;
    resultListLabel?: string | null;
    suggestionListLabel?: string | null;
    suggestionListCollection?: {
      __typename?: 'SearchSuggestionListCollection';
      items: Array<{
        __typename?: 'Page';
        name?: string | null;
        slug?: string | null;
        sys: { __typename?: 'Sys'; id: string };
      } | null>;
    } | null;
  } | null;
  links?: {
    __typename?: 'FaqHeroLinksCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      label?: string | null;
      url?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type FaqHeroQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqHeroQueryQuery = {
  __typename?: 'Query';
  FaqHero?: {
    __typename?: 'FaqHero';
    name?: string | null;
    heading?: string | null;
    textColor?: string | null;
    primaryBackgroundColor?: string | null;
    secondaryBackgroundColor?: string | null;
    sendToText?: string | null;
    allCountries?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    footerImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    search?: {
      __typename: 'Search';
      name?: string | null;
      heading?: string | null;
      inputLabel?: string | null;
      resultListLabel?: string | null;
      suggestionListLabel?: string | null;
      suggestionListCollection?: {
        __typename?: 'SearchSuggestionListCollection';
        items: Array<{
          __typename?: 'Page';
          name?: string | null;
          slug?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null;
    links?: {
      __typename?: 'FaqHeroLinksCollection';
      items: Array<{
        __typename?: 'Link';
        name?: string | null;
        label?: string | null;
        url?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type FaqModuleItemFragment = {
  __typename: 'FaqModuleItem';
  name?: string | null;
  title?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: {
    __typename?: 'FaqModuleItemDescription';
    json: Document;
    links: {
      __typename?: 'FaqModuleItemDescriptionLinks';
      assets: {
        __typename?: 'FaqModuleItemDescriptionAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'FaqModuleItemDescriptionEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'Image';
              name?: string | null;
              align?: string | null;
              position?: string | null;
              width?: number | null;
              height?: number | null;
              sys: { __typename?: 'Sys'; id: string };
              asset?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
};

export type FaqModuleItemQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqModuleItemQueryQuery = {
  __typename?: 'Query';
  FaqModuleItem?: {
    __typename?: 'FaqModuleItemCollection';
    items: Array<{
      __typename: 'FaqModuleItem';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'FaqModuleItemDescription';
        json: Document;
        links: {
          __typename?: 'FaqModuleItemDescriptionLinks';
          assets: {
            __typename?: 'FaqModuleItemDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'FaqModuleItemDescriptionEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  name?: string | null;
                  align?: string | null;
                  position?: string | null;
                  width?: number | null;
                  height?: number | null;
                  sys: { __typename?: 'Sys'; id: string };
                  asset?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type FaqModuleFragment = {
  __typename: 'FaqModule';
  name?: string | null;
  title?: string | null;
  subheading?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  questions?: {
    __typename?: 'FaqModuleQuestionsCollection';
    items: Array<{
      __typename?: 'FaqModuleItem';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'FaqModuleItemDescription';
        json: Document;
      } | null;
    } | null>;
  } | null;
  ctaButton?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type FaqModuleQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type FaqModuleQueryQuery = {
  __typename?: 'Query';
  FaqModule?: {
    __typename?: 'FaqModuleCollection';
    items: Array<{
      __typename: 'FaqModule';
      name?: string | null;
      title?: string | null;
      subheading?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      questions?: {
        __typename?: 'FaqModuleQuestionsCollection';
        items: Array<{
          __typename?: 'FaqModuleItem';
          name?: string | null;
          title?: string | null;
          sys: { __typename?: 'Sys'; id: string };
          description?: {
            __typename?: 'FaqModuleItemDescription';
            json: Document;
          } | null;
        } | null>;
      } | null;
      ctaButton?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FaqPopularQuestionsFragment = {
  __typename?: 'FaqPopularQuestions';
  name?: string | null;
  title?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  questions?: {
    __typename?: 'FaqPopularQuestionsQuestionsCollection';
    items: Array<{
      __typename?: 'FaqItem';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      body?: {
        __typename?: 'FaqItemBody';
        json: Document;
        links: {
          __typename?: 'FaqItemBodyLinks';
          assets: {
            __typename?: 'FaqItemBodyAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'FaqItemBodyEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSectionV2';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type FaqPopularQuestionsQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqPopularQuestionsQueryQuery = {
  __typename?: 'Query';
  FaqPopularQuestions?: {
    __typename?: 'FaqPopularQuestions';
    name?: string | null;
    title?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    questions?: {
      __typename?: 'FaqPopularQuestionsQuestionsCollection';
      items: Array<{
        __typename?: 'FaqItem';
        name?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        body?: {
          __typename?: 'FaqItemBody';
          json: Document;
          links: {
            __typename?: 'FaqItemBodyLinks';
            assets: {
              __typename?: 'FaqItemBodyAssets';
              block: Array<{
                __typename?: 'Asset';
                contentType?: string | null;
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
                sys: { __typename?: 'Sys'; id: string };
              } | null>;
            };
            entries: {
              __typename?: 'FaqItemBodyEntries';
              block: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSectionV2';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSectionV2';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'TextSectionV2';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type FaqTitleAndDescriptionFragment = {
  __typename: 'FaqTitleAndDescription';
  name?: string | null;
  title?: string | null;
  description?: string | null;
  sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
  content?: {
    __typename?: 'FaqTitleAndDescriptionContent';
    json: Document;
    links: {
      __typename?: 'FaqTitleAndDescriptionContentLinks';
      assets: {
        __typename?: 'FaqTitleAndDescriptionContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'FaqTitleAndDescriptionContentEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSectionV2';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
};

export type FaqTitleAndDescriptionQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqTitleAndDescriptionQueryQuery = {
  __typename?: 'Query';
  FaqTitleAndDescription?: {
    __typename?: 'FaqTitleAndDescriptionCollection';
    items: Array<{
      __typename: 'FaqTitleAndDescription';
      name?: string | null;
      title?: string | null;
      description?: string | null;
      sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
      content?: {
        __typename?: 'FaqTitleAndDescriptionContent';
        json: Document;
        links: {
          __typename?: 'FaqTitleAndDescriptionContentLinks';
          assets: {
            __typename?: 'FaqTitleAndDescriptionContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'FaqTitleAndDescriptionContentEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSectionV2';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type CountryFragment = {
  __typename: 'Country';
  name?: string | null;
  code?: string | null;
  prepositionFrom?: string | null;
  prepositionIn?: string | null;
  prepositionTo?: string | null;
  sys: { __typename?: 'Sys'; id: string };
};

export type CountryQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type CountryQueryQuery = {
  __typename?: 'Query';
  Country?: {
    __typename?: 'CountryCollection';
    items: Array<{
      __typename: 'Country';
      name?: string | null;
      code?: string | null;
      prepositionFrom?: string | null;
      prepositionIn?: string | null;
      prepositionTo?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type FaqItemFragment = {
  __typename?: 'FaqItem';
  name?: string | null;
  title?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  body?: {
    __typename?: 'FaqItemBody';
    json: Document;
    links: {
      __typename?: 'FaqItemBodyLinks';
      assets: {
        __typename?: 'FaqItemBodyAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'FaqItemBodyEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSectionV2';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  countries?: {
    __typename?: 'FaqItemCountriesCollection';
    items: Array<{
      __typename: 'Country';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type FaqItemQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqItemQueryQuery = {
  __typename?: 'Query';
  FaqItem?: {
    __typename?: 'FaqItemCollection';
    items: Array<{
      __typename?: 'FaqItem';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      body?: {
        __typename?: 'FaqItemBody';
        json: Document;
        links: {
          __typename?: 'FaqItemBodyLinks';
          assets: {
            __typename?: 'FaqItemBodyAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'FaqItemBodyEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSectionV2';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      countries?: {
        __typename?: 'FaqItemCountriesCollection';
        items: Array<{
          __typename: 'Country';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type FaqTopicFragment = {
  __typename?: 'FaqTopic';
  name?: string | null;
  title?: string | null;
  subtitle?: string | null;
  description?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  icon?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  faqItems?: {
    __typename?: 'FaqTopicFaqItemsCollection';
    items: Array<{
      __typename: 'FaqItem';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type FaqTopicQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqTopicQueryQuery = {
  __typename?: 'Query';
  FaqTopic?: {
    __typename?: 'FaqTopicCollection';
    items: Array<{
      __typename?: 'FaqTopic';
      name?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      icon?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      faqItems?: {
        __typename?: 'FaqTopicFaqItemsCollection';
        items: Array<{
          __typename: 'FaqItem';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type FaqSectionFragment = {
  __typename: 'FaqSection';
  name?: string | null;
  title?: string | null;
  clearText?: string | null;
  resultsForText?: string | null;
  emptySearchText?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  faqTopicsCollection?: {
    __typename?: 'FaqSectionFaqTopicsCollection';
    items: Array<{
      __typename: 'FaqTopic';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  popularQuestions?: {
    __typename?: 'FaqPopularQuestions';
    name?: string | null;
    title?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    questions?: {
      __typename?: 'FaqPopularQuestionsQuestionsCollection';
      items: Array<{
        __typename?: 'FaqItem';
        name?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        body?: {
          __typename?: 'FaqItemBody';
          json: Document;
          links: {
            __typename?: 'FaqItemBodyLinks';
            assets: {
              __typename?: 'FaqItemBodyAssets';
              block: Array<{
                __typename?: 'Asset';
                contentType?: string | null;
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
                sys: { __typename?: 'Sys'; id: string };
              } | null>;
            };
            entries: {
              __typename?: 'FaqItemBodyEntries';
              block: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSectionV2';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSectionV2';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'TextSectionV2';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type FaqSectionQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqSectionQueryQuery = {
  __typename?: 'Query';
  FaqSection?: {
    __typename?: 'FaqSectionCollection';
    items: Array<{
      __typename: 'FaqSection';
      name?: string | null;
      title?: string | null;
      clearText?: string | null;
      resultsForText?: string | null;
      emptySearchText?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      faqTopicsCollection?: {
        __typename?: 'FaqSectionFaqTopicsCollection';
        items: Array<{
          __typename: 'FaqTopic';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      popularQuestions?: {
        __typename?: 'FaqPopularQuestions';
        name?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        questions?: {
          __typename?: 'FaqPopularQuestionsQuestionsCollection';
          items: Array<{
            __typename?: 'FaqItem';
            name?: string | null;
            title?: string | null;
            sys: { __typename?: 'Sys'; id: string };
            body?: {
              __typename?: 'FaqItemBody';
              json: Document;
              links: {
                __typename?: 'FaqItemBodyLinks';
                assets: {
                  __typename?: 'FaqItemBodyAssets';
                  block: Array<{
                    __typename?: 'Asset';
                    contentType?: string | null;
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                  } | null>;
                };
                entries: {
                  __typename?: 'FaqItemBodyEntries';
                  block: Array<
                    | {
                        __typename?: 'Accordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AccordionItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBannerSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContainedBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountriesGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExchangeCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopic';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlags';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroTextVariation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGridItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InfoCards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeam';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeamEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'LinkGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppQrCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhotoCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSectionV2';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TrustpilotWidget';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  inline: Array<
                    | {
                        __typename?: 'Accordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AccordionItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBannerSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContainedBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountriesGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExchangeCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopic';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlags';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroTextVariation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGridItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InfoCards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeam';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeamEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'LinkGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppQrCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhotoCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSectionV2';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TrustpilotWidget';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  hyperlink: Array<
                    | {
                        __typename?: 'Accordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AccordionItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBannerSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContainedBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountriesGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExchangeCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopic';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlags';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroTextVariation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGridItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InfoCards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeam';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeamEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'LinkGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppQrCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhotoCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename: 'TextSectionV2';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TrustpilotWidget';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                };
              };
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FooterNavigationGroupExtrasFragment = {
  __typename?: 'FooterNavigationGroupExtras';
  backgroundColor?: string | null;
  color?: string | null;
  title?: string | null;
};

export type FooterNavigationGroupFragment = {
  __typename?: 'FooterNavigationGroup';
  title?: string | null;
  linksCollection?: {
    __typename?: 'FooterNavigationGroupLinksCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  extras?: {
    __typename?: 'FooterNavigationGroupExtras';
    backgroundColor?: string | null;
    color?: string | null;
    title?: string | null;
  } | null;
};

export type LegalMessagingFragment = {
  __typename: 'LegalMessaging';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: { __typename?: 'LegalMessagingContent'; json: Document } | null;
};

export type FooterFragment = {
  __typename: 'LayoutFooter';
  name?: string | null;
  copyrightText?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  socialIconsLinksCollection?: {
    __typename?: 'LayoutFooterSocialIconsLinksCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  navigationCollection?: {
    __typename?: 'LayoutFooterNavigationCollection';
    items: Array<{
      __typename?: 'FooterNavigationGroup';
      title?: string | null;
      linksCollection?: {
        __typename?: 'FooterNavigationGroupLinksCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      extras?: {
        __typename?: 'FooterNavigationGroupExtras';
        backgroundColor?: string | null;
        color?: string | null;
        title?: string | null;
      } | null;
    } | null>;
  } | null;
  footerLogo?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type FullWidthBannerFragment = {
  __typename: 'FullWidthBanner';
  name?: string | null;
  backgroundColor?: string | null;
  secondBackgroundColor?: string | null;
  textColor?: string | null;
  textAlign?: string | null;
  borderColor?: string | null;
  secondBorderColor?: string | null;
  contentWidth?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  backgroundImageMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  text?: { __typename?: 'FullWidthBannerText'; json: Document } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type FullWidthBannerQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type FullWidthBannerQueryQuery = {
  __typename?: 'Query';
  FullWidthBanner?: {
    __typename?: 'FullWidthBannerCollection';
    items: Array<{
      __typename: 'FullWidthBanner';
      name?: string | null;
      backgroundColor?: string | null;
      secondBackgroundColor?: string | null;
      textColor?: string | null;
      textAlign?: string | null;
      borderColor?: string | null;
      secondBorderColor?: string | null;
      contentWidth?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      backgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      backgroundImageMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      text?: { __typename?: 'FullWidthBannerText'; json: Document } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type HeaderSwFragment = {
  __typename?: 'LayoutHeader';
  name?: string | null;
  menuLabel?: string | null;
  welcome?: string | null;
  lastLogin?: string | null;
  myAccount?: string | null;
  notLoggedInMenuItemsCollection?: {
    __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
    items: Array<
      | {
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        }
      | {
          __typename: 'LinkGroup';
          name?: string | null;
          label?: string | null;
          sys: { __typename?: 'Sys'; id: string };
          linksCollection?: {
            __typename?: 'LinkGroupLinksCollection';
            items: Array<{
              __typename?: 'Link';
              url?: string | null;
              name?: string | null;
              gaTrackName?: string | null;
              label?: string | null;
            } | null>;
          } | null;
        }
      | null
    >;
  } | null;
  loggedInMenuItemsCollection?: {
    __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
    items: Array<
      | {
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        }
      | {
          __typename: 'LinkGroup';
          name?: string | null;
          label?: string | null;
          sys: { __typename?: 'Sys'; id: string };
          linksCollection?: {
            __typename?: 'LinkGroupLinksCollection';
            items: Array<{
              __typename?: 'Link';
              url?: string | null;
              name?: string | null;
              gaTrackName?: string | null;
              label?: string | null;
            } | null>;
          } | null;
        }
      | null
    >;
  } | null;
  userDropdownMenuItemsCollection?: {
    __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
  referFriendLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  logoLight?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  logoLightMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  logoDark?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  logoDarkMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  mobileAppLeadingLinks?: {
    __typename?: 'MobileAppLeadingLinks';
    mobileAppButtonText?: string | null;
    qrCode?: {
      __typename?: 'MobileAppQrCode';
      imageUrl?: string | null;
      description?: string | null;
    } | null;
  } | null;
};

export type HeroTextVariationFragment = {
  __typename?: 'HeroTextVariation';
  name?: string | null;
  heading?: string | null;
  description?: string | null;
  textColor?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  bottomWaveImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type HeroTextVariationQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type HeroTextVariationQueryQuery = {
  __typename?: 'Query';
  HeroTextVariation?: {
    __typename?: 'HeroTextVariation';
    name?: string | null;
    heading?: string | null;
    description?: string | null;
    textColor?: string | null;
    backgroundColor?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    backgroundImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    bottomWaveImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type BetterWayTopSwFragment = {
  __typename: 'BetterWayTop';
  name?: string | null;
  headingColor?: string | null;
  backgroundColor?: string | null;
  secondBackgroundColor?: string | null;
  hideDescriptionOnMobile?: boolean | null;
  trustpilotMessage?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  rightModule?:
    | { __typename?: 'Calculator' }
    | {
        __typename?: 'ExchangeCalculator';
        name?: string | null;
        heading?: string | null;
        title?: string | null;
        description?: string | null;
        sendLabel?: string | null;
        receiveLabel?: string | null;
        limitReachedError?: string | null;
        exchangeRate?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        mobile?: { __typename?: 'Asset'; url?: string | null } | null;
        bank?: { __typename?: 'Asset'; url?: string | null } | null;
        cash?: { __typename?: 'Asset'; url?: string | null } | null;
      }
    | { __typename?: 'Image' }
    | { __typename?: 'Search' }
    | { __typename?: 'SendToCountryModule' }
    | null;
  headingRichText?: {
    __typename?: 'BetterWayTopHeading';
    json: Document;
  } | null;
  subHeadingRichText?: {
    __typename?: 'BetterWayTopSubheading';
    json: Document;
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  trustpilotLogo?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  trustpilotLink?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  foregroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  backgroundImageMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  bottomWaveImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  calculatorPage?: {
    __typename?: 'CalculatorPage';
    slug?: string | null;
  } | null;
  trustpilotWidget?: {
    __typename: 'TrustpilotWidget';
    name?: string | null;
    url?: string | null;
    sys: { __typename?: 'Sys'; id: string };
  } | null;
  qrCode?: {
    __typename?: 'MobileAppQrCode';
    imageUrl?: string | null;
    description?: string | null;
  } | null;
  appStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    openInNewTab?: boolean | null;
    isInternal?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  playStoreIconLink?: {
    __typename?: 'Link';
    url?: string | null;
    openInNewTab?: boolean | null;
    isInternal?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type BetterWayTopQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type BetterWayTopQueryQuery = {
  __typename?: 'Query';
  BetterWayTop?: {
    __typename?: 'BetterWayTopCollection';
    items: Array<{
      __typename: 'BetterWayTop';
      name?: string | null;
      headingColor?: string | null;
      backgroundColor?: string | null;
      secondBackgroundColor?: string | null;
      hideDescriptionOnMobile?: boolean | null;
      trustpilotMessage?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      rightModule?:
        | { __typename?: 'Calculator' }
        | {
            __typename?: 'ExchangeCalculator';
            name?: string | null;
            heading?: string | null;
            title?: string | null;
            description?: string | null;
            sendLabel?: string | null;
            receiveLabel?: string | null;
            limitReachedError?: string | null;
            exchangeRate?: string | null;
            sys: { __typename?: 'Sys'; id: string };
            mobile?: { __typename?: 'Asset'; url?: string | null } | null;
            bank?: { __typename?: 'Asset'; url?: string | null } | null;
            cash?: { __typename?: 'Asset'; url?: string | null } | null;
          }
        | { __typename?: 'Image' }
        | { __typename?: 'Search' }
        | { __typename?: 'SendToCountryModule' }
        | null;
      headingRichText?: {
        __typename?: 'BetterWayTopHeading';
        json: Document;
      } | null;
      subHeadingRichText?: {
        __typename?: 'BetterWayTopSubheading';
        json: Document;
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      trustpilotLogo?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      trustpilotLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      foregroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      backgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      backgroundImageMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      bottomWaveImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      calculatorPage?: {
        __typename?: 'CalculatorPage';
        slug?: string | null;
      } | null;
      trustpilotWidget?: {
        __typename: 'TrustpilotWidget';
        name?: string | null;
        url?: string | null;
        sys: { __typename?: 'Sys'; id: string };
      } | null;
      qrCode?: {
        __typename?: 'MobileAppQrCode';
        imageUrl?: string | null;
        description?: string | null;
      } | null;
      appStoreIconLink?: {
        __typename?: 'Link';
        url?: string | null;
        openInNewTab?: boolean | null;
        isInternal?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      playStoreIconLink?: {
        __typename?: 'Link';
        url?: string | null;
        openInNewTab?: boolean | null;
        isInternal?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ImageGridItemFragment = {
  __typename: 'ImageGridItem';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type ImageGridFragment = {
  __typename: 'ImageGrid';
  name?: string | null;
  heading?: string | null;
  gridColumnSize?: number | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: {
    __typename?: 'ImageGridContent';
    json: Document;
    links: {
      __typename?: 'ImageGridContentLinks';
      assets: {
        __typename?: 'ImageGridContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'ImageGridContentEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  gridItems?: {
    __typename?: 'ImageGridGridItemsCollection';
    items: Array<{
      __typename: 'ImageGridItem';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ImageGridQueryQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type ImageGridQueryQuery = {
  __typename?: 'Query';
  ImageGrid?: {
    __typename?: 'ImageGridCollection';
    items: Array<{
      __typename: 'ImageGrid';
      name?: string | null;
      heading?: string | null;
      gridColumnSize?: number | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: {
        __typename?: 'ImageGridContent';
        json: Document;
        links: {
          __typename?: 'ImageGridContentLinks';
          assets: {
            __typename?: 'ImageGridContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'ImageGridContentEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      gridItems?: {
        __typename?: 'ImageGridGridItemsCollection';
        items: Array<{
          __typename: 'ImageGridItem';
          name?: string | null;
          sys: { __typename?: 'Sys'; id: string };
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ImageGridItemQueryQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type ImageGridItemQueryQuery = {
  __typename?: 'Query';
  ImageGridItem?: {
    __typename?: 'ImageGridItemCollection';
    items: Array<{
      __typename: 'ImageGridItem';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type TextCardFragment = {
  __typename: 'TextCard';
  name?: string | null;
  contentAlignment?: string | null;
  backgroundColor?: string | null;
  textColor?: string | null;
  sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
  content?: {
    __typename?: 'TextCardContent';
    json: Document;
    links: {
      __typename?: 'TextCardContentLinks';
      entries: {
        __typename?: 'TextCardContentEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
  icon?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type TextCardQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type TextCardQueryQuery = {
  __typename?: 'Query';
  TextCard?: {
    __typename?: 'TextCardCollection';
    items: Array<{
      __typename: 'TextCard';
      name?: string | null;
      contentAlignment?: string | null;
      backgroundColor?: string | null;
      textColor?: string | null;
      sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null };
      content?: {
        __typename?: 'TextCardContent';
        json: Document;
        links: {
          __typename?: 'TextCardContentLinks';
          entries: {
            __typename?: 'TextCardContentEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      icon?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type InfoCardsFragment = {
  __typename: 'InfoCards';
  name?: string | null;
  header?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  cards?: {
    __typename?: 'InfoCardsCardsCollection';
    items: Array<{
      __typename: 'TextCard';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type InfoCardsQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type InfoCardsQueryQuery = {
  __typename?: 'Query';
  InfoCards?: {
    __typename?: 'InfoCardsCollection';
    items: Array<{
      __typename: 'InfoCards';
      name?: string | null;
      header?: string | null;
      backgroundColor?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      cards?: {
        __typename?: 'InfoCardsCardsCollection';
        items: Array<{
          __typename: 'TextCard';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type LeadershipTeamFragment = {
  __typename?: 'LeadershipTeam';
  name?: string | null;
  title?: string | null;
  description?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  teamMembers?: {
    __typename?: 'LeadershipTeamTeamMembersCollection';
    items: Array<{
      __typename?: 'LeadershipTeamEntry';
      name?: string | null;
      jobTitle?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type LeadershipTeamQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type LeadershipTeamQueryQuery = {
  __typename?: 'Query';
  LeadershipTeam?: {
    __typename?: 'LeadershipTeam';
    name?: string | null;
    title?: string | null;
    description?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    teamMembers?: {
      __typename?: 'LeadershipTeamTeamMembersCollection';
      items: Array<{
        __typename?: 'LeadershipTeamEntry';
        name?: string | null;
        jobTitle?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type LinkGroupFragment = {
  __typename: 'LinkGroup';
  name?: string | null;
  label?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  linksCollection?: {
    __typename?: 'LinkGroupLinksCollection';
    items: Array<{
      __typename?: 'Link';
      url?: string | null;
      name?: string | null;
      gaTrackName?: string | null;
      label?: string | null;
    } | null>;
  } | null;
};

export type LinkGroupQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type LinkGroupQueryQuery = {
  __typename?: 'Query';
  LinkGroup?: {
    __typename: 'LinkGroup';
    name?: string | null;
    label?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    linksCollection?: {
      __typename?: 'LinkGroupLinksCollection';
      items: Array<{
        __typename?: 'Link';
        url?: string | null;
        name?: string | null;
        gaTrackName?: string | null;
        label?: string | null;
      } | null>;
    } | null;
  } | null;
};

export type LinkFragment = {
  __typename?: 'Link';
  name?: string | null;
  url?: string | null;
  isInternal?: boolean | null;
  label?: string | null;
  ariaLabel?: string | null;
  openInNewTab?: boolean | null;
  gaTrackName?: string | null;
  isDisplayedWhenLoggedIn?: boolean | null;
  color?: string | null;
  variant?: string | null;
  underline?: string | null;
  align?: string | null;
  noFollow?: boolean | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type LinkQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type LinkQueryQuery = {
  __typename?: 'Query';
  Link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type ListOfCountriesFragment = {
  __typename: 'ListOfCountries';
  name?: string | null;
  header?: string | null;
  subheader?: string | null;
  buttonLabelExpandedList?: string | null;
  buttonLabelHiddenList?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  countriesCollection?: {
    __typename?: 'ListOfCountriesCountriesCollection';
    items: Array<{
      __typename?: 'Country';
      name?: string | null;
      code?: string | null;
      slug?: string | null;
    } | null>;
  } | null;
};

export type ListOfCountriesQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type ListOfCountriesQueryQuery = {
  __typename?: 'Query';
  ListOfCountries?: {
    __typename?: 'ListOfCountriesCollection';
    items: Array<{
      __typename: 'ListOfCountries';
      name?: string | null;
      header?: string | null;
      subheader?: string | null;
      buttonLabelExpandedList?: string | null;
      buttonLabelHiddenList?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      countriesCollection?: {
        __typename?: 'ListOfCountriesCountriesCollection';
        items: Array<{
          __typename?: 'Country';
          name?: string | null;
          code?: string | null;
          slug?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type MessagesFragment = {
  __typename?: 'Messages';
  chooseYourRegion?: string | null;
  countries?: string | null;
  international?: string | null;
  all?: string | null;
};

export type FaqTopicsModuleFragment = {
  __typename?: 'FaqTopicsModule';
  name?: string | null;
  title?: string | null;
  backgroundColor?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  nestedAccordionItems?: {
    __typename?: 'FaqTopicsModuleTopicsCollection';
    items: Array<{
      __typename: 'FaqTopicsModuleItems';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  popularQuestions?: {
    __typename?: 'FaqPopularQuestions';
    name?: string | null;
    title?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    questions?: {
      __typename?: 'FaqPopularQuestionsQuestionsCollection';
      items: Array<{
        __typename?: 'FaqItem';
        name?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        body?: {
          __typename?: 'FaqItemBody';
          json: Document;
          links: {
            __typename?: 'FaqItemBodyLinks';
            assets: {
              __typename?: 'FaqItemBodyAssets';
              block: Array<{
                __typename?: 'Asset';
                contentType?: string | null;
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
                sys: { __typename?: 'Sys'; id: string };
              } | null>;
            };
            entries: {
              __typename?: 'FaqItemBodyEntries';
              block: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSectionV2';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              inline: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                    text?: {
                      __typename?: 'TextWithColor';
                      name?: string | null;
                      inline?: boolean | null;
                      color?: string | null;
                      backgroundColor?: string | null;
                      align?: string | null;
                      text?: {
                        __typename?: 'TextWithColorText';
                        json: Document;
                      } | null;
                    } | null;
                    icon?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSectionV2';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
              hyperlink: Array<
                | {
                    __typename?: 'Accordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AccordionItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'AppBannerSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BetterWayTop';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'BodyText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Calculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CalculatorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Card';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Cards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrency';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexCurrencyCorridor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CexSearch';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTable';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableCell';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ComparisonTableRow';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ContainedBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Correspondents';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'CountriesGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Country';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ExchangeCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqHero';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqModuleItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqPopularQuestions';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopic';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FaqTopicsModuleItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlag';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FeatureFlags';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Footer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FooterNavigationGroupExtras';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'FullWidthBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'GenericErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroSlot';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'HeroTextVariation';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'IconWithText';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Image';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGrid';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ImageGridItem';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'InfoCards';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutFooter';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LayoutHeader';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeam';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LeadershipTeamEntry';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'LegalMessaging';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Link';
                    name?: string | null;
                    url?: string | null;
                    isInternal?: boolean | null;
                    label?: string | null;
                    ariaLabel?: string | null;
                    openInNewTab?: boolean | null;
                    gaTrackName?: string | null;
                    isDisplayedWhenLoggedIn?: boolean | null;
                    color?: string | null;
                    variant?: string | null;
                    underline?: string | null;
                    align?: string | null;
                    noFollow?: boolean | null;
                    sys: { __typename?: 'Sys'; id: string };
                    image?: {
                      __typename?: 'Asset';
                      url?: string | null;
                      title?: string | null;
                      width?: number | null;
                      height?: number | null;
                      alt?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'LinkGroup';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ListOfCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Messages';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppLeadingLinks';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'MobileAppQrCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordion';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionItems';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NestedAccordionTitleAndDescription';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundContent';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'NotFoundErrorPage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Page';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PageSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PayoutMethod';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Person';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PhotoCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PopularCountries';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PricingCalculator';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoApplicability';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromoMessage';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBanner';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'PromotionalBannerDisclaimer';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Redirect';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RegionCode';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'ReviewsTimeDictionary';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'RobotsConfiguration';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Search';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SendToCountryModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoContactPoint';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoOrganizationStructuredData';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'SeoSameAs';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Sitemap';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Step';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'StepByStep';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextCard';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextMeTheAppModule';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextSection';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename: 'TextSectionV2';
                    name?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TextWithColor';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'TrustpilotWidget';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'Video';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WebForm';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChoose';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | {
                    __typename?: 'WhyChooseReason';
                    sys: { __typename?: 'Sys'; id: string };
                  }
                | null
              >;
            };
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type FaqTopicsModuleQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqTopicsModuleQueryQuery = {
  __typename?: 'Query';
  FaqTopicsModule?: {
    __typename?: 'FaqTopicsModuleCollection';
    items: Array<{
      __typename?: 'FaqTopicsModule';
      name?: string | null;
      title?: string | null;
      backgroundColor?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      nestedAccordionItems?: {
        __typename?: 'FaqTopicsModuleTopicsCollection';
        items: Array<{
          __typename: 'FaqTopicsModuleItems';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      popularQuestions?: {
        __typename?: 'FaqPopularQuestions';
        name?: string | null;
        title?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        questions?: {
          __typename?: 'FaqPopularQuestionsQuestionsCollection';
          items: Array<{
            __typename?: 'FaqItem';
            name?: string | null;
            title?: string | null;
            sys: { __typename?: 'Sys'; id: string };
            body?: {
              __typename?: 'FaqItemBody';
              json: Document;
              links: {
                __typename?: 'FaqItemBodyLinks';
                assets: {
                  __typename?: 'FaqItemBodyAssets';
                  block: Array<{
                    __typename?: 'Asset';
                    contentType?: string | null;
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                    sys: { __typename?: 'Sys'; id: string };
                  } | null>;
                };
                entries: {
                  __typename?: 'FaqItemBodyEntries';
                  block: Array<
                    | {
                        __typename?: 'Accordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AccordionItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBannerSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContainedBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountriesGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExchangeCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopic';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlags';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroTextVariation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGridItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InfoCards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeam';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeamEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'LinkGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppQrCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhotoCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSectionV2';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TrustpilotWidget';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  inline: Array<
                    | {
                        __typename?: 'Accordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AccordionItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBannerSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContainedBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountriesGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExchangeCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopic';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlags';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroTextVariation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                        text?: {
                          __typename?: 'TextWithColor';
                          name?: string | null;
                          inline?: boolean | null;
                          color?: string | null;
                          backgroundColor?: string | null;
                          align?: string | null;
                          text?: {
                            __typename?: 'TextWithColorText';
                            json: Document;
                          } | null;
                        } | null;
                        icon?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGridItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InfoCards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeam';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeamEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'LinkGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppQrCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhotoCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSectionV2';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TrustpilotWidget';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                  hyperlink: Array<
                    | {
                        __typename?: 'Accordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AccordionItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'AppBannerSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BetterWayTop';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'BodyText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Calculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CalculatorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Card';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Cards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrency';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexCurrencyCorridor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CexSearch';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTable';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableCell';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ComparisonTableRow';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ContainedBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Correspondents';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'CountriesGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Country';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ExchangeCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqHero';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqModuleItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqPopularQuestions';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopic';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FaqTopicsModuleItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlag';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FeatureFlags';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Footer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FooterNavigationGroupExtras';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'FullWidthBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'GenericErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroSlot';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'HeroTextVariation';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'IconWithText';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Image';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGrid';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ImageGridItem';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'InfoCards';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutFooter';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LayoutHeader';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeam';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LeadershipTeamEntry';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'LegalMessaging';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Link';
                        name?: string | null;
                        url?: string | null;
                        isInternal?: boolean | null;
                        label?: string | null;
                        ariaLabel?: string | null;
                        openInNewTab?: boolean | null;
                        gaTrackName?: string | null;
                        isDisplayedWhenLoggedIn?: boolean | null;
                        color?: string | null;
                        variant?: string | null;
                        underline?: string | null;
                        align?: string | null;
                        noFollow?: boolean | null;
                        sys: { __typename?: 'Sys'; id: string };
                        image?: {
                          __typename?: 'Asset';
                          url?: string | null;
                          title?: string | null;
                          width?: number | null;
                          height?: number | null;
                          alt?: string | null;
                        } | null;
                      }
                    | {
                        __typename?: 'LinkGroup';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ListOfCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Messages';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppLeadingLinks';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'MobileAppQrCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordion';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionItems';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NestedAccordionTitleAndDescription';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundContent';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'NotFoundErrorPage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Page';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PageSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PayoutMethod';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Person';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PhotoCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PopularCountries';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PricingCalculator';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoApplicability';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromoMessage';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBanner';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'PromotionalBannerDisclaimer';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Redirect';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RegionCode';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'ReviewsTimeDictionary';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'RobotsConfiguration';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Search';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SendToCountryModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoContactPoint';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoOrganizationStructuredData';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'SeoSameAs';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Sitemap';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Step';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'StepByStep';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextCard';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextMeTheAppModule';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextSection';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename: 'TextSectionV2';
                        name?: string | null;
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TextWithColor';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'TrustpilotWidget';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'Video';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WebForm';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChoose';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | {
                        __typename?: 'WhyChooseReason';
                        sys: { __typename?: 'Sys'; id: string };
                      }
                    | null
                  >;
                };
              };
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type FaqTopicsModuleItemsFragment = {
  __typename: 'FaqTopicsModuleItems';
  title?: string | null;
  description?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  icon?: { __typename?: 'Asset'; url?: string | null } | null;
  subtitles?: {
    __typename?: 'FaqTopicsModuleItemsSubtitleCollection';
    items: Array<{
      __typename: 'FaqTitleAndDescription';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  nestedAccordionLinks?: {
    __typename?: 'FaqTopicsModuleItemsTopicLinksCollection';
    items: Array<{
      __typename: 'Page';
      name?: string | null;
      slug?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type FaqTopicsModuleItemsQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type FaqTopicsModuleItemsQueryQuery = {
  __typename?: 'Query';
  FaqTopicsModuleItems?: {
    __typename?: 'FaqTopicsModuleItemsCollection';
    items: Array<{
      __typename: 'FaqTopicsModuleItems';
      title?: string | null;
      description?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      icon?: { __typename?: 'Asset'; url?: string | null } | null;
      subtitles?: {
        __typename?: 'FaqTopicsModuleItemsSubtitleCollection';
        items: Array<{
          __typename: 'FaqTitleAndDescription';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      nestedAccordionLinks?: {
        __typename?: 'FaqTopicsModuleItemsTopicLinksCollection';
        items: Array<{
          __typename: 'Page';
          name?: string | null;
          slug?: string | null;
          title?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type NotFoundContentFragment = {
  __typename: 'NotFoundContent';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  message?: { __typename?: 'NotFoundContentMessage'; json: Document } | null;
  buttonsCollection?: {
    __typename?: 'NotFoundContentButtonsCollection';
    items: Array<{
      __typename?: 'Link';
      name?: string | null;
      url?: string | null;
      isInternal?: boolean | null;
      label?: string | null;
      ariaLabel?: string | null;
      openInNewTab?: boolean | null;
      gaTrackName?: string | null;
      isDisplayedWhenLoggedIn?: boolean | null;
      color?: string | null;
      variant?: string | null;
      underline?: string | null;
      align?: string | null;
      noFollow?: boolean | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type NotFoundContentQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type NotFoundContentQueryQuery = {
  __typename?: 'Query';
  NotFoundContent?: {
    __typename?: 'NotFoundContentCollection';
    items: Array<{
      __typename: 'NotFoundContent';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      message?: {
        __typename?: 'NotFoundContentMessage';
        json: Document;
      } | null;
      buttonsCollection?: {
        __typename?: 'NotFoundContentButtonsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ReviewsCardFragment = {
  __typename: 'ReviewsCard';
  name?: string | null;
  title?: string | null;
  description?: string | null;
  ratings?: number | null;
  sys: { __typename?: 'Sys'; id: string };
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  country?: {
    __typename?: 'Country';
    name?: string | null;
    code?: string | null;
  } | null;
};

export type ReviewsSectionFragment = {
  __typename?: 'ReviewsSection';
  name?: string | null;
  header?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  reviewsCardCollection?: {
    __typename?: 'ReviewsSectionReviewsCardCollection';
    items: Array<{
      __typename: 'ReviewsCard';
      name?: string | null;
      title?: string | null;
      description?: string | null;
      ratings?: number | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      country?: {
        __typename?: 'Country';
        name?: string | null;
        code?: string | null;
      } | null;
    } | null>;
  } | null;
  trustpilotWidget?: {
    __typename: 'TrustpilotWidget';
    name?: string | null;
    url?: string | null;
    sys: { __typename?: 'Sys'; id: string };
  } | null;
};

export type ReviewsSectionQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type ReviewsSectionQueryQuery = {
  __typename?: 'Query';
  ReviewsSection?: {
    __typename?: 'ReviewsSectionCollection';
    items: Array<{
      __typename?: 'ReviewsSection';
      name?: string | null;
      header?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      backgroundImage?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      reviewsCardCollection?: {
        __typename?: 'ReviewsSectionReviewsCardCollection';
        items: Array<{
          __typename: 'ReviewsCard';
          name?: string | null;
          title?: string | null;
          description?: string | null;
          ratings?: number | null;
          sys: { __typename?: 'Sys'; id: string };
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
          country?: {
            __typename?: 'Country';
            name?: string | null;
            code?: string | null;
          } | null;
        } | null>;
      } | null;
      trustpilotWidget?: {
        __typename: 'TrustpilotWidget';
        name?: string | null;
        url?: string | null;
        sys: { __typename?: 'Sys'; id: string };
      } | null;
    } | null>;
  } | null;
};

export type ReviewsCardQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type ReviewsCardQueryQuery = {
  __typename?: 'Query';
  ReviewsCardQuery?: {
    __typename?: 'ReviewsCardCollection';
    items: Array<{
      __typename: 'ReviewsCard';
      name?: string | null;
      title?: string | null;
      description?: string | null;
      ratings?: number | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      country?: {
        __typename?: 'Country';
        name?: string | null;
        code?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ReviewsCardByReceiveCountryQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  receiveCountry: Scalars['String'];
}>;

export type ReviewsCardByReceiveCountryQuery = {
  __typename?: 'Query';
  ReviewsCardByReceiveCountry?: {
    __typename?: 'ReviewsCardCollection';
    items: Array<{
      __typename: 'ReviewsCard';
      name?: string | null;
      title?: string | null;
      description?: string | null;
      ratings?: number | null;
      sys: { __typename?: 'Sys'; id: string };
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      country?: {
        __typename?: 'Country';
        name?: string | null;
        code?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type EmbeddedImageFragment = {
  __typename?: 'Image';
  name?: string | null;
  align?: string | null;
  position?: string | null;
  width?: number | null;
  height?: number | null;
  asset?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type IconWithTextFragment = {
  __typename?: 'IconWithText';
  name?: string | null;
  text?: {
    __typename?: 'TextWithColor';
    name?: string | null;
    inline?: boolean | null;
    color?: string | null;
    backgroundColor?: string | null;
    align?: string | null;
    text?: { __typename?: 'TextWithColorText'; json: Document } | null;
  } | null;
  icon?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type SitemapFragment = {
  __typename: 'Sitemap';
  name?: string | null;
  sys: { __typename?: 'Sys'; id: string };
};

export type SitemapQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type SitemapQueryQuery = {
  __typename?: 'Query';
  Sitemap?: {
    __typename: 'Sitemap';
    name?: string | null;
    sys: { __typename?: 'Sys'; id: string };
  } | null;
};

export type StepFragment = {
  __typename?: 'Step';
  name?: string | null;
  header?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: { __typename?: 'StepDescription'; json: Document } | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type StepByStepFragment = {
  __typename?: 'StepByStep';
  name?: string | null;
  header?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  stepsCollection?: {
    __typename?: 'StepByStepStepsCollection';
    items: Array<{
      __typename: 'Step';
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
  link?: {
    __typename?: 'Link';
    name?: string | null;
    url?: string | null;
    isInternal?: boolean | null;
    label?: string | null;
    ariaLabel?: string | null;
    openInNewTab?: boolean | null;
    gaTrackName?: string | null;
    isDisplayedWhenLoggedIn?: boolean | null;
    color?: string | null;
    variant?: string | null;
    underline?: string | null;
    align?: string | null;
    noFollow?: boolean | null;
    image?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type StepByStepQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type StepByStepQueryQuery = {
  __typename?: 'Query';
  StepByStep?: {
    __typename?: 'StepByStepCollection';
    items: Array<{
      __typename?: 'StepByStep';
      name?: string | null;
      header?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      stepsCollection?: {
        __typename?: 'StepByStepStepsCollection';
        items: Array<{
          __typename: 'Step';
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      } | null;
      link?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type StepQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type StepQueryQuery = {
  __typename?: 'Query';
  Step?: {
    __typename?: 'StepCollection';
    items: Array<{
      __typename?: 'Step';
      name?: string | null;
      header?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: { __typename?: 'StepDescription'; json: Document } | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type TextSectionV2Fragment = {
  __typename: 'TextSectionV2';
  name?: string | null;
  maxWidth?: string | null;
  textColumnSize?: number | null;
  imageColumnSize?: number | null;
  backgroundColor?: string | null;
  imagePosition?: string | null;
  imagePositionMobile?: string | null;
  imageAlignment?: string | null;
  columnAlignment?: string | null;
  contentAlign?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: {
    __typename?: 'TextSectionV2Content';
    json: Document;
    links: {
      __typename?: 'TextSectionV2ContentLinks';
      assets: {
        __typename?: 'TextSectionV2ContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'TextSectionV2ContentEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              name?: string | null;
              inline?: boolean | null;
              color?: string | null;
              backgroundColor?: string | null;
              align?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColorText';
                json: Document;
              } | null;
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSectionV2';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  imageCollection?: {
    __typename?: 'AssetCollection';
    items: Array<{
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null>;
  } | null;
  imageMobile?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type TextSectionV2QueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type TextSectionV2QueryQuery = {
  __typename?: 'Query';
  TextSectionV2?: {
    __typename?: 'TextSectionV2Collection';
    items: Array<{
      __typename: 'TextSectionV2';
      name?: string | null;
      maxWidth?: string | null;
      textColumnSize?: number | null;
      imageColumnSize?: number | null;
      backgroundColor?: string | null;
      imagePosition?: string | null;
      imagePositionMobile?: string | null;
      imageAlignment?: string | null;
      columnAlignment?: string | null;
      contentAlign?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: {
        __typename?: 'TextSectionV2Content';
        json: Document;
        links: {
          __typename?: 'TextSectionV2ContentLinks';
          assets: {
            __typename?: 'TextSectionV2ContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'TextSectionV2ContentEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  name?: string | null;
                  inline?: boolean | null;
                  color?: string | null;
                  backgroundColor?: string | null;
                  align?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColorText';
                    json: Document;
                  } | null;
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSectionV2';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      imageCollection?: {
        __typename?: 'AssetCollection';
        items: Array<{
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null>;
      } | null;
      imageMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type TextSectionFragment = {
  __typename: 'TextSection';
  name?: string | null;
  maxWidth?: string | null;
  backgroundColor?: string | null;
  isOrderReversed?: boolean | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: {
    __typename?: 'TextSectionContent';
    json: Document;
    links: {
      __typename?: 'TextSectionContentLinks';
      assets: {
        __typename?: 'TextSectionContentAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'TextSectionContentEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSection';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  imageCollection?: {
    __typename?: 'AssetCollection';
    items: Array<{
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null>;
  } | null;
};

export type TextSectionQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  sendCountryCode?: InputMaybe<Scalars['String']>;
  receiveCountryCode?: InputMaybe<Scalars['String']>;
}>;

export type TextSectionQueryQuery = {
  __typename?: 'Query';
  TextSection?: {
    __typename?: 'TextSectionCollection';
    items: Array<{
      __typename: 'TextSection';
      name?: string | null;
      maxWidth?: string | null;
      backgroundColor?: string | null;
      isOrderReversed?: boolean | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: {
        __typename?: 'TextSectionContent';
        json: Document;
        links: {
          __typename?: 'TextSectionContentLinks';
          assets: {
            __typename?: 'TextSectionContentAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'TextSectionContentEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSection';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      imageCollection?: {
        __typename?: 'AssetCollection';
        items: Array<{
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type TextWithColorFragment = {
  __typename?: 'TextWithColor';
  name?: string | null;
  inline?: boolean | null;
  color?: string | null;
  backgroundColor?: string | null;
  align?: string | null;
  text?: { __typename?: 'TextWithColorText'; json: Document } | null;
};

export type TrustpilotWidgetFragment = {
  __typename: 'TrustpilotWidget';
  name?: string | null;
  url?: string | null;
  sys: { __typename?: 'Sys'; id: string };
};

export type TrustpilotWidgetQueryQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type TrustpilotWidgetQueryQuery = {
  __typename?: 'Query';
  TrustpilotWidget?: {
    __typename?: 'TrustpilotWidgetCollection';
    items: Array<{
      __typename: 'TrustpilotWidget';
      name?: string | null;
      url?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type VideoFragment = {
  __typename: 'Video';
  name?: string | null;
  videoId?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  content?: { __typename?: 'VideoContent'; json: Document } | null;
  backgroundImage?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
};

export type VideoQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type VideoQueryQuery = {
  __typename?: 'Query';
  Video?: {
    __typename: 'Video';
    name?: string | null;
    videoId?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    content?: { __typename?: 'VideoContent'; json: Document } | null;
    backgroundImage?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type WebFormFragment = {
  __typename: 'WebForm';
  name?: string | null;
  header?: string | null;
  disclaimer?: string | null;
  submitButtonLabel?: string | null;
  jsonSchema?: Document | null;
  uiSchema?: Document | null;
  sys: { __typename?: 'Sys'; id: string };
  description?: {
    __typename?: 'WebFormDescription';
    json: Document;
    links: {
      __typename?: 'WebFormDescriptionLinks';
      assets: {
        __typename?: 'WebFormDescriptionAssets';
        block: Array<{
          __typename?: 'Asset';
          contentType?: string | null;
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
          sys: { __typename?: 'Sys'; id: string };
        } | null>;
      };
      entries: {
        __typename?: 'WebFormDescriptionEntries';
        block: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        inline: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              text?: {
                __typename?: 'TextWithColor';
                name?: string | null;
                inline?: boolean | null;
                color?: string | null;
                backgroundColor?: string | null;
                align?: string | null;
                text?: {
                  __typename?: 'TextWithColorText';
                  json: Document;
                } | null;
              } | null;
              icon?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
        hyperlink: Array<
          | {
              __typename?: 'Accordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CexSearch';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'ImageGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'InfoCards';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              sys: { __typename?: 'Sys'; id: string };
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'PhotoCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'SeoSameAs';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSectionV2';
              name?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename?: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename?: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename?: 'WhyChoose';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename?: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      };
    };
  } | null;
  successPage?: { __typename?: 'Page'; slug?: string | null } | null;
};

export type WebFormQueryQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type WebFormQueryQuery = {
  __typename?: 'Query';
  WebForm?: {
    __typename?: 'WebFormCollection';
    items: Array<{
      __typename: 'WebForm';
      name?: string | null;
      header?: string | null;
      disclaimer?: string | null;
      submitButtonLabel?: string | null;
      jsonSchema?: Document | null;
      uiSchema?: Document | null;
      sys: { __typename?: 'Sys'; id: string };
      description?: {
        __typename?: 'WebFormDescription';
        json: Document;
        links: {
          __typename?: 'WebFormDescriptionLinks';
          assets: {
            __typename?: 'WebFormDescriptionAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'WebFormDescriptionEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSectionV2';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
      successPage?: { __typename?: 'Page'; slug?: string | null } | null;
    } | null>;
  } | null;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countryCollection?: {
    __typename?: 'CountryCollection';
    items: Array<{
      __typename?: 'Country';
      code?: string | null;
      slug?: string | null;
      name?: string | null;
      calculatorReceiveCountry?: {
        __typename?: 'Country';
        code?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CountryArticlesAndPrepositionsQueryVariables = Exact<{
  countryCodes?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  locale: Scalars['String'];
  preview: Scalars['Boolean'];
}>;

export type CountryArticlesAndPrepositionsQuery = {
  __typename?: 'Query';
  countryCollection?: {
    __typename?: 'CountryCollection';
    items: Array<{
      __typename?: 'Country';
      name?: string | null;
      slug?: string | null;
      code?: string | null;
      article?: string | null;
      prepositionFrom?: string | null;
      prepositionTo?: string | null;
      prepositionIn?: string | null;
    } | null>;
  } | null;
};

export type SearchFaqItemsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type SearchFaqItemsQuery = {
  __typename?: 'Query';
  faqItemCollection?: {
    __typename?: 'FaqItemCollection';
    items: Array<{
      __typename?: 'FaqItem';
      name?: string | null;
      title?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      body?: {
        __typename?: 'FaqItemBody';
        json: Document;
        links: {
          __typename?: 'FaqItemBodyLinks';
          assets: {
            __typename?: 'FaqItemBodyAssets';
            block: Array<{
              __typename?: 'Asset';
              contentType?: string | null;
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
              sys: { __typename?: 'Sys'; id: string };
            } | null>;
          };
          entries: {
            __typename?: 'FaqItemBodyEntries';
            block: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            inline: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                  text?: {
                    __typename?: 'TextWithColor';
                    name?: string | null;
                    inline?: boolean | null;
                    color?: string | null;
                    backgroundColor?: string | null;
                    align?: string | null;
                    text?: {
                      __typename?: 'TextWithColorText';
                      json: Document;
                    } | null;
                  } | null;
                  icon?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSectionV2';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
            hyperlink: Array<
              | {
                  __typename?: 'Accordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AccordionItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'AppBannerSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BetterWayTop';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'BodyText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Calculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CalculatorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Card'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'Cards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrency';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexCurrencyCorridor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CexSearch';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTable';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableCell';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ComparisonTableRow';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ContainedBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Correspondents';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CountriesGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Country';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ExchangeCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqHero';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqModuleItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqPopularQuestions';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FaqTopicsModuleItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlag';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FeatureFlags';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Footer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FooterNavigationGroupExtras';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'FullWidthBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'GenericErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroSlot';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HeroTextVariation';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'IconWithText';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Image';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGrid';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ImageGridItem';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'InfoCards';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutFooter';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LayoutHeader';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeam';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LeadershipTeamEntry';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'LegalMessaging';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Link';
                  name?: string | null;
                  url?: string | null;
                  isInternal?: boolean | null;
                  label?: string | null;
                  ariaLabel?: string | null;
                  openInNewTab?: boolean | null;
                  gaTrackName?: string | null;
                  isDisplayedWhenLoggedIn?: boolean | null;
                  color?: string | null;
                  variant?: string | null;
                  underline?: string | null;
                  align?: string | null;
                  noFollow?: boolean | null;
                  sys: { __typename?: 'Sys'; id: string };
                  image?: {
                    __typename?: 'Asset';
                    url?: string | null;
                    title?: string | null;
                    width?: number | null;
                    height?: number | null;
                    alt?: string | null;
                  } | null;
                }
              | {
                  __typename?: 'LinkGroup';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ListOfCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Messages';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppLeadingLinks';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'MobileAppQrCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionItems';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NestedAccordionTitleAndDescription';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundContent';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'NotFoundErrorPage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'PageSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PayoutMethod';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Person';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PhotoCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PopularCountries';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PricingCalculator';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoApplicability';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromoMessage';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBanner';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'PromotionalBannerDisclaimer';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Redirect';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RegionCode';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'ReviewsTimeDictionary';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'RobotsConfiguration';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Search';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SendToCountryModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoContactPoint';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoOrganizationStructuredData';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'SeoSameAs';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Sitemap';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | { __typename?: 'Step'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename?: 'StepByStep';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextCard';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextMeTheAppModule';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextSection';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename: 'TextSectionV2';
                  name?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextWithColor';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TrustpilotWidget';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'Video';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WebForm';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChoose';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'WhyChooseReason';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type FeatureFlagsQueryVariables = Exact<{
  preview: Scalars['Boolean'];
}>;

export type FeatureFlagsQuery = {
  __typename?: 'Query';
  featureFlagCollection?: {
    __typename?: 'FeatureFlagCollection';
    items: Array<{
      __typename?: 'FeatureFlag';
      name?: string | null;
      value?: boolean | null;
    } | null>;
  } | null;
};

export type GenericErrorPageQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type GenericErrorPageQuery = {
  __typename?: 'Query';
  genericErrorPageCollection?: {
    __typename?: 'GenericErrorPageCollection';
    items: Array<{
      __typename?: 'GenericErrorPage';
      header?: string | null;
      name?: string | null;
      description?: {
        __typename?: 'GenericErrorPageDescription';
        json: Document;
      } | null;
      subDescription?: {
        __typename?: 'GenericErrorPageSubDescription';
        json: Document;
      } | null;
    } | null>;
  } | null;
};

export type SeoOrganizationStructuredDataFragment = {
  __typename?: 'SeoOrganizationStructuredData';
  addressCountry?: string | null;
  addressLocality?: string | null;
  description?: string | null;
  email?: string | null;
  name?: string | null;
  postalCode?: string | null;
  streetAddress?: string | null;
  url?: string | null;
  image?: {
    __typename?: 'Asset';
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
  } | null;
  sameAsCollection?: {
    __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
    items: Array<{ __typename?: 'SeoSameAs'; value?: string | null } | null>;
  } | null;
  contactPointCollection?: {
    __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
    items: Array<{
      __typename?: 'SeoContactPoint';
      telephone?: string | null;
      areaServed?: string | null;
      contactType?: string | null;
    } | null>;
  } | null;
};

export type OrganizationSchemaQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type OrganizationSchemaQuery = {
  __typename?: 'Query';
  seoOrganizationStructuredDataCollection?: {
    __typename?: 'SeoOrganizationStructuredDataCollection';
    items: Array<{
      __typename?: 'SeoOrganizationStructuredData';
      addressCountry?: string | null;
      addressLocality?: string | null;
      description?: string | null;
      email?: string | null;
      name?: string | null;
      postalCode?: string | null;
      streetAddress?: string | null;
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      sameAsCollection?: {
        __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
        items: Array<{
          __typename?: 'SeoSameAs';
          value?: string | null;
        } | null>;
      } | null;
      contactPointCollection?: {
        __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
        items: Array<{
          __typename?: 'SeoContactPoint';
          telephone?: string | null;
          areaServed?: string | null;
          contactType?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type PageFragment = {
  __typename: 'Page';
  name?: string | null;
  slug?: string | null;
  title?: string | null;
  description?: string | null;
  noIndex?: boolean | null;
  regions?: Array<string | null> | null;
  headerPosition?: string | null;
  headerTheme?: string | null;
  analyticsPageName?: string | null;
  analyticsPageType?: string | null;
  sys: { __typename?: 'Sys'; id: string };
  ogImage?: { __typename?: 'Asset'; url?: string | null } | null;
  modulesCollection?: {
    __typename?: 'PageModulesCollection';
    items: Array<
      | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'AccordionItem'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'AppBanner'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'AppBannerSection';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'BetterWayTop'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Calculator'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'CalculatorAppLeadingLinks';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'CalculatorPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Card'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'CexCurrency'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'CexCurrencyCorridor';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'CexSearch'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ComparisonTable';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ComparisonTableCell';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ComparisonTableRow';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ContainedBanner';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'Correspondents';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'CountriesGrid'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Country'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ExchangeCalculator';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'FaqModule'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'FaqModuleItem'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'FaqPopularQuestions';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'FaqSection'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'FaqTitleAndDescription';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'FaqTopicsModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FaqTopicsModuleItems';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'FeatureFlag'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'FeatureFlags'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'FooterNavigationGroup';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FooterNavigationGroupExtras';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'FullWidthBanner';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'GenericErrorPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'HeroTextVariation';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'IconWithText'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Image'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'ImageGrid'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'ImageGridItem'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'InfoCards'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'LayoutFooter'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'LayoutHeader'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'LeadershipTeam';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'LeadershipTeamEntry';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'LegalMessaging';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'LinkGroup'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ListOfCountries';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'MobileAppLeadingLinks';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'MobileAppQrCode';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'NestedAccordion';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'NestedAccordionItems';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'NestedAccordionTitleAndDescription';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'NotFoundContent';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'NotFoundErrorPage';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'PageSection'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'PayoutMethod'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'PhotoCard'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'PopularCountries';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PricingCalculator';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PromoApplicability';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'PromoMessage'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'PromotionalBanner';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'PromotionalBannerDisclaimer';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'RegionCode'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'ReviewsCard'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'ReviewsSection';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'ReviewsTimeDictionary';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'RobotsConfiguration';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Search'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'SendToCountryModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SeoContactPoint';
          sys: { __typename?: 'Sys'; id: string };
        }
      | {
          __typename: 'SeoOrganizationStructuredData';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'SeoSameAs'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'Step'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'StepByStep'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'TextMeTheAppModule';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'TextSection'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'TextSectionV2'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'TextWithColor'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'TrustpilotWidget';
          sys: { __typename?: 'Sys'; id: string };
        }
      | { __typename: 'Video'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename: 'WhyChoose'; sys: { __typename?: 'Sys'; id: string } }
      | {
          __typename: 'WhyChooseReason';
          sys: { __typename?: 'Sys'; id: string };
        }
      | null
    >;
  } | null;
  footer?: {
    __typename: 'LayoutFooter';
    name?: string | null;
    copyrightText?: string | null;
    sys: { __typename?: 'Sys'; id: string };
    socialIconsLinksCollection?: {
      __typename?: 'LayoutFooterSocialIconsLinksCollection';
      items: Array<{
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null>;
    } | null;
    navigationCollection?: {
      __typename?: 'LayoutFooterNavigationCollection';
      items: Array<{
        __typename?: 'FooterNavigationGroup';
        title?: string | null;
        linksCollection?: {
          __typename?: 'FooterNavigationGroupLinksCollection';
          items: Array<{
            __typename?: 'Link';
            name?: string | null;
            url?: string | null;
            isInternal?: boolean | null;
            label?: string | null;
            ariaLabel?: string | null;
            openInNewTab?: boolean | null;
            gaTrackName?: string | null;
            isDisplayedWhenLoggedIn?: boolean | null;
            color?: string | null;
            variant?: string | null;
            underline?: string | null;
            align?: string | null;
            noFollow?: boolean | null;
            image?: {
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null;
          } | null>;
        } | null;
        extras?: {
          __typename?: 'FooterNavigationGroupExtras';
          backgroundColor?: string | null;
          color?: string | null;
          title?: string | null;
        } | null;
      } | null>;
    } | null;
    footerLogo?: {
      __typename?: 'Asset';
      url?: string | null;
      title?: string | null;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
  } | null;
};

export type HomePageQueryVariables = Exact<{
  locale: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type HomePageQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename: 'Page';
      name?: string | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      noIndex?: boolean | null;
      regions?: Array<string | null> | null;
      headerPosition?: string | null;
      headerTheme?: string | null;
      analyticsPageName?: string | null;
      analyticsPageType?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      ogImage?: { __typename?: 'Asset'; url?: string | null } | null;
      modulesCollection?: {
        __typename?: 'PageModulesCollection';
        items: Array<
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'AppBanner'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'CexSearch'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'FaqModule'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'ImageGrid'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'InfoCards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'LinkGroup'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'PhotoCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'SeoSameAs'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'WhyChoose'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      } | null;
      footer?: {
        __typename: 'LayoutFooter';
        name?: string | null;
        copyrightText?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        socialIconsLinksCollection?: {
          __typename?: 'LayoutFooterSocialIconsLinksCollection';
          items: Array<{
            __typename?: 'Link';
            name?: string | null;
            url?: string | null;
            isInternal?: boolean | null;
            label?: string | null;
            ariaLabel?: string | null;
            openInNewTab?: boolean | null;
            gaTrackName?: string | null;
            isDisplayedWhenLoggedIn?: boolean | null;
            color?: string | null;
            variant?: string | null;
            underline?: string | null;
            align?: string | null;
            noFollow?: boolean | null;
            image?: {
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null;
          } | null>;
        } | null;
        navigationCollection?: {
          __typename?: 'LayoutFooterNavigationCollection';
          items: Array<{
            __typename?: 'FooterNavigationGroup';
            title?: string | null;
            linksCollection?: {
              __typename?: 'FooterNavigationGroupLinksCollection';
              items: Array<{
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              } | null>;
            } | null;
            extras?: {
              __typename?: 'FooterNavigationGroupExtras';
              backgroundColor?: string | null;
              color?: string | null;
              title?: string | null;
            } | null;
          } | null>;
        } | null;
        footerLogo?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type PageBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type PageBySlugQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename: 'Page';
      name?: string | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      noIndex?: boolean | null;
      regions?: Array<string | null> | null;
      headerPosition?: string | null;
      headerTheme?: string | null;
      analyticsPageName?: string | null;
      analyticsPageType?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      ogImage?: { __typename?: 'Asset'; url?: string | null } | null;
      modulesCollection?: {
        __typename?: 'PageModulesCollection';
        items: Array<
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'AppBanner'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AppBannerSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'BetterWayTop';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'BodyText'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Calculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CalculatorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Card'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Cards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CexCurrency';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CexCurrencyCorridor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'CexSearch'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ComparisonTable';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableCell';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ComparisonTableRow';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ContainedBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'Correspondents';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'CountriesGrid';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Country'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExchangeCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'FaqHero'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'FaqItem'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'FaqModule'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqModuleItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqPopularQuestions';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'FaqTopic'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqTopicsModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FaqTopicsModuleItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FeatureFlag';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FeatureFlags';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Footer'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FooterNavigationGroup';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FooterNavigationGroupExtras';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'FullWidthBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'GenericErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'HeroSlot'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'HeroTextVariation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'IconWithText';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Image'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'ImageGrid'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageGridItem';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'InfoCards'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LayoutFooter';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LayoutHeader';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LeadershipTeam';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LeadershipTeamEntry';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'LegalMessaging';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'LinkGroup'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ListOfCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Messages'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MobileAppLeadingLinks';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'MobileAppQrCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NestedAccordion';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NestedAccordionItems';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NestedAccordionTitleAndDescription';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundContent';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'NotFoundErrorPage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PayoutMethod';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'PhotoCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PopularCountries';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PricingCalculator';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoApplicability';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromoMessage';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBanner';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'PromotionalBannerDisclaimer';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Redirect'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RegionCode';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReviewsCard';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReviewsSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'ReviewsTimeDictionary';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'RobotsConfiguration';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Search'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SendToCountryModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoContactPoint';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'SeoOrganizationStructuredData';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'SeoSameAs'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Sitemap'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Step'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'StepByStep';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'TextCard'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'TextMeTheAppModule';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSection';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextSectionV2';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TextWithColor';
              sys: { __typename?: 'Sys'; id: string };
            }
          | {
              __typename: 'TrustpilotWidget';
              sys: { __typename?: 'Sys'; id: string };
            }
          | { __typename: 'Video'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'WebForm'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'WhyChoose'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'WhyChooseReason';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
        >;
      } | null;
      footer?: {
        __typename: 'LayoutFooter';
        name?: string | null;
        copyrightText?: string | null;
        sys: { __typename?: 'Sys'; id: string };
        socialIconsLinksCollection?: {
          __typename?: 'LayoutFooterSocialIconsLinksCollection';
          items: Array<{
            __typename?: 'Link';
            name?: string | null;
            url?: string | null;
            isInternal?: boolean | null;
            label?: string | null;
            ariaLabel?: string | null;
            openInNewTab?: boolean | null;
            gaTrackName?: string | null;
            isDisplayedWhenLoggedIn?: boolean | null;
            color?: string | null;
            variant?: string | null;
            underline?: string | null;
            align?: string | null;
            noFollow?: boolean | null;
            image?: {
              __typename?: 'Asset';
              url?: string | null;
              title?: string | null;
              width?: number | null;
              height?: number | null;
              alt?: string | null;
            } | null;
          } | null>;
        } | null;
        navigationCollection?: {
          __typename?: 'LayoutFooterNavigationCollection';
          items: Array<{
            __typename?: 'FooterNavigationGroup';
            title?: string | null;
            linksCollection?: {
              __typename?: 'FooterNavigationGroupLinksCollection';
              items: Array<{
                __typename?: 'Link';
                name?: string | null;
                url?: string | null;
                isInternal?: boolean | null;
                label?: string | null;
                ariaLabel?: string | null;
                openInNewTab?: boolean | null;
                gaTrackName?: string | null;
                isDisplayedWhenLoggedIn?: boolean | null;
                color?: string | null;
                variant?: string | null;
                underline?: string | null;
                align?: string | null;
                noFollow?: boolean | null;
                image?: {
                  __typename?: 'Asset';
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  alt?: string | null;
                } | null;
              } | null>;
            } | null;
            extras?: {
              __typename?: 'FooterNavigationGroupExtras';
              backgroundColor?: string | null;
              color?: string | null;
              title?: string | null;
            } | null;
          } | null>;
        } | null;
        footerLogo?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type PageDataQueryVariables = Exact<{
  locale: Scalars['String'];
  sendCountryCode: Scalars['String'];
  paths?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type PageDataQuery = {
  __typename?: 'Query';
  layoutHeaderCollection?: {
    __typename?: 'LayoutHeaderCollection';
    items: Array<{
      __typename?: 'LayoutHeader';
      name?: string | null;
      menuLabel?: string | null;
      welcome?: string | null;
      lastLogin?: string | null;
      myAccount?: string | null;
      notLoggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderNotLoggedInMenuItemsCollection';
        items: Array<
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename: 'LinkGroup';
              name?: string | null;
              label?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              linksCollection?: {
                __typename?: 'LinkGroupLinksCollection';
                items: Array<{
                  __typename?: 'Link';
                  url?: string | null;
                  name?: string | null;
                  gaTrackName?: string | null;
                  label?: string | null;
                } | null>;
              } | null;
            }
          | null
        >;
      } | null;
      loggedInMenuItemsCollection?: {
        __typename?: 'LayoutHeaderLoggedInMenuItemsCollection';
        items: Array<
          | {
              __typename?: 'Link';
              name?: string | null;
              url?: string | null;
              isInternal?: boolean | null;
              label?: string | null;
              ariaLabel?: string | null;
              openInNewTab?: boolean | null;
              gaTrackName?: string | null;
              isDisplayedWhenLoggedIn?: boolean | null;
              color?: string | null;
              variant?: string | null;
              underline?: string | null;
              align?: string | null;
              noFollow?: boolean | null;
              image?: {
                __typename?: 'Asset';
                url?: string | null;
                title?: string | null;
                width?: number | null;
                height?: number | null;
                alt?: string | null;
              } | null;
            }
          | {
              __typename: 'LinkGroup';
              name?: string | null;
              label?: string | null;
              sys: { __typename?: 'Sys'; id: string };
              linksCollection?: {
                __typename?: 'LinkGroupLinksCollection';
                items: Array<{
                  __typename?: 'Link';
                  url?: string | null;
                  name?: string | null;
                  gaTrackName?: string | null;
                  label?: string | null;
                } | null>;
              } | null;
            }
          | null
        >;
      } | null;
      userDropdownMenuItemsCollection?: {
        __typename?: 'LayoutHeaderUserDropdownMenuItemsCollection';
        items: Array<{
          __typename?: 'Link';
          name?: string | null;
          url?: string | null;
          isInternal?: boolean | null;
          label?: string | null;
          ariaLabel?: string | null;
          openInNewTab?: boolean | null;
          gaTrackName?: string | null;
          isDisplayedWhenLoggedIn?: boolean | null;
          color?: string | null;
          variant?: string | null;
          underline?: string | null;
          align?: string | null;
          noFollow?: boolean | null;
          image?: {
            __typename?: 'Asset';
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            alt?: string | null;
          } | null;
        } | null>;
      } | null;
      referFriendLink?: {
        __typename?: 'Link';
        name?: string | null;
        url?: string | null;
        isInternal?: boolean | null;
        label?: string | null;
        ariaLabel?: string | null;
        openInNewTab?: boolean | null;
        gaTrackName?: string | null;
        isDisplayedWhenLoggedIn?: boolean | null;
        color?: string | null;
        variant?: string | null;
        underline?: string | null;
        align?: string | null;
        noFollow?: boolean | null;
        image?: {
          __typename?: 'Asset';
          url?: string | null;
          title?: string | null;
          width?: number | null;
          height?: number | null;
          alt?: string | null;
        } | null;
      } | null;
      logoLight?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoLightMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDark?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      logoDarkMobile?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      mobileAppLeadingLinks?: {
        __typename?: 'MobileAppLeadingLinks';
        mobileAppButtonText?: string | null;
        qrCode?: {
          __typename?: 'MobileAppQrCode';
          imageUrl?: string | null;
          description?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
  payoutMethodCollection?: {
    __typename?: 'PayoutMethodCollection';
    items: Array<{
      __typename?: 'PayoutMethod';
      name?: string | null;
      payoutMethodId?: string | null;
    } | null>;
  } | null;
  messagesCollection?: {
    __typename?: 'MessagesCollection';
    items: Array<{
      __typename?: 'Messages';
      chooseYourRegion?: string | null;
      countries?: string | null;
      international?: string | null;
      all?: string | null;
    } | null>;
  } | null;
  legalMessagingCollection?: {
    __typename?: 'LegalMessagingCollection';
    items: Array<{
      __typename: 'LegalMessaging';
      name?: string | null;
      sys: { __typename?: 'Sys'; id: string };
      content?: { __typename?: 'LegalMessagingContent'; json: Document } | null;
    } | null>;
  } | null;
  breadcrumbs?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      heading?: string | null;
      slug?: string | null;
    } | null>;
  } | null;
  seoOrganizationStructuredDataCollection?: {
    __typename?: 'SeoOrganizationStructuredDataCollection';
    items: Array<{
      __typename?: 'SeoOrganizationStructuredData';
      addressCountry?: string | null;
      addressLocality?: string | null;
      description?: string | null;
      email?: string | null;
      name?: string | null;
      postalCode?: string | null;
      streetAddress?: string | null;
      url?: string | null;
      image?: {
        __typename?: 'Asset';
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        alt?: string | null;
      } | null;
      sameAsCollection?: {
        __typename?: 'SeoOrganizationStructuredDataSameAsCollection';
        items: Array<{
          __typename?: 'SeoSameAs';
          value?: string | null;
        } | null>;
      } | null;
      contactPointCollection?: {
        __typename?: 'SeoOrganizationStructuredDataContactPointCollection';
        items: Array<{
          __typename?: 'SeoContactPoint';
          telephone?: string | null;
          areaServed?: string | null;
          contactType?: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type CountryPagesSlugsQueryVariables = Exact<{
  locale: Scalars['String'];
  analyticsPageType: Scalars['String'];
}>;

export type CountryPagesSlugsQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{ __typename?: 'Page'; slug?: string | null } | null>;
  } | null;
};

export type PageSitemapLinkFragment = {
  __typename?: 'Page';
  slug?: string | null;
  title?: string | null;
  heading?: string | null;
  name?: string | null;
  analyticsPageType?: string | null;
  sys: { __typename?: 'Sys'; publishedAt?: string | null };
};

export type PagesSitemapLinksQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  excludedSlugs?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type PagesSitemapLinksQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      slug?: string | null;
      title?: string | null;
      heading?: string | null;
      name?: string | null;
      analyticsPageType?: string | null;
      sys: { __typename?: 'Sys'; publishedAt?: string | null };
    } | null>;
  } | null;
};

export type BlogPagesCardsQueryVariables = Exact<{
  analyticsPageType: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
  tags: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  shouldIncludeSearchOptions: Scalars['Boolean'];
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type BlogPagesCardsQuery = {
  __typename?: 'Query';
  searchOptions?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
    } | null>;
  } | null;
  cards?: {
    __typename?: 'PageCollection';
    total: number;
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
      publishedDate?: string | null;
      contentfulMetadata: {
        __typename?: 'ContentfulMetadata';
        tags: Array<{
          __typename?: 'ContentfulTag';
          id?: string | null;
          name?: string | null;
        } | null>;
      };
      banner?: {
        __typename?: 'Asset';
        title?: string | null;
        url?: string | null;
        alt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type BlogPagesTagsQueryVariables = Exact<{
  analyticsPageType: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type BlogPagesTagsQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      contentfulMetadata: {
        __typename?: 'ContentfulMetadata';
        tags: Array<{
          __typename?: 'ContentfulTag';
          id?: string | null;
          name?: string | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type RedirectsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type RedirectsQuery = {
  __typename?: 'Query';
  redirectCollection?: {
    __typename?: 'RedirectCollection';
    items: Array<{
      __typename?: 'Redirect';
      source?: string | null;
      destination?: string | null;
      permanent?: boolean | null;
      locale?: boolean | null;
    } | null>;
  } | null;
};

export type RegionCodeQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type RegionCodeQuery = {
  __typename?: 'Query';
  regionCodeCollection?: {
    __typename?: 'RegionCodeCollection';
    items: Array<{
      __typename?: 'RegionCode';
      name?: string | null;
      frCa?: boolean | null;
      esUs?: boolean | null;
      enGb?: boolean | null;
      enUs?: boolean | null;
      enNz?: boolean | null;
      enSe?: boolean | null;
      enMy?: boolean | null;
      enAu?: boolean | null;
      enCa?: boolean | null;
      daDk?: boolean | null;
      en?: boolean | null;
    } | null>;
  } | null;
};

export type RobotsConfigurationQueryVariables = Exact<{
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type RobotsConfigurationQuery = {
  __typename?: 'Query';
  robotsConfigurationCollection?: {
    __typename?: 'RobotsConfigurationCollection';
    items: Array<{
      __typename?: 'RobotsConfiguration';
      allow?: Array<string | null> | null;
      disallow?: Array<string | null> | null;
      userAgent?: string | null;
      blogSitemapUrl?: string | null;
    } | null>;
  } | null;
};

export type WebFormJsonSchemaQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type WebFormJsonSchemaQuery = {
  __typename?: 'Query';
  WebForm?: {
    __typename?: 'WebForm';
    name?: string | null;
    jsonSchema?: Document | null;
  } | null;
};

export type SearchFragment = {
  __typename: 'Search';
  name?: string | null;
  heading?: string | null;
  inputLabel?: string | null;
  resultListLabel?: string | null;
  suggestionListLabel?: string | null;
  suggestionListCollection?: {
    __typename?: 'SearchSuggestionListCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
      sys: { __typename?: 'Sys'; id: string };
    } | null>;
  } | null;
};

export type PagesWithTagsQueryVariables = Exact<{
  analyticsPageType: Scalars['String'];
  locale: Scalars['String'];
  preview?: InputMaybe<Scalars['Boolean']>;
}>;

export type PagesWithTagsQuery = {
  __typename?: 'Query';
  pageCollection?: {
    __typename?: 'PageCollection';
    items: Array<{
      __typename?: 'Page';
      name?: string | null;
      slug?: string | null;
      contentfulMetadata: {
        __typename?: 'ContentfulMetadata';
        tags: Array<{
          __typename?: 'ContentfulTag';
          name?: string | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export const Accordion = gql`
  fragment Accordion on Accordion {
    sys {
      id
    }
    name
    heading
    backgroundColor
    items: itemsCollection {
      items {
        sys {
          id
        }
        __typename
        name
        title
        content {
          json
        }
        contentBackgroundColor
      }
    }
  }
`;
export const Asset = gql`
  fragment Asset on Asset {
    title
    alt: description
    width
    height
  }
`;
export const CoreImage = gql`
  fragment CoreImage on Asset {
    ...Asset
    url
  }
  ${Asset}
`;
export const Link = gql`
  fragment Link on Link {
    name
    url
    isInternal
    label
    ariaLabel
    openInNewTab
    image {
      ...CoreImage
    }
    gaTrackName
    isDisplayedWhenLoggedIn
    color
    variant
    underline
    align
    noFollow
  }
  ${CoreImage}
`;
export const TextWithColor = gql`
  fragment TextWithColor on TextWithColor {
    name
    text {
      json
    }
    inline
    color
    backgroundColor
    align
  }
`;
export const ImageXs = gql`
  fragment ImageXS on Asset {
    ...Asset
    url(transform: { width: 160, format: WEBP, quality: 60 })
  }
  ${Asset}
`;
export const IconWithText = gql`
  fragment IconWithText on IconWithText {
    name
    text {
      ...TextWithColor
    }
    icon {
      ...ImageXS
    }
  }
  ${TextWithColor}
  ${ImageXs}
`;
export const AppBannerSection = gql`
  fragment AppBannerSection on AppBannerSection {
    sys {
      id
    }
    name
    __typename
    content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
            ... on AppBannerSection {
              __typename
              name
            }
          }
        }
      }
    }
    imageCollection {
      items {
        ...CoreImage
      }
    }
    imageMobile {
      ...CoreImage
    }
    linkDesktop {
      ...Link
    }
    appStoreIconLink {
      url
      openInNewTab
      isInternal
      image {
        ...CoreImage
      }
    }
    playStoreIconLink {
      url
      openInNewTab
      isInternal
      image {
        ...CoreImage
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
`;
export const AppBanner = gql`
  fragment AppBanner on AppBanner {
    sys {
      id
    }
    name
    __typename
    bannerDescription
    bannerTitle
    image {
      ...CoreImage
    }
    appStoreIconLink {
      url
      image {
        ...CoreImage
      }
    }
    playStoreIconLink {
      url
      image {
        ...CoreImage
      }
    }
  }
  ${CoreImage}
`;
export const EmbeddedImage = gql`
  fragment EmbeddedImage on Image {
    name
    align
    position
    asset {
      ...CoreImage
    }
    width
    height
  }
  ${CoreImage}
`;
export const BodyText = gql`
  fragment BodyText on BodyText {
    sys {
      id
    }
    name
    __typename
    bodyTextHeading: heading {
      json
    }
    bodyTextContent: content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...EmbeddedImage
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    bodyTextContentTwo: contentTwo {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...EmbeddedImage
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    headingAlign
    contentAlign
    listIndent
    paragraphIndent
    isPageSection
  }
  ${CoreImage}
  ${Link}
  ${EmbeddedImage}
  ${IconWithText}
`;
export const Card = gql`
  fragment Card on Card {
    sys {
      id
      publishedAt
    }
    name
    __typename
    payoutMethodId
    header
    description
    backgroundColor
    backgroundImage {
      ...Asset
      url(transform: { width: 1200 })
    }
    link {
      ...Link
    }
  }
  ${Asset}
  ${Link}
`;
export const Cards = gql`
  fragment Cards on Cards {
    sys {
      id
    }
    __typename
    name
    header
    description
    carouselText
    cardsCollection {
      items {
        ... on Card {
          sys {
            id
          }
          __typename
        }
      }
    }
    paymentText
    paymentIcons {
      title
      url
    }
  }
`;
export const ContainedBanner = gql`
  fragment ContainedBanner on ContainedBanner {
    sys {
      id
    }
    __typename
    name
    desktopBanner {
      ...CoreImage
    }
    mobileBanner {
      ...CoreImage
    }
    text {
      json
    }
    link {
      ...Link
    }
    textBodyColor
    textHeadingColor
    textAlign
    backgroundColor
  }
  ${CoreImage}
  ${Link}
`;
export const CountriesGrid = gql`
  fragment CountriesGrid on CountriesGrid {
    sys {
      id
      publishedAt
    }
    __typename
    name
    moneyTransfers
  }
`;
export const Search = gql`
  fragment Search on Search {
    __typename
    name
    heading
    inputLabel
    resultListLabel
    suggestionListLabel
    suggestionListCollection(limit: 5) {
      items {
        ... on Page {
          sys {
            id
          }
          name
          slug
        }
      }
    }
  }
`;
export const FaqHero = gql`
  fragment FaqHero on FaqHero {
    name
    sys {
      id
    }
    heading
    textColor
    primaryBackgroundColor
    secondaryBackgroundColor
    footerImage {
      ...CoreImage
    }
    search {
      ...Search
    }
    sendToText
    allCountries
    links: linksCollection {
      items {
        sys {
          id
        }
        name
        image {
          ...CoreImage
        }
        label
        url
      }
    }
  }
  ${CoreImage}
  ${Search}
`;
export const FaqModuleItem = gql`
  fragment FaqModuleItem on FaqModuleItem {
    sys {
      id
    }
    name
    __typename
    title
    description {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...EmbeddedImage
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${EmbeddedImage}
  ${IconWithText}
`;
export const FaqModule = gql`
  fragment FaqModule on FaqModule {
    sys {
      id
    }
    name
    __typename
    title
    subheading
    image {
      ...CoreImage
    }
    questions: questionsCollection {
      items {
        sys {
          id
        }
        name
        title
        description {
          json
        }
      }
    }
    ctaButton {
      ...Link
    }
  }
  ${CoreImage}
  ${Link}
`;
export const FaqTitleAndDescription = gql`
  fragment FaqTitleAndDescription on FaqTitleAndDescription {
    sys {
      id
      publishedAt
    }
    __typename
    name
    title
    description
    content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
            ... on TextSectionV2 {
              __typename
              name
            }
          }
        }
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
`;
export const Country = gql`
  fragment Country on Country {
    sys {
      id
    }
    name
    code
    prepositionFrom
    prepositionIn
    prepositionTo
    __typename
  }
`;
export const FaqItem = gql`
  fragment FaqItem on FaqItem {
    sys {
      id
    }
    name
    title
    body {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
            ... on TextSectionV2 {
              __typename
              name
            }
          }
        }
      }
    }
    countries: countriesCollection {
      items {
        ... on Country {
          sys {
            id
          }
          __typename
        }
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
`;
export const FaqTopic = gql`
  fragment FaqTopic on FaqTopic {
    sys {
      id
    }
    name
    title
    subtitle
    description
    icon {
      ...CoreImage
    }
    faqItems: faqItemsCollection {
      items {
        ... on FaqItem {
          sys {
            id
          }
          __typename
        }
      }
    }
  }
  ${CoreImage}
`;
export const FaqPopularQuestions = gql`
  fragment FaqPopularQuestions on FaqPopularQuestions {
    sys {
      id
    }
    name
    title
    questions: questionsCollection {
      items {
        sys {
          id
        }
        name
        title
        body {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                contentType
                ...CoreImage
              }
            }
            entries {
              block {
                sys {
                  id
                }
                ...Link
                ...IconWithText
              }
              inline {
                sys {
                  id
                }
                ...Link
                ...IconWithText
              }
              hyperlink {
                sys {
                  id
                }
                ...Link
                ... on TextSectionV2 {
                  __typename
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
`;
export const FaqSection = gql`
  fragment FaqSection on FaqSection {
    sys {
      id
    }
    name
    __typename
    title
    clearText
    resultsForText
    emptySearchText
    faqTopicsCollection {
      items {
        ... on FaqTopic {
          sys {
            id
          }
          __typename
        }
      }
    }
    popularQuestions {
      ...FaqPopularQuestions
    }
  }
  ${FaqPopularQuestions}
`;
export const LegalMessaging = gql`
  fragment LegalMessaging on LegalMessaging {
    sys {
      id
    }
    __typename
    name
    content {
      json
    }
  }
`;
export const FullWidthBanner = gql`
  fragment FullWidthBanner on FullWidthBanner {
    sys {
      id
    }
    __typename
    name
    backgroundImage {
      ...CoreImage
    }
    backgroundImageMobile {
      ...CoreImage
    }
    backgroundColor
    secondBackgroundColor
    image {
      ...CoreImage
    }
    text {
      json
    }
    link {
      ...Link
    }
    textColor
    textAlign
    borderColor
    secondBorderColor
    contentWidth
  }
  ${CoreImage}
  ${Link}
`;
export const LinkGroup = gql`
  fragment LinkGroup on LinkGroup {
    sys {
      id
    }
    __typename
    name
    label
    linksCollection {
      items {
        url
        name
        gaTrackName
        label
      }
    }
  }
`;
export const HeaderSw = gql`
  fragment HeaderSw on LayoutHeader {
    name
    menuLabel
    welcome
    lastLogin
    myAccount
    notLoggedInMenuItemsCollection(limit: 30) {
      items {
        ...Link
        ...LinkGroup
      }
    }
    loggedInMenuItemsCollection(limit: 30) {
      items {
        ...Link
        ...LinkGroup
      }
    }
    userDropdownMenuItemsCollection(limit: 30) {
      items {
        ...Link
      }
    }
    referFriendLink {
      ...Link
    }
    logoLight {
      ...CoreImage
    }
    logoLightMobile {
      ...CoreImage
    }
    logoDark {
      ...CoreImage
    }
    logoDarkMobile {
      ...CoreImage
    }
    mobileAppLeadingLinks {
      mobileAppButtonText
      qrCode {
        imageUrl
        description
      }
    }
  }
  ${Link}
  ${LinkGroup}
  ${CoreImage}
`;
export const HeroTextVariation = gql`
  fragment HeroTextVariation on HeroTextVariation {
    sys {
      id
    }
    name
    heading
    description
    textColor
    backgroundColor
    backgroundImage {
      ...CoreImage
    }
    bottomWaveImage {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const ExchangeCalculator = gql`
  fragment ExchangeCalculator on ExchangeCalculator {
    sys {
      id
    }
    name
    heading
    title
    description
    sendLabel
    receiveLabel
    limitReachedError
    exchangeRate
    mobile {
      url
    }
    bank {
      url
    }
    cash {
      url
    }
  }
`;
export const TrustpilotWidget = gql`
  fragment TrustpilotWidget on TrustpilotWidget {
    sys {
      id
    }
    __typename
    name
    url
  }
`;
export const BetterWayTopSw = gql`
  fragment BetterWayTopSw on BetterWayTop {
    sys {
      id
    }
    __typename
    name
    headingColor
    backgroundColor
    secondBackgroundColor
    hideDescriptionOnMobile
    rightModule {
      ...ExchangeCalculator
    }
    headingRichText: heading {
      json
    }
    subHeadingRichText: subheading {
      json
    }
    link {
      ...Link
    }
    trustpilotMessage
    trustpilotLogo {
      ...CoreImage
    }
    trustpilotLink {
      ...Link
    }
    foregroundImage: foregroundImage {
      ...CoreImage
    }
    backgroundImage: backgroundImage {
      ...CoreImage
    }
    backgroundImageMobile: backgroundImageMobile {
      ...CoreImage
    }
    bottomWaveImage: bottomWaveImage {
      ...CoreImage
    }
    calculatorPage {
      slug
    }
    trustpilotWidget {
      ...TrustpilotWidget
    }
    qrCode {
      imageUrl
      description
    }
    appStoreIconLink {
      url
      openInNewTab
      isInternal
      image {
        ...CoreImage
      }
    }
    playStoreIconLink {
      url
      openInNewTab
      isInternal
      image {
        ...CoreImage
      }
    }
  }
  ${ExchangeCalculator}
  ${Link}
  ${CoreImage}
  ${TrustpilotWidget}
`;
export const ImageGridItem = gql`
  fragment ImageGridItem on ImageGridItem {
    sys {
      id
    }
    __typename
    name
    image {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const ImageGrid = gql`
  fragment ImageGrid on ImageGrid {
    sys {
      id
    }
    __typename
    name
    heading
    content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    gridColumnSize
    gridItems: gridItemsCollection {
      items {
        ...ImageGridItem
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
  ${ImageGridItem}
`;
export const TextCard = gql`
  fragment TextCard on TextCard {
    sys {
      id
      publishedAt
    }
    __typename
    name
    content {
      json
      links {
        entries {
          block {
            sys {
              id
            }
            ...Link
          }
          inline {
            sys {
              id
            }
            ...Link
          }
          hyperlink {
            sys {
              id
            }
            ...Link
          }
        }
      }
    }
    link {
      ...Link
    }
    contentAlignment
    backgroundColor
    textColor
    icon {
      ...CoreImage
    }
  }
  ${Link}
  ${CoreImage}
`;
export const InfoCards = gql`
  fragment InfoCards on InfoCards {
    sys {
      id
    }
    __typename
    name
    header
    cards: cardsCollection {
      items {
        ... on TextCard {
          sys {
            id
          }
          __typename
        }
      }
    }
    backgroundColor
  }
`;
export const LeadershipTeam = gql`
  fragment LeadershipTeam on LeadershipTeam {
    sys {
      id
    }
    name
    title
    description
    teamMembers: teamMembersCollection {
      items {
        sys {
          id
        }
        name
        jobTitle
        image {
          ...CoreImage
        }
      }
    }
  }
  ${CoreImage}
`;
export const ListOfCountries = gql`
  fragment ListOfCountries on ListOfCountries {
    sys {
      id
    }
    __typename
    name
    header
    subheader
    countriesCollection {
      items {
        name
        code
        slug
      }
    }
    buttonLabelExpandedList
    buttonLabelHiddenList
  }
`;
export const Messages = gql`
  fragment Messages on Messages {
    chooseYourRegion
    countries
    international
    all
  }
`;
export const FaqTopicsModule = gql`
  fragment FaqTopicsModule on FaqTopicsModule {
    sys {
      id
    }
    name
    title
    backgroundColor
    nestedAccordionItems: topicsCollection {
      items {
        ... on FaqTopicsModuleItems {
          sys {
            id
          }
          __typename
        }
      }
    }
    popularQuestions {
      ...FaqPopularQuestions
    }
  }
  ${FaqPopularQuestions}
`;
export const FaqTopicsModuleItems = gql`
  fragment FaqTopicsModuleItems on FaqTopicsModuleItems {
    sys {
      id
    }
    __typename
    title
    description
    icon {
      url
    }
    subtitles: subtitleCollection {
      items {
        ... on FaqTitleAndDescription {
          sys {
            id
          }
          __typename
        }
      }
    }
    nestedAccordionLinks: topicLinksCollection {
      items {
        sys {
          id
        }
        name
        __typename
        slug
        title
      }
    }
  }
`;
export const NotFoundContent = gql`
  fragment NotFoundContent on NotFoundContent {
    sys {
      id
    }
    __typename
    name
    message {
      json
    }
    buttonsCollection {
      items {
        ...Link
      }
    }
  }
  ${Link}
`;
export const ReviewsCard = gql`
  fragment ReviewsCard on ReviewsCard {
    sys {
      id
    }
    __typename
    name
    title
    description
    image {
      ...CoreImage
    }
    ratings
    country {
      name
      code
    }
  }
  ${CoreImage}
`;
export const ReviewsSection = gql`
  fragment ReviewsSection on ReviewsSection {
    sys {
      id
    }
    name
    header
    backgroundImage {
      ...CoreImage
    }
    reviewsCardCollection {
      items {
        ...ReviewsCard
      }
    }
    trustpilotWidget {
      ...TrustpilotWidget
    }
  }
  ${CoreImage}
  ${ReviewsCard}
  ${TrustpilotWidget}
`;
export const Sitemap = gql`
  fragment Sitemap on Sitemap {
    sys {
      id
    }
    __typename
    name
  }
`;
export const Step = gql`
  fragment Step on Step {
    sys {
      id
    }
    name
    header
    description {
      json
    }
    image {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const StepByStep = gql`
  fragment StepByStep on StepByStep {
    sys {
      id
    }
    name
    header
    stepsCollection {
      items {
        ... on Step {
          sys {
            id
          }
          __typename
        }
      }
    }
    link {
      ...Link
    }
  }
  ${Link}
`;
export const TextSectionV2 = gql`
  fragment TextSectionV2 on TextSectionV2 {
    sys {
      id
    }
    name
    __typename
    content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...TextWithColor
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
            ... on TextSectionV2 {
              __typename
              name
            }
          }
        }
      }
    }
    imageCollection {
      items {
        ...CoreImage
      }
    }
    imageMobile {
      ...CoreImage
    }
    maxWidth
    textColumnSize
    imageColumnSize
    backgroundColor
    imagePosition
    imagePositionMobile
    imageAlignment
    columnAlignment
    contentAlign
  }
  ${CoreImage}
  ${Link}
  ${TextWithColor}
  ${IconWithText}
`;
export const TextSection = gql`
  fragment TextSection on TextSection {
    sys {
      id
    }
    name
    __typename
    content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
            ... on TextSection {
              __typename
              name
            }
          }
        }
      }
    }
    imageCollection {
      items {
        ...CoreImage
      }
    }
    maxWidth
    backgroundColor
    isOrderReversed
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
`;
export const Video = gql`
  fragment Video on Video {
    sys {
      id
    }
    name
    __typename
    videoId
    content {
      json
    }
    backgroundImage {
      ...CoreImage
    }
  }
  ${CoreImage}
`;
export const WebForm = gql`
  fragment WebForm on WebForm {
    sys {
      id
    }
    __typename
    name
    header
    description {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            contentType
            ...CoreImage
          }
        }
        entries {
          block {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          inline {
            sys {
              id
            }
            ...Link
            ...IconWithText
          }
          hyperlink {
            sys {
              id
            }
            ...Link
            ... on TextSectionV2 {
              __typename
              name
            }
          }
        }
      }
    }
    disclaimer
    submitButtonLabel
    successPage {
      slug
    }
    jsonSchema
    uiSchema
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
`;
export const SeoOrganizationStructuredData = gql`
  fragment SeoOrganizationStructuredData on SeoOrganizationStructuredData {
    addressCountry
    addressLocality
    description
    email
    image {
      ...Asset
      url(transform: { width: 500 })
    }
    name
    postalCode
    sameAsCollection {
      items {
        value
      }
    }
    contactPointCollection {
      items {
        telephone
        areaServed
        contactType
      }
    }
    streetAddress
    url
  }
  ${Asset}
`;
export const FooterNavigationGroupExtras = gql`
  fragment FooterNavigationGroupExtras on FooterNavigationGroupExtras {
    backgroundColor
    color
    title
  }
`;
export const FooterNavigationGroup = gql`
  fragment FooterNavigationGroup on FooterNavigationGroup {
    title
    linksCollection {
      items {
        ...Link
      }
    }
    extras {
      ...FooterNavigationGroupExtras
    }
  }
  ${Link}
  ${FooterNavigationGroupExtras}
`;
export const Footer = gql`
  fragment Footer on LayoutFooter {
    __typename
    name
    sys {
      id
    }
    copyrightText
    socialIconsLinksCollection(limit: 10) {
      items {
        ...Link
      }
    }
    navigationCollection(limit: 30) {
      items {
        ...FooterNavigationGroup
      }
    }
    footerLogo {
      ...Asset
      url(transform: { width: 160 })
    }
  }
  ${Link}
  ${FooterNavigationGroup}
  ${Asset}
`;
export const Page = gql`
  fragment Page on Page {
    sys {
      id
    }
    __typename
    name
    slug
    title
    description
    noIndex
    regions
    headerPosition
    headerTheme
    analyticsPageName
    analyticsPageType
    ogImage {
      url(transform: { width: 500 })
    }
    modulesCollection {
      items {
        ... on Entry {
          __typename
          sys {
            id
          }
        }
      }
    }
    footer {
      ...Footer
    }
  }
  ${Footer}
`;
export const PageSitemapLink = gql`
  fragment PageSitemapLink on Page {
    slug
    title
    heading
    name
    analyticsPageType
    sys {
      publishedAt
    }
  }
`;
export const AccordionQuery = gql`
  query AccordionQuery($id: String!, $locale: String!, $preview: Boolean) {
    Accordion: accordionCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...Accordion
      }
    }
  }
  ${Accordion}
`;
export const AppBannerSectionQuery = gql`
  query AppBannerSectionQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    AppBannerSection: appBannerSectionCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...AppBannerSection
      }
    }
  }
  ${AppBannerSection}
`;
export const AppBannerQuery = gql`
  query AppBannerQuery($id: String!, $locale: String!, $preview: Boolean) {
    AppBanner: appBanner(id: $id, locale: $locale, preview: $preview) {
      ...AppBanner
    }
  }
  ${AppBanner}
`;
export const BodyTextQuery = gql`
  query BodyTextQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    BodyText: bodyTextCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...BodyText
      }
    }
  }
  ${BodyText}
`;
export const CardsQuery = gql`
  query CardsQuery($id: String!, $locale: String!, $preview: Boolean) {
    Cards: cardsCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...Cards
      }
    }
  }
  ${Cards}
`;
export const CardQuery = gql`
  query CardQuery($id: String!, $locale: String!, $preview: Boolean) {
    Card: cardCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...Card
      }
    }
  }
  ${Card}
`;
export const ContainedBannerQuery = gql`
  query ContainedBannerQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    ContainedBanner: containedBanner(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...ContainedBanner
    }
  }
  ${ContainedBanner}
`;
export const CountriesGridQuery = gql`
  query CountriesGridQuery($id: String!, $locale: String!, $preview: Boolean) {
    CountriesGrid: countriesGrid(id: $id, locale: $locale, preview: $preview) {
      ...CountriesGrid
    }
  }
  ${CountriesGrid}
`;
export const FaqHeroQuery = gql`
  query FaqHeroQuery($id: String!, $locale: String!, $preview: Boolean) {
    FaqHero: faqHero(id: $id, locale: $locale, preview: $preview) {
      ...FaqHero
    }
  }
  ${FaqHero}
`;
export const FaqModuleItemQuery = gql`
  query FaqModuleItemQuery($id: String!, $locale: String!, $preview: Boolean) {
    FaqModuleItem: faqModuleItemCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqModuleItem
      }
    }
  }
  ${FaqModuleItem}
`;
export const FaqModuleQuery = gql`
  query FaqModuleQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    FaqModule: faqModuleCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...FaqModule
      }
    }
  }
  ${FaqModule}
`;
export const FaqPopularQuestionsQuery = gql`
  query FaqPopularQuestionsQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    FaqPopularQuestions: faqPopularQuestions(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...FaqPopularQuestions
    }
  }
  ${FaqPopularQuestions}
`;
export const FaqTitleAndDescriptionQuery = gql`
  query FaqTitleAndDescriptionQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    FaqTitleAndDescription: faqTitleAndDescriptionCollection(
      locale: $locale
      limit: 2
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqTitleAndDescription
      }
    }
  }
  ${FaqTitleAndDescription}
`;
export const CountryQuery = gql`
  query CountryQuery($id: String!, $locale: String!, $preview: Boolean) {
    Country: countryCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...Country
      }
    }
  }
  ${Country}
`;
export const FaqItemQuery = gql`
  query FaqItemQuery($id: String!, $locale: String!, $preview: Boolean) {
    FaqItem: faqItemCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqItem
      }
    }
  }
  ${FaqItem}
`;
export const FaqTopicQuery = gql`
  query FaqTopicQuery($id: String!, $locale: String!, $preview: Boolean) {
    FaqTopic: faqTopicCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqTopic
      }
    }
  }
  ${FaqTopic}
`;
export const FaqSectionQuery = gql`
  query FaqSectionQuery($id: String!, $locale: String!, $preview: Boolean) {
    FaqSection: faqSectionCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqSection
      }
    }
  }
  ${FaqSection}
`;
export const FullWidthBannerQuery = gql`
  query FullWidthBannerQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    FullWidthBanner: fullWidthBannerCollection(
      locale: $locale
      preview: $preview
      limit: 1
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...FullWidthBanner
      }
    }
  }
  ${FullWidthBanner}
`;
export const HeroTextVariationQuery = gql`
  query HeroTextVariationQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    HeroTextVariation: heroTextVariation(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...HeroTextVariation
    }
  }
  ${HeroTextVariation}
`;
export const BetterWayTopQuery = gql`
  query BetterWayTopQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    BetterWayTop: betterWayTopCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...BetterWayTopSw
      }
    }
  }
  ${BetterWayTopSw}
`;
export const ImageGridQuery = gql`
  query ImageGridQuery($locale: String!, $preview: Boolean) {
    ImageGrid: imageGridCollection(
      locale: $locale
      limit: 1
      preview: $preview
    ) {
      items {
        ...ImageGrid
      }
    }
  }
  ${ImageGrid}
`;
export const ImageGridItemQuery = gql`
  query ImageGridItemQuery($locale: String!, $preview: Boolean) {
    ImageGridItem: imageGridItemCollection(
      locale: $locale
      limit: 1
      preview: $preview
    ) {
      items {
        ...ImageGridItem
      }
    }
  }
  ${ImageGridItem}
`;
export const TextCardQuery = gql`
  query TextCardQuery($id: String!, $locale: String!, $preview: Boolean) {
    TextCard: textCardCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...TextCard
      }
    }
  }
  ${TextCard}
`;
export const InfoCardsQuery = gql`
  query InfoCardsQuery($id: String!, $locale: String!, $preview: Boolean) {
    InfoCards: infoCardsCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...InfoCards
      }
    }
  }
  ${InfoCards}
`;
export const LeadershipTeamQuery = gql`
  query LeadershipTeamQuery($id: String!, $locale: String!, $preview: Boolean) {
    LeadershipTeam: leadershipTeam(
      id: $id
      locale: $locale
      preview: $preview
    ) {
      ...LeadershipTeam
    }
  }
  ${LeadershipTeam}
`;
export const LinkGroupQuery = gql`
  query LinkGroupQuery($id: String!, $locale: String!, $preview: Boolean) {
    LinkGroup: linkGroup(id: $id, locale: $locale, preview: $preview) {
      ...LinkGroup
    }
  }
  ${LinkGroup}
`;
export const LinkQuery = gql`
  query LinkQuery($id: String!, $locale: String!, $preview: Boolean) {
    Link: link(id: $id, locale: $locale, preview: $preview) {
      ...Link
    }
  }
  ${Link}
`;
export const ListOfCountriesQuery = gql`
  query ListOfCountriesQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    ListOfCountries: listOfCountriesCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...ListOfCountries
      }
    }
  }
  ${ListOfCountries}
`;
export const FaqTopicsModuleQuery = gql`
  query FaqTopicsModuleQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    FaqTopicsModule: faqTopicsModuleCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqTopicsModule
      }
    }
  }
  ${FaqTopicsModule}
`;
export const FaqTopicsModuleItemsQuery = gql`
  query FaqTopicsModuleItemsQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    FaqTopicsModuleItems: faqTopicsModuleItemsCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...FaqTopicsModuleItems
      }
    }
  }
  ${FaqTopicsModuleItems}
`;
export const NotFoundContentQuery = gql`
  query NotFoundContentQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
  ) {
    NotFoundContent: notFoundContentCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...NotFoundContent
      }
    }
  }
  ${NotFoundContent}
`;
export const ReviewsSectionQuery = gql`
  query ReviewsSectionQuery($id: String!, $locale: String!, $preview: Boolean) {
    ReviewsSection: reviewsSectionCollection(
      where: { sys: { id: $id } }
      locale: $locale
      limit: 1
      preview: $preview
    ) {
      items {
        ...ReviewsSection
      }
    }
  }
  ${ReviewsSection}
`;
export const ReviewsCardQuery = gql`
  query ReviewsCardQuery($id: String!, $locale: String!, $preview: Boolean) {
    ReviewsCardQuery: reviewsCardCollection(
      where: { sys: { id: $id } }
      locale: $locale
      limit: 1
      preview: $preview
    ) {
      items {
        ...ReviewsCard
      }
    }
  }
  ${ReviewsCard}
`;
export const ReviewsCardByReceiveCountry = gql`
  query ReviewsCardByReceiveCountry(
    $locale: String!
    $preview: Boolean
    $receiveCountry: String!
  ) {
    ReviewsCardByReceiveCountry: reviewsCardCollection(
      where: { country: { code: $receiveCountry } }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...ReviewsCard
      }
    }
  }
  ${ReviewsCard}
`;
export const SitemapQuery = gql`
  query SitemapQuery($id: String!, $locale: String!, $preview: Boolean) {
    Sitemap: sitemap(id: $id, locale: $locale, preview: $preview) {
      ...Sitemap
    }
  }
  ${Sitemap}
`;
export const StepByStepQuery = gql`
  query StepByStepQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    StepByStep: stepByStepCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...StepByStep
      }
    }
  }
  ${StepByStep}
`;
export const StepQuery = gql`
  query StepQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    Step: stepCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...Step
      }
    }
  }
  ${Step}
`;
export const TextSectionV2Query = gql`
  query TextSectionV2Query(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    TextSectionV2: textSectionV2Collection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...TextSectionV2
      }
    }
  }
  ${TextSectionV2}
`;
export const TextSectionQuery = gql`
  query TextSectionQuery(
    $id: String!
    $locale: String!
    $preview: Boolean
    $sendCountryCode: String
    $receiveCountryCode: String
  ) {
    TextSection: textSectionCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        sys: { id: $id }
        AND: [
          {
            OR: [
              { sendCountryList_exists: false }
              {
                allSendCountriesExcept_exists: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: false
                sendCountryList_contains_some: [$sendCountryCode]
              }
              {
                allSendCountriesExcept: true
                sendCountryList_contains_none: [$sendCountryCode]
              }
            ]
          }
          {
            OR: [
              { receiveCountryList_exists: false }
              {
                allReceiveCountriesExcept_exists: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: false
                receiveCountryList_contains_some: [$receiveCountryCode]
              }
              {
                allReceiveCountriesExcept: true
                receiveCountryList_contains_none: [$receiveCountryCode]
              }
            ]
          }
        ]
      }
    ) {
      items {
        ...TextSection
      }
    }
  }
  ${TextSection}
`;
export const TrustpilotWidgetQuery = gql`
  query TrustpilotWidgetQuery($locale: String!, $preview: Boolean) {
    TrustpilotWidget: trustpilotWidgetCollection(
      locale: $locale
      limit: 1
      preview: $preview
    ) {
      items {
        ...TrustpilotWidget
      }
    }
  }
  ${TrustpilotWidget}
`;
export const VideoQuery = gql`
  query VideoQuery($id: String!, $locale: String!, $preview: Boolean) {
    Video: video(id: $id, locale: $locale, preview: $preview) {
      ...Video
    }
  }
  ${Video}
`;
export const WebFormQuery = gql`
  query WebFormQuery($id: String!, $locale: String!, $preview: Boolean) {
    WebForm: webFormCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: { sys: { id: $id } }
    ) {
      items {
        ...WebForm
      }
    }
  }
  ${WebForm}
`;
export const GetCountries = gql`
  query getCountries {
    countryCollection(limit: 200) {
      items {
        code
        slug
        name
        calculatorReceiveCountry {
          code
        }
      }
    }
  }
`;
export const CountryArticlesAndPrepositions = gql`
  query CountryArticlesAndPrepositions(
    $countryCodes: [String]
    $locale: String!
    $preview: Boolean!
  ) {
    countryCollection(
      where: { code_in: $countryCodes }
      locale: $locale
      preview: $preview
      limit: 200
    ) {
      items {
        name
        slug
        code
        article
        prepositionFrom
        prepositionTo
        prepositionIn
      }
    }
  }
`;
export const SearchFaqItems = gql`
  query SearchFaqItems(
    $searchTerm: String!
    $locale: String!
    $preview: Boolean
  ) {
    faqItemCollection(
      locale: $locale
      preview: $preview
      limit: 10
      where: {
        OR: [{ body_contains: $searchTerm }, { title_contains: $searchTerm }]
      }
    ) {
      items {
        sys {
          id
        }
        name
        title
        body {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                contentType
                ...CoreImage
              }
            }
            entries {
              block {
                sys {
                  id
                }
                ...Link
                ...IconWithText
              }
              inline {
                sys {
                  id
                }
                ...Link
                ...IconWithText
              }
              hyperlink {
                sys {
                  id
                }
                ...Link
                ... on TextSectionV2 {
                  __typename
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${CoreImage}
  ${Link}
  ${IconWithText}
`;
export const FeatureFlags = gql`
  query FeatureFlags($preview: Boolean!) {
    featureFlagCollection(preview: $preview) {
      items {
        name
        value
      }
    }
  }
`;
export const GenericErrorPage = gql`
  query GenericErrorPage($locale: String!, $preview: Boolean) {
    genericErrorPageCollection(locale: $locale, limit: 1, preview: $preview) {
      items {
        header
        name
        description {
          json
        }
        subDescription {
          json
        }
      }
    }
  }
`;
export const OrganizationSchema = gql`
  query OrganizationSchema($locale: String!, $preview: Boolean) {
    seoOrganizationStructuredDataCollection(
      preview: $preview
      locale: $locale
      limit: 1
    ) {
      items {
        ...SeoOrganizationStructuredData
      }
    }
  }
  ${SeoOrganizationStructuredData}
`;
export const HomePage = gql`
  query HomePage($locale: String!, $region: String, $preview: Boolean) {
    pageCollection(
      locale: $locale
      where: {
        OR: [
          { regions_contains_some: [$locale] }
          { regions_contains_some: [$region] }
        ]
        slug_exists: false
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...Page
      }
    }
  }
  ${Page}
`;
export const PageBySlug = gql`
  query PageBySlug($slug: String, $locale: String!, $preview: Boolean) {
    pageCollection(
      locale: $locale
      where: { regions_contains_some: [$locale], slug: $slug }
      limit: 1
      preview: $preview
    ) {
      items {
        ...Page
      }
    }
  }
  ${Page}
`;
export const PageData = gql`
  query PageData(
    $locale: String!
    $sendCountryCode: String!
    $paths: [String]
    $preview: Boolean
  ) {
    layoutHeaderCollection(locale: $locale, preview: $preview, limit: 1) {
      items {
        ...HeaderSw
      }
    }
    payoutMethodCollection(locale: $locale, preview: $preview, limit: 10) {
      items {
        name
        payoutMethodId
      }
    }
    messagesCollection(locale: $locale, preview: $preview, limit: 1) {
      items {
        ...Messages
      }
    }
    legalMessagingCollection(
      locale: $locale
      limit: 1
      preview: $preview
      where: {
        OR: [
          { sendCountryList_exists: false }
          {
            allSendCountriesExcept_exists: false
            sendCountryList_contains_some: [$sendCountryCode]
          }
          {
            allSendCountriesExcept: false
            sendCountryList_contains_some: [$sendCountryCode]
          }
          {
            allSendCountriesExcept: true
            sendCountryList_contains_none: [$sendCountryCode]
          }
        ]
      }
    ) {
      items {
        ...LegalMessaging
      }
    }
    breadcrumbs: pageCollection(
      preview: $preview
      locale: $locale
      where: { OR: [{ slug_exists: false }, { slug_in: $paths }] }
    ) {
      items {
        name
        heading
        slug
      }
    }
    seoOrganizationStructuredDataCollection(
      preview: $preview
      locale: $locale
      limit: 1
    ) {
      items {
        ...SeoOrganizationStructuredData
      }
    }
  }
  ${HeaderSw}
  ${Messages}
  ${LegalMessaging}
  ${SeoOrganizationStructuredData}
`;
export const CountryPagesSlugs = gql`
  query CountryPagesSlugs($locale: String!, $analyticsPageType: String!) {
    pageCollection(
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
      }
    ) {
      items {
        slug
      }
    }
  }
`;
export const PagesSitemapLinks = gql`
  query PagesSitemapLinks(
    $locale: String!
    $preview: Boolean
    $skip: Int
    $excludedSlugs: [String]
  ) {
    pageCollection(
      where: {
        noIndex_not: true
        regions_contains_some: [$locale]
        slug_not_in: $excludedSlugs
      }
      preview: $preview
      locale: $locale
      limit: 1000
      skip: $skip
    ) {
      items {
        ...PageSitemapLink
      }
    }
  }
  ${PageSitemapLink}
`;
export const BlogPagesCards = gql`
  query BlogPagesCards(
    $analyticsPageType: String!
    $locale: String!
    $preview: Boolean
    $tags: [String]!
    $title: String
    $shouldIncludeSearchOptions: Boolean!
    $skip: Int
    $limit: Int
  ) {
    searchOptions: pageCollection(
      preview: $preview
      locale: $locale
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: {
          tags_exists: true
          tags: { id_contains_all: $tags }
        }
        title_contains: $title
      }
    ) @include(if: $shouldIncludeSearchOptions) {
      items {
        name
        slug
      }
    }
    cards: pageCollection(
      preview: $preview
      locale: $locale
      skip: $skip
      limit: $limit
      order: publishedDate_DESC
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: {
          tags_exists: true
          tags: { id_contains_all: $tags }
        }
        title_contains: $title
        publishedDate_exists: true
      }
    ) {
      total
      items {
        contentfulMetadata {
          tags {
            id
            name
          }
        }
        name
        slug
        banner {
          title
          alt: description
          url(transform: { width: 1200 })
        }
        publishedDate
      }
    }
  }
`;
export const BlogPagesTags = gql`
  query BlogPagesTags(
    $analyticsPageType: String!
    $locale: String!
    $preview: Boolean
  ) {
    pageCollection(
      preview: $preview
      locale: $locale
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: { tags_exists: true }
      }
    ) {
      items {
        contentfulMetadata {
          tags {
            id
            name
          }
        }
      }
    }
  }
`;
export const Redirects = gql`
  query Redirects($limit: Int) {
    redirectCollection(limit: $limit) {
      items {
        source
        destination
        permanent
        locale
      }
    }
  }
`;
export const RegionCode = gql`
  query RegionCode($limit: Int) {
    regionCodeCollection(limit: $limit) {
      items {
        name
        frCa
        esUs
        enGb
        enUs
        enNz
        enSe
        enMy
        enAu
        enCa
        daDk
        en
      }
    }
  }
`;
export const RobotsConfiguration = gql`
  query RobotsConfiguration($locale: String!, $preview: Boolean) {
    robotsConfigurationCollection(
      preview: $preview
      locale: $locale
      limit: 1
    ) {
      items {
        allow
        disallow
        userAgent
        blogSitemapUrl
      }
    }
  }
`;
export const WebFormJsonSchema = gql`
  query WebFormJsonSchema($id: String!) {
    WebForm: webForm(id: $id, preview: false) {
      name
      jsonSchema
    }
  }
`;
export const PagesWithTags = gql`
  query PagesWithTags(
    $analyticsPageType: String!
    $locale: String!
    $preview: Boolean
  ) {
    pageCollection(
      preview: $preview
      locale: $locale
      where: {
        regions_contains_some: [$locale]
        analyticsPageType: $analyticsPageType
        contentfulMetadata: { tags_exists: true }
      }
      limit: 1000
    ) {
      items {
        name
        slug
        contentfulMetadata {
          tags {
            name
          }
        }
      }
    }
  }
`;
